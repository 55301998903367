<template>
  <div v-observe-visibility="visibilityChanged" class="pod tile is-parent is-narrow">
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold">Inventory - Pre-Entry ({{total}})
        <i class="fas fa-ellipsis-v icon-more"></i>
        <i style="display: inline-block;" class="fas fa-sync" :class="{'fa-spin': isLoading}" @click="onPodRefreshRequired"></i>
      </h4>
      <div class="pod-content scrollbar">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th class="is-uppercase no-wrap-table-cell" :class="{'is-current-sort': sortField === column.id}" v-for="column in inventoryData.columns.filter((x) => x.visible)" :key="column.name" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                {{column.name}}
                <span class="icon sort-icon"><i class="mdi" :class="{'mdi-arrow-up': sortOrder === 'asc', 'mdi-arrow-down': sortOrder === 'desc'}" v-if="sortField === column.id"></i></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable" v-for="(item, index) in inventoryData.data" :key="item.id" @click="onItemClick(item)">
              <td v-for="column in inventoryData.columns.filter((x) => x.visible)" :key="column.name + index.toString()" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                <template v-if="column.format && column.format === 'currency'">{{item[column.id] | currency('$', 0)}}</template>
                <template v-else-if="column.format && column.format === 'datetime'">{{item[column.id] | formatDateOnly}}</template>
                <template v-else-if="column.name && column.name === 'DSP'" :style="getDspAgingStyle(item)"><i class="fal fa-hourglass-end fa-sm aging-icon" :style="getDspAgingStyle(item)"/>{{item.daysSincePurchase}}</template>
                <template v-else>{{item[column.id]}}</template>
              </td>
            </tr>
            <tr v-if="this.inventoryData.data.length === 0" class="subtitle is-7">
              <td style="text-align:center; height:100px;vertical-align:middle;" :colspan="inventoryData.columns.length">No data available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</template>
<script>

import eventBus from '@/eventBus'
import formattingMixin from '@/mixins/inventory/formatting'
// import axios from 'axios'
import inventoryService from '@/services/inventoryService'
import CaptureArrivalStatus from '@/components/generic/inventory/ArrivalCapture'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'InventoryPreEntryPod',
  mixins: [formattingMixin],
  data () {
    return {
      total: 0,
      isLoading: false,
      page: 0,
      sortField: 'stockNo',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      serverErrorMessage: '',
      perPage: this.$store.state.config.defaultPageSize,
      inventoryData: {
        columns: [
          {
            id: 'id',
            name: 'Id',
            visible: false
          },
          {
            id: 'stockNo',
            name: 'Stock No',
            visible: true,
            maxWidth: '80px'
          },
          {
            id: 'vinNo',
            name: 'Vin No.',
            visible: true,
            maxWidth: '130px'
          },
          {
            id: 'name',
            name: 'Name',
            visible: true
          },
          {
            id: 'type',
            name: 'Type',
            visible: false
          },
          {
            id: 'daysSincePurchase',
            name: 'DSP',
            visible: !this.$isMobile,
            maxWidth: '55px'
          }
        ],
        data: []
      },
      selectedInventory: null
    }
  },
  components: {
    'arrival-capture': CaptureArrivalStatus
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentUser', 'currentPendingActions', 'currentNotifications']),
    ...mapDealerState(['currentDealer'])
  },
  methods: {
    onItemClick: function (item) {
      this.selectedInventory = item
      eventBus.$emit('pod-item-highlight', {componentId: this._uid})
      if (this.hasFeatureAccess('inventory.view')) {
        this.$router.push({name: 'InventoryQuickView', params: { id: item.id, isServiceRequestPod: false, isLoanerPod: false, offerPod: -1 }})
      }
    },
    onPodRefreshRequired: function () {
      this.loadPreEntryInventoryData()
    },
    loadPreEntryInventoryData: function () {
      const searchCriteria = {
        pageSize: 32767,
        page: 0,
        sortField: 'stockNo',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
        typeId: [-1],
        showServiceLoaners: false,
        showPriorityNotes: false,
        showDamaged: false,
        showFleet: false,
        showDemo: false,
        showCancelledOnly: false,
        showFullVins: false,
        showAll: false,
        agingIndex: 0,
        showInStockOnly: true,
        dealerId: this.currentDealer.id,
        costIndex: 0,
        intIndex: 0,
        extIndex: 0,
        showCost: false,
        showColors: false,
        tabIndex: 0,
        query: '',
        showUnGrouped: false,
        showPreEntry: true,
        sortBy: this.sortField + '.' + this.sortOrder
      }

      this.isLoading = true
      inventoryService.all(searchCriteria).then(response => {
        this.inventoryData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          this.inventoryData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.inventoryData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        if (this.currentDealer.id) {
          this.loadPreEntryInventoryData()
        }
      }
    },
    itemHighLightCheck: function (options) {
      let componentId = options.componentId
      if (this._uid !== componentId) {
        this.selectedInventory = null
      }
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.loadPreEntryInventoryData()
      }
    }
  },
  beforeDestroy () {
    // axios.cancel('inventory-pending-arrival')
    eventBus.$off('pod-item-highlight', this.itemHighLightCheck)
  },
  mounted: function () {
    eventBus.$on('pod-item-highlight', this.itemHighLightCheck)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .no-wrap-table-cell {
    white-space: nowrap;
  }

  .sort-icon {
    height:auto !important;
  }

  .is-current-sort {
    color: rgb(0, 177, 31) !important;
  }
</style>
