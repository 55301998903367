<template>
  <div>
    <div class="tags">
      <span class="tag" v-for="tag in data" :key="tag.id" v-tooltip="{
        content: getTagDescription(tag),
        classes: ['info']
      }"><a @click.stop.prevent='copyTagDesc(tag.description)'>{{ tag.name }}</a></span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TagView',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      data: []
    }
  },
  methods: {
    getTagDescription (tag) {
      return tag.description
    },
    copyTagDesc: function (data) {
      let that = this
      if (data && data !== '') {
        this.$copyText(data).then(function (e) {
          that.$buefy.toast.open(
            {
              duration: 2500,
              message: 'Description copied to clipboard',
              position: 'is-top',
              type: 'is-info'
            })
        }, function (e) {
          that.$buefy.toast.open(
            {
              duration: 2500,
              message: 'Could not copy description to clipboard',
              position: 'is-top',
              type: 'is-warning'
            })
        })
      } else {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: 'No description to copy',
            position: 'is-top',
            type: 'is-warning'
          })
      }
    }
  },
  computed: {
  },
  watch: {
    value: function (newValue, oldValue) {
      this.data = newValue
    }
  },
  mounted: function () {
    this.data = this.value
  }
}
</script>

<style scoped>
  .column {
    padding-bottom:0.3em
  }
</style>
