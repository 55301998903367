<template>
  <portal to="global-modal-portal" :disabled="!usePortal" v-if="value.isAddingCost">
    <b-modal :active.sync="value.isAddingCost" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="min-width: 640px; max-height: 90vh; overflow-y: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">Adding New Inventory Cost</p>
        </header>
        <section class="modal-card-body" style="padding:12px">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form data-vv-scope="supplemental-costs-form">
            <div class="box">
              <div class="columns is-multiline">
                <div class="column is-7">
                  <div class="field">
                    <label class="label">Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect
                        placeholder="Name"
                        ref="name"
                        name="name"
                        label="label"
                        trackBy="name"
                        :showLabels="false"
                        :taggable="true"
                        @tag="onNameAdded"
                        @select="onNameSelected"
                        v-model="selectedName"
                        :maxHeight="180"
                        :options="allNames"
                        :disabled="isReadOnly"
                        :hideSelected="true">
                      </multiselect>
                    </div>
                    <span v-show="errors.has('supplemental-costs-form.selectedName')" class="help is-danger"><i v-show="errors.has('supplemental-costs-form.selectedName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('supplemental-costs-form.selectedName') }}</span>
                  </div>
                </div>
                <div class="column is-5">
                  <button-list-selector
                    validateAs="payee type"
                    label="Payee Type"
                    :scope="'supplemental-costs-form'"
                    :required="true"
                    v-model="selectedPayeeType"
                    :allowDeselection="false"
                    v-on:on-change="onPayeeTypeChange"
                    :availableValues="filteredCostPayeeTypes"
                    :enabled="!isReadOnly">
                  </button-list-selector>
                </div>
                <div class="column is-12" v-if="selectedPayeeType && selectedPayeeType.id === 1">
                  <supplier-selector v-model="selectedVendor" label="Pay To Wholesale Dealer" :scope="'supplemental-costs-form'" validateAs="wholesale dealer" :required="false" :isDisabled="isReadOnly"></supplier-selector>
                </div>
                <div class="column is-12" v-if="selectedPayeeType && selectedPayeeType.id === 2">
                  <bank-selector v-model="selectedVendor" label="Pay To Bank" :scope="'supplemental-costs-form'" validateAs="bank" :required="false" :isDisabled="isReadOnly"></bank-selector>
                </div>
                <div class="column is-12" v-if="selectedPayeeType && selectedPayeeType.id === 3">
                  <customer-selector :selectedDealer="currentDealer" v-model="selectedVendor" label="Pay To Customer" :scope="'supplemental-costs-form'" validateAs="customer" :required="false" :isDisabled="isReadOnly"></customer-selector>
                </div>
                <div class="column is-12">
                  <label class="label">Description<b-icon pack="fas" icon="" class="fa-ss"></b-icon></label>
                  <div class="control">
                    <textarea name="description" :class="{'textarea': true}" type="text" v-model="value.description" :disabled="isReadOnly"></textarea>
                  </div>
                </div>
                <div class="column is-6">
                  <label class="label">Cost Price<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control has-icons-left">
                    <money name="cost" data-vv-as="cost" v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has('supplemental-costs-form.cost') }" type="text" placeholder="0000.00" v-model="value.cost" v-bind="$globalMoneyFormat" :disabled="isReadOnly"></money>
                    <span v-show="errors.has('supplemental-costs-form.cost')" class="help is-danger">
                      <i v-show="errors.has('supplemental-costs-form.cost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('supplemental-costs-form.cost') }}
                    </span>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                </div>
                <div class="column is-6">
                  <label class="label">Selling Price<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control has-icons-left">
                    <money name="sellingPrice" data-vv-as="price" v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has('supplemental-costs-form.sellingPrice') }" type="text" placeholder="0000.00" v-model="value.sellingPrice" v-bind="$globalMoneyFormat" :disabled="isReadOnly"></money>
                    <span v-show="errors.has('supplemental-costs-form.sellingPrice')" class="help is-danger">
                      <i v-show="errors.has('supplemental-costs-form.sellingPrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('supplemental-costs-form.sellingPrice') }}
                    </span>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                </div>
                <div class="column is-6">
                  <button-list-selector :scope="'supplemental-costs-form'" label="Applicable Processing Action" :required="true" v-model="selectedProcessingAction" :availableValues="definedTypes.defaultCostProcessingActions.options" :enabled="!isReadOnly" validateAs="applicable processing actions"></button-list-selector>
                </div>
                <div class="column is-6">
                  <switch-selector type="is-info" label="Is In GL Balance" :required="true" v-model="value.isInGlBalance" tabindex="6" :isDisabled="isReadOnly"></switch-selector>
                </div>
                <div class="column is-6">
                  <button-list-selector :scope="'supplemental-costs-form'" label="Applies to Disposition Intention" :required="true" v-model="selectedAppliesToDispositionIntention" :availableValues="definedTypes.defaultCostDispositionIntentions.options" :enabled="true" validateAs="applies to disposition intentions"></button-list-selector>
                </div>
                <div class="column is-6">
                  <switch-selector type="is-info" label="Add*" :required="true" v-model="value.additionalIncome" tabindex="5"></switch-selector>
                </div>
                <div class="column is-6">
                  <switch-selector type="is-info" label="Process Check Request/PO on Sale Only" :required="true" v-model="value.appliesOnSaleOnly" tabindex="5"></switch-selector>
                </div>
                <div class="column is-6">
                  <switch-selector type="is-info" label="Change if type is toggled between wholesale or retail" :required="true" v-model="value.requiresMatchedSaleIntention" tabindex="5"></switch-selector>
                </div>
                <div class="column is-12">
                  <file-selector
                  v-model="supplementPhotoFiles"
                  label="Files"
                  :required="false"
                  :multiple="true"
                  :isBoxed="false"
                  displaySize="64"
                  :usePortalViewer="false"
                  :enabled="!isReadOnly"
                  @uploadStarted="uploadStarted"
                  @uploadComplete="uploadComplete">
                  </file-selector>
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot is-block">
          <button class="button is-primary" @click="onSave()" type="button" :disabled="isReadOnly || isUploadingFile" v-if="!saveNewCost">Confirm</button>
          <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onSaveSupplementalCost()" type="button" :disabled="isReadOnly || isUploadingFile" v-else>Save</button>
          <button class="button is-danger" @click="onCancel()" type="button">Cancel</button>
          <button class="button is-danger is-pulled-right" @click="onDeleteSupplementalCost()" type="button" v-if="allowDelete && hasFeatureAccess(deleteSupplementalCostAccessPermission) && value && value.id && value.id > 0">Delete</button>
        </footer>
      </div>
    </b-modal>
  </portal>
</template>

<script>
import inventoryService from '@/services/inventoryService'
import utilitiesMixin from '@/mixins/generic/utilities'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import SupplierSelector from '@/components/generic/SupplierSelector'
import BankSelector from '@/components/generic/BankSelector'
import CustomerSelector from '@/components/generic/CustomerSelector'
import FileSelector from '@/components/generic/FileSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  components: {
    'supplier-selector': SupplierSelector,
    'bank-selector': BankSelector,
    'customer-selector': CustomerSelector,
    'button-list-selector': ButtonListSelector,
    'file-selector': FileSelector,
    'switch-selector': SwitchSelector,
    'error-display-component': ErrorDisplayComponent
  },
  name: 'SupplementalCostCapture',
  mixins: [utilitiesMixin],
  props: {
    value: {
      type: Object,
      default: null
    },
    supplementalCosts: {
      type: Array,
      default: () => []
    },
    usePortal: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    inventoryId: {
      type: Number,
      default: null
    },
    saveNewCost: {
      type: Boolean,
      defalt: false
    },
    allowDelete: {
      type: Boolean,
      defalt: false
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      selectedName: null,
      selectedVendor: null,
      selectedPayeeType: null,
      selectedProcessingAction: null,
      selectedAppliesToDispositionIntention: null,
      supplementPhotoFiles: [],
      allNames: [],
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      deleteSupplementalCostAccessPermission: 'inventory.view.delete.supplemental.cost',
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    filteredCostPayeeTypes: function () {
      let excludedTypes = [4]
      let filteredTypes = this.definedTypes.costPayeeTypes.filter(function (item) {
        return excludedTypes.indexOf(item.id) === -1
      })
      return filteredTypes
    },
    successToastMessage: function () {
      if (this.value.id === 0) {
        return 'Success! Supplemental cost added.'
      }
      return 'Success! Supplemental cost updated.'
    }
  },
  methods: {
    onPayeeTypeChange: function (newPayeeType) {
      this.value.payeeType = newPayeeType ? newPayeeType.altName : null
      this.selectedVendor = null
    },
    onNameAdded: function (data) {
      let supplementalCost = {
        id: 0,
        name: data,
        label: data,
        vendorId: 0,
        payeeType: 'Supplier',
        vendorName: '',
        attachments: [],
        description: '',
        cost: null,
        sellingPrice: 0.00,
        isAddingCost: false,
        processingAction: null,
        appliesToDispositionIntention: 'All',
        appliesOnSaleOnly: false,
        requiresMatchedSaleIntention: false,
        isInGlBalance: false,
        additionalIncome: false,
        dealerDefaultCostId: null
      }

      this.allNames.push(supplementalCost)
      this.selectedName = supplementalCost
      this.errors.clear('supplemental-costs-form')
    },
    onNameSelected: function (newValue) {
      this.errors.clear('supplemental-costs-form')
    },
    onCancel: function () {
      this.value.isAddingCost = false
      this.errors.clear('supplemental-costs-form')
    },
    onSave: function () {
      if (this.selectedName === null || this.selectedName === '') {
        this.errors.items.push({field: 'selectedName', scope: 'supplemental-costs-form', msg: 'The cost name field is required'})
        this.$refs['name'].$el.scrollIntoView()
        return
      }

      this.$validator.validateAll('supplemental-costs-form').then((result) => {
        if (result) {
          this.value.processingAction = this.selectedProcessingAction ? this.selectedProcessingAction.altName : null
          this.value.appliesToDispositionIntention = this.selectedAppliesToDispositionIntention ? this.selectedAppliesToDispositionIntention.name : null

          let foundIndex = this.supplementalCosts.findIndex(x => x.id === this.value.id)
          if (foundIndex !== -1) {
            this.$delete(this.supplementalCosts, foundIndex)
          }

          if (this.supplementPhotoFiles.length > 0) {
            var photoFiles = this.supplementPhotoFiles.filter((y) => y.id > 0)
            photoFiles.push(...this.supplementPhotoFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
              return {
                uploadName: x.name,
                uploadSize: x.size,
                uniqueId: x.uniqueId
              }
            }))
            this.value.attachments = photoFiles
          }
          this.supplementalCosts.push(this.value)
          this.$emit('on-save-supplemental-cost', this.value)
          this.value.isAddingCost = false
        }
      })
    },
    initInputValues: function () {
      let that = this
      var dealerId = this.$store.state.dealer.currentDealer.id
      var searchCriteria = {
        dealerId: dealerId
      }
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.allNames = []
      this.selectedProcessingAction = null
      this.selectedAppliesToDispositionIntention = null

      inventoryService.getDistinctDealerSupplementalCosts(searchCriteria).then(response => {
        if (response.errors) {
          that.serverErrors = response.errors
        } else {
          let supplementalCostList = response
          var supplementalCost = supplementalCostList.map((x) => {
            return {
              id: 0,
              dealerDefaultCostId: x.id,
              name: x.name,
              label: x.name + ' - ' + x.description,
              cost: x.cost,
              description: x.description,
              sellingPrice: x.sellingPrice,
              processingAction: that.definedTypes.defaultCostProcessingActions.options.find(y => y.name === x.processingAction || y.altName === x.processingAction).altName,
              isInGlBalance: x.isInGlBalance,
              appliesToDispositionIntention: that.definedTypes.defaultCostDispositionIntentions.options.find(y => y.name === x.appliesToDispositionIntention || y.altName === x.appliesToDispositionIntention).altName,
              additionalIncome: x.additionalIncome,
              appliesOnSaleOnly: x.appliesOnSaleOnly,
              requiresMatchedSaleIntention: x.requiresMatchedSaleIntention,
              attachments: []
            }
          })
          if (!that.allNames.includes(x => x.name === supplementalCost.name)) {
            that.allNames.push(...supplementalCost)
          }
        }
        that.isLoading = false
      }).catch((error) => {
        that.isLoading = false
        that.serverErrorMessage = error.message
      })
    },
    onDeleteSupplementalCost: function () {
      this.$emit('on-delete-supplemental-cost', this.value)
      this.value.isAddingCost = false
    },
    onSaveSupplementalCost: function () {
      if (this.selectedName === null || this.selectedName.name === '') {
        this.errors.items.push({field: 'selectedName', scope: 'supplemental-costs-form', msg: 'The cost name field is required'})
        this.$refs['name'].$el.scrollIntoView()
        return
      }
      this.$validator.validateAll('supplemental-costs-form').then((result) => {
        if (result) {
          this.isSaving = true
          this.value.inventoryId = this.inventoryId
          this.value.processingAction = this.selectedProcessingAction ? this.selectedProcessingAction.altName : null
          this.value.appliesToDispositionIntention = this.selectedAppliesToDispositionIntention ? this.selectedAppliesToDispositionIntention.name : null
          if (this.supplementPhotoFiles.length > 0) {
            var photoFiles = this.supplementPhotoFiles.filter((y) => y.id > 0)
            photoFiles.push(...this.supplementPhotoFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
              return {
                uploadName: x.name,
                uploadSize: x.size,
                uniqueId: x.uniqueId
              }
            }))
            this.value.attachments = photoFiles
          }
          inventoryService.saveSupplementalCost(this.value).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.failedToast('Oops! Something went wrong')
              this.serverErrors = response.errors
            } else {
              this.successToast(this.successToastMessage)
              this.$emit('on-save-supplemental-cost', response.inventory)
              this.value.isAddingCost = false
            }
          }).catch((error) => {
            this.failedToast('Oops! Something went wrong')
            this.isSaving = false
            this.serverErrorMessage = error.message
          })
        }
      })
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  watch: {
    selectedName: function (newValue, oldValue) {
      if (newValue !== null) {
        this.value.dealerDefaultCostId = newValue.dealerDefaultCostId
        this.value.name = newValue.name
        this.value.label = newValue.name ? (newValue.name + ' - ' + newValue.description) : ''
        this.value.cost = newValue.cost
        this.value.description = newValue.description
        this.value.sellingPrice = newValue.sellingPrice
        this.selectedProcessingAction = this.definedTypes.defaultCostProcessingActions.options.find(x => x.altName === newValue.processingAction)
        this.value.isInGlBalance = newValue.isInGlBalance
        this.selectedAppliesToDispositionIntention = this.definedTypes.defaultCostDispositionIntentions.options.find(x => x.altName === newValue.appliesToDispositionIntention)
        this.value.additionalIncome = newValue.additionalIncome
        this.value.appliesOnSaleOnly = newValue.appliesOnSaleOnly
        this.value.requiresMatchedSaleIntention = newValue.requiresMatchedSaleIntention
      }
    },
    supplementalCosts: function (newValue, oldValue) {
      this.$emit('input', {supplementalCosts: newValue})
    },
    value: function (newValue, oldValue) {
      if (newValue && newValue.name !== '' && newValue.name !== null) {
        this.selectedName = {
          dealerDefaultCostId: newValue.dealerDefaultCostId,
          name: newValue.name,
          label: newValue.name ? (newValue.name + ' - ' + newValue.description) : '',
          cost: newValue.cost,
          description: newValue.description,
          sellingPrice: newValue.sellingPrice,
          processingAction: newValue.processingAction,
          isInGlBalance: newValue.isInGlBalance,
          appliesToDispositionIntention: newValue.appliesToDispositionIntention,
          additionalIncome: newValue.additionalIncome,
          appliesOnSaleOnly: newValue.appliesOnSaleOnly,
          requiresMatchedSaleIntention: newValue.requiresMatchedSaleIntention
        }
      } else {
        this.selectedName = null
      }

      if (newValue && newValue.payeeType) {
        this.selectedPayeeType = this.definedTypes.costPayeeTypes.find(x => x.altName === newValue.payeeType)
      }

      if (newValue && newValue.processingAction) {
        this.selectedProcessingAction = this.definedTypes.defaultCostProcessingActions.options.find(x => x.altName === newValue.processingAction)
      } else {
        this.selectedProcessingAction = null
      }

      if (newValue && newValue.appliesToDispositionIntention) {
        this.selectedAppliesToDispositionIntention = this.definedTypes.defaultCostDispositionIntentions.options.find(x => x.altName === newValue.appliesToDispositionIntention)
      } else {
        this.selectedAppliesToDispositionIntention = null
      }

      if (newValue && (newValue.vendorId !== null || newValue.vendorId > 0)) {
        this.selectedVendor = { id: newValue.vendorId, name: newValue.vendorName, fullName: newValue.vendorName }
      } else {
        this.selectedVendor = null
      }

      this.supplementPhotoFiles = []
      if (newValue && (newValue.attachments && newValue.attachments.length > 0)) {
        newValue.attachments.forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation ? x.fileLocation : x.imageData
          x.fileType = x.fileLocation ? this.getFileType(x.fileLocation) : x.fileType
          x.name = x.name ? x.name : x.uploadName
          this.supplementPhotoFiles.push(x)
        })
      }
    },
    selectedVendor: function (newValue, oldValue) {
      if (newValue !== null) {
        this.value.vendorId = newValue.id
        this.value.vendorName = newValue.name
      }
    },
    selectedProcessingAction: function (newValue, oldValue) {
      if (newValue !== null && newValue !== undefined) {
        if (newValue.id === this.definedTypes.defaultCostProcessingActions.AccountingEntry) {
          this.value.isInGlBalance = !this.value.appliesOnSaleOnly
        } else if (newValue.id === this.definedTypes.defaultCostProcessingActions.RequestCheck) {
          this.value.isInGlBalance = false
        } else if (newValue.id === this.definedTypes.defaultCostProcessingActions.PoSchedule) {
          this.value.isInGlBalance = false
        }
      }
    },
    'value.appliesOnSaleOnly': {
      handler: function (newValue, oldValue) {
        if (this.selectedProcessingAction !== null && this.selectedProcessingAction !== undefined) {
          if (this.selectedProcessingAction.id === this.definedTypes.defaultCostProcessingActions.AccountingEntry) {
            this.value.isInGlBalance = !newValue
          } else if (this.selectedProcessingAction.id === this.definedTypes.defaultCostProcessingActions.RequestCheck) {
            this.value.isInGlBalance = false
          } else if (this.selectedProcessingAction.id === this.definedTypes.defaultCostProcessingActions.PoSchedule) {
            this.value.isInGlBalance = false
          }
        }
      },
      deep: false,
      immediate: false
    }
  },
  mounted: function () {
    this.initInputValues()
  }
}
</script>

<style scoped>

  .modal-card-body {
    overflow:auto;
    height: calc(50vh - 10px);
  }

  .column {
    padding: 1rem;
  }

  .box {
    background-color: #f6f8fa;
  }

  .animation-content {
    max-width: 700px;
  }

  .process-info {
    margin-top: 10px;
  }
</style>
