<template>
  <span>
    <button
      v-if="!isSkipTradeArrivalActive"
      v-tooltip="getTooltip"
      class="button is-danger is-pulled-right"
      @click="onOpenModal"
      type="button"
      :disabled="isBtnDisabled">
      <i title="Skip" class="fal fa-forward fa-lg" v-if="mobile"></i><span v-else>Skip</span>
    </button>
    <b-modal v-if="isSkipTradeArrivalActive" :active.sync="isSkipTradeArrivalActive" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Warning</p>
        </header>
        <section class="modal-card-body">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form class="view-container">
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="notification is-danger is-flex">
                  <span>
                    <i class="fas fa-exclamation-triangle fa-2x"></i>
                  </span>
                  <span class="ml-10">
                    WARNING: By skipping the trade vehicle arrival process, you accept responsibility for the capturing the arrival and inspection.
                  </span>
                </div>
              </div>
              <div class="column is-6">
                <switch-selector type="is-info" label="Accept Responsibility" v-model="skip"></switch-selector>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">User</label>
                  <div class="field">
                    {{ userName }}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onSkipArrival" type="button" :disabled="isContinueBtnDisabled">Continue</button>
          <button class="button is-danger" @click="onClose">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </span>
</template>

<script>
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import utilitiesMixin from '@/mixins/generic/utilities'
import { createNamespacedHelpers } from 'vuex'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import switchSelector from '@/components/generic/SwitchSelector'
import inventoryService from '@/services/inventoryService'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'SkipTradeArrivalAction',
  mixins: [validateBeforeSubmitMixin, utilitiesMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'switch-selector': switchSelector
  },
  props: {
    inventory: {
      type: Object,
      default: null
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSkipTradeArrivalActive: false,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      currentInventory: this.inventory,
      skip: false
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    hasAccess: function () {
      let allowedPermissions = ['deal.modify.unpaid']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    iconTitle: function () {
      return 'Mark this payment request as unpaid'
    },
    userName: function () {
      if (this.currentUser) {
        return this.currentUser.fullName
      }
      return ''
    },
    managerId: function () {
      if (this.currentUser) {
        return this.currentUser.id
      }
      return null
    },
    isContinueBtnDisabled: function () {
      return !this.skip
    },
    canSkipTradeArrival: function () {
      return this.$store.state.dealer.currentDealer.canSkipTradeArrival
    },
    isBtnDisabled: function () {
      return !this.canSkipTradeArrival
    },
    getTooltip: function () {
      return this.isBtnDisabled ? 'Skip arrival is not allowed. This setting can be changed via the dealer configuration' : 'Skip Arrival Process'
    }
  },
  methods: {
    clearErrors: function () {
      this.serverErrors = []
      this.serverErrorMessage = ''
    },
    onOpenModal: function () {
      this.clearErrors()
      this.isSkipTradeArrivalActive = true
    },
    onClose: function () {
      this.reset()
      this.isSkipTradeArrivalActive = false
    },
    reset: function () {
      this.skip = false
    },
    onSkipArrival: function () {
      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      const arrivalSkipModel = {
        id: this.currentInventory.id,
        arrivalSkippedById: this.currentUser.id,
        mustCreateFirstRo: true
      }

      inventoryService.markArrivalSkipped(arrivalSkipModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrorMessage = response.message
          this.serverErrors = response.errors
        } else {
          // this.successToast('Success, you can now proceed to book the deal', 2500)
          this.$emit('on-skip-arrival', true)
          this.onClose()
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    }
  }
}

</script>

<style scoped>
  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-card-body {
    padding: 20px !important;
    overflow: auto;
    max-width: 550px;
  }

  .icon-paid {
      color: #00C9B2;
    }
</style>
