<template>
  <div :id="type" class="box" v-if="inventoryDefaultCosts.length > 0">
    <section-header
      :title="typeDisplayName"
      :parentSelector="'#' + type"
      :collapsed="true"
      :overview="'('+ inventoryDefaultCosts.length +')'">
    </section-header>
    <table class="table background-white is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Line Item</th>
          <th v-if="isItemsDeletedInLiveType">Note Instruction</th>
          <th v-if="isItemsYouCanRemoveType" class="text-right">Remove from LIVE</th>
          <th class="text-right no-content-wrap" style="white-space: nowrap">Apply to CDK</th>
          <th v-if="isItemsDeletedInLiveType" class="text-right no-content-wrap" style="white-space: nowrap">Apply To LIVE</th>
        </tr>
      </thead>
      <tr v-for="(supplementalCost) in inventoryDefaultCosts" :key="type + supplementalCost.id + supplementalCost.name">
        <td :class="getItemsYouCanRemoveClass(supplementalCost)">{{(supplementalCost.cost - supplementalCost.sellingPrice) | currency}} - {{supplementalCost.name}}</td>
        <td v-if="isItemsDeletedInLiveType"><span class="tag is-warning">Remove in CDK</span> {{supplementalCost.name}} as it was removed in Live</td>
        <td v-if="isItemsYouCanRemoveType" class="text-right">
          <i v-if="!supplementalCost.deleted || supplementalCost.deleted === false"
            class="icon fas fa-trash-alt pointer"
            @click="toggleDeleteActiveCost(supplementalCost)"
            title="Delete"/>
        </td>
        <td class="text-right">
          <b-checkbox v-if="isItemsDeletedInLiveType"
            :ref="supplementalCost.id + '_chk'"
            :name="supplementalCost.id + '_chk'"
            @click.native="toggleInActiveCost(supplementalCost, 'cdk')"
            v-model="supplementalCost.checked">
          </b-checkbox>

          <b-checkbox v-else
            :ref="supplementalCost.id + '_chk'"
            :name="supplementalCost.id + '_chk'"
            @click.native="toggleCost(supplementalCost)"
            v-model="supplementalCost.checked">
          </b-checkbox>
        </td>
        <td v-if="isItemsDeletedInLiveType" class="text-right">
          <b-checkbox
            :ref="supplementalCost.id + '_chkLive'"
            :name="supplementalCost.id + '_chkLive'"
            @click.native="toggleInActiveCost(supplementalCost, 'live')"
            v-model="supplementalCost.checkedLive">
          </b-checkbox>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

import SectionHeader from '@/components/generic/SectionHeader'
import eventBus from '@/eventBus'

export default {
  name: 'FrontGrossAccrualInventoryCosts',
  components: {
    'section-header': SectionHeader
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    initAdjustments: {
      type: Array,
      default: null
    },
    type: {
      type: String,
      default: 'ActiveItemsInLive'
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      forInventory: this.value
    }
  },
  computed: {
    inventoryDefaultCosts: function () {
      let list = []

      switch (this.type) {
        case 'ActiveItemsInLive': {
          if (this.forInventory && this.forInventory.supplementalCosts) {
            // list = this.$lodash.differenceWith(this.forInventory.supplementalCosts, this.forInventory.addedSupplementalCostsAfterInitialCreate, this.$lodash.isEqual)
            list = this.forInventory.supplementalCosts.filter(x => x.isAuto)
          }
          break
        }
        case 'ItemsYouCanRemove': {
          if (this.forInventory && this.forInventory.supplementalCosts) {
            // list = this.forInventory.addedSupplementalCostsAfterInitialCreate.length > 0 ? this.forInventory.addedSupplementalCostsAfterInitialCreate : []
            list = this.forInventory.supplementalCosts.filter(x => !x.isAuto)
          }
          break
        }
        case 'ItemsDeletedInLive': {
          if (this.forInventory && this.forInventory.inActiveSupplementalCosts) {
            list = this.forInventory.inActiveSupplementalCosts.length > 0 ? this.forInventory.inActiveSupplementalCosts : []
          }
          break
        }
        default: {
          list = []
          break
        }
      }
      return list
    },
    total: function () {
      if (!this.forInventory) return 0

      if (this.inventoryDefaultCosts.length > 0) {
        let activeItemsCheckedAmount = this.$lodash.sumBy(this.inventoryDefaultCosts, function (x) {
          return x.checked !== null && x.checked !== undefined && x.checked === true ? x.cost - x.sellingPrice : 0
        })
        return activeItemsCheckedAmount
      }

      return 0
    },
    typeDisplayName: function () {
      let name = ''
      switch (this.type) {
        case 'ActiveItemsInLive': {
          name = 'Packs'
          break
        }
        case 'ItemsYouCanRemove': {
          name = 'Manual'
          break
        }
        case 'ItemsDeletedInLive': {
          name = 'Items Deleted In LIVE'
          break
        }
        default: {
          name = 'Packs'
          break
        }
      }
      return name
    },
    isItemsYouCanRemoveType: function () {
      return this.type === 'ItemsYouCanRemove'
    },
    isActiveItemsInLiveType: function () {
      return this.type === 'ActiveItemsInLive'
    },
    isItemsDeletedInLiveType: function () {
      return this.type === 'ItemsDeletedInLive'
    }
  },
  methods: {
    toggleCost: function (row) {
      let found = this.inventoryDefaultCosts.find(x => x.id === row.id)
      if (found) {
        found.text = 'Add in CDK - ' + row.name
        this.markCheckedStatus(found, 'cdk')
      }
    },
    toggleInActiveCost: function (row, app) {
      let found = this.inventoryDefaultCosts.find(x => x.id === row.id)
      if (found) {
        found.text = 'Remove in CDK ' + row.name + ' as it was removed in LIVE'
        if (app === 'live') found.text = 'Add to LIVE ' + row.name + ' to match recon in CDK'
        this.markCheckedStatus(found, app)
      }
    },
    markCheckedStatus: function (cost, app) {
      if (cost && app === 'cdk') {
        if (cost.checked === null || cost.checked === undefined) {
          cost.checked = true
        } else {
          cost.checked = !cost.checked
        }

        if (cost.checked === true) {
          this.$emit('onPersistAdjustment', {cost, app})
        } else {
          this.$emit('onRemoveAdjustment', {cost, app})
        }
      }

      if (cost && app === 'live') {
        if (cost.checkedLive === null || cost.checkedLive === undefined) {
          cost.checkedLive = true
        } else {
          cost.checkedLive = !cost.checkedLive
        }

        if (cost.checkedLive === true) {
          this.$emit('onPersistAdjustment', {cost, app})
        } else {
          this.$emit('onRemoveAdjustment', {cost, app})
        }
      }

      this.$emit('input', this.forInventory)
      this.$forceUpdate()
    },
    unCheckItems: function () {
      this.inventoryDefaultCosts.forEach(element => {
        if (element.checked) element.checked = false
        this.$forceUpdate()
      })
    },
    init: function () {
      let that = this

      this.$nextTick(() => {
        setTimeout(function () {
          that.inventoryDefaultCosts.forEach(element => {
            let found = that.initAdjustments.find(x => x.id === element.id)

            if (found) {
              // add in backward compatibility with app as older versions would not have this field
              found.app = found.app !== undefined && found.app !== null ? found.app : 'cdk'

              element.text = found.text
              that.markCheckedStatus(element, found.app)
            }
          })
        }, 20)
      })
    },
    toggleDeleteActiveCost: function (row) {
      let found = this.inventoryDefaultCosts.find(x => x.id === row.id)
      if (found) {
        found.text = 'Item You Can Remove - ' + row.name

        if (found.deleted === null || found.deleted === undefined) {
          found.deleted = true
        }

        // if its checked, it means the adjustment is applied. This must now be unchecked as we deleting the cost now
        if (found.checked !== null && found.checked !== undefined && found.checked === true) {
          this.markCheckedStatus(found, 'cdk')
        }

        this.$forceUpdate()

        // handle delete in inventory supp component
        eventBus.$emit('delete-cost-by-id', found.id)

        /*
        let that = this
        this.$nextTick(() => {
          setTimeout(function () {
            that.syncChanges()

            // auto save adjustments
            let bypassNotification = true
            that.saveAdjustments(bypassNotification)
          }, 500)
        })
        */
      }
    },
    getItemsYouCanRemoveClass: function (cost) {
      if (this.isItemsDeletedInLiveType) return 'striked'

      return cost && cost.deleted ? 'striked' : ''
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      this.forInventory = newValue
      this.init()
    },
    initAdjustments: function (newValue, oldValue) {
      // if adjustments was removed by parent we need to clear it here
      if (newValue.length === 0 && oldValue.length > 0) {
        this.unCheckItems()
      }
    }
  },
  mounted: function () {
    this.init()
  },
  created: function () {
    // eventBus.$on('fg-uncheck-items', this.unCheckItems)
  },
  destroyed () {
    // eventBus.$on('fg-uncheck-items', this.unCheckItems)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

table.header {
  background-color:hsl(206, 70%, 96%) !important;
}

.text-right {
  text-align: right !important
}

.background-white {
  background-color: white !important;
}
</style>
