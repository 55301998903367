<template>
  <section class="view-container">
      <portal :to="headerPortal" :disabled="headerPortal === ''">
        <h1 class="title is-size-5 has-text-link has-text-weight-semibold">
          {{ purchaserName }} - {{ buildCreditStatusForTitle(currentDeal) }}
          <a @click.stop.prevent="onStockNoClick(firstSoldItemInventory)" v-if="firstSoldItemInventory">
            <span :class="getStockNoColorClassByGetReadyAndAccessoryStatus(firstSoldItemInventory.stockNo, firstSoldItemInventory.getReadyStatusId, firstSoldItemInventory.accessoryDetailOverview)">
              {{firstSoldItemStockNo}}
            </span>
          </a>
          <span v-if="isPreDeal" @click="onClearPreDeal" v-tooltip="{content:'Click to deactivate the pre-deal status on this deal.'}" class="tag is-violet is-large is-size-6 clickable">{{getTitlePreDealText}}</span>
        </h1>
      </portal>
      <div>
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <div v-if="currentDeal.id !== 0">
          <div id="deal-overview" class="box">
            <form class="view-container" @change="onFormChange">
              <section-header :title="buildDealSectionTitle(currentDeal, true, this.firstSoldItem)" parentSelector="#deal-overview"></section-header>
              <div class="columns is-mobile is-multiline" style="margin-top: -20px">
                <div v-if="isCancelledDeal && hasCancelledReason" class="column is-12">
                  <div class="notification is-info">
                    <span>
                      <i class="fas fa-info-circle is-info fa-lg"></i>
                    </span>
                    {{ currentDeal.cancellationReason }}
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Deal Status</label>
                    <div class="field">
                      {{ dealTypeFriendlyName }}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Sale Type</label>
                    <div class="field">
                      {{currentDeal.saleType}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-mobile" v-if="currentDeal.saleType === 'Retail'">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">
                      Customer
                      <dealer-peak-control :key="currentDeal.customer.id" :customerId="currentDeal.customer.id"></dealer-peak-control>
                      </label>
                    <div class="field">
                      <span class="icon-text">
                        <span>
                          <a class="is-link" @click="onViewCustomer(currentDeal.customer.id)">
                            {{ purchaserName }}
                          </a>
                        </span>
                        <span class="icon" v-if="showDealCompletedIcon(currentDeal.type, currentDeal.status, currentDeal.isBookingFinalised)" v-tooltip="'Booking finalized'">
                          <i style="text-shadow: 0 0 3px #000;" class="grid-icon fa-fw fal fa-check fa-lg has-text-primary is-pulled-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Customer Number</label>
                    <div class="field">
                      {{ currentDeal.forPurchaserModel.number || '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-mobile" v-if="canShowCoCustomerDetails">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Co-Customer</label>
                    <div class="field">
                      <a class="is-link" @click="onViewCustomer(currentDeal.coCustomer.id)">
                        {{currentDeal.coCustomer.fullName}}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Co-Customer Relationship</label>
                    <div class="field">
                      {{currentDeal.coCustomerRelationship}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-mobile" v-if="currentDeal.saleType === 'Wholesale'">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Wholesaled to <span class="is-pulled-right" v-if="currentDeal.supplierIsTrusted" :title="'Trusted Dealer - can pickup without payment'"><i class="fal fa-lg fa-shield-check has-text-success"/> Trusted</span></label>
                    <div class="field is-flex" style="align-items: center;">
                      <span class="icon-text">
                        <span>
                          <a class="is-link" @click="onViewSupplier(currentDeal.supplierId)">
                            {{currentDeal.supplierName}}
                          </a>
                        </span>
                        <span class="icon" v-if="showDealCompletedIcon(currentDeal.type, currentDeal.status, currentDeal.isBookingFinalised)" v-tooltip="'Booking finalized'">
                          <i style="text-shadow: 0 0 3px #000;" class="grid-icon fa-fw fal fa-check fa-lg has-text-primary is-pulled-right" />
                        </span>
                      </span>
                      <verify-supplier-control :key="currentDeal.purchaserId" :supplierId="currentDeal.purchaserId" :isVerifiedLocalProp="currentDeal.purchaserVerified" v-if="!isSaleTypeOfRetail"
                      v-on:on-open-sendwirecontrol="onOpenSendWireControl"></verify-supplier-control>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Sales Person</label>
                    <div class="field">
                      {{currentDeal.salesPersonName}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Stock Type</label>
                    <div class="field">
                      {{ stockTypeFriendlyName }}
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="canShowDealNumber && !isEditLocked">
                  <div class="field">
                    <label class="label">Deal #</label>
                    <div class="control is-width-100">
                      <input name="dealCode" data-vv-as="deal number" v-validate="'max:10'" :class="{'input': true, 'is-danger': errors.has('dealCode') }" type="text" placeholder="0000000000" v-model="currentDeal.dealCode">
                      <span v-show="errors.has('dealCode')" class="help is-danger">
                        <i v-show="errors.has('dealCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first('dealCode') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="canShowDealNumber && isEditLocked">
                  <label class="label">Deal #</label>
                  <div class="field">
                    {{ currentDeal.dealCode || 'Not Specified' }}
                  </div>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column is-6" v-if="!isEditLocked">
                  <lead-source-selector v-model="selectedLeadSource" :required="isLeadSourceRequired" label="Advertising Source" validateAs="advertising source" :selectedDealer="this.selectedDealer"></lead-source-selector>
                </div>
                <div class="column is-6" v-if="isEditLocked">
                  <label class="label">Advertising Source</label>
                  <div class="field">
                    {{ currentDeal.sourceName || 'Not Specified' }}
                  </div>
                </div>
                <div class="column is-6" v-if="canShowWorkshopStatus">
                  <label class="label">Workshop</label>
                  <div class="field">
                    <a @click.stop.prevent="onServiceRequestStatusClick()"><span v-html="buildWorkshopSectionOverview(firstSoldItemInventory, false)"></span></a>
                  </div>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column is-6" v-if="currentDeal.saleType === 'Retail'">
                  <div class="field">
                    <label class="label">Sales Person</label>
                    <div class="field">
                      {{currentDeal.salesPersonName}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Sales Manager</label>
                    <div class="field" v-if="currentDeal.salesManagerName && currentDeal.salesManagerName.length > 0">
                      {{currentDeal.salesManagerName}}
                    </div>
                    <div class="field" v-else>
                      -
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-mobile" v-if="currentDeal && currentDeal.type === 'AwaitingArrival'">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Inbound Arrival Status</label>
                    <div class="field" v-if="currentDeal.inboundStatusType && currentDeal.inboundStatusType.length > 0">
                      {{ getMatchingInboundStatus(currentDeal.inboundStatusType)}}
                    </div>
                    <div class="field" v-else>
                      -
                    </div>
                  </div>
                </div>
              </div>

              <hr v-if="!isPreDeal && !isPendingCredit" class="block-hr-divider" style="margin: 0.5rem 0;"/>

              <div class="columns is-mobile is-multiline">
                <div class="column is-6" v-if="isEditLocked === false && (!isPreDeal && !isPendingCredit)">
                  <div class="field">
                    <b-field label="Sold Date">
                      <el-date-picker
                        size="mini"
                        @change="onFormChange()"
                        v-model="currentDeal.soldAt"
                        type="date"
                        class="is-width-200"
                        placeholder="Click to select..."
                        popper-class="datepicker-container"
                        :clearable="false"
                        :editable="false"
                        format="MM-dd-yyyy">
                      </el-date-picker>
                    </b-field>
                  </div>
                </div>
                <div class="column is-6 is-mobile" v-if="isEditLocked === true && (!isPreDeal && !isPendingCredit)">
                  <div class="field">
                    <label class="label">Sold Date</label>
                    <div class="field">
                      {{currentDeal.soldAtUtc | formatDateOnly}}
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="showPromisedDate && (!isPreDeal && !isPendingCredit)">
                  <div class="field">
                    <b-field label="Expected Delivery Date">
                      <el-date-picker
                        size="mini"
                        @change="(isEditLocked) ? savePartialDeal(false, false) : onFormChange()"
                        v-model="currentDeal.promiseDate"
                        type="datetime"
                        class="is-width-200"
                        placeholder="Click to select..."
                        popper-class="datepicker-container"
                        :clearable="false"
                        :editable="false"
                        format="MM-dd-yyyy h:mm A"
                        :default-value="this.getDefaultDateTimeForCalendar"
                        default-time="13:00:00">
                      </el-date-picker>
                    </b-field>
                  </div>
                </div>
                <div class="column is-6 is-mobile" v-if="showAppointmentDate">
                  <div class="field">
                    <label class="label">Appointment Date</label>
                    <div class="field">
                      <div>{{currentDeal.appointmentDateUtc | formatDate}}</div>
                      <div><appointment-status-control v-model="currentDeal" ></appointment-status-control></div>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="showPromisedDate">
                  <div class="field">
                    <label class="label">Appointment Status</label>
                    <div class="field">
                      <div v-if="!showDeliveredDate">
                        <appointment-status-control v-model="currentDeal" ></appointment-status-control>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="showTimeToDeliver">
                  <deal-time-to-deliver-component
                    :key="currentDeal.id"
                    :currentValue="currentDeal.timeToDeliver"
                    :timeToDeliverHistory="currentDeal.dealTimeToDeliverEvents"
                    :dealId="currentDeal.id"
                    :showEditIcon="isDeliveredDeal || isBookedDeal">
                  </deal-time-to-deliver-component>
                </div>
                <div class="column is-6" v-if="isPendingDeal">
                  <div class="field">
                    <label class="label">Time To Deliver Counter</label>
                    <div class="field">
                      {{  currentDeal.ttdCounter !== null && currentDeal.ttdCounter !== '' ? currentDeal.ttdCounter : "-" }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns is-mobile is-multiline">
                <div class="column is-12" v-if="showDeliveredDate && !isEditLocked">
                  <div class="field">
                    <b-field label="Delivered Date">
                      <el-date-picker
                        size="mini"
                        @change="onFormChange()"
                        v-model="currentDeal.deliveryDate"
                        type="datetime"
                        class="is-width-200"
                        placeholder="Click to select..."
                        popper-class="datepicker-container"
                        :clearable="false"
                        :editable="false"
                        format="MM-dd-yyyy h:mm A"
                        :default-value="this.getDefaultDateTimeForCalendar"
                        default-time="13:00:00">
                      </el-date-picker>
                    </b-field>
                  </div>
                </div>
                <div class="column is-6" v-if="showDeliveredDate && isEditLocked">
                  <div class="field">
                    <label class="label">Delivered Date</label>
                    <div class="field">
                      <span :class="getPromiseDateColorClassByCurrentDate(currentDeal.deliveryDate, currentDeal.type)">{{currentDeal.deliveryDateUtc | formatDate}}</span>
                    </div>
                  </div>
                </div>

                <div class="column is-6" v-if="showOrderedDate">
                  <div class="field">
                    <label class="label">Ordered Date</label>
                    <div class="field">
                      <span>{{currentDeal.orderedDate | formatDateOnly}}</span>
                    </div>
                  </div>
                </div>

                <div class="column is-6" v-if="showAllocatedMonth && !isEditLocked">
                  <div class="field">
                    <b-field label="Allocated Month">
                      <el-date-picker
                        size="mini"
                        @change="onFormChange()"
                        v-model="currentDeal.allocatedMonth"
                        type="month"
                        format="MMM yyyy"
                        name="allocatedMonth"
                        id="allocatedMonth"
                        data-vv-as="allocated month"
                        v-validate="'required'"
                        default-time="00:00:00"
                        class="is-width-200"
                        placeholder="Click to select..."
                        popper-class="datepicker-container"
                        :clearable="false">
                      </el-date-picker>
                    </b-field>
                  </div>
                </div>
                <div class="column is-6" v-if="showAllocatedMonth && isEditLocked">
                  <div class="field">
                    <label class="label">Allocated Month</label>
                    <div class="field">
                      <span>{{currentDeal.allocatedMonth | formatDateMonthYearOnly}}</span>
                    </div>
                  </div>
                </div>

                <div class="column is-6" v-if="showOrderAllocationSubmission  && !isEditLocked">
                  <div class="field">
                    <b-field label="Order Allocation Submission">
                      <el-date-picker
                        size="mini"
                        v-model="currentDeal.orderAllocationSubmission"
                        type="date"
                        class="is-width-200"
                        placeholder="Click to select..."
                        popper-class="datepicker-container"
                        :clearable="false"
                        :editable="false"
                        format="MM-dd-yyyy"
                        default-time="00:00:00">
                      </el-date-picker>
                    </b-field>
                  </div>
                </div>
                <div class="column is-6" v-if="showOrderAllocationSubmission && isEditLocked">
                  <div class="field">
                    <label class="label">Order Allocation Submission</label>
                    <div class="field">
                      <span>{{currentDeal.orderAllocationSubmission | formatDateOnly}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="block-hr-divider" style="margin: 0.75rem 0;" />

              <div class="columns is-mobile">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Sale Price</label>
                    <div class="field">
                      {{currentDeal.totalSalesValue | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Total GP</label>
                    <div class="field">
                      {{totalSalesGP | currency}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns is-mobile">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Spiff</label>
                    <div class="field">
                      {{currentDeal.totalSpiff | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Product GP</label>
                    <div class="field">
                      {{currentDeal.totalProductGpValue | currency}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-mobile" v-if="currentDeal.optionsPrice">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Options price</label>
                    <div class="field">
                      {{currentDeal.optionsPrice | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label"></label>
                    <div class="field">
                    </div>
                  </div>
                </div>
              </div>

              <quick-action-container containerClass="quick-action-no-z-container">
                <deal-buyers-agreement v-if="isRetailContract" :currentDeal="currentDeal" :key="'B' + currentDeal.id"></deal-buyers-agreement>
                <deal-lease-agreement v-if="isLeaseContract" :currentDeal="currentDeal" :key="'BL' + currentDeal.id"></deal-lease-agreement>
                <div v-if="false && currentDeal.saleType === 'Wholesale' && hasCheckRequestAccess && !bookedLocked" class="quick-action is-pulled-left">
                  <a class="button" target="_blank" @click="updateEmailModalState(true)" title="Click to send out a check request instruction.">
                    <b-icon pack="fal" icon="envelope-open-dollar" custom-size="fa-lg"></b-icon>
                  </a>
                </div>
                <div v-if="lastTitleDocument !== ''" class="quick-action is-pulled-left">
                  <a class="button is-success" ftarget="_blank" :href="lastTitleDocument" title="Title documents have been received.">
                    <b-icon pack="fal" icon="badge-check" custom-size="fa-lg"></b-icon>
                  </a>
                </div>
                <send-title-reassignment-documentation :dealItem="currentDeal" :isListMode="false" v-if="!currentDealIsCancelled && !isDeliveredDeal"></send-title-reassignment-documentation>
                <payment-control
                  v-if="hasDepositCreateAccess"
                  :key="currentDeal.id + 'pc'"
                  :currentDeal="currentDeal"
                  :defaultNote="'%PAYMENTTYPE% for stock no ' + currentDeal.stockNo + ' - ' + currentDeal.vehicleProductionYear + ' ' + currentDeal.vehicleMakeName + ' ' + currentDeal.vehicleModelName"
                  v-on:on-save="onSavedPaymentControl"
                  v-on:on-cancel="onCancelPaymentRequest"
                  v-on:on-open-verify-supplier="onOpenVerifySupplier">
                </payment-control>
                <div class="quick-action is-pulled-left" v-if="showSmsConversationBtn">
                  <button class="button is-pulled-left" type="button" @click="smsConversationControlActive=true"  title="Click to open sms conversation">
                    <b-icon pack="fal" icon="fal fa-comments-alt" custom-size="fa-lg"></b-icon>
                  </button>
                </div>
                <customer-credit-application v-if="showCustomerIdentification && !bookedLocked" :currentDeal="currentDeal"></customer-credit-application>
                <supplier-identity-verification v-if="showSupplierIdentification" :currentDeal="currentDeal"></supplier-identity-verification>
                <view-deal-washout :currentDeal="currentDeal"></view-deal-washout>
                <ofac-control v-if="ofacInfo" :currentCustomer="ofacInfo"></ofac-control>
                <credit-application-status v-if="showCustomerIdentification" :currentDeal="currentDeal" :creditApplicationStatus="currentDeal.creditApplicationStatus"></credit-application-status>
                <fulfill-order-control v-if="hasFeatureAccess('deal.modify') && isPendingOrderDeal" :currentDeal="currentDeal"></fulfill-order-control>
                <identity-verification-action v-if="showCustomerIdentification" :currentDeal="currentDeal"></identity-verification-action>
                <customer-identity-verification-status v-if="currentDeal && currentDeal.saleType === 'Retail'" :currentDeal="currentDeal"></customer-identity-verification-status>
                <sales-delivery-signage :currentDeal="currentDeal"></sales-delivery-signage>
              </quick-action-container>
            </form>
          </div>

          <div class="box" v-for="(dealItem, index) in this.soldItems" :key="dealItem.id">
            <a @click.stop.prevent="onStockNoClick(dealItem.forInventory)">
              <section-header :title="'Sold Item (' + (index + 1) +')'" :overview="dealItem.forInventory.stockNo"></section-header>
            </a>
            <div v-if="!dealItem.isPrimary && dealItem.primaryDealForInventory && dealItem.primaryDealForInventory.primaryDealLoaded && currentDeal.type !== 'Cancelled' && currentDeal.type !== 'Allocated'" class="notification is-warning backup-deal-qv" :ref="'notification' + index">
              <button class="delete" type="button" @click="onCloseDuplicateNotification('notification' + index)"></button>
              <span>
                <i class="fas fa-exclamation-triangle is-warning"></i>
              </span>
                Warning: Stock# <a @click.stop.prevent="onStockNoClick(dealItem.forInventory)"><span class="has-text-weight-semibold" :title="dealItem.forInventory.stockNo">{{dealItem.forInventory.stockNo}}</span></a> is sold to {{(dealItem.primaryDealForInventory) ? dealItem.primaryDealForInventory.buyerName : "-"}} by {{(dealItem.primaryDealForInventory) ? dealItem.primaryDealForInventory.salesPersonName : "-"}}. Please note that this is a backup deal for this inventory record.
                <span>Click <a @click="showNotificationModal(dealItem)">here</a> to make this the primary deal.</span>
            </div>
            <div :hidden="!(dealItem.forInventory.deals && dealItem.forInventory.deals.length > 1)" v-else-if="dealItem.isPrimary" class="is-success backup-deal-qv" :ref="'notification' + index">
              <span class="has-text-success"><i class="fal fa-usd-circle is-success"></i>Primary Deal</span>
            </div>
            <div class="columns is-mobile is-multiline">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Vehicle</label>
                  <div class="field">
                    <a class="is-link" @click="onViewInventory(dealItem)">
                      <span :class="getStockNoColorClassByGetReadyAndAccessoryStatus(dealItem.forInventory.stockNo, dealItem.forInventory.getReadyStatusId, dealItem.forInventory.accessoryDetailOverview)" v-html="makeOverview(dealItem.forInventory)"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Preview</label>
                  <figure class="image is-100x100" v-viewer.static="{inline: false, title: false}">
                    <img :src="getImage(dealItem.forInventory)"/>
                    <img v-for="image in getAllImages(dealItem.forInventory)" :key="image.id" :src="image.src" style="display:none"/>
                  </figure>
                </div>
              </div>
              <div class="column is-12">
                <div class="columns">
                  <div class="column is-6">
                    <car-fax-control :key="dealItem.forInventory.id" :inventory="dealItem.forInventory" :autoUpdateInventory="false" :carFaxRating="dealItem.forInventory.carFaxRating" :vinNo="dealItem.forInventory.vinNo"></car-fax-control>
                  </div>
                  <div class="column is-6">
                    <auto-check-control
                      v-if="autoCheckEnabled"
                      :key="dealItem.forInventory.id"
                      :inventory="dealItem.forInventory"
                      :autoUpdateInventory="false"
                      :autoCheckRating="dealItem.forInventory.autoCheckRating"
                      :vinNo="dealItem.forInventory.vinNo"
                      :purchasedFromType="dealItem.forInventory.purchasedFromType"
                      :inventoryType="firstSoldInventoryType">
                    </auto-check-control>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Possession Status</label>
                  <div :class="{'has-text-danger':dealItem.forInventory.possessionStatus === 'Locating'}">
                    {{ dealItem.forInventory.possessionStatus }}
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Location</label>
                  <div class="field">
                    {{ dealItem.forInventory.locationName }}
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <field-rotator v-model="daysList"></field-rotator>
              </div>
              <div class="column is-6"></div>
              <div class="column is-12">
                <div class="field">
                  <label class="label">Pickup Location</label>
                  <div class="field">
                    {{ dealItem.forInventory.pickupLocation || "-" }}
                  </div>
                </div>
              </div>
            </div>

            <quick-action-container>
              <send-pickup-location-control :deal="currentDeal" :inventory="dealItem.forInventory"></send-pickup-location-control>
            </quick-action-container>

            <hr class="block-hr-divider" style="margin-top: -1px;"/>

            <span class="title is-6 is-inline-block">Pricing</span>
            <template v-if="isEditLocked === true">
              <div class="columns is-mobile is-multiline">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Front GP</label>
                    <div class="field">
                      {{dealItem.frontValue | currency}}
                      <!--
                      <label class="label-frontgp is-pulled-right" :class="{'has-text-danger': round(dealItem.frontValue) !== round(calculatedFrontGross(dealItem)), 'has-text-success': round(dealItem.frontValue) === round(calculatedFrontGross(dealItem))}"
                        title="This is the expected value for front GP.">({{calculatedFrontGross(dealItem) | currency}})</label>
                        -->
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Warranty GP</label>
                    <div class="field">
                      {{dealItem.warValue | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Sale Price</label>
                    <div class="field">
                      {{dealItem.salePrice | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Finance Reserve</label>
                    <div class="field">
                      {{dealItem.finResValue | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="dealItem.vehicleType === 'New'">
                  <div class="field">
                    <label class="label">Holdback</label>
                    <div class="field">
                      {{dealItem.holdValue | currency}}
                      <label class="label-frontgp is-pulled-right" :class="{'has-text-danger': round(dealItem.holdValue) !== round(inventoryHoldValue(dealItem.forInventory)), 'has-text-success': round(dealItem.holdValue) === round(inventoryHoldValue(dealItem.forInventory))}"
                        title="This is the holdback value for this model.">{{inventoryHoldValue(dealItem.forInventory) | currency}}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Bonus</label>
                    <div class="field">
                      {{dealItem.mvpValue | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Spiff</label>
                    <div class="field">
                      {{dealItem.spiff | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Product GP</label>
                    <div class="field">
                      {{dealItem.itemProducts.filter(y => y.type === "Product" && !y.delete).map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next, 0) | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Recon Cost</label>
                    <div class="field">
                      {{getDealItemReconCost(dealItem) | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Trade Allowance</label>
                    <div class="field">
                      {{totalTradeAllowance | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">{{acvLabel}}<span class="icon has-text-danger acv-warning" v-if="showAcvWarning"><i class="fas fa-exclamation-triangle"></i></span></label>
                    <div class="field">
                      {{totalAcvDifference | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Total GP</label>
                    <div class="field">
                      {{dealItemTotalGP(dealItem) | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Total MSRP</label>
                    <div class="field">
                      {{dealItem.forInventory && dealItem.forInventory.msrp ? dealItem.forInventory.msrp : 0.00 | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Base MSRP</label>
                    <div class="field">
                      {{firstSoldItemVehicleMakeModel && firstSoldItemVehicleMakeModel.msrp ? firstSoldItemVehicleMakeModel.msrp : 0.00 | currency}}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label class="label">Front GP Calculation</label>
                <table class="table">
                  <tr>
                    <th>Item</th>
                    <th>CDK</th>
                    <th>LIVE</th>
                  </tr>
                  <tr>
                    <td>Purchase Cost</td>
                    <td>{{(dealItem.forInventory && dealItem.forInventory.purchaseCostTotal) ? dealItem.forInventory.purchaseCostTotal : 0.00 | currency}}</td>
                    <td>{{(dealItem.forInventory && dealItem.forInventory.purchaseCostTotal) ? dealItem.forInventory.purchaseCostTotal : 0.00 | currency}}</td>
                  </tr>
                  <tr>
                    <td>Recon</td>
                    <td>{{calculatedReconCDK(dealItem) | currency}}</td>
                    <td>{{calculatedReconLive(dealItem) | currency}}</td>
                  </tr>
                  <tr>
                    <td>Inventory Additional Costs</td>
                    <td>{{inventoryAdditionalCostsTotal(dealItem) | currency}}</td>
                    <td>{{inventoryAdditionalCostsTotal(dealItem) | currency}}</td>
                  </tr>
                  <tr>
                    <td class="has-text-weight-bold">GL Balance (subtotal)</td>
                    <td class="has-text-weight-bold">{{frontGrossSubtotalCDK(dealItem) | currency}}</td>
                    <td class="has-text-weight-bold">{{frontGrossSubtotalLive(dealItem) | currency}}</td>
                  </tr>
                    <tr>
                    <td>Sale Price</td>
                    <td>{{dealItem.salePrice ? dealItem.salePrice  : 0.00 | currency}}</td>
                    <td>{{dealItem.salePrice ? dealItem.salePrice  : 0.00 | currency}}</td>
                  </tr>
                  <tr>
                    <td>Acv</td>
                    <td>{{totalAcvDifference ? totalAcvDifference : 0.00 | currency}}</td>
                    <td>{{totalAcvDifference ? totalAcvDifference : 0.00 | currency}}</td>
                  </tr>
                  <tr>
                    <td>Fees</td>
                    <td>{{additionalCostsGP | currency}}</td>
                    <td>{{additionalCostsGP | currency}}</td>
                  </tr>
                  <tr>
                    <td class="has-text-weight-bold">Total Front GP</td>
                    <td class="has-text-weight-bold">{{calculatedFrontGrossCDK(dealItem) | currency}}</td>
                    <td class="has-text-weight-bold">{{calculatedFrontGross(dealItem) | currency}}</td>
                  </tr>
                </table>
              </div>

              <br/>
              <label class="label">Optional Products</label>
              <div>
                <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                  <thead v-if="dealItem.itemProducts.length > 0">
                    <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Cost</th>
                    <th>Price</th>
                    </tr>
                  </thead>
                  <tr v-for="(product, index) in dealItem.itemProducts" :key="product.id">
                    <td>{{index + 1}}</td>
                    <td>{{product.name}}</td>
                    <td>{{product.cost | currency}}</td>
                    <td>{{product.sellingPrice | currency}}</td>
                  </tr>
                  <tr v-if="dealItem.itemProducts.length === 0">
                    <td>None specified</td>
                  </tr>
                </table>
              </div>
              <div v-if="(dealItem.vehicleType === 'New' && dealItem.forInventory.rdrComplete === true)">
                <hr class="block-hr-divider"/>
                <span class="title is-6 is-inline-block">Rdr Status</span>
                <div class="columns is-mobile">
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">RDR Complete</label>
                      <div class="field">
                        {{dealItem.forInventory.rdrComplete ? 'Yes' : 'No'}}
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">RDR Completed Date</label>
                      <div class="field">
                        {{dealItem.forInventory.rdrCompletedAt | formatDateOnly}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-else>
              <form class="view-container" @change="onFormChange">
                <div class="columns is-mobile is-multiline">
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Front GP</label>
                      <div class="control has-icons-left">
                        <money :disabled="isGrossDisabled(dealItem)" name="frontValue" data-vv-as="front GP" v-validate="'required|decimal'" :class="{'input': true, 'is-danger': errors.has('frontValue') }" type="text" placeholder="0.00" v-model="dealItem.frontValue" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('frontValue')" class="help is-danger"><i v-show="errors.has('frontValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('frontValue') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                      <!--
                      <label class="label-frontgp is-pulled-right" :class="{'has-text-danger': round(dealItem.frontValue) !== round(calculatedFrontGross(dealItem)), 'has-text-success': round(dealItem.frontValue) === round(calculatedFrontGross(dealItem))}"
                        @click="dealItem.frontValue = calculatedFrontGross(dealItem)" title="This is the expected value for front GP. Click here to make this the front GP value.">{{calculatedFrontGross(dealItem) | currency}}</label>
                        -->
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Warranty GP</label>
                      <div class="control has-icons-left">
                        <money name="warValue" data-vv-as="warranty GP" v-validate="'required|decimal'" :class="{'input': true, 'is-danger': errors.has('warValue') }" type="text" placeholder="0.00" v-model="dealItem.warValue" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('warValue')" class="help is-danger"><i v-show="errors.has('warValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('warValue') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Sale Price</label>
                      <div class="control has-icons-left">
                        <money name="salePrice" data-vv-as="sale price" v-validate="'required|decimal'" :class="{'input': true, 'is-danger': errors.has('salePrice') }" type="text" placeholder="0.00" v-model="dealItem.salePrice" v-bind="$globalMoneyFormat" @change.native="updateFrontGross()"></money>
                        <span v-show="errors.has('salePrice')" class="help is-danger"><i v-show="errors.has('salePrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('salePrice') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Finance Reserve</label>
                      <div class="control has-icons-left">
                        <money name="finResValue" data-vv-as="finance reserve" v-validate="'required|decimal'" :class="{'input': true, 'is-danger': errors.has('finResValue') }" type="text" placeholder="0.00" v-model="dealItem.finResValue" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('finResValue')" class="help is-danger"><i v-show="errors.has('finResValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('finResValue') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" v-if="dealItem.vehicleType === 'New'">
                    <div class="field">
                      <label class="label">Holdback</label>
                      <div class="control has-icons-left">
                        <money name="holdValue" data-vv-as="holdback" style="width:64%" v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('holdValue') }" type="text" placeholder="0.00" v-model="dealItem.holdValue" v-bind="$globalMoneyFormat" :disabled="!isHoldbackEditable"></money>
                        <span class="control" v-if="hasFeatureAccess('deal.modify.holdback', false)">
                          <a class="button" v-if="!isHoldbackEditable" @click="isHoldbackEditable = true" title="Click to unlock field for editing">
                            <i class="fal fa-unlock fa-sm"></i>
                          </a>
                          <a class="button" @click="isHoldbackEditable = false" title="Click to re-lock opened field without saving" v-else>
                            <i class="fal fa-lock fa-sm"></i>
                          </a>
                        </span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                      <label class="label-frontgp is-pulled-right" :class="{'has-text-danger': round(dealItem.holdValue) !== round(inventoryHoldValue(dealItem.forInventory)), 'has-text-success': round(dealItem.holdValue) === round(inventoryHoldValue(dealItem.forInventory))}"
                        @click="onSetHoldBack(dealItem)" title="This is the holdback value for this model. Click here to make this the holdback value for this deal.">{{inventoryHoldValue(dealItem.forInventory) | currency}}
                      </label>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label no-content-wrap">Bonus</label>
                      <div class="control has-icons-left">
                        <money name="mvpValue" data-vv-as="bonus" v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('mvpValue') }" type="text" placeholder="0.00" v-model="dealItem.mvpValue" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Spiff</label>
                      <div class="control has-icons-left">
                        <money name="spiff" data-vv-as="spiff" v-validate="'required|decimal'" :class="{'input': true, 'is-danger': errors.has('spiff') }" type="text" placeholder="0.00" v-model="dealItem.spiff" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('spiff')" class="help is-danger"><i v-show="errors.has('spiff')" class="fas fa-exclamation-triangle"></i> {{ errors.first('spiff') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Product GP</label>
                      <div class="control has-icons-left">
                        <money :class="{'input': true}" type="text" placeholder="0.00" :value="dealItemProductGP(dealItem)" v-bind="$globalMoneyFormat" disabled></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Recon Cost</label>
                      <div class="control has-icons-left">
                        <money :class="{'input': true}" type="text" placeholder="0.00" :value="getDealItemReconCost(dealItem)" v-bind="$globalMoneyFormat" disabled></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Trade Allowance</label>
                      <div class="control has-icons-left">
                        <money :class="{'input': true}" type="text" placeholder="0.00" :value="totalTradeAllowance" v-bind="$globalMoneyFormat" disabled></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">{{acvLabel}}<span class="icon has-text-danger acv-warning" v-if="showAcvWarning"><i class="fas fa-exclamation-triangle"></i></span></label>
                      <div class="control has-icons-left">
                        <money :class="{'input': true}" type="text" placeholder="0.00" :value="totalAcvDifference" v-bind="$globalMoneyFormat" disabled></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Total GP</label>
                      <div class="control has-icons-left">
                        <money :class="{'input': true}" type="text" placeholder="0.00" :value="dealItemTotalGP(dealItem)" v-bind="$globalMoneyFormat" disabled></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <label class="label">Front GP Calculation</label>
                <div>
                  <table class="table">
                    <tr>
                      <th>Item</th>
                      <th>CDK</th>
                      <th>LIVE</th>
                    </tr>
                    <tr>
                      <td>Purchase Cost</td>
                      <td>{{(dealItem.forInventory && dealItem.forInventory.purchaseCostTotal) ? dealItem.forInventory.purchaseCostTotal : 0.00 | currency}}</td>
                      <td>{{(dealItem.forInventory && dealItem.forInventory.purchaseCostTotal) ? dealItem.forInventory.purchaseCostTotal : 0.00 | currency}}</td>
                    </tr>
                    <tr>
                      <td>Recon</td>
                      <td>{{calculatedReconCDK(dealItem) | currency}}</td>
                      <td>{{calculatedReconLive(dealItem) | currency}}</td>
                    </tr>
                    <tr>
                      <td class="has-text-weight-bold">GL Balance (subtotal)</td>
                      <td class="has-text-weight-bold">{{frontGrossSubtotalCDK(dealItem) | currency}}</td>
                      <td class="has-text-weight-bold">{{frontGrossSubtotalLive(dealItem) | currency}}</td>
                    </tr>
                      <tr>
                      <td>Sale Price</td>
                      <td>{{dealItem.salePrice ? dealItem.salePrice  : 0.00 | currency}}</td>
                      <td>{{dealItem.salePrice ? dealItem.salePrice  : 0.00 | currency}}</td>
                    </tr>
                    <tr>
                      <td>Acv</td>
                      <td>{{totalAcvDifference ? totalAcvDifference : 0.00 | currency}}</td>
                      <td>{{totalAcvDifference ? totalAcvDifference : 0.00 | currency}}</td>
                    </tr>
                    <tr>
                      <td>Fees</td>
                      <td>{{additionalCostsGP | currency}}</td>
                      <td>{{additionalCostsGP | currency}}</td>
                    </tr>
                    <tr>
                      <td class="has-text-weight-bold">Total Front GP</td>
                      <td class="has-text-weight-bold">{{calculatedFrontGrossCDK(dealItem) | currency}}</td>
                      <td class="has-text-weight-bold">{{calculatedFrontGross(dealItem) | currency}}</td>
                    </tr>
                  </table>
                </div>

                <br/>
                <product-cost-container v-model="soldItems[index]" :showDescription="false" :showType="true" :usePortal="true"></product-cost-container>
                <hr class="block-hr-divider"  v-if="dealItem.vehicleType === 'New'"/>
                <span class="title is-6 is-inline-block" v-if="dealItem.vehicleType === 'New'">Rdr Status</span>
                <rdr-capture v-model="dealItem.forInventory" v-if="dealItem.vehicleType === 'New'"></rdr-capture>
              </form>
            </template>

            <p><br/></p>

            <div v-if="currentDeal.stockType !== 'Locate' && currentDeal.stockType !== 'Order'">
              <hr class="block-hr-divider"/>
              <span class="title is-6 is-inline-block">Accessories</span>
              <div class="columns" v-if="currentDealer.trackVehicleAccessories === true && hasAccessories">
                <div class="column is-12 no-h-padding">
                  <b-table
                    :data="dealItem.forInventory.accessoryEvents"
                    :sortable="false"
                    :mobile-cards="false"
                    :selecteable="false"
                    :row-class="() => 'accesory-event-row'">
                    <template slot-scope="props">
                      <b-table-column field="type" label="Status" class="accesory-event-column">
                        <i class="icon fal fa-key fa-sm" :class="getColorClassByAccessoryStatus(props.row.typeId)" title="Accessory Status"/>
                        {{ props.row.type == 'CheckedOut' ? 'Out' : 'In' }}
                      </b-table-column>

                      <b-table-column field="binsDescription" label="Bins" class="accesory-event-column" :title="props.row.binsDescription">
                        {{ (props.row.binsDescription !== '' ? props.row.binsDescription : 'n/a') | truncate(10) }}
                      </b-table-column>

                      <b-table-column field="givenTo" label="Given To" class="accesory-event-column">
                        {{ props.row.givenTo !== '' ? props.row.givenTo : 'n/a' }}
                      </b-table-column>

                      <b-table-column field="createdAt" label="Created" class="accesory-event-column">
                        {{ props.row.createdAt | formatDateOnly }}
                      </b-table-column>

                      <b-table-column field="createdBy" label="Creator" class="accesory-event-column">
                        {{ props.row.createdBy}}
                      </b-table-column>

                      <b-table-column field="id" label="View" class="accesory-event-column">
                        <accessory-event-view :currentInventory="dealItem.forInventory" :accessoryEvent="props.row"></accessory-event-view>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No accessory events recorded for this vehicle.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </div>
              </div>
              <quick-action-container>
                <accessory-event-capture :currentInventory="dealItem.forInventory"></accessory-event-capture>
              </quick-action-container>
            </div>

            <div v-if="currentDealer.trackWorkshopInfo === true && currentDeal.stockType !== 'Locate' && currentDeal.stockType !== 'Order'">
              <hr class="block-hr-divider"/>

              <div ref="serviceRequestPod" v-if="currentDealer.trackWorkshopInfo === true && dealItem.forInventory.possessionStatus !== 'Locating'">
                <span class="title is-6 is-inline-block">Workshop</span>
                <div class="columns" v-if="hasWorks">
                  <div class="column is-12 no-h-padding">
                    <b-table
                      :data="dealItem.forInventory.serviceRequests"
                      :mobile-cards="false"
                      :sortable="false"
                      :selecteable="false"
                      @select="onServiceRequestRowSelected"
                      @dblclick="onServiceRequestRowSelected">
                      :row-class="() => 'service-request-row'">
                      <template slot-scope="props">
                        <b-table-column field="requestStatusType" label="Status" class="service-request-column">
                          <i class="icon fal fa-layer-plus fa-lg list-icon" :class="getColorClassByGetReadyStatus(props.row.requestStatusTypeId)" title="Status" v-if="props.row.requestStatusTypeId === 0"/>
                          <i class="icon fal fa-wrench fa-lg list-icon" :class="getColorClassByGetReadyStatus(props.row.requestStatusTypeId)" title="Status" v-else/>
                          {{ requestStatusType(props.row.requestStatusType) }}
                        </b-table-column>

                        <b-table-column field="serviceRequestType" label="Type" class="service-request-column">
                          {{ requestType(props.row.serviceRequestType) }}
                        </b-table-column>

                        <b-table-column field="pendingCosts" label="Pending" class="service-request-column has-text-right" :title="props.row.pendingCosts | currency">
                          {{ props.row.pendingCosts | currency }}
                        </b-table-column>

                        <b-table-column field="approvedCosts" label="Approved" class="service-request-column has-text-right" :title="props.row.approvedCosts | currency">
                          {{ props.row.approvedCosts | currency }}
                        </b-table-column>
                      </template>
                      <template slot="empty">
                        <section class="section">
                          <div class="has-text-centered">
                            <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                            <p>No service requests recorded for this vehicle.</p>
                          </div>
                        </section>
                      </template>
                      <template slot="footer" v-if="dealItem.forInventory.serviceRequests.length > 0">
                        <div class="is-flex" style="justify-content: space-evenly">
                          <span class="tag is-black">Declined: {{ totalDeclinedServiceRequestCosts(dealItem) | currency }}</span>
                          <span class="tag is-danger">Pending: {{ totalPendingServiceRequestCosts(dealItem) | currency }}</span>
                          <span class="tag is-primary">Approved: {{ totalApprovedServiceRequestCosts(dealItem) | currency}}</span>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
                <quick-action-container>
                  <service-request-capture :currentInventory="dealItem.forInventory"></service-request-capture>
                </quick-action-container>
              </div>
              <div v-else class="column is-12 is-size-7 has-text-info">No information available at this time</div>
            </div>
          </div>

          <div class="box" v-for="(dealItem, index) in this.tradeItems.filter(x=>x.id != 0)" :key="dealItem.id">
            <section-header :title="'Trade Item (' + (index + 1) +')'"></section-header>
            <div class="columns is-mobile is-multiline">
              <div class="column is-12">
                <div v-if="dealItem.forInventory.arrivalSkipped" class="notification is-danger is-flex">
                  <span>
                    <i class="fas fa-exclamation-triangle fa-2x"></i>
                  </span>
                  <span class="ml-10">
                    WARNING: Trade vehicle arrival was skipped by <span class="has-text-weight-semibold">{{ dealItem.forInventory.arrivalSkippedBy }}</span>. This vehicle requires arrival and inspection.
                  </span>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Traded Vehicle</label>
                  <div class="field">
                    <a class="is-link" @click="onViewInventory(dealItem)">
                      <span class="is-inline-block" v-html="makeOverview(dealItem.forInventory)"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Preview</label>
                  <figure class="image is-100x100" v-viewer.static="{inline: false, title: false}">
                    <img :src="getImage(dealItem.forInventory)"/>
                    <img v-for="image in getAllImages(dealItem.forInventory)" :key="image.id" :src="image.src" style="display:none"/>
                  </figure>
                </div>
              </div>
              <div class="column is-12">
                <car-fax-control :key="dealItem.forInventory.id" :inventory="dealItem.forInventory" :autoUpdateInventory="false" :carFaxRating="dealItem.forInventory.carFaxRating" :vinNo="dealItem.forInventory.vinNo"></car-fax-control>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Possession Status</label>
                  <div class="field">
                    {{ dealItem.forInventory ? dealItem.forInventory.possessionStatus : "" }}
                  </div>
                </div>
              </div>
              <div class="column is-6" v-if=" dealItem.forInventory && dealItem.forInventory.locationName">
                <div class="field">
                  <label class="label">Location</label>
                  <div class="field">
                    {{ dealItem.forInventory ? dealItem.forInventory.locationName : ""}}
                  </div>
                </div>
              </div>
              <div class="column is-12" v-if=" dealItem.forInventory && dealItem.forInventory.accessoryStatus">
                <div class="field">
                  <label class="label">Accessory Status</label>
                  <div class="field">
                    {{ dealItem.forInventory ? dealItem.forInventory.accessoryStatus : ""}}
                  </div>
                </div>
              </div>
            </div>
            <quick-action-container>
              <inventory-sellers-agreement :currentInventory="dealItem.forInventory" :key="'S' + dealItem.forInventory.id"></inventory-sellers-agreement>
              <inventory-batch-payment-request :currentInventory="dealItem.forInventory" :key="'P' + dealItem.forInventory.id"></inventory-batch-payment-request>
              <send-trade-in-title-reassignment-documentation :inventoryItem="dealItem.forInventory" :isListMode="false" v-if="!currentDealIsCancelled"></send-trade-in-title-reassignment-documentation>
            </quick-action-container>
          </div>

          <div id="supplement-cost-section" class="box" v-if="isEditLocked" v-show="showCostsSection">
            <section-header title="Additional Costs" parentSelector="#supplement-cost-section" :collapsed="supplementalCosts.length === 0"></section-header>
            <div>
              <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                <thead v-if="supplementalCosts.length > 0">
                  <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Cost</th>
                  <th>Selling Price</th>
                  </tr>
                </thead>
                <tr v-for="(supplementalCost, index) in supplementalCosts" :key="supplementalCost.id">
                  <td>{{index + 1}}</td>
                  <td>{{supplementalCost.name}}</td>
                  <td>{{supplementalCost.cost | currency}}</td>
                  <td>{{supplementalCost.sellingPrice | currency}}</td>
                </tr>
                <tr v-if="supplementalCosts.length === 0">
                  <td>None specified</td>
                </tr>
              </table>
            </div>
          </div>

          <div id="supplement-cost-section" class="box" v-else v-show="showCostsSection">
            <section-header title="Additional Costs" parentSelector="#supplement-cost-section" :collapsed="supplementalCosts.length === 0"></section-header>
            <div class="columns">
              <div class="column is-12 no-h-padding">
                <b-table
                  :mobile-cards="false"
                  :data="this.supplementalCosts">
                  <template slot-scope="props">
                    <b-table-column field="name" label="Name" :class="{'striked': props.row.delete === true}" sortable>
                      {{ props.row.name }}
                    </b-table-column>

                    <b-table-column field="cost" label="Cost" :class="{'striked': props.row.delete === true}" sortable>
                      {{ props.row.cost | currency }}
                    </b-table-column>

                    <b-table-column field="sellingPrice" label="Selling Price" :class="{'striked': props.row.delete === true}" sortable>
                      {{ props.row.sellingPrice | currency }}
                    </b-table-column>

                    <b-table-column field="" label="Actions">
                      <span class="icon" @click="editSupplementalCost(props.row, props.index)" v-if="props.row.delete !== true">
                        <i class="fal fa-pencil"></i>
                      </span>
                      <span class="icon" @click="removeSupplementalCost(props.index)" v-if="props.row.delete !== true">
                        <i class="fal fa-minus-circle"></i>
                      </span>
                      <span class="is-size-7 has-text-danger" v-if="props.row.delete === true">
                        Pending Delete
                      </span>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="has-text-centered">
                        <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                        <p>No additional costs have been added.</p>
                      </div>
                    </section>
                  </template>
                  <template slot="footer" v-if="availableAdditionalCosts.length > 0">
                    <th colspan="2"></th>
                    <th colspan="1"><label class="label">{{additionalCostsTotal | currency}}</label></th>
                    <th colspan="1"></th>
                  </template>
                </b-table>
              </div>
            </div>
            <supplement-cost-capture :value="this.newSupplementalCost" :supplementalCosts="this.supplementalCosts" :usePortal="true"></supplement-cost-capture>
            <div class="inner-add-remove-button">
              <div class="quick-action">
                <button class="button is-pulled-left" type="button" @click="addSupplementalCost()" title="Click to add new supplemental cost.">
                  <b-icon pack="fal" icon="plus" custom-size="fa-lg"></b-icon>
                </button>
                <button class="button is-warning is-pulled-right" type="button" @click="restoreDealAdditionalCosts()">
                  <b-icon pack="fal" icon="undo" custom-size="fa-lg"></b-icon>
                </button>
              </div>
            </div>
            <br/>
          </div>

          <div id="commission-section" class="box" v-if="isEditLocked" v-show="showCommissionsSection">
            <section-header title="Commissions" parentSelector="#commission-section" :collapsed="commissions.length === 0"></section-header>
            <div>
              <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                <thead v-if="commissions.length > 0">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Commission</th>
                  </tr>
                </thead>
                <tr v-for="(commission, index) in commissions" :key="commission.id">
                  <td>{{index + 1}}</td>
                  <td>{{commission.salesPersonName}}</td>
                  <td :class="getCommissionRequestIconColour(commission)" :title="commission.requestStatus">{{commission.commission | currency}}</td>
                </tr>
                <tr v-if="commissions.length === 0">
                  <td>None specified</td>
                </tr>
              </table>
            </div>
          </div>

          <div id="commission-section" class="box" v-else v-show="showCommissionsSection">
            <section-header title="Commissions" parentSelector="#commission-section" :collapsed="this.commissions.length === 0"></section-header>
            <div class="columns">
              <div class="column is-12 no-h-padding">
                <b-table
                :mobile-cards="false"
                :data="this.commissions">
                  <template slot-scope="props">
                    <b-table-column field="salesPersonName" label="Sales Person" :class="{'striked': props.row.delete === true}" sortable>
                      {{ props.row.salesPersonName }}
                    </b-table-column>

                    <b-table-column field="commission" label="Commission" :class="{'striked': props.row.delete === true}" sortable>
                      {{ props.row.commission | currency }}
                    </b-table-column>

                    <b-table-column field="" label="Actions">
                      <section style="padding-top: 0em; display: flex; justify-content: flex-start;flex-wrap: wrap;">
                        <span class="icon" @click="editCommission(props.row, props.index)" v-if="props.row.delete !== true">
                          <i class="fal fa-pencil"></i>
                        </span>
                        <span class="icon" @click="removeCommission(props.index)" v-if="props.row.delete !== true">
                          <i class="fal fa-minus-circle"></i>
                        </span>
                        <span class="is-size-7 has-text-danger" v-if="props.row.delete === true">
                          Pending Delete
                        </span>
                        <deal-commission-processing-action :commissionRequest="props.row" :saveStatusChange="false" v-on:commission-request-status-change="commissionRequestStatusChange" :index="props.index"></deal-commission-processing-action>
                      </section>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="has-text-centered">
                        <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                        <p>No commissions have been added.</p>
                      </div>
                    </section>
                  </template>
                  <template slot="footer" v-if="this.availableCommissions.length > 0">
                    <th colspan="1"></th>
                    <th colspan="1"><label class="label">{{commissionsTotal | currency}}</label></th>
                    <th colspan="1"></th>
                  </template>
                </b-table>
              </div>
            </div>
            <commission-capture :value="this.newCommission" :commissions="this.commissions" :usePortal="true"></commission-capture>
            <div class="inner-add-remove-button">
              <div class="quick-action is-pulled-left">
                <button class="button is-pulled-left" type="button" @click="addCommission()" title="Click to add a new commission.">
                  <b-icon pack="fal" icon="plus" custom-size="fa-lg"></b-icon>
                </button>
              </div>
              <br/>
            </div>
          </div>

          <div class="box" v-show="showNotesSection">
            <section-header
              title="Notes"
              :collapsed="true"
              :overview="buildNotesSectionOverview(currentDeal, 50)">
            </section-header>
            <br/>
            <note-timeline-view
              v-model="currentDeal.notes"
              entity="deal"
              :isHtml="true">
            </note-timeline-view>
            <quick-action-container>
              <deal-note-capture :deal="currentDeal" :isListMode="false"></deal-note-capture>
            </quick-action-container>
          </div>

          <div ref="documents"></div>
          <documents-capture :currentDeal="currentDeal" :isCollapsed="isDocumentPodCollapsed" v-if="currentDeal && currentDeal.id"></documents-capture>

          <div class="box">
            <section-header title="Tags" :collapsed="true"></section-header>
              <tag-view v-model="this.tagsList"></tag-view>
              <quick-action-container>
                <deal-tags-capture :dealItem="currentDeal" :isListMode="false" :key="currentDeal.id"></deal-tags-capture>
              </quick-action-container>
          </div>

          <div class="box" ref="creditApplicationStatusContainer">
            <section-header title="Credit Application Status" :collapsed="false" ></section-header>
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="field">
                  <label class="label">Credit Application Status</label>
                  <div class="field">
                    {{ buildCreditStatusToolTip(currentDeal) }}
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <label class="label">Credit Application Status</label>
                <div class="field">
                  <multiselect
                    :hide-selected="true"
                    :allowEmpty="false"
                    :showLabels="false"
                    name="selectedCreditStatus"
                    v-model="dealCreditApplicationStatus"
                    label="name"
                    :options="this.definedTypes.dealCreditApplicationStatus.options">
                  </multiselect>
                </div>
                <div class="field is-size-7 is-pulled-right mt-10">
                  <b-checkbox
                    :native-value="false"
                    name="chkBankApproval"
                    type="is-info"
                    v-model="bankApprovalStatus">
                    Bank Approval Status
                  </b-checkbox>
                </div>
              </div>
              <div class="column is-12">
                <label class="label">Credit Application Note</label>
                <div class="field">
                  <textarea name="note" :class="{ 'textarea': true }" placeholder="" v-model="creditApplicationNote"></textarea>
                </div>
              </div>
              <div class="column is-12" style="padding-left: 20px;">
                <div class="field">
                  <div class="field">
                    <file-selector
                      v-model="bankCreditApplicationFiles"
                      label="Bank Credit Application Approval Files"
                      :enabled="true"
                      :multiple="true"
                      :showFileOnly="false"
                      :isBoxed="false"
                      :formatImages="true"
                      :usePortalViewer="true">
                    </file-selector>
                  </div>
                </div>
              </div>
              <div class="column is-narrow quick-action">
                <a class="button is-pulled-left has-text-success" target="_blank" @click="saveCreditApplicationStatusInfo" v-tooltip="{content: 'quick save'}">
                  <i class="fal fa-save fa-m"></i>
                </a>
                <a style="margin-left: 10px;" class="button is-pulled-left has-text-danger" target="_blank" @click="clearCreditApplicationStatus" v-tooltip="{content: 'clear status'}">
                  <i class="fal fa-ban fa-m"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="box" v-if="currentDeal.clonedFromOrderDeal">
            <section-header title="Order Deal" :collapsed="false"></section-header>
            <div class="field">
              <label class="label">View order deal</label>
              <div class="field">
                <a class="is-link" @click="onViewDeal(currentDeal.clonedFromDealId)">
                  Deal Code: {{ currentDeal.clonedFromDealCode || 'Not Specified' }}, Ordered At: {{currentDeal.clonedFromDealCreatedAt}}
                </a>
              </div>
            </div>
          </div>

          <div class="box">
            <section-header title="Update History" :collapsed="true"></section-header>
            <br />
            <div class="columns is-mobile is-multiline">
              <div class="column is-6">
                <label class="label">Created By:</label>
                <div class="field">
                  {{ currentDeal.createdByFullName }}
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Created At:</label>
                <div class="field">
                  {{ currentDeal.createdAt | formatDate }}
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Modified By:</label>
                <div class="field">
                  {{ currentDeal.modifiedByFullName }}
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Modified At:</label>
                <div class="field">
                  {{ currentDeal.modifiedAt | formatDate }}
                </div>
              </div>
            </div>
            <br/>
            <table class="table is-bordered is-narrow is-hoverable is-fullwidth" v-if="currentDeal && currentDeal.statusAuditHistory && currentDeal.statusAuditHistory.length > 0">
              <thead>
                <tr>
                  <th>To Status</th>
                  <th>Booking Finalised</th>
                  <th>Actioned By</th>
                  <th>Actioned On</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="currentStatus in currentDeal.statusAuditHistory" :key="currentStatus.id">
                  <td>{{ currentStatus.toStatus}}</td>
                  <td>{{ currentStatus.newIsBookingFinalised | yesno}}</td>
                  <td>{{ currentStatus.actionedBy}}</td>
                  <td>{{ currentStatus.actionedAt | formatDate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="arrival-status-section" class="box" v-if="isEditLocked">
            <section-header title="Inbound Status History" parentSelector="#arrival-status-section" :collapsed="currentInboundStatusHistoryItems.length === 0"></section-header>
            <div>
              <table class="table is-bordered is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Initial Status</th>
                    <th>New Status</th>
                    <th>Actioned By</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tr v-for="(inboundStatusHistoryItem, index) in currentInboundStatusHistoryItems" :key="inboundStatusHistoryItem.id">
                  <td>{{index + 1}}</td>
                  <td>
                    {{getMatchingInboundStatus(inboundStatusHistoryItem.fromStatus)}}
                  </td>
                  <td>{{getMatchingInboundStatus(inboundStatusHistoryItem.toStatus)}}</td>
                  <td>{{inboundStatusHistoryItem.actionedBy}}</td>
                  <td>{{inboundStatusHistoryItem.actionedAt | formatDateOnly}}</td>
                </tr>
                <tr v-if="currentInboundStatusHistoryItems.length === 0">
                  <td colspan="5">None</td>
                </tr>
              </table>
            </div>
          </div>

          <portal :to="actionPortal">
            <div class="field is-grouped">
              <p class="field">
                <a class="button is-danger" @click="onCancel()" :class="{'is-loading': isSavingAllChanges }">Close</a>
              </p>
              <p class="field" v-if="hasFeatureAccess('deal.modify') && isEditLocked && !bookedLocked">
                <a class="button is-primary" @click="onEdit()">Edit</a>
              </p>
              <p class="field" v-if="hasFeatureAccess('deal.modify') && !isEditLocked && !bookedLocked && !cancelLocked">
                <a class="button is-primary" @click="saveChanges()" :class="{'is-loading': isSavingAllChanges }">Save</a>
              </p>
              <p class="field" v-if="hasFeatureAccess('deal.modify')">
                <a class="button is-primary" @click="statusProgressionWizardActive = true" :class="{'is-loading': isSavingAllChanges }">Progress</a>
              </p>
              <div class="quick-action-list">
                <div class="quick-action" v-if="currentDeal && currentDeal.type === 'Booked'">
                  <button class="button" @click="showPricing" title="View deal pricing">
                    <i class="icon fal fa-file-invoice-dollar fa-lg clickable"/>
                  </button>
                </div>
                <deal-code-capture v-if="currentDeal.type === 'Booked' && !currentDeal.isBookingFinalised" :dealListModel="currentDeal" :key="'dcc2' + currentDeal.id" :isListMode="false"></deal-code-capture>
                <deal-note-capture v-if="!isAllocatedDeal" :deal="currentDeal" :isListMode="false"></deal-note-capture>
                <arrival-status-change :dealItem="currentDeal" v-if="currentDeal && currentDeal.type === 'AwaitingArrival'" :isListMode="false"></arrival-status-change>
                <pod-lock-control v-if="!isAllocatedDeal" v-model="isEditLocked" :bypassPermissions="byPassPermissions" :masterBypassPermissions="!bookedLocked ? [] : ['deal.modify.unlockbooked']"></pod-lock-control>
              </div>
            </div>
          </portal>
        </div>

        <portal to="global-modal-portal" v-if="inventoryDealIsPrimaryActive">
          <b-modal :active.sync="inventoryDealIsPrimaryActive" :width="640" scroll="keep" :has-modal-card="true">
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                  <p class="modal-card-title">Warning</p>
              </header>
              <section class="modal-card-body">
                Do you want to make this deal the primary deal for this inventory record?
              </section>
              <footer class="modal-card-foot">
                <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmInventoryDealIsPrimary(true)" type="button">Yes</button>
                <button class="button is-danger" @click="onConfirmInventoryDealIsPrimary(false)" type="button">No</button>
              </footer>
            </div>
          </b-modal>
        </portal>
      </div>

      <portal to="global-modal-portal" v-if="isNewArrivalCaptureActive">
        <b-modal :active.sync="isNewArrivalCaptureActive" scroll="keep" :has-modal-card="true" v-if="firstSoldItemInventory" :canCancel="['escape', 'x']">
          <arrival-capture-detail
            :inventoryItem="firstSoldItemInventory"
            v-on:arrival-capture-complete="onArrivalCaptureComplete"
            :useModal="false"
            :setPending="setDealPendingArrival"
            :isBookingWizard="true">
          </arrival-capture-detail>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="isNewCheckRequest">
        <b-modal :active.sync="isNewCheckRequest" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Configure Check Request</p>
            </header>
            <section class="modal-card-body view-container" style="width: 420px;">
              <div class="columns">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Recipients: </label>
                    <multiselect
                      :multiple="true"
                      :taggable="true"
                      @tag="emailAdded"
                      name="email address selector"
                      tagPlaceholder="Enter to add email address"
                      v-model="selectedAddresses"
                      :options="selectedAddresses">
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Overriding Subject</label>
                    <div class="control">
                      <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Optional Message</label>
                    <div class="control">
                      <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onEmailCheckRequest" type="button">Email</button>
              <button class="button is-danger" @click="updateEmailModalState(false)" type="button">Cancel</button>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="statusProgressionWizardActive">
        <b-modal
          :active.sync="statusProgressionWizardActive"
          :no-close-on-esc="true"
          :no-close-on-backdrop="true"
          scroll="keep"
          :has-modal-card="true"
          :canCancel="['x']"
          id="deal-progression-modal"
          :key="'spw' + currentDealId">
          <div id="status-progression-wizard-modal" class="modal-card">
            <header class="modal-card-head">
              <div class="columns">
                <div class="column">
                  <div class="modal-card-title is-size-5 has-text-weight-semibold">Status Progression Wizard</div>
                  <span v-if="isPreDeal" class="mt-10 tag is-violet is-large is-size-6 clickable" @click="onClearPreDeal" title="Click to deactivate the pre-deal status on this deal.">{{getTitlePreDealText}}</span>
                </div>
                <div class="column is-4">
                  <div class="is-marginless is-paddingless">
                    <div style="overflow: hidden;">
                      <h1 class="is-size-7 has-text-weight-semibold is-inline-block is-marginless is-paddingless is-pulled-right">{{ purchaserName }} -
                        <a @click.stop.prevent="onStockNoClick(firstSoldItemInventory)" v-if="firstSoldItemInventory"><span :class="getStockNoColorClassByGetReadyAndAccessoryStatus(firstSoldItemInventory.stockNo, firstSoldItemInventory.getReadyStatusId, firstSoldItemInventory.accessoryStatusId)" > {{ firstSoldItemStockNo }}</span></a>
                      </h1>
                      <br>
                      <h1 class="is-size-7 has-text-weight-semibold is-inline-block is-marginless is-paddingless is-pulled-right">
                        <span v-html="buildDealWidgetTitle(currentDeal, this.firstSoldItem)"></span>
                      </h1>
                    </div>
                    <div class="is-flex is-pulled-right mt-10" style="align-items: flex-start">
                      <span v-if="showTradeWorkFlowItemAction" v-for="(tradeDealItem, index) in tradeDealItems" :key="index" class="icon-text has-text-centered ml-10">
                        <div class="icon has-text-info">
                          <i style="margin-top: 10px; margin-right: 10px;"
                            :data-type="getDataTypeByTradeStatus(createTradeWorkFlowItem(tradeDealItem))"
                            :attr="getTradeStatusBorderStatus(createTradeWorkFlowItem(tradeDealItem))"
                            class="grid-icon fa-fw fal fa-lg clickable"
                            :class="getColorClassByTradeStatus(createTradeWorkFlowItem(tradeDealItem))"
                            v-tooltip="getTradeDescriptionByTradeStatus(createTradeWorkFlowItem(tradeDealItem))"
                            @click.exact.stop.prevent="onTradeItemClick(createTradeWorkFlowItem(tradeDealItem))"/>
                        </div>
                        <div>
                          <h1 class="is-size-7 has-text-weight-semibold is-inline-block is-marginless is-paddingless is-pulled-right">{{tradeDealItem.forInventory.stockNo}}</h1>
                        </div>
                      </span>

                      <i v-if="showEditPreEntryAction" class="grid-icon fa-fw fal fa-lg clickable fa-car-tilt" v-tooltip="{content: 'Click to stock-in trade pre-entry'}" @click="editTradePreEntryActive=true"></i>
                      <convert-ppa-to-purchase-action v-if="showPPAConvertAction" :dealId="currentDeal.id " :inventory="tradeItem" :usePortal="false" :defaultType="{id: 4, name: 'Trade In', altName: 'TradeIn'}" :openEditInventoryInModal="true" :key="'cptp' + tradeItem.id"></convert-ppa-to-purchase-action>
                      <approve-ppa-action v-if="showPPAApproveAction" :inventoryItem="tradeItem" :reloadList="false" :usePortal="false"></approve-ppa-action>
                      <trade-deal-capture v-if="!dealHasTrade && !bookedLocked  && !isCancelledDeal && !isAllocatedDeal" :deal="currentDeal" :selectedDealer="selectedDealer" :usePortal="false"></trade-deal-capture>
                      <i style="margin-left: 10px; cursor: pointer " class="far fa-sync" @click.exact.stop.prevent="reloadDeal()" v-tooltip="{content: 'refresh deal'}"></i>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <section class="modal-card-body">
              <div class="columns is-mobile is-multiline">
                <div class="column is-full" v-if="serverErrorMessage.length">
                  <p>
                    <b>An unforseen error has occurred:</b>
                    <br/>
                    <ul class="errors">
                        <li>{{ serverErrorMessage }}</li>
                    </ul>
                    <br/>
                  </p>
                </div>
                <div class="column is-full">
                  <nav id="deal-status" class="breadcrumb has-arrow-separator is-size-7" aria-label="breadcrumbs">
                    <ul class="status-breadcrumb">
                      <li :id="getProgressionStatusName(data)" @click="onLaunchStatusChangeModal(data)" v-bind:class="{isactive: selectedDealStatus === data,selectable: data.widgetClass === 'selectable' && selectedDealStatus !== data,
                        notselectable: data.widgetClass === 'notselectable' && selectedDealStatus !== data}" v-for="(data, index) in dealTypeItems" :key="index">
                        <div :class="{box: selectedDealStatus === data}">
                          <span id="deal-status-icon" class="icon is-small">
                            <i :class="data.icon" aria-hidden="true"></i>
                          </span>
                          <span :class="{line: selectedDealStatus === data}">{{data.name}}</span>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div class="column is-full" v-if="currentDeal.type === 'Cancelled'" >
                  <div class="notification is-info">
                    <span>
                      <i class="fas fa-info-circle is-info fa-lg"></i>
                    </span>
                      Cancellation Reason: {{ currentDeal.cancellationReason }}
                  </div>
                </div>
                <div class="column is-full" v-if="bookedLocked && !this.bookedLockedNotificationHidden && this.currentDeal.type !== 'Cancelled'" >
                  <div class="notification is-info">
                    <button class="delete" type="button" @click="bookedLockedNotificationHidden = true"></button>
                    <span>
                      <i class="fas fa-info-circle is-info"></i>
                    </span>
                      Please note: This deal is booked. You will need to unlock it by clicking the lock button below to change its status to Delivered.
                  </div>
                </div>
                <div class="column is-full" v-if="isBackupDeal && firstSoldItem && firstSoldItem.primaryDealForInventory && !isAllocatedDeal">
                  <div class="notification is-warning">
                    <span>
                      <i class="fas fa-exclamation-triangle is-warning"></i>
                    </span>
                      Warning: Stock# <a @click.stop.prevent="onStockNoClick(firstSoldItemInventory)"><span class="has-text-weight-semibold">{{(firstSoldItemInventory) ? firstSoldItemInventory.stockNo : ''}}</span></a> is
                      sold to {{ firstSoldItem && firstSoldItem.primaryDealForInventory ? firstSoldItem.primaryDealForInventory.customerName : ''}}
                      by {{firstSoldItem && firstSoldItem.primaryDealForInventory ? firstSoldItem.primaryDealForInventory.salesPersonName : ''}}.
                      Please note that this is a backup deal for this inventory record.
                      <span v-if="!isCancelledDeal">Click <a @click="showNotificationModal(firstSoldItem)">here</a> to make this the primary deal.</span>
                  </div>
                </div>
                <div class="column is-full" v-if="isBookingStepBlocked">
                  <div class="is-flex" style="justify-content:flex-end; align-items: center;">
                    <span v-if="showTradeWorkFlowItemAction" v-for="(tradeDealItem, index) in tradeDealItems" :key="index" class="icon-text has-text-centered ml-10">
                      <div class="icon has-text-info">
                        <i style="margin-top: 10px; margin-right: 10px;"
                          :data-type="getDataTypeByTradeStatus(createTradeWorkFlowItem(tradeDealItem))"
                          :attr="getTradeStatusBorderStatus(createTradeWorkFlowItem(tradeDealItem))"
                          class="grid-icon fa-fw fal fa-lg clickable"
                          :class="getColorClassByTradeStatus(createTradeWorkFlowItem(tradeDealItem))"
                          v-tooltip="getTradeDescriptionByTradeStatus(createTradeWorkFlowItem(tradeDealItem))"
                          @click.exact.stop.prevent="onTradeItemClick(createTradeWorkFlowItem(tradeDealItem))"/>
                      </div>
                      <div>
                        <h1 class="is-size-7 has-text-weight-semibold is-inline-block is-marginless is-paddingless is-pulled-right">{{tradeDealItem.forInventory.stockNo}}</h1>
                      </div>
                    </span>
                    <i v-if="showEditPreEntryAction" class="grid-icon fa-fw fal fa-lg clickable fa-car-tilt" v-tooltip="{content: 'Click to stock-in trade pre-entry'}" @click="editTradePreEntryActive=true"></i>
                    <convert-ppa-to-purchase-action v-if="showPPAConvertAction" :inventory="tradeItem" :usePortal="false" :defaultType="{id: 4, name: 'Trade In', altName: 'TradeIn'}" :openEditInventoryInModal="true" :key="'cptp-2' + tradeItem.id"></convert-ppa-to-purchase-action>
                    <approve-ppa-action v-if="showPPAApproveAction" :inventoryItem="tradeItem" :reloadList="false" :usePortal="false"></approve-ppa-action>
                    <i style="margin-left: 10px; cursor: pointer" class="far fa-sync" @click.exact.stop.prevent="reloadDeal()" title="refresh deal"></i>
                  </div>
                  <div class="notification is-warning is-flex">
                    <span>
                      <i class="fas fa-info-circle is-danger fa-2x"></i>
                    </span>
                    <span class="ml-10"><b>Please note, this deal cannot progress to booked until:</b><p v-html="bookingStepBlockingContent"></p></span>
                  </div>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <div class="spw-footer">
                <button class="button is-danger" @click="onConfirmStatusProgressionWizard(false)" type="button">Close</button>
                <button v-if="canShowNextProgressBtn && !isPendingOrderDeal" class="button is-primary" @click="onMoveToNextStatus()" type="button" :disabled="disableProgressBtn">Progress</button>
                <button v-if="canShowNextProgressBtn && !isBookingStepBlockedByTradeWorkFlow && !isPendingOrderDeal && clonedFromOrderDeal" class="button is-primary" @click="setInventoryActive()" type="button" :disabled="disableProgressBtn">Arrived</button>
                <button v-if="isBookingStepBlockedByTradeWorkFlow && !isBookingStepBlockedPPATrade && !isBookingStepBlockedPreEntryTrade" class="button is-warning" @click="onTradeItemClick(tradeWorkFlowItem)" type="button">Finalize Trade</button>
                <button v-if="(canShowRebookBtn && this.isBookedDeal)" class="button is-primary" @click="onLoadBookDealCapture(true)" type="button">Re-book</button>
                <button v-if="canShowRestoreBtn" class="button is-primary" @click="onRestoreDealStatus()" type="button">Restore Deal</button>
                <button  v-if="isPendingOrderDeal" class="button is-primary" @click="openConfirmFulfillModal()" type="button">Progress</button>
                <a v-if="bookedLocked && hasFeatureAccess('deal.modify.unlockbooked') && this.currentDeal.type !== 'Cancelled'" class="button is-link is-small" @click="unlockBookedDeal(false)">
                  <span class="icon is-small"><i class="fas fa-unlock"></i></span>
                </a>
                <button class="button is-primary float-button-right" v-if="canShowBookingWidget" @click="onLoadBookDealCapture(false)" type="button">Open Widget</button>
                <button class="button is-primary float-button-right"  v-if="bookedLocked || isCancelledDeal || isAllocatedDeal" @click="onLoadBookDealCapture(false)" type="button">Open Widget (read only)</button>
              </div>
            </footer>
            <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :canCancel="false"></b-loading>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="cancelBackupDealsActive">
        <b-modal :active.sync="cancelBackupDealsActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Do you want to cancel any backup deals?</p>
            </header>
            <section class="modal-card-body">
              <cancel-backup-deals-control :selectedDealIds.sync="selectedDealIds" :backupDeals="backupDeals" :hasErrors.sync="cancelBackupDealsErrors"/>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onConfirmCancelBackupDeals(true)" type="button">Yes</button>
              <button class="button is-danger" @click="onConfirmCancelBackupDeals(false)" type="button">No</button>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="selectBackupDealActive">
        <b-modal :active.sync="selectBackupDealActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Select Primary Deal</p>
            </header>
            <section class="modal-card-body">
              <select-primary-backup-deal-control :selectedDealItemId.sync="selectedDealItemId" :backupDeals="backupDeals" :hasErrors.sync="selectBackupDealErrors"/>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmSelectBackupDeal(true)" type="button">Confirm</button>
              <!--<button class="button is-danger" @click="onConfirmSelectBackupDeal(false)" type="button">Cancel</button>-->
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="bookDealCaptureActive">
        <b-modal
          v-if="firstSoldItemInventory"
          :active.sync="bookDealCaptureActive"
          scroll="keep"
          :has-modal-card="true"
          :canCancel="['escape']"
          class="modal-fullscreen"
          id="book-deal-capture-modal">
          <book-deal-capture
            v-model="currentDeal"
            :bookDealCaptureActive.sync="bookDealCaptureActive"
            :progressStatus="progressStatus"
            :statusProgressionWizardActive.sync="statusProgressionWizardActive"
            :pricingMode.sync="pricingMode"
            :isReadOnly="bookedLocked || isCancelledDeal || isAllocatedDeal"
            :unbookDealActive.sync="unbookDealActive">
          </book-deal-capture>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="isLocatedCaptureActive">
        <b-modal :active.sync="isLocatedCaptureActive" scroll="keep" :has-modal-card="true" v-if="firstSoldItemInventory" :canCancel="['escape', 'x']">
          <locate-deal-capture v-model="currentDeal" :isLocatedCaptureActive.sync="isLocatedCaptureActive" :statusProgressionWizardActive.sync="statusProgressionWizardActive"></locate-deal-capture>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="unbookDealActive">
        <b-modal :active.sync="unbookDealActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Warning</p>
            </header>
            <section class="modal-card-body">
              Do you want to unbook this deal and set its status to Delivered?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmUnbookDeal(true)" type="button">Yes</button>
              <button class="button is-danger" @click="onConfirmUnbookDeal(false)" type="button">No</button>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="confirmStatusChangeActive">
        <b-modal :active.sync="confirmStatusChangeActive" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']" id="deal-update-status-modal">
          <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title"> Warning {{statusHeadingSuffix}}</p>
            </header>
            <section class="modal-card-body">
              <form>
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="notification is-flex is-warning">
                      <div><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></div>
                      <div class="ml-10">
                        Do you want to change this deal's status to <b>{{changeStatusTo.name}}?</b>
                      </div>
                    </div>
                  </div>
                  <div class="column is-12" v-if="changeStatusTo.name === 'Cancelled'">
                    <div class="field">
                        <label class="label">Reason<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <multiselect
                          v-validate="{'required': true}"
                          data-vv-as="reason"
                          data-vv-validate-on="input|close"
                          :class="{'is-danger': errors.has('reason') }"
                          name="reason"
                          label="name"
                          trackBy="id"
                          v-model="selectedReason"
                          :options="dealCancellationReasons"
                          placeholder="select cancellation reason">
                        </multiselect>
                        <span v-show="errors.has('reason')" class="help is-danger"><i v-show="errors.has('reason')" class="fas fa-exclamation-triangle"></i> {{ errors.first('reason') }}</span>
                      </div>
                  </div>
                  <div class="column is-12" v-if="changeStatusTo.name === 'Cancelled' && this.tradeItems.length > 0">
                    <switch-selector type="is-info" label="Return the trade vehicle?" v-model="returnTrade"></switch-selector>
                  </div>
                  <div class="column is-6" v-if="changeStatusTo.name === 'Cancelled' && hasPaidDeposits">
                    <div class="field">
                      <div class="control">
                        <switch-selector type="is-info" label="Do you want to refund paid deposits?" v-model="refund"></switch-selector>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" v-if="changeStatusTo.name === 'Cancelled' && hasPaidDeposits">
                    <div v-if="refund && hasNonSquarePaidDeposits">
                      <button-list-selector
                        validateAs="refund type"
                        label="Refund Type (Wire, Check, Cash)"
                        :required="true"
                        v-model="selectedRefundType"
                        :allowDeselection="false"
                        :availableValues="refundTypes"
                        :useValidator="thisValidator">
                      </button-list-selector>
                    </div>
                  </div>
                  <div class="column is-12" v-if="changeStatusTo.name === 'Cancelled' && hasSquarePaidDeposits && refund">
                    <div class="notification is-flex is-danger">
                      <span><i class="fas fas fa-exclamation-triangle is-danger fa-2x"></i></span>
                      <span class="ml-10">
                        <p><b>ATTENTION:</b>
                        <br />There are paid Square deposits linked to this deal. Do you want to refund these Square deposits? If yes, this will send a refund instruction directly to Square.</p>
                        <br /><p><switch-selector extraStyles="has-text-white" type="is-info" label="" v-model="refundSquare"></switch-selector></p>
                      </span>
                    </div>
                  </div>
                  <div class="column is-12" v-if="changeStatusTo.name === 'Delivered' || changeStatusTo.name === 'Booked'">
                    <div class="notification is-danger is-flex">
                      <span>
                        <i class="fas fa-exclamation-triangle is-danger fa-2x"></i>
                      </span>
                      <span class="ml-10">Make sure to review deal numbers for accuracy !!!</span>
                    </div>
                  </div>
                </div>
              </form>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmStatusChange(true)" type="button">Proceed</button>
              <button class="button is-danger" @click="onConfirmStatusChange(false)" type="button">Close</button>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="setDeliveryDateActive">
        <b-modal :active.sync="setDeliveryDateActive" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
          <div class="modal-card" style="width: 500px">
            <header class="modal-card-head">
                <p class="modal-card-title">Delivered Date Selection {{statusHeadingSuffix}}</p>
            </header>
            <section class="modal-card-body">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="field">
                    <p>1. Changing this deal's status to {{changeStatusTo.name}} requires a delivered date selection.</p>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Delivery Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <el-date-picker
                        @change="onFormChange()"
                        v-model="currentDeal.deliveryDate"
                        type="datetime"
                        placeholder="Click to select..."
                        popper-class="datepicker-container"
                        :clearable="false"
                        :editable="false"
                        format="MM-dd-yyyy h:mm A"
                        default-time="13:00:00"
                        name="deliveryDate"
                        data-vv-scope="progress-to-delivered"
                        v-validate="'required'"
                        data-vv-as="delivery date"
                        :class="{'is-danger': errors.has('progress-to-delivered.deliveryDate') }">
                      </el-date-picker>
                      <span v-show="errors.has('progress-to-delivered.deliveryDate')" class="help is-danger">
                        <i v-show="errors.has('progress-to-delivered.deliveryDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('progress-to-delivered.deliveryDate') }}
                      </span>
                      <pop-fill-control title="Click to replace delivery date with the current date" :fillableValue="currentDateFormatted" :onFillAction="onPopFillDeliveryDate"></pop-fill-control>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Sold Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <el-date-picker
                      @change="onFormChange()"
                      v-model="currentDeal.soldAt"
                      type="date"
                      placeholder="Click to select..."
                      :clearable="true"
                      :editable="false"
                      popper-class="datepicker-container"
                      name="soldAt"
                      data-vv-scope="progress-to-delivered"
                      v-validate="'required'"
                      data-vv-as="sold date"
                      :class="{'is-danger': errors.has('progress-to-delivered.soldAt') }"
                      format="MM-dd-yyyy">
                    </el-date-picker>
                    <span v-show="errors.has('progress-to-delivered.soldAt')" class="help is-danger">
                      <i v-show="errors.has('progress-to-delivered.soldAt')" class="fas fa-exclamation-triangle"></i> {{ errors.first('progress-to-delivered.soldAt') }}
                    </span>
                  </div>
                </div>
                <div class="column is-12" v-if="firstSoldItemInventory && firstSoldItemInventory.status === 'PreEntry'">
                  <div class="field">
                    <p>2. Complete the pre-entry of vehicle: {{firstSoldItemInventory.listName}}</p>
                  </div>
                </div>
                <div class="column is-12" v-if="firstSoldItemInventory && firstSoldItemInventory.status === 'PreEntry'">
                  <div class="quick-action is-pulled-left">
                    <button class="button is-pulled-left has-text-danger" type="button" title="Click to complete pre-entry" @click="editInventoryActive=true">
                      <b-icon pack="fal" icon="car-tilt" custom-size="fa-lg"></b-icon>
                    </button>
                  </div>
                </div>
                <div class="column is-12" v-if="changeStatusTo.name === 'Delivered' || changeStatusTo.name === 'Booked'">
                  <div class="notification is-danger is-flex">
                    <span>
                      <i class="fas fa-exclamation-triangle is-danger fa-2x"></i>
                    </span>
                    <span class="ml-10">Make sure to review deal numbers for accuracy !!!</span>
                  </div>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmPendingStatusChange(true)" type="button" :disabled="firstSoldItemInventory && firstSoldItemInventory.status === 'PreEntry'">Save</button>
              <button class="button is-danger" @click="onConfirmStatusChange(false)" type="button">Cancel</button>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="editInventoryActive">
        <b-modal :active.sync="editInventoryActive" scroll="keep">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
            </header>
            <section class="modal-card-body edit-inventory" v-if="firstSoldItemInventory">
              <edit-inventory
                :inventoryId="this.firstSoldItemInventory.id"
                mode="modal"
                actionPortal="modal-footer"
                overviewPortal=""
                headerPortal="modal-header"
                :defaultPreEntry="false"
                v-on:on-save="onInventorySaved"
                v-on:on-cancel="onInventoryCancelled"></edit-inventory>
            </section>
            <footer class="modal-card-foot">
              <portal-target name="modal-footer" class="actions" slim/>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="smsConversationControlActive">
        <b-modal :active.sync="smsConversationControlActive" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: 400px;">
            <header class="modal-card-head">
              <p class="modal-card-title">Sms Conversation</p>
            </header>
            <sms-conversation-control v-model="currentDeal"></sms-conversation-control>
            <footer class="modal-card-foot">
              <button class="button is-danger" @click="onCloseSmsConversationControl" type="button">Close</button>
            </footer>
          </div>
        </b-modal>
      </portal>

      <deal-get-ready-capture
        :dealItems="soldItems"
        :openDialog="isGetReadyCapture"
        :expectedDeliveryDate="expectedDeliveryDateDeal"
        v-on:get-ready-created="onSaveGetReady"
        v-on:get-ready-cancelled="cancelGetReady"
        v-on:get-ready-skipped="skipGetReady"
        :usePortal="true">
      </deal-get-ready-capture>

      <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :canCancel="false"></b-loading>

      <dealer-peak-lookup></dealer-peak-lookup>

      <trade-fullfillment-workflow
        v-on:trade-fullfillment-workflow-complete="onTradeWorkFlowComplete"
        v-on:trade-fullfillment-title-capture-skipped="onTradeWorkFlowTitleCaptureSkipped"
        v-on:trade-fullfillment-arrival-capture-skipped="onTradeWorkFlowArrivalCaptureSkipped"
        v-if="inventoryForTradeWorkFlow !== null"
        :forInventory="inventoryForTradeWorkFlow"
        :titleSkipped="tradeWorkflowTitleCaptureSkipped"
        :arrivalCaptureSkipped="tradeWorkflowArrivalCaptureSkipped"
        :key="'TFW' + inventoryForTradeWorkFlow.id">
      </trade-fullfillment-workflow>

      <portal to="global-modal-portal" v-if="editTradePreEntryActive">
        <b-modal :active.sync="editTradePreEntryActive" scroll="keep">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
            </header>
            <section class="modal-card-body edit-inventory">
              <edit-inventory
                :inventoryId="this.tradeItem.id"
                mode="modal"
                actionPortal="modal-footer"
                overviewPortal=""
                headerPortal="modal-header"
                :defaultPreEntry="false"
                v-on:on-save="onInventorySaved"
                v-on:on-cancel="onInventoryCancelled"></edit-inventory>
            </section>
            <footer class="modal-card-foot">
              <portal-target name="modal-footer" class="actions" slim/>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="restoreCancelledDealActive">
        <b-modal :active.sync="restoreCancelledDealActive" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
          <div class="modal-card" style="max-width: 525px !important">
            <header class="modal-card-head">
                <p class="modal-card-title">Restore Cancelled Deal</p>
            </header>
            <section class="modal-card-body">
              <div class="notification is-warning is-flex-tablet">
                <span><i class="fas fa-exclamation-triangle fa-2x"></i></span>
                <span class="ml-10">
                  This deal has one or more refunds.<br /> Do you want to reverse the refunds?
                </span>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onRestoreRefund(true)" type="button">Yes</button>
              <button class="button is-link" @click="onRestoreRefund(false)" type="button">No</button>
              <button class="button is-danger" @click="restoreCancelledDealActive = false" type="button">Cancel</button>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="setPromiseDateActive">
        <b-modal :active.sync="setPromiseDateActive" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
          <div class="modal-card" style="width: 500px">
            <header class="modal-card-head">
                <p class="modal-card-title">Expected Delivery Date Selection</p>
            </header>
            <section class="modal-card-body">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="field">
                    <p>1. Changing this deal's status from {{selectedDealStatus.name}} requires a expected delivery date selection.</p>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Expected Delivery Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <el-date-picker
                        @change="onFormChange()"
                        v-model="currentDeal.promiseDate"
                        type="datetime"
                        placeholder="Click to select..."
                        popper-class="datepicker-container"
                        :clearable="false"
                        :editable="false"
                        format="MM-dd-yyyy h:mm A"
                        default-time="13:00:00"
                        name="promiseDate"
                        data-vv-scope="progress-from-pending-credit"
                        v-validate="'required'"
                        data-vv-as="expected delivery date"
                        :class="{'is-danger': errors.has('progress-from-pending-credit.promiseDate') }">
                      </el-date-picker>
                      <span v-show="errors.has('progress-from-pending-credit.promiseDate')" class="help is-danger">
                        <i v-show="errors.has('progress-from-pending-credit.promiseDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('progress-from-pending-credit.promiseDate') }}
                      </span>
                  </div>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmPendingCreditStatusChange(true)" type="button" :disabled="firstSoldItemInventory && firstSoldItemInventory.status === 'PreEntry'">Save</button>
              <button class="button is-danger" @click="onConfirmPendingCreditStatusChange(false)" type="button">Cancel</button>
            </footer>
          </div>
        </b-modal>
      </portal>
    </section>
</template>

<script>

import axios from 'axios'
import moment from 'moment'
import eventBus from '@/eventBus'
import SectionHeader from '@/components/generic/SectionHeader'
import dealService from '@/services/dealService'
import NoteTimelineView from '@/components/generic/NoteTimelineView'
import PodLockControl from '@/components/generic/PodLockControl'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import LeadSourceSelector from '@/components/generic/LeadSourceSelector'
import CaptureNewDealNote from '@/components/sales/deals/CaptureNewDealNote'
import RdrCapture from '@/components/generic/sales/RdrQuickViewCapture'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import CancelBackupDealsControl from '@/components/generic/sales/CancelBackupDealsControl'
import SelectPrimaryBackupDealControl from '@/components/generic/sales/SelectPrimaryBackupDealControl'
import ArrivalCaptureDetail from '@/components/generic/inventory/ArrivalCaptureDetail'
import BookDealCapture from '@/components/generic/sales/BookDealCapture'
import LocateDealCapture from '@/components/generic/sales/LocateDealCapture'
import ProductCostContainer from '@/components/generic/sales/ProductCostContainer'
import SupplementalCostCapture from '@/components/generic/sales/SupplementalCostCapture'
import CommissionCapture from '@/components/generic/sales/CommissionCapture'
import FieldRotator from '@/components/generic/FieldRotator'
import QuickActionContainer from '@/components/generic/QuickActionContainer'
import DealBuyersAgreement from '@/components/sales/deals/DealBuyersAgreementAction'
import ViewDealWashoutAction from '@/components/sales/deals/ViewDealWashoutAction'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import formattingMixin from '@/mixins/inventory/formatting'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import AccessoryEventView from '@/components/generic/inventory/AccessoryEventView'
import AccessoryEventCapture from '@/components/generic/inventory/AccessoryEventCapture'
import ServiceRequestCapture from '@/components/generic/inventory/ServiceRequestCapture'
import CreditApplicationAction from '@/components/sales/deals/DealCustomerCreditApplicationAction'
import utilitiesMixin from '@/mixins/generic/utilities'
import DocumentsCapture from '@/components/sales/deals/DocumentsCapture'
import SmsConversationControl from '@/components/generic/SmsConversationControl'
import SendPickupLocationControl from '@/components/generic/SendPickupLocationControl'
import IdentityVerificationAction from '@/components/sales/deals/DealSupplierIdentityVerificationAction'
import CarFaxControl from '@/components/generic/CarFaxControl'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import OfacControl from '@/components/generic/OfacControl'
import AppointmentStatusControl from '@/components/generic/sales/AppointmentStatusControl'
import InventorySellersAgreementAction from '@/components/inventory/InventorySellersAgreementAction'
import InventoryBatchPaymentRequest from '@/components/inventory/InventoryBatchPaymentRequestAction'
import SwitchSelector from '@/components/generic/SwitchSelector'
import TagView from '@/components/generic/TagView'
import DealerPeakControl from '@/components/generic/DealerPeakControl'
import DealerPeakLookup from '@/components/generic/DealerPeakLookup'
import DealCreditApplicationStatus from '@/components/sales/deals/DealCustomerCreditApplicationStatus'
import VerifySupplierControl from '@/components/generic/VerifySupplierControl'
import TradeFullFillmentWorkFlow from '@/components/inventory/TradeFullFillmentWorkFlow'
import DealGetReadyCapture from '@/components/sales/deals/DealGetReadyCapture'
import CaptureDealTags from '@/components/sales/deals/CaptureDealTags'
import FulFillOrderControl from '@/components/generic/FulFillOrderControl'
import PaymentControl from '@/components/generic/PaymentControl'
import AutoCheckControl from '@/components/generic/AutoCheckControl'
import _ from 'lodash'
import ConvertPpaToPurchaseAction from '@/components/inventory/ConvertPpaToPurchaseAction'
import ApprovePpaAction from '@/components/inventory/ApprovePpaAction'
import TradeDealCapture from '@/components/generic/sales/TradeDealCapture'
import PopFillControl from '@/components/generic/PopFillControl'
import CustomerIdentityVerificationStatus from '@/components/sales/deals/DealCustomerIdentityVerificationStatus'
import DealTimeToDeliverComponent from '@/components/sales/deals/DealTimeToDeliverComponent'
import DealCustomerIdentityVerificationAction from '@/components/sales/deals/DealCustomerIdentityVerificationAction'
import DealCodeCapture from '@/components/generic/DealCodeCapture'
import ArrivalStatusChangeAction from '@/components/sales/deals/ArrivalStatusChangeAction'
import FileSelector from '@/components/generic/FileSelector'
import DealLeaseAgreement from '@/components/sales/deals/DealLeaseAgreementAction'
import SendTitleReassignmentDocumentAction from '@/components/generic/sales/SendTitleReassignmentDocument'
import SendTradeInTitleReassignmentDocument from '@/components/generic/sales/SendTradeInTitleReassignmentDocument'
import DealSalesDeliverySignage from '@/components/sales/deals/DealSalesDeliverySignage'
import DealCommissionProcessingAction from '@/components/sales/deals/DealCommissionProcessingAction'
// import $ from 'jquery'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'ViewDeal',
  mixins: [humanizedSwitchMixin, validateBeforeSubmitMixin, formattingMixin, dealInventoryStyleClasses, utilitiesMixin],
  components: {
    'inventory-sellers-agreement': InventorySellersAgreementAction,
    'inventory-batch-payment-request': InventoryBatchPaymentRequest,
    'button-list-selector': ButtonListSelector,
    'lead-source-selector': LeadSourceSelector,
    'note-timeline-view': NoteTimelineView,
    'section-header': SectionHeader,
    'deal-note-capture': CaptureNewDealNote,
    'pod-lock-control': PodLockControl,
    'rdr-capture': RdrCapture,
    'cancel-backup-deals-control': CancelBackupDealsControl,
    'select-primary-backup-deal-control': SelectPrimaryBackupDealControl,
    'arrival-capture-detail': ArrivalCaptureDetail,
    'book-deal-capture': BookDealCapture,
    'locate-deal-capture': LocateDealCapture,
    'product-cost-container': ProductCostContainer,
    'supplement-cost-capture': SupplementalCostCapture,
    'commission-capture': CommissionCapture,
    'car-fax-control': CarFaxControl,
    'quick-action-container': QuickActionContainer,
    'field-rotator': FieldRotator,
    'deal-buyers-agreement': DealBuyersAgreement,
    'view-deal-washout': ViewDealWashoutAction,
    'accessory-event-view': AccessoryEventView,
    'accessory-event-capture': AccessoryEventCapture,
    'service-request-capture': ServiceRequestCapture,
    'customer-credit-application': CreditApplicationAction,
    'documents-capture': DocumentsCapture,
    'sms-conversation-control': SmsConversationControl,
    'send-pickup-location-control': SendPickupLocationControl,
    'supplier-identity-verification': IdentityVerificationAction,
    'error-display-component': ErrorDisplayComponent,
    'ofac-control': OfacControl,
    'appointment-status-control': AppointmentStatusControl,
    'switch-selector': SwitchSelector,
    'tag-view': TagView,
    'edit-inventory': () => import('@/components/inventory/EditInventory'),
    'dealer-peak-control': DealerPeakControl,
    'dealer-peak-lookup': DealerPeakLookup,
    'credit-application-status': DealCreditApplicationStatus,
    'verify-supplier-control': VerifySupplierControl,
    'trade-fullfillment-workflow': TradeFullFillmentWorkFlow,
    'deal-get-ready-capture': DealGetReadyCapture,
    'deal-tags-capture': CaptureDealTags,
    'fulfill-order-control': FulFillOrderControl,
    'payment-control': PaymentControl,
    'auto-check-control': AutoCheckControl,
    'convert-ppa-to-purchase-action': ConvertPpaToPurchaseAction,
    'approve-ppa-action': ApprovePpaAction,
    'trade-deal-capture': TradeDealCapture,
    'pop-fill-control': PopFillControl,
    'customer-identity-verification-status': CustomerIdentityVerificationStatus,
    'deal-time-to-deliver-component': DealTimeToDeliverComponent,
    'identity-verification-action': DealCustomerIdentityVerificationAction,
    'deal-code-capture': DealCodeCapture,
    'arrival-status-change': ArrivalStatusChangeAction,
    'file-selector': FileSelector,
    'deal-lease-agreement': DealLeaseAgreement,
    'send-title-reassignment-documentation': SendTitleReassignmentDocumentAction,
    'send-trade-in-title-reassignment-documentation': SendTradeInTitleReassignmentDocument,
    'sales-delivery-signage': DealSalesDeliverySignage,
    'deal-commission-processing-action': DealCommissionProcessingAction
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    actionPortal: {
      type: String,
      default: 'qv-footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: 'qv-header'
    }
  },
  data () {
    return {
      isLoading: false,
      isFullPage: true,
      currentDealId: this.id,
      currentCustomerId: 0,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      inventoryPhotoFiles: [],
      isEditLocked: true,
      selectedType: null,
      selectedStockType: null,
      selectedLeadSource: null,
      selectedSaleType: { id: 0, name: 'Retail', altName: 'Retail' },
      selectedDealer: { id: 0, name: '' },
      customerImage: '',
      allProducts: [],
      selectedAccessoryStatus: null,
      selectedGetReadyStatus: null,
      currentDeal: {
        id: 0,
        vinNo: '',
        stockNo: '',
        notes: [],
        deliveryDate: '',
        appointmentDate: ''
      },
      currentCustomer: {
        id: 0
      },
      dealItems: [],
      inventoryDealIsPrimaryActive: false,
      selectedDealItem: null,
      // bookedLocked: false,
      cancelBackupDealsActive: false,
      isNewCheckRequest: false,
      selectedAddresses: [],
      backupDeals: [],
      selectedDealIds: [],
      cancelBackupDealsErrors: false,
      selectBackupDealActive: false,
      selectBackupDealErrors: false,
      selectedDealItemId: null,
      statusProgressionWizardActive: false,
      selectedDealStatus: null,
      statusWidgetDealTypes: [],
      isNewArrivalCaptureActive: false,
      canBypassAwaitingToPendingCheck: false,
      bookDealCaptureActive: false,
      canBypassDeliveredToBookedCheck: false,
      bookedLockedNotificationHidden: false,
      canShowRebookBtn: false,
      isLocatedCaptureActive: false,
      canBypassLocateToAwaitingCheck: false,
      daysList: [],
      optionalMessage: '',
      overridingSubject: '',
      products: [],
      newProduct: this.createGenericProduct(),
      supplementalCosts: [],
      newSupplementalCost: this.createGenericSupplementalCost(),
      commissions: [],
      tagsList: [],
      newCommission: this.createGenericCommission(),
      unbookDealActive: false,
      progressStatus: true,
      changeStatusTo: null,
      confirmStatusChangeActive: false,
      isHoldbackEditable: false,
      setDeliveryDateActive: false,
      appointmentTime: '',
      smsConversationControlActive: false,
      unlockBookedDealFromBookingWidgetFlag: false,
      returnTrade: false,
      editInventoryActive: false,
      reopenSendWireControlModal: false,
      inventoryForTradeWorkFlow: null,
      isGetReadyCapture: false,
      confirmedGetReadySave: false,
      soldServiceRequest: null,
      hasServiceRequest: false,
      autoContinueTradeFlow: false,
      instanceId: this.$uuid.v4(),
      tradeWorkflowTitleCaptureSkipped: false,
      tradeWorkflowArrivalCaptureSkipped: false,
      clonedFromOrderDeal: false,
      setDealPendingArrival: true,
      initialSupplementalCosts: [],
      initialCommissions: [],
      isSavingAllChanges: false,
      editTradePreEntryActive: false,
      isDocumentPodCollapsed: true,
      target: '',
      selectedRefundType: null,
      refund: true,
      refundSquare: false,
      restoreCancelledDealActive: false,
      restoreRefund: false,
      setPromiseDateActive: false,
      pricingMode: false,
      selectedReason: null,
      currentInboundStatusHistoryItems: [],
      dealCreditApplicationStatus: null,
      creditApplicationNote: null,
      bankApprovalStatus: false,
      bankCreditApplicationFiles: [],
      initialBankCreditApplicationFiles: [],
      syncFileTypes: []
    }
  },
  computed: {
    canShowCoCustomerDetails: function () {
      return this.currentDeal.saleType === 'Retail' && this.currentDeal.coCustomer && this.currentDeal.coCustomer.id
    },
    totalTradeAllowance: function () {
      return this.tradeItems.map(x => x.salePrice).reduce((prev, next) => prev + next, 0)
    },
    acvLabel: function () {
      if (this.totalAcvDifference > 0) {
        return 'Over Allowance'
      } else {
        return 'ACV\'ed Up'
      }
    },
    showAcvWarning: function () {
      return this.totalAcvDifference < 0
    },
    totalAcvDifference: function () {
      return this.tradeItems.map(x => x.acvDifference).reduce((prev, next) => prev + next, 0)
    },
    lastTitleDocument: function () {
      if (this.currentDeal && this.soldItems.length > 0) {
        let firstDealInventoryTitleAttachments = this.soldItems[0].forInventory.attachments.filter((y) => y.classType === 'Title')
        if (firstDealInventoryTitleAttachments.length > 0) {
          return firstDealInventoryTitleAttachments[0].fileLocation
        }
      }
      return ''
    },
    isQuickView: function () {
      return this.actionPortal.startsWith('qv-')
    },
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapConfigGetters(['definedTypes']),
    ...mapDealerState(['currentDealer']),
    totalGP: function () {
      return 0
    },
    firstSoldItemStockNo: function () {
      return this.soldItems.length > 0 ? this.soldItems[0].forInventory.stockNo : ''
    },
    soldItems: function () {
      return (this.currentDeal && this.currentDeal.items) ? this.currentDeal.items.filter((x) => x.dealItemType === 'Sold') : []
    },
    tradeItems: function () {
      return (this.currentDeal && this.currentDeal.items) ? this.currentDeal.items.filter((x) => x.dealItemType === 'Trade') : []
    },
    leaseRenewalItems: function () {
      return (this.currentDeal && this.currentDeal.items) ? this.currentDeal.items.filter((x) => x.dealItemType === 'LeaseRenewal') : []
    },
    isLeadSourceRequired: function () {
      if (this.selectedType && this.isSaleTypeOfRetail && (this.selectedType.name === 'Booked' || this.selectedType.name === 'Delivered')) {
        return true
      } else {
        return false
      }
    },
    activeDealTypeItems: function () {
      if (this.selectedStockType && ['Order'].indexOf(this.selectedStockType.name) >= 0) {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'Ordered' || x.altName === 'Cancelled')
      }

      if (this.selectedStockType && ['Locate'].indexOf(this.selectedStockType.name) >= 0) {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'Locating' || x.altName === 'Cancelled')
      }

      if (this.isBackupDeal) {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'AwaitingArrival' || x.altName === 'Pending' || x.altName === 'Cancelled')
      }

      return this.definedTypes.dealTypes.filter((x) => x.altName !== 'Ordered' && x.altName !== 'Locating')
    },
    isBackupDeal: function () {
      return this.soldItems.filter(x => !x.isPrimary).length > 0
    },
    stockTypeFriendlyName: function () {
      if (!this.currentDeal || !this.currentDeal.type) {
        return ''
      }
      return this.definedTypes.dealStockTypes.find((x) => x.name === this.currentDeal.stockType || x.altName === this.currentDeal.stockType).name
    },
    dealTypeFriendlyName: function () {
      if (!this.currentDeal || !this.currentDeal.type) {
        return ''
      }
      return this.definedTypes.dealTypes.find((x) => x.name === this.currentDeal.type || x.altName === this.currentDeal.type).name
    },
    byPassPermissions: function () {
      if (this.currentDeal.type === 'Cancelled') {
        return ['quickview.deal.*', 'quickview.deal.modifycancelled']
      } else {
        return ['quickview.deal.*', 'quickview.deal.modify']
      }
    },
    firstSoldItemInventory: function () {
      return this.soldItems.length > 0 ? this.soldItems[0].forInventory : null
    },
    dealTypeItems: function () {
      if (this.currentDeal && this.currentDeal.type === 'Ordered') {
        let excludedTypes = [0, 1, 2, 3, 6]
        let filteredTypes = this.statusWidgetDealTypes.filter(function (item) {
          return excludedTypes.indexOf(item.id) === -1
        })
        return filteredTypes
      } else if (this.currentDeal && this.currentDeal.type !== 'Allocated') {
        let excludedTypes = [7]
        let filteredTypes = this.statusWidgetDealTypes.filter(function (item) {
          return excludedTypes.indexOf(item.id) === -1
        })
        return filteredTypes
      } else {
        let includedTypes = [7]
        let filteredTypes = this.statusWidgetDealTypes.filter(function (item) {
          return includedTypes.indexOf(item.id) > -1
        })
        return filteredTypes
      }
    },
    canShowNextProgressBtn: function () {
      return this.selectedDealStatus && this.selectedDealStatus.selectableId.length > 1 && !this.isBookingStepBlocked
    },
    disableProgressBtn: function () {
      if (this.isBackupDeal && (this.isBookedDeal || this.isDeliveredDeal)) {
        return true
      } else if (this.isBookingStepBlocked) {
        return true
      } else {
        return false
      }
    },
    productsTotal: function () {
      if (!this.products) {
        return 0
      }
      return this.products.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.sellingPrice)
      }, 0)
    },
    availableProducts: function () {
      if (!this.products) {
        return []
      }
      return this.products.filter(x => !x.delete)
    },
    canShowRestoreBtn: function () {
      if (this.isCancelledDeal) {
        return true
      } else {
        return false
      }
    },
    additionalCostsTotal: function () {
      if (!this.supplementalCosts) {
        return 0
      }
      return this.supplementalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.sellingPrice)
      }, 0)
    },
    commissionsTotal: function () {
      if (!this.commissions) {
        return 0
      }
      return this.commissions.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.commission)
      }, 0)
    },
    availableAdditionalCosts: function () {
      return this.supplementalCosts.filter(x => !x.delete)
    },
    availableCommissions: function () {
      return this.commissions.filter(x => !x.delete)
    },
    totalSalesGP: function () {
      let total = 0
      this.soldItems.forEach((item) => { total += this.dealItemTotalGP(item) }, total)
      return total
    },
    canShowBookingWidget: function () {
      return !(this.isBookedDeal || this.isCancelledDeal || this.isOrderedDeal || this.isLocatingDeal || this.isAllocatedDeal)
    },
    hasCheckRequestAccess: function () {
      let allowedPermissions = ['deal.issue.cheque-request']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    additionalCostsGP: function () {
      if (!this.availableAdditionalCosts) {
        return 0
      }
      return this.availableAdditionalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true || cur.isInGlBalance ? +0 : +(cur.sellingPrice - cur.cost))
      }, 0)
    },
    additionalCostsRecon: function () {
      if (!this.availableAdditionalCosts) {
        return 0
      }
      return this.availableAdditionalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true || !cur.isInGlBalance ? +0 : +(cur.sellingPrice - cur.cost))
      }, 0)
    },
    bookedLocked: function () {
      return this.isBookedDeal
    },
    canShowWorkshopStatus: function () {
      return (this.currentDeal.type !== 'Ordered' && this.currentDeal.type !== 'Allocated')
    },
    showPromisedDate: function () {
      return !this.isBookedDeal &&
        !this.isDeliveredDeal &&
        (this.currentDeal.appointmentStatus === 'NotSet' || this.currentDeal.appointmentStatus === 'Cancelled') &&
        (this.currentDeal.type !== 'Ordered' && this.currentDeal.type !== 'Allocated') &&
        !this.isPreDeal
    },
    showDeliveredDate: function () {
      return this.isBookedDeal || this.isDeliveredDeal
    },
    showAppointmentDate: function () {
      return !this.isBookedDeal && !this.isDeliveredDeal && this.currentDeal.appointmentStatus !== 'NotSet' && this.currentDeal.appointmentStatus !== 'Cancelled' && (this.currentDeal.type !== 'Ordered' && this.currentDeal.type !== 'Allocated')
    },
    appointmentDate: function () {
      return (this.currentDeal) ? this.currentDeal.appointmentDate : null
    },
    hasSendDeliveryDateSmsAccess: function () {
      let allowedPermissions = ['deal.modify.deliverydatesms']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    showSendWireDetailBtn: function () {
      return !(this.currentDealer.wireInfo === '' || (this.currentDeal.purchaserContactEmail === '' && this.currentDeal.purchaserContactMobile === ''))
    },
    hasDepositCreateAccess: function () {
      let allowedPermissions = ['deposit.create.wire', 'deposit.create.square', 'deposit.create.check']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    getDefaultDateTimeForCalendar: function () {
      let localDateValue = moment.utc(String(new Date())).tz(moment.defaultZone.name)
      let localDate = new Date(localDateValue)
      return new Date(localDate.toDateString() + ' 13:00')
    },
    isAppointmentSaved: function () {
      return this.currentDeal.appointmentStatus !== 'NotSet'
    },
    isSaleTypeOfRetail: function () {
      if (this.selectedSaleType !== null && this.selectedSaleType.name === 'Retail') {
        return true
      } else {
        return false
      }
    },
    isTypeofWholesale: function () {
      if (this.selectedSaleType !== null && this.selectedSaleType.name === 'Wholesale') {
        return true
      } else {
        return false
      }
    },
    showSmsConversationBtn: function () {
      return this.hasSendDeliveryDateSmsAccess && this.currentDeal.appointmentStatus !== 'NotSet'
    },
    showCustomerIdentification: function () {
      return this.currentDeal.saleType === 'Retail'
    },
    showSupplierIdentification: function () {
      return this.currentDeal.saleType === 'Wholesale'
    },
    ofacInfo: function () {
      if (this.isSaleTypeOfRetail && this.currentDeal.ofacInfo) {
        return {
          id: this.currentDeal.customer.id,
          firstName: this.currentDeal.ofacInfo.firstName,
          lastName: this.currentDeal.ofacInfo.lastName,
          ssn: this.currentDeal.ofacInfo.ssn,
          ofacStatus: this.currentDeal.ofacInfo.ofacStatus,
          ofacRequestedAt: this.currentDeal.ofacInfo.ofacRequestedAt
        }
      }
      return null
    },
    availableAppointmentStatuses: function () {
      return this.definedTypes.dealAppointmentTypes.options.filter((x) => x.altName !== 'All Appointments')
    },
    activeAppointmentStatuses: function () {
      return this.definedTypes.dealAppointmentTypes.options.filter((x) => x.altName === 'Confirmed' || x.altName === 'Cancelled')
    },
    showAppointStatusList: function () {
      return !this.showPromisedDate
    },
    statusHeadingSuffix: function () {
      if (this.changeStatusTo.name === 'Booked') {
        return '- Handoff to Accounting'
      }
      if (this.changeStatusTo.name === 'Delivered') {
        return '- Handoff to Sales Manager'
      }
      return ' - Status Change to ' + this.changeStatusTo.name
    },
    appointmentDateLabel: function () {
      let appointmentDate = this.currentDeal.appointmentDate
      try {
        var appointmentDateLocalValue = appointmentDate.getFullYear() + '-' + ('0' + (appointmentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + appointmentDate.getDate()).slice(-2) + ' ' + ('0' + (this.appointmentTime.getHours())).slice(-2) + ':' + ('0' + (this.appointmentTime.getMinutes())).slice(-2)
        let utcValue = moment.tz(appointmentDateLocalValue, moment.defaultZone.name).utc().format('YYYY-MM-DD HH:mm')
        return utcValue
      } catch (dateConversionError) {
        console.log('Error converting date to UTC')
        console.log(this.appointmentDate)
        console.log(dateConversionError)
        return ''
      }
    },
    deliveryDateLabel: function () {
      let deliveryDate = this.currentDeal.deliveryDate
      try {
        var deliveryDateLocalValue = deliveryDate.getFullYear() + '-' + ('0' + (deliveryDate.getMonth() + 1)).slice(-2) + '-' + ('0' + deliveryDate.getDate()).slice(-2) + ' ' + ('0' + deliveryDate.getHours()).slice(-2) + ':' + ('0' + deliveryDate.getMinutes()).slice(-2)
        let deliveryDateUtcValue = moment.tz(deliveryDateLocalValue, moment.defaultZone.name).utc().format('YYYY-MM-DD HH:mm')
        return deliveryDateUtcValue
      } catch (dateConversionError) {
        console.log('Error converting date to UTC')
        console.log(this.deliveryDate)
        console.log(dateConversionError)
        return ''
      }
    },
    isAllocatedDeal: function () {
      return this.selectedDealStatus && this.selectedDealStatus.altName === 'Allocated'
    },
    isCancelledDeal: function () {
      return this.selectedDealStatus && this.selectedDealStatus.altName === 'Cancelled'
    },
    hasCancelledReason: function () {
      return this.isCancelledDeal && this.currentDeal.cancellationReason && this.currentDeal.cancellationReason.length > 0
    },
    isDeliveredDeal: function () {
      return this.selectedDealStatus && this.selectedDealStatus.altName === 'Delivered'
    },
    isBookedDeal: function () {
      return this.selectedDealStatus && this.selectedDealStatus.altName === 'Booked'
    },
    isPendingDeal: function () {
      return this.selectedDealStatus && this.selectedDealStatus.altName === 'Pending'
    },
    isOrderedDeal: function () {
      return this.selectedDealStatus && this.selectedDealStatus.altName === 'Ordered'
    },
    isLocatingDeal: function () {
      return this.selectedDealStatus && this.selectedDealStatus.altName === 'Locating'
    },
    isPendingCredit: function () {
      return this.selectedDealStatus && this.selectedDealStatus.altName === 'PendingCredit'
    },
    showCostsSection: function () {
      if (this.selectedStockType && (this.selectedStockType.name === 'Locate' || this.selectedStockType.name === 'Order')) {
        return false
      } else {
        return true
      }
    },
    showCommissionsSection: function () {
      if (this.selectedStockType && (this.selectedStockType.name === 'Locate' || this.selectedStockType.name === 'Order')) {
        return false
      } else {
        return true
      }
    },
    showNotesSection: function () {
      return true
    },
    firstSoldItemSaleType: function () {
      return this.soldItems.length > 0 && this.soldItems[0].vehicleType ? this.soldItems[0].vehicleType : ''
    },
    tradeItem: function () {
      // default to the first trade inventory if one exists
      let trade = null
      if (this.tradeItems.length > 0) {
        trade = {
          ...this.tradeItems[0].forInventory,
          tradeAllowanceApproved: this.tradeItems[0].tradeAllowanceApproved
        }
      }

      // get the next trade invenory item that must complete trade fullfillment flow, if all is complete the default will be used
      this.tradeItems.some(x => {
        if (x.forInventory && x.forInventory.paymentRequestStatus !== 'Sent') {
          trade = x.forInventory
          trade = {
            ...x.forInventory,
            tradeAllowanceApproved: x.tradeAllowanceApproved
          }
          return true
        }
      })

      return trade
    },
    tradeWorkFlowItem: function () {
      if (this.dealHasTrade) {
        let incoming = this.tradeItem.possessionStatus === 'Inbound'
        let tradeHasTitle = this.tradeItem.titleStatus === 'Yes' || this.tradeItem.titleStatus === 'PayOff' || this.tradeItem.titleStatus === 'Lost'
        let tradeHasAgreement = this.tradeItem.sellersAgreementStatus === 'Signed' || this.tradeItem.sellersAgreementStatus === 'Complete'
        let tradeHasAgreementPending = this.tradeItem.hasPendingRetailAgreements
        let tradeHasCheckRequest = this.tradeItem.paymentRequestStatus === 'Sent'
        let carFaxStatus = -1
        if (this.tradeItem.carFaxRating && (this.tradeItem.carFaxRating.brandedTitle === 1 || this.tradeItem.carFaxRating.totalAccidents > 0 ||
          this.tradeItem.carFaxRating.accidentIndicator === 1 || this.tradeItem.carFaxRating.frameDamageOrRepair === 1 || this.tradeItem.carFaxRating.airBagDeployed === 1 ||
          this.tradeItem.carFaxRating.damaged === 1 || this.tradeItem.carFaxRating.majorProblems === 1 || this.tradeItem.carFaxRating.recall === 1)) {
          carFaxStatus = 0
        }
        let isPpa = this.tradeItem.type === 'ForPpaAppraisal'
        let tradeAllowanceApproved = this.tradeItem.tradeAllowanceApproved
        let item = {
          tradeId: this.tradeItem.id,
          hasTrade: true,
          tradeIncoming: incoming,
          tradeHasTitle: tradeHasTitle,
          tradeHasAgreement: tradeHasAgreement,
          tradeHasCheckRequest: tradeHasCheckRequest,
          tradeDamaged: carFaxStatus === 0 || this.tradeItem.isDamaged,
          carFaxStatus: carFaxStatus,
          tradeHasAgreementPending: tradeHasAgreementPending,
          tradeArrivalSkipped: this.tradeItem.arrivalSkipped,
          tradeArrivalSkippedBy: this.tradeItem.arrivalSkippedBy,
          tradeIsPPA: isPpa,
          dealId: this.currentDealId,
          tradeAllowanceApproved: tradeAllowanceApproved,
          tradeStockNo: this.tradeItem.stockNo,
          tradeExamined: this.tradeItem.ppaExamined,
          tradeExaminedByName: this.tradeItem.ppaExaminedByName,
          tradeExaminedAt: this.tradeItem.ppaExaminedAt,
          stockNo: this.tradeItem.stockNo
        }
        return item
      }
      return { tradeId: null, hasTrade: false, tradeIncoming: false, tradeHasTitle: false, tradeHasAgreement: false, tradeHasCheckRequest: false, tradeDamaged: false, carFaxStatus: -1, tradeHasAgreementPending: false, tradeArrivalSkipped: false, tradeArrivalSkippedBy: '', tradeIsPPA: false, dealId: 0, tradeAllowanceApproved: false, tradeStockNo: '', tradeExamined: false, tradeExaminedByName: '', tradeExaminedAt: '' }
    },
    isTradeWorkFlowComplete: function () {
      return this.dealHasTrade && this.tradeWorkFlowItem.tradeHasCheckRequest
    },
    isBookingStepBlockedByTradeWorkFlow: function () {
      if (this.dealHasTrade && this.isDeliveredDeal) {
        if (!this.isTradeWorkFlowComplete && (this.isTradeTypeTradeIn || this.isTradeTypePpaAppraisal)) {
          return true
        }
      }

      return false
    },
    mustCreateDealPendingServiceRequest: function () {
      let dealer = this.$store.state.dealer.currentDealer
      return this.isSaleTypeOfRetail || (this.isTypeofWholesale && dealer.wholesaleGetReadyRequired)
    },
    firstSoldItem: function () {
      return this.soldItems.length > 0 ? this.soldItems[0] : null
    },
    isPendingOrderDeal: function () {
      if (this.currentDeal && this.currentDeal.type && this.currentDeal.type === 'Ordered' &&
      this.currentDeal.stockType && this.currentDeal.stockType === 'Order' &&
      this.currentDeal.stockNo && this.currentDeal.stockNo === 'PENDING') {
        this.setStatusAsNotSelectable('AwaitingArrival')
        this.setStatusAsSelectable('Allocated')
        return true
      }
      return false
    },
    firstSoldInventoryType: function () {
      return this.definedTypes.inventoryTypes.find((x) => (x.name === this.firstSoldItemInventory.type || x.altName === this.firstSoldItemInventory.type))
    },
    isTradeTypePpaAppraisal: function () {
      return this.dealHasTrade && this.tradeItem.type === 'ForPpaAppraisal'
    },
    isBookingStepBlockedPPATrade: function () {
      return this.isTradeTypePpaAppraisal && this.isDeliveredDeal
    },
    bookingStepBlockingContent: function () {
      let content = ''

      if (this.isBookingStepBlockedPPATrade) {
        content += ' <p>The PPA must be approved and converted. Click the approve or conversion icon to proceed</p>'
      }

      if (this.showEditPreEntryAction) {
        content += ' <p>The trade is still in pre-entry status. Click the pre-entry icon and complete the stock-in to proceed.</p>'
      }

      if (this.isBookingStepBlockedByTradeWorkFlow) {
        content += ' <p>The trade needs to be been finalized. Click the trade icon to proceed.</p>'
      }

      return content
    },
    isTradeStatusPreEntry: function () {
      return this.dealHasTrade && this.tradeItem.status === 'PreEntry'
    },
    isTradeTypeTradeIn: function () {
      return this.dealHasTrade && this.tradeItem.type === 'TradeIn'
    },
    dealHasTrade: function () {
      return this.tradeItem !== null && this.tradeItem !== undefined
    },
    showTradeWorkFlowItemAction: function () {
      return this.dealHasTrade && !this.isTradeTypePpaAppraisal && !this.isTradeStatusPreEntry
    },
    showPPAConvertAction () {
      return this.dealHasTrade && this.isTradeTypePpaAppraisal && this.tradeItem.ppaApproved
    },
    showPPAApproveAction () {
      return this.dealHasTrade && this.isTradeTypePpaAppraisal && !this.tradeItem.ppaApproved
    },
    showEditPreEntryAction () {
      if (this.dealHasTrade) {
        if (this.isTradeStatusPreEntry) {
          if (this.isTradeTypePpaAppraisal && this.tradeItem.ppaApproved) {
            return true
          } else if (this.isTradeTypePpaAppraisal) {
            return false
          }
          return true
        }
      }
      return false
      // return this.dealHasTrade && this.isTradeStatusPreEntry && ((this.tradeItem.ppaApproved && this.isTradeTypePpaAppraisal) || this.isTradeTypeTradeIn)
    },
    isBookingStepBlocked () {
      return this.isBookingStepBlockedByTradeWorkFlow || this.isBookingStepBlockedPPATrade || this.isBookingStepBlockedPreEntryTrade
    },
    isBookingStepBlockedPreEntryTrade: function () {
      return this.showEditPreEntryAction && this.isDeliveredDeal
    },
    getColSize () {
      return this.isPendingOrderDeal ? 'is-4' : 'is-2'
    },
    currentDealIsAllocated () {
      if (this.currentDeal) {
        return this.currentDeal.type === 'Allocated'
      }
      return false
    },
    currentDealIsOrdered () {
      if (this.currentDeal) {
        return this.currentDeal.type === 'Ordered'
      }
      return false
    },
    canShowDealNumber () {
      return !this.currentDealIsAllocated && !this.currentDealIsOrdered
    },
    showAllocatedMonth () {
      return this.currentDeal.allocatedMonth !== undefined && this.currentDeal.allocatedMonth !== null
    },
    showOrderedDate () {
      return this.currentDeal.orderedDate !== undefined && this.currentDeal.orderedDate !== null
    },
    firstSoldItemVehicleMakeModel: function () {
      let vehicleMakeModel = null
      if (this.firstSoldItemInventory) {
        if (this.firstSoldItemVehicleMake) {
          vehicleMakeModel = this.firstSoldItemVehicleMake.versions.find((x) => x.id === (this.firstSoldItemInventory.modelId || this.firstSoldItemInventory.vehicleMakeVersionId))
        }
      }
      return vehicleMakeModel
    },
    showOrderAllocationSubmission () {
      return this.currentDeal.orderAllocationSubmission !== undefined && this.currentDeal.orderAllocationSubmission !== null
    },
    getTitlePreDealText () {
      return this.isPreDeal ? 'Pre-Deal' : ''
    },
    isPreDeal () {
      return this.currentDeal && this.currentDeal.status === 'PreDeal'
    },
    firstSoldItemVehicleMake: function () {
      return this.allVehicleMakes.find((x) => x.id === (this.firstSoldItemInventory.makeId || this.firstSoldItemInventory.vehicleMakeId))
    },
    defaultMsrpDeductions: function () {
      let defaultMsrpDeductions = this.firstSoldItemVehicleMake && this.firstSoldItemVehicleMake.msrpDeductions ? this.firstSoldItemVehicleMake.msrpDeductions : 0.00
      return this.firstSoldItemVehicleMakeModel && this.firstSoldItemVehicleMakeModel.msrpDeductionsToUse ? this.firstSoldItemVehicleMakeModel.msrpDeductionsToUse : defaultMsrpDeductions
    },
    refundTypes: function () {
      return [
        {
          id: 0,
          name: 'Refund'
        },
        {
          id: 1,
          name: 'Retain As Deposit'
        }
      ]
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    paidDeposits: function () {
      return this.currentDeal.paymentTrackings.filter(x => (x.paymentStatus === 'Paid' || x.paymentStatus === 'PendingInAccounting') && x.status === 'Active')
    },
    hasPaidDeposits: function () {
      return this.paidDeposits && this.paidDeposits.length > 0
    },
    squarePaidDeposits: function () {
      return this.paidDeposits.filter(x => x.type === 'Square')
    },
    hasSquarePaidDeposits: function () {
      return this.squarePaidDeposits && this.squarePaidDeposits.length > 0
    },
    nonSquarePaidDeposits: function () {
      return this.paidDeposits.filter(x => x.type !== 'Square')
    },
    hasNonSquarePaidDeposits: function () {
      return this.nonSquarePaidDeposits && this.nonSquarePaidDeposits.length > 0
    },
    promiseDateFormatted: function () {
      return this.currentDeal.promiseDate.getFullYear() + '-' + ('0' + (this.currentDeal.promiseDate.getMonth() + 1)).slice(-2) + '-' + ('0' + this.currentDeal.promiseDate.getDate()).slice(-2) + ' ' + ('0' + this.currentDeal.promiseDate.getHours()).slice(-2) + ':' + ('0' + this.currentDeal.promiseDate.getMinutes()).slice(-2)
    },
    currentDateFormatted: function () {
      var currentDate = new Date()
      return currentDate.getFullYear() + '-' + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + currentDate.getDate()).slice(-2) + ' ' + ('0' + currentDate.getHours()).slice(-2) + ':' + ('0' + currentDate.getMinutes()).slice(-2)
    },
    expectedDeliveryDateDeal: function () {
      return this.currentDeal.promiseDate ? new Date(this.currentDeal.promiseDate) : null
    },
    refunds: function () {
      return this.currentDeal.paymentTrackings.filter(x => x.paymentStatus === 'Refund' && x.status === 'Active')
    },
    hasRefunds: function () {
      return this.refunds && this.refunds.length > 0
    },
    showTimeToDeliver: function () {
      return this.isDeliveredDeal || this.isBookedDeal
    },
    isInStockDealType: function () {
      return this.selectedStockType && this.selectedStockType.altName === 'InStock'
    },
    autoCheckEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.autoCheckEnabledStatus
      }
      return false
    },
    purchaserName: function () {
      return this.isDealLoaded ? this.currentDeal.forPurchaserModel.fullName : ''
    },
    isDealLoaded: function () {
      return this.currentDeal && this.currentDeal.id > 0
    },
    cancelLocked: function () {
      return this.isCancelledDeal
    },
    dealCancellationReasons: function () {
      return this.definedTypes.dealCancellationReasons.options
    },
    availableCreditStatusOptions: function () {
      let excludedTypes = []
      if (this.currentDeal.creditApplicationStatus === 'Completed' && this.currentDeal.dealCreditApplicationStatus === 'Approved') {
        excludedTypes.push(0)
      }
      if (this.currentDeal.creditApplicationStatus === 'Completed' && this.currentDeal.dealCreditApplicationStatus === 'NeedPOI') {
        excludedTypes.push(1)
      }
      if (this.currentDeal.creditApplicationStatus === 'Completed' && this.currentDeal.dealCreditApplicationStatus === 'NeedPOR') {
        excludedTypes.push(2)
      }
      if (this.currentDeal.creditApplicationStatus === 'Completed' && this.currentDeal.dealCreditApplicationStatus === 'NeedSSCard') {
        excludedTypes.push(3)
      }
      if (this.currentDeal.creditApplicationStatus === 'Completed' && this.currentDeal.dealCreditApplicationStatus === 'NeedInterview') {
        excludedTypes.push(4)
      }
      if (this.currentDeal.creditApplicationStatus === 'Completed' && this.currentDeal.dealCreditApplicationStatus === 'SingleVehicleOnly') {
        excludedTypes.push(5)
      }
      if (this.currentDeal.creditApplicationStatus === 'Completed' && this.currentDeal.dealCreditApplicationStatus === 'AwaitingDealStructure') {
        excludedTypes.push(6)
      }
      if (this.currentDeal.creditApplicationStatus === 'Completed' && this.currentDeal.dealCreditApplicationStatus === 'ConditionalApproval') {
        excludedTypes.push(7)
      }

      if (this.currentDeal.dealCreditApplicationStatus === 'Approved') {
        excludedTypes.push(1)
        excludedTypes.push(2)
        excludedTypes.push(3)
        excludedTypes.push(4)
        excludedTypes.push(5)
        excludedTypes.push(6)
        excludedTypes.push(7)
      }
      if (excludedTypes.length > 0) {
        let filteredTypes = this.definedTypes.dealCreditApplicationStatus.options.filter(function (item) {
          return excludedTypes.indexOf(item.id) === -1
        })
        return filteredTypes
      }
      return this.definedTypes.dealCreditApplicationStatus.options
    },
    isRetailContract: function () {
      return this.currentDeal && this.currentDeal.contractType === 'Retail'
    },
    isLeaseContract: function () {
      return this.currentDeal && this.currentDeal.contractType === 'Lease'
    },
    tradeDealItems: function () {
      return (this.currentDeal && this.currentDeal.items) ? this.currentDeal.items.filter((x) => x.dealItemType === 'Trade') : []
    },
    hasTradeDealItems: function () {
      return this.tradeDealItems.length > 0
    },
    currentDealIsCancelled () {
      if (this.currentDeal) {
        return this.currentDeal.type === 'Cancelled'
      }
      return false
    }
  },
  methods: {
    totalDeclinedServiceRequestCosts: function (dealItem) {
      return dealItem.forInventory.serviceRequests.reduce((total, item) => {
        return total + (item.requestStatusType === 'Cancelled' || item.requestStatusType === 'Declined' ? 0 : item.declinedCosts)
      }, 0)
    },
    totalPendingServiceRequestCosts: function (dealItem) {
      return dealItem.forInventory.serviceRequests.reduce((total, item) => {
        return total + (item.requestStatusType === 'Cancelled' || item.requestStatusType === 'Declined' ? 0 : item.pendingCosts)
      }, 0)
    },
    totalApprovedServiceRequestCosts: function (dealItem) {
      return dealItem.forInventory.serviceRequests.reduce((total, item) => {
        return total + (item.requestStatusType === 'Cancelled' || item.requestStatusType === 'Declined' ? 0 : item.approvedCosts)
      }, 0)
    },
    onClearPreDeal: function () {
      let that = this
      this.isSaving = true
      dealService.clearPreDeal(this.currentDealId).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          eventBus.$emit('reload-deal-list')
          that.loadDeal(this.currentDealId, () => {
            if ((that.currentDeal.type === 'Pending' && that.mustCreateDealPendingServiceRequest) && !that.currentDeal.hasServiceRequest && (!that.hasServiceRequest || !that.confirmedGetReadySave)) {
              that.isGetReadyCapture = true
            }
          })
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onServiceRequestStatusClick: function () {
      let that = this
      this.$nextTick(() => {
        setTimeout(function () {
          let elements = that.$refs['serviceRequestPod']
          if (!elements) return
          let element = Array.isArray(elements) ? elements[0] : elements
          if (element) {
            that.$goToBlock(element, {
              behavior: 'smooth',
              block: 'end',
              inline: 'end'
            })
          }
        }, 100)
      })
    },
    onTradeWorkFlowTitleCaptureSkipped: function () {
      this.tradeWorkflowTitleCaptureSkipped = true
    },
    onTradeWorkFlowArrivalCaptureSkipped: function () {
      this.tradeWorkflowArrivalCaptureSkipped = true
    },
    onTradeWorkFlowComplete: function (inventoryItem) {
      this.inventoryForTradeWorkFlow = null
      this.autoContinueTradeFlow = false

      // action is cancelled we dont need to do anything further
      if (inventoryItem.actionCancelled !== null && inventoryItem.actionCancelled) return false

      // background processor sets the checkrequest so we dont want to sit in a loop here
      if (inventoryItem.checkRequestSent === null || inventoryItem.checkRequestSent === undefined) {
        this.autoContinueTradeFlow = true
      }

      eventBus.$emit('reload-deal-list')
      this.reloadDeal()
    },
    onTradeItemClick: function (tradeWorkFlowItem) {
      if (this.isTradeWorkFlowComplete) return false
      let inventoryId = +tradeWorkFlowItem.tradeId
      if (inventoryId !== -1) {
        this.inventoryForTradeWorkFlow = {
          ...tradeWorkFlowItem,
          id: inventoryId
        }
      } else {
        this.inventoryForTradeWorkFlow = null
      }
    },
    onServiceRequestRowSelected: function (row) {
      if (this.hasFeatureAccess('servicerequest.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'ServiceRequestsQuickView', params: { id: row.id }})
        })
      }
    },
    getDealItemReconCost: function (dealItem) {
      if (dealItem && dealItem.forInventory) {
        return dealItem.forInventory.reconCost || 0.00
      }
      return 0.00
    },
    hasLoaners: function (dealItem) {
      if (dealItem && dealItem.forInventory.Loaners && dealItem.forInventory.Loaners.length > 0) {
        return true
      } else {
        return false
      }
    },
    hasAccessories: function (dealItem) {
      if (dealItem.forInventory.Accessories && dealItem.forInventory.Accessories.length > 0) {
        return true
      } else {
        return false
      }
    },
    hasWorks: function (dealItem) {
      if (dealItem && dealItem.forInventory.serviceRequests && dealItem.forInventory.serviceRequests.length > 0) {
        return true
      } else {
        return false
      }
    },
    onViewInventory: function (dealItem) {
      if (this.hasFeatureAccess('inventory.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'InventoryQuickView', params: { id: dealItem.forInventory.id, isServiceRequestPod: false, isLoanerPod: false, offerPod: -1, autoLaunchControl: null }})
        })
      }
    },
    onViewCustomer: function (customerId) {
      if (this.hasFeatureAccess('customer.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'CustomersQuickView', params: { id: customerId }})
        })
      }
    },
    onViewSupplier: function (supplierId) {
      if (this.hasFeatureAccess('supplier.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'SuppliersQuickView', params: { id: supplierId }})
        })
      }
    },
    onEdit: function () {
      let that = this
      this.checkDirty(function () {
        that.$emit('close')
        that.$router.push({name: 'EditDeal', params: { id: that.currentDealId }})
      })
    },
    onCancel: function () {
      let that = this
      this.checkDirty(function () {
        that.$emit('close')
        if (!that.isQuickView) {
          that.$router.push({name: that.lastActiveListPage !== '' ? that.lastActiveListPage : 'ListDeals'})
        } else {
          eventBus.$emit('clear-selected-item', {})
          that.$router.replace({name: that.lastActiveListPage !== '' ? that.lastActiveListPage : 'ListDeals'})
        }
      })
    },
    savePricing: function (dealItem) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var dealPricingModel = {
            inventoryId: dealItem.forInventory.id,
            dealItemId: dealItem.id,
            frontValue: dealItem.frontValue ? dealItem.frontValue : null,
            holdValue: dealItem.holdValue ? dealItem.holdValue : null,
            warValue: dealItem.warValue ? dealItem.warValue : null,
            finResValue: dealItem.finResValue ? dealItem.finResValue : null,
            productValue: dealItem.itemProducts.filter(y => y.type === 'Product' && !y.delete).map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next, 0),
            mvpValue: dealItem.mvpValue ? dealItem.mvpValue : null,
            salePrice: dealItem.salePrice ? dealItem.salePrice : null,
            products: dealItem.itemProducts,
            rdrComplete: dealItem.forInventory.rdrComplete,
            rdrCompleteAt: dealItem.forInventory.rdrCompletedAt,
            spiff: dealItem.spiff ? dealItem.spiff : null
          }

          this.serverErrorMessage = ''
          this.serverErrors = []
          this.isSaving = true

          dealService.updatePricingInfo(dealPricingModel).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              if (!this.isSavingAllChanges) {
                this.isEditLocked = true
                this.onChangesSaved()
                eventBus.$emit('reload-deal-list')
                this.loadDeal(this.currentDealId)
              }
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        } else {
          this.$goToFirstValidationError(this.$validator.errors.items)
        }
      })
    },
    savePartialDeal: function (loadDeal, hasSelectedDealStatusChanged) {
      let promisedDate = this.currentDeal.promiseDate ? this.convertLocalToUtc(this.currentDeal.promiseDate) : null
      let deliveryDate = this.currentDeal.deliveryDate ? this.convertLocalToUtc(this.currentDeal.deliveryDate) : null
      let appointmentDate = this.currentDeal.appointmentDate ? this.convertLocalToUtc(this.currentDeal.appointmentDate) : null
      let allocatedMonth = this.currentDeal.allocatedMonth ? this.convertLocalToUtc(this.currentDeal.allocatedMonth) : null
      let orderAllocationSubmission = this.currentDeal.orderAllocationSubmission ? this.convertLocalToUtc(this.currentDeal.orderAllocationSubmission) : null

      if (hasSelectedDealStatusChanged) {
        if ((this.currentDeal.type === 'Pending' && this.mustCreateDealPendingServiceRequest) && !this.currentDeal.hasServiceRequest && (!this.hasServiceRequest || !this.confirmedGetReadySave)) {
          this.isGetReadyCapture = true
          return
        }
      }

      let type = (this.selectedDealStatus) ? this.selectedDealStatus.altName : ''
      let sourceId = (this.selectedLeadSource !== null) ? this.selectedLeadSource.id : null
      let soldAt = this.currentDeal.soldAt ? this.convertLocalToUtc(new Date(this.currentDeal.soldAt)) : null
      let cancellationReason = this.selectedReason ? this.selectedReason.name : null

      let dealPartial = {
        dealId: this.currentDealId,
        promisedDate: promisedDate,
        type: type,
        sourceId: sourceId,
        soldAt: soldAt,
        deliveryDate: deliveryDate,
        appointmentDate: appointmentDate,
        returnTrade: this.returnTrade,
        soldServiceRequest: this.soldServiceRequest,
        isRefundDeposit: !this.selectedRefundType || this.refund === false ? null : this.selectedRefundType.name === 'Refund',
        refundSquare: this.refund === false ? null : this.refundSquare,
        restoreRefund: this.restoreRefund,
        allocatedMonth: allocatedMonth,
        orderAllocationSubmission: orderAllocationSubmission,
        cancellationReason: cancellationReason
      }

      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      this.isLoading = true
      dealService.updatePartialDeal(dealPartial).then(response => {
        this.isSaving = false
        this.isLoading = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          if ((response.deal.type === 'Booked' || response.deal.type === 'Delivered') && response.backupDeals && response.backupDeals.length > 0) {
            this.cancelBackupDealsActive = true
            this.backupDeals = response.backupDeals
          } else if (response.deal.type === 'Cancelled' && response.backupDeals && response.backupDeals.length > 0) {
            this.selectBackupDealActive = true
            this.backupDeals = response.backupDeals
          } else {
            if (!this.isSavingAllChanges) {
              this.isEditLocked = true
              this.onChangesSaved()
              eventBus.$emit('reload-deal-list')
              if (loadDeal) {
                this.loadDeal(this.currentDealId)
              }
            }
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error
        this.isSaving = false
        // this.loadDeal(this.currentDealId)
        this.isLoading = false
      })
    },
    loadDeal (id, callBack) {
      if (id) {
        axios.cancel('view-deal-quickview')
        let that = this
        this.serverErrorMessage = ''
        this.currentDealId = id
        this.isLoading = true
        this.isDirty = false
        dealService.single(this.currentDealId, 'view-deal-quickview').then(response => {
          that.currentDeal = response.deal
          if (this.currentDeal.soldAt) {
            this.currentDeal.soldAtUtc = this.currentDeal.soldAt
            let localValue = moment.utc(String(this.currentDeal.soldAt)).tz(moment.defaultZone.name)
            this.currentDeal.soldAt = new Date(localValue.format('YYYY-MM-DD HH:mm'))
          }

          if (this.currentDeal.promiseDate) {
            let localValue = moment.utc(String(this.currentDeal.promiseDate)).tz(moment.defaultZone.name)
            this.currentDeal.promiseDate = new Date(localValue.format('YYYY-MM-DD HH:mm'))
          }

          if (this.currentDeal.appointmentDate) {
            this.currentDeal.appointmentDateUtc = this.currentDeal.appointmentDate
            let localValue = moment.utc(String(this.currentDeal.appointmentDate)).tz(moment.defaultZone.name)
            this.currentDeal.appointmentDate = new Date(localValue.format('YYYY-MM-DD HH:mm'))
            this.appointmentTime = this.currentDeal.appointmentDate
          } else {
            this.appointmentTime = ''
          }

          if (this.currentDeal.deliveryDate) {
            this.currentDeal.deliveryDateUtc = this.currentDeal.deliveryDate
            let deliveryDateLocalValue = moment.utc(String(this.currentDeal.deliveryDate)).tz(moment.defaultZone.name)
            this.currentDeal.deliveryDate = new Date(deliveryDateLocalValue.format('YYYY-MM-DD HH:mm'))
          }

          if (this.currentDeal.orderedDate) {
            let localValue = moment.utc(String(this.currentDeal.orderedDate)).tz(moment.defaultZone.name)
            this.currentDeal.orderedDate = new Date(localValue.format('YYYY-MM-DD HH:mm'))
          }

          if (this.currentDeal.allocatedMonth) {
            let localValue = moment.utc(String(this.currentDeal.allocatedMonth)).tz(moment.defaultZone.name)
            this.currentDeal.allocatedMonth = new Date(localValue.format('YYYY-MM-DD HH:mm'))
          }

          if (this.currentDeal.orderAllocationSubmission) {
            let localValue = moment.utc(String(this.currentDeal.orderAllocationSubmission)).tz(moment.defaultZone.name)
            this.currentDeal.orderAllocationSubmission = new Date(localValue.format('YYYY-MM-DD HH:mm'))
          }

          this.currentCustomer = this.currentDeal.customer

          if (this.currentCustomer && this.currentCustomer.fileLocation) {
            this.customerImage = this.currentCustomer.fileLocation
          } else {
            this.customerImage = ''
          }

          this.selectedDealer = { id: this.currentDeal.dealerId, name: this.currentDeal.dealerName }
          this.populateProducts()
          this.selectedType = this.definedTypes.dealTypes.find((x) => x.name === this.currentDeal.type || x.altName === this.currentDeal.type)
          this.selectedSaleType = this.definedTypes.dealSaleTypes.find((x) => x.name === this.currentDeal.saleType)
          this.selectedLeadSource = { id: this.currentDeal.sourceId, name: this.currentDeal.sourceName, dealerId: this.currentDeal.dealerId }
          this.selectedStockType = this.definedTypes.dealStockTypes.find((x) => x.name === this.currentDeal.stockType || x.altName === this.currentDeal.stockType)
          this.statusWidgetDealTypes = []
          this.definedTypes.dealTypes.forEach((x) => {
            this.statusWidgetDealTypes.push(x)
          })

          // make current status selectable
          this.setStatusAsSelectable(this.currentDeal.type)

          // make available statuses selectable
          this.makeStatusesSelectable(this.selectedType.id)

          this.selectedDealStatus = this.statusWidgetDealTypes.find((x) => x.name === this.currentDeal.type || x.altName === this.currentDeal.type)

          // check if this is pending credit instock deal, if it is then override default default selectable status
          if (this.isPendingCredit && this.isInStockDealType) {
            this.setStatusAsSelectable('Pending')
            this.setStatusAsNotSelectable('AwaitingArrival')
          }

          this.setCreditInfo()

          this.soldItems.forEach(function (dealItem) {
            this.selectedAccessoryStatus = dealItem.forInventory.accessoryStatusId !== null ? (this.definedTypes.accessoryEventType.options.find((x) => x.id === dealItem.forInventory.accessoryStatusId)) : null
            this.selectedGetReadyStatus = dealItem.forInventory.getReadyStatusId !== null ? (this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === dealItem.forInventory.getReadyStatusId)) : null
            this.isLoading = true
            if (!dealItem.isPrimary) {
              dealService.GetPrimaryDealForInventory(dealItem.forInventory.id).then(response => {
                dealItem.primaryDealForInventory = (response.deal !== null) ? response.deal : { customerName: '-', salesPersonName: '-' }
                dealItem.primaryDealForInventory.primaryDealLoaded = true
                this.isLoading = false
              }).catch((error) => {
                this.serverErrorMessage = error.message
                this.isLoading = false
              })
            } else {
              dealService.byInventory(dealItem.forInventory.id).then(response => {
                dealItem.forInventory.deals = response.results
                this.isLoading = false
              }).catch((error) => {
                this.isLoading = false
                this.serverErrorMessage = error.message
              })
            }

            this.daysList = []

            this.daysList.push({
              label: 'Days on Lot',
              value: dealItem.forInventory.daysOnLotDisplay
            },
            {
              label: 'Days Since Purchase',
              value: dealItem.forInventory.daysSincePurchaseDisplay
            },
            {
              label: 'Days Since Market',
              value: dealItem.forInventory.daysToMarketDisplay
            },
            {
              label: 'Days Since Last Service',
              value: dealItem.forInventory.daysLastServiceDisplay
            })
          }, this)

          this.supplementalCosts = []
          this.initialSupplementalCosts = []
          this.currentDeal.supplementalCosts.forEach(function (supplementalCost) {
            this.supplementalCosts.push(supplementalCost)
            this.initialSupplementalCosts.push(supplementalCost)
          }, this)

          this.commissions = []
          this.initialCommissions = []
          this.currentDeal.commissions.forEach(function (commission) {
            this.commissions.push(commission)
            this.initialCommissions.push(commission)
          }, this)

          this.tagsList = []
          this.currentDeal.tags.forEach(function (tag) {
            this.tagsList.push(tag)
          }, this)
          this.clonedFromOrderDeal = this.currentDeal.clonedFromOrderDeal
          this.isLoading = false

          // keep this action last, load all dependencies
          if (this.autoContinueTradeFlow && !this.isTradeWorkFlowComplete && !this.tradeWorkFlowItem.tradeHasAgreementPending) {
            this.autoContinueTradeFlow = false
            this.onTradeItemClick(this.tradeWorkFlowItem)
          }
          if (this.isSavingAllChanges) {
            this.isEditLocked = true
            this.onChangesSaved()
            eventBus.$emit('reload-deal-list')
            this.isSavingAllChanges = false
          }
          that.currentInboundStatusHistoryItems = [...that.currentDeal.inboundStatusHistoryItems]

          this.bankCreditApplicationFiles = []
          this.initialBankCreditApplicationFiles = []

          this.bankCreditApplicationFiles = this.getDealAttachmentsByType('BankCreditApplicationApproval')
          this.initialBankCreditApplicationFiles = _.cloneDeep(this.bankCreditApplicationFiles)

          if (callBack) {
            callBack()
          }
        }).catch((error) => {
          this.supplementalCosts = []
          this.commissions = []
          this.tagsList = []
          this.isLoading = false
          this.serverErrorMessage = error.message
          this.initialSupplementalCosts = []
          this.initialCommissions = []
        })
      }
    },
    onConfirmInventoryDealIsPrimary: function (bool) {
      if (bool) {
        this.isSaving = true
        let dealUpdatePrimaryModel = {
          dealItemId: this.selectedDealItem.id,
          isPrimary: bool,
          inventoryId: this.selectedDealItem.forInventory.id,
          originalInventoryId: this.selectedDealItem.forInventory.id,
          originalIsPrimary: this.selectedDealItem.isPrimary
        }
        this.selectedDealItem.isPrimary = bool
        if (this.selectedDealItem.id !== 0) {
          dealService.updateIsPrimary(dealUpdatePrimaryModel).then(response => {
            if (response.errors) {
              this.serverErrors = response.errors
            }
            eventBus.$emit('reload-deal-list')
            this.isSaving = false
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      }
      this.selectedDealItem = null
      this.inventoryDealIsPrimaryActive = false
    },
    onCloseDuplicateNotification: function (index) {
      this.$refs[index][0].hidden = true
    },
    showNotificationModal: function (dealItem) {
      this.inventoryDealIsPrimaryActive = true
      this.selectedDealItem = dealItem
    },
    getPromiseDateColorClassByCurrentDate: function (promiseDate, type) {
      var formattedPromiseDate = new Date(promiseDate)
      var currentDate = new Date()
      var result = (formattedPromiseDate < currentDate && type !== 'Booked') ? 'has-text-danger' : ''
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    updateFrontGross: function () {
      if (!this.firstSoldItem) {
        return
      }

      let dealItem = this.firstSoldItem
      let reconCost = this.calculatedReconLive(dealItem)
      let purchaseCostTotal = 0.00
      let tradeAcvDifference = this.totalAcvDifference || 0.00
      if (dealItem && dealItem.forInventory) {
        if (dealItem.forInventory.purchaseCostTotal) {
          purchaseCostTotal = dealItem.forInventory.purchaseCostTotal
        }
      }

      let actualInventoryCost = purchaseCostTotal + reconCost + tradeAcvDifference
      var fg = this.firstSoldItem.salePrice + this.additionalCostsGP - (actualInventoryCost)
      this.firstSoldItem.frontValue = fg
    },
    onConfirmCancelBackupDeals (confirmed) {
      if (this.cancelBackupDealsActive) {
        if (confirmed) {
          if (this.selectedDealIds.length === 0) {
            this.cancelBackupDealsErrors = true
          } else {
            this.cancelBackupDealsErrors = false
            this.isSaving = true
            dealService.cancelBackupDeals(this.selectedDealIds).then(response => {
              this.isSaving = false
              if (response.errors) {
                this.serverErrors = response.errors
              } else {
                this.cancelBackupDealsActive = false
                this.isEditLocked = true
                this.onChangesSaved()
                eventBus.$emit('reload-deal-list')
                this.loadDeal(this.currentDealId)
              }
            }).catch((error) => {
              this.serverErrorMessage = error.message
              this.isSaving = false
            })
          }
        } else {
          this.cancelBackupDealsErrors = false
          this.cancelBackupDealsActive = false
          this.isEditLocked = true
          this.onChangesSaved()
          eventBus.$emit('reload-deal-list')
          this.loadDeal(this.currentDealId)
        }
      }
    },
    onConfirmSelectBackupDeal (confirmed) {
      if (this.selectBackupDealActive) {
        if (confirmed) {
          if (!this.selectedDealItemId) {
            this.selectBackupDealErrors = true
          } else {
            this.selectBackupDealErrors = false
            this.isSaving = true
            let dealUpdatePrimaryModel = null
            this.backupDeals.forEach(deal => {
              deal.items.forEach(dealItem => {
                if (dealItem.id === this.selectedDealItemId) {
                  dealUpdatePrimaryModel = {
                    dealItemId: this.selectedDealItemId,
                    isPrimary: true,
                    inventoryId: dealItem.forInventory.id,
                    originalInventoryId: dealItem.forInventory.id,
                    originalIsPrimary: dealItem.isPrimary
                  }
                }
              })
            })
            if (dealUpdatePrimaryModel) {
              dealService.updateIsPrimary(dealUpdatePrimaryModel).then(response => {
                if (response.errors) {
                  this.serverErrors = response.errors
                } else {
                  this.selectBackupDealActive = false
                  this.isEditLocked = true
                  this.onChangesSaved()
                  eventBus.$emit('reload-deal-list')
                  this.loadDeal(this.currentDealId)
                }
              }).catch((error) => {
                this.serverErrorMessage = error.message
                this.isSaving = false
              })
            } else {
              this.selectBackupDealActive = false
            }
          }
        } else {
          this.selectBackupDealErrors = false
          this.selectBackupDealActive = false
          this.isEditLocked = true
          this.onChangesSaved()
          eventBus.$emit('reload-deal-list')
          this.loadDeal(this.currentDealId)
        }
      }
    },
    onConfirmStatusProgressionWizard (confirmed) {
      this.statusProgressionWizardActive = false
    },
    setStatusPending: function () {
      if (this.selectedDealStatus) {
        this.isNewArrivalCaptureActive = false
        this.canBypassAwaitingToPendingCheck = true
        this.selectedDealStatus = this.statusWidgetDealTypes.find((x) => x.name === 'Pending' || x.altName === 'Pending')
        this.statusProgressionWizardActive = true
      }
    },
    setStatusBooked: function () {
      if (this.selectedDealStatus) {
        this.selectedLeadSource = { id: this.currentDeal.sourceId, name: this.currentDeal.sourceName, dealerId: this.currentDeal.dealerId }
        this.bookDealCaptureActive = false
        this.canBypassDeliveredToBookedCheck = true
        this.canShowRebookBtn = false
        if (this.isBookedDeal) {
          this.definedTypes.dealTypes.forEach((x) => {
            var index = this.statusWidgetDealTypes.findIndex((y) => (y.name === x.name || y.altName === x.altname) && y.id !== this.selectedDealStatus.id)
            if (index !== -1) {
              this.$set(this.statusWidgetDealTypes, index, {
                id: x.id,
                name: x.name,
                altName: x.altName,
                widgetOrder: x.widgetOrder,
                icon: x.icon,
                widgetClass: 'notselectable',
                selectableId: x.selectableId,
                statusProgressionWizardActive: x.statusProgressionWizardActive })
            }
          })
          // make next step status selectable
          this.makeStatusesSelectable(this.selectedDealStatus.id)
          if (this.currentDeal.backupDeals && this.currentDeal.backupDeals.length > 0) {
            this.backupDeals = this.currentDeal.backupDeals
            this.cancelBackupDealsActive = true
          }
        } else {
          this.selectedDealStatus = this.statusWidgetDealTypes.find((x) => x.name === 'Booked' || x.altName === 'Booked')
        }
      }
      this.statusProgressionWizardActive = true
    },
    unlockBookedDeal: function (isBeingCalledFromBookDealCapture) {
      this.unbookDealActive = true
      this.statusProgressionWizardActive = false
      this.unlockBookedDealFromBookingWidgetFlag = isBeingCalledFromBookDealCapture
    },
    setStatusAsSelectable: function (altName) {
      var index = this.statusWidgetDealTypes.findIndex((x) => x.altName === altName || x.name === altName)
      if (index !== -1) {
        var statusObject = this.statusWidgetDealTypes.find((x) => x.altName === altName)
        if (statusObject) {
          this.$set(this.statusWidgetDealTypes, index, {
            id: statusObject.id,
            name: statusObject.name,
            altName: statusObject.altName,
            widgetOrder: statusObject.widgetOrder,
            icon: statusObject.icon,
            widgetClass: 'selectable',
            selectableId: statusObject.selectableId,
            statusProgressionWizardActive: statusObject.statusProgressionWizardActive
          })
        }
      }
    },
    makeStatusesSelectable: function (id) {
      var availableStepIds = this.statusWidgetDealTypes.find((x) => x.id === id).selectableId
      for (var i = 0; i < availableStepIds.length; i++) {
        var nextStepIndex = this.statusWidgetDealTypes.findIndex((x) => x.id === availableStepIds[i])
        if (nextStepIndex !== -1) {
          var nextStep = this.statusWidgetDealTypes.find((x) => x.id === availableStepIds[i])
          if (nextStep &&
            nextStep.altName === 'Booked' &&
            this.dealHasTrade &&
            !this.isTradeWorkFlowComplete &&
            (this.isTradeTypeTradeIn || this.isTradeTypePpaAppraisal)) {

          } else if (nextStep) {
            this.$set(this.statusWidgetDealTypes, nextStepIndex, {
              id: nextStep.id,
              name: nextStep.name,
              altName: nextStep.altName,
              widgetOrder: nextStep.widgetOrder,
              icon: nextStep.icon,
              widgetClass: 'selectable',
              selectableId: nextStep.selectableId,
              statusProgressionWizardActive: nextStep.statusProgressionWizardActive
            })
          }
        }
      }
    },
    onMoveToNextStatus: function () {
      if (!this.selectedDealStatus || (this.selectedDealStatus && !this.selectedDealStatus.selectableId.length > 1)) return

      var nextStepId = this.selectedDealStatus.selectableId[this.selectedDealStatus.selectableId.length - 2]
      var nextStep = this.statusWidgetDealTypes.find((x) => x.id === nextStepId)

      // check if this is pending credit instock deal, if it is then next step is pending deal
      if (this.isPendingCredit && this.isInStockDealType) {
        this.changeStatusTo = this.statusWidgetDealTypes.find((x) => x.altName === 'Pending')
      } else {
        this.changeStatusTo = nextStep
      }

      if (nextStep && nextStep.altName === 'Delivered' && this.isPendingDeal) {
        this.statusProgressionWizardActive = false
        this.setDeliveryDateActive = true
        return
      }

      if (this.isPendingCredit && nextStep && (nextStep.altName === 'Pending' || nextStep.altName === 'AwaitingArrival')) {
        this.statusProgressionWizardActive = false
        this.setPromiseDateActive = true
        return
      }

      this.statusProgressionWizardActive = false
      this.confirmStatusChangeActive = true
    },
    setStatusAwaitingArrival: function () {
      if (this.selectedDealStatus) {
        this.isLocatedCaptureActive = false
        this.canBypassLocateToAwaitingCheck = true
        this.selectedDealStatus = this.statusWidgetDealTypes.find((x) => x.name === 'AwaitingArrival' || x.altName === 'AwaitingArrival')
      }
      this.statusProgressionWizardActive = true
    },
    populateProducts: function () {
      this.products = []
      if (this.firstSoldItem && this.firstSoldItem.itemProducts) {
        this.firstSoldItem.itemProducts.forEach(function (product) {
          this.products.push(product)
        }, this)
      }
    },
    addProduct: function () {
      this.newProduct = this.createGenericProduct()
      this.newProduct.isAddingCost = true
    },
    editProduct (product, index) {
      this.newProduct = this.createGenericProduct()
      this.newProduct = {
        index: index,
        id: product.id,
        name: product.name,
        description: product.description,
        cost: product.cost,
        sellingPrice: product.sellingPrice,
        dealItemId: product.dealItemId,
        isAddingCost: true,
        type: product.type
      }
    },
    createGenericProduct () {
      return {
        id: 0,
        name: null,
        description: '',
        cost: null,
        sellingPrice: null,
        isAddingCost: false
      }
    },
    removeProduct (index) {
      var item = this.products[index]
      if (item.id === 0) {
        this.$delete(this.products, index)
      } else {
        item.delete = true
      }
    },
    sumSalePriceProductTotal: function (salePrice, productTotal) {
      return salePrice + productTotal
    },
    reloadDeal () {
      this.canShowRebookBtn = false
      this.loadDeal(this.currentDealId)
    },
    onArrivalCaptureComplete: function () {
      this.reloadDeal()
      this.isNewArrivalCaptureActive = false
      this.statusProgressionWizardActive = true
      this.setDealPendingArrival = true
    },
    onRestoreDealStatus: function () {
      if (this.selectedDealStatus) {
        this.currentDeal.typeBeforeCancellation = (this.currentDeal.typeBeforeCancellation === 'Booked') ? 'Pending' : this.currentDeal.typeBeforeCancellation
        let previousStatus = this.statusWidgetDealTypes.find((x) => x.altName === this.currentDeal.typeBeforeCancellation || x.name === this.currentDeal.typeBeforeCancellation)

        if (previousStatus && this.hasRefunds) {
          this.restoreCancelledDealActive = true
          return
        }

        if (previousStatus) {
          this.selectedDealStatus = previousStatus
        }
      }
    },
    onSaveDeal () {
      let promisedDate = this.currentDeal.promiseDate ? this.convertLocalToUtc(this.currentDeal.promiseDate) : null
      let deliveryDate = this.currentDeal.deliveryDate ? this.convertLocalToUtc(this.currentDeal.deliveryDate) : null
      let appointmentDate = this.currentDeal.appointmentDate ? this.convertLocalToUtc(this.currentDeal.appointmentDate) : null
      let allocatedMonth = this.currentDeal.allocatedMonth ? this.convertLocalToUtc(this.currentDeal.allocatedMonth) : null
      let orderAllocationSubmission = this.currentDeal.orderAllocationSubmission ? this.convertLocalToUtc(this.currentDeal.orderAllocationSubmission) : null
      let type = (this.selectedDealStatus) ? this.selectedDealStatus.altName : ''
      let sourceId = (this.selectedLeadSource !== null) ? this.selectedLeadSource.id : null
      let soldAt = this.currentDeal.soldAt ? this.convertLocalToUtc(new Date(this.currentDeal.soldAt)) : null

      let dealModel = {
        ...this.currentDeal,
        supplementalCosts: [],
        commissions: [],
        promiseDate: promisedDate,
        type: type,
        sourceId: sourceId,
        soldAt: soldAt,
        deliveryDate: deliveryDate,
        appointmentDate: appointmentDate,
        allocatedMonth: allocatedMonth,
        orderAllocationSubmission: orderAllocationSubmission
      }

      this.saveSupplementalCost(dealModel)
      this.saveCommission(dealModel)
      this.isSaving = true
      this.isLoading = true

      dealService.update(dealModel).then(response => {
        this.isSaving = false
        this.isLoading = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.currentDeal = response.deal
          this.reloadDeal()
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
        this.isLoading = false
      })
    },
    saveSupplementalCost: function (dealModel) {
      this.supplementalCosts.forEach(function (cost) {
        var supplementalCost = {
          id: cost.id,
          dealerDefaultCostId: cost.dealerDefaultCostId,
          name: cost.name,
          payeeType: cost.payeeType,
          vendorId: cost.vendorId,
          vendorName: cost.vendorName,
          attachments: cost.attachments,
          processingAction: cost.processingAction,
          description: cost.description,
          cost: cost.cost,
          sellingPrice: cost.sellingPrice,
          isInGlBalance: cost.isInGlBalance,
          delete: cost.delete === undefined ? false : cost.delete
        }

        dealModel.supplementalCosts.push(supplementalCost)
      }, this)
    },
    saveCommission: function (dealModel) {
      this.commissions.forEach(function (commission) {
        var dealCommission = {
          id: commission.id,
          salesPersonId: commission.salesPersonId,
          commission: commission.commission,
          delete: commission.delete === undefined ? false : commission.delete,
          requestStatus: commission.requestStatus
        }
        dealModel.commissions.push(dealCommission)
      }, this)
    },
    removeSupplementalCost (index) {
      var item = this.supplementalCosts[index]
      if (item.id === 0) {
        this.$delete(this.supplementalCosts, index)
      } else {
        item.delete = true
      }
    },
    removeCommission (index) {
      var item = this.commissions[index]
      if (item.id === 0) {
        this.$delete(this.commissions, index)
      } else {
        this.commissions[index].delete = true
      }
    },
    createGenericSupplementalCost () {
      return {
        id: 0,
        name: null,
        payeeType: 'Supplier',
        vendorId: null,
        vendorName: '',
        attachments: [],
        processingAction: null,
        description: '',
        cost: null,
        sellingPrice: null,
        isInGlBalance: false,
        isAddingCost: false,
        dealerDefaultCostId: null
      }
    },
    createGenericCommission () {
      return {
        id: 0,
        salesPersonId: null,
        salesPersonName: null,
        commission: null,
        isAddingCommission: false,
        requestStatus: 'Requested'
      }
    },
    addSupplementalCost: function () {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost.isAddingCost = true
    },
    addCommission: function () {
      this.newCommission = this.createGenericCommission()
      this.newCommission.isAddingCommission = true
    },
    editSupplementalCost (supplement, index) {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost = {
        index: index,
        id: supplement.id,
        dealerDefaultCostId: supplement.dealerDefaultCostId,
        name: supplement.name,
        payeeType: supplement.payeeType,
        vendorId: supplement.vendorId,
        vendorName: supplement.vendorName,
        attachments: supplement.attachments,
        processingAction: supplement.processingAction,
        description: supplement.description,
        cost: supplement.cost,
        sellingPrice: supplement.sellingPrice,
        dealId: supplement.dealId,
        isInGlBalance: supplement.isInGlBalance,
        isAddingCost: true
      }
    },
    editCommission (commission, index) {
      this.newCommission = this.createGenericCommission()
      this.newCommission = {
        index: index,
        id: commission.id,
        salesPersonId: commission.salesPersonId,
        salesPersonName: commission.salesPersonName,
        commission: commission.commission,
        dealId: commission.dealId,
        isAddingCommission: true,
        requestStatus: commission.requestStatus
      }
    },
    onConfirmUnbookDeal (confirmed) {
      if (confirmed) {
        this.canShowRebookBtn = true
        this.setStatusAsSelectable('AwaitingArrival')
        this.setStatusAsSelectable('Pending')
        this.setStatusAsSelectable('Delivered')
        this.setStatusAsSelectable('Cancelled')
        this.selectedDealStatus = this.statusWidgetDealTypes.find((x) => x.altName === 'Delivered' || x.name === 'Delivered')
      }

      this.unbookDealActive = false
      if (this.unlockBookedDealFromBookingWidgetFlag) {
        this.bookDealCaptureActive = true
        this.unlockBookedDealFromBookingWidgetFlag = false
      } else {
        this.statusProgressionWizardActive = true
      }
    },
    updateEmailModalState: function (state) {
      this.isNewCheckRequest = state

      if (state === true) {
        this.overridingSubject = 'Check Request Required'
        this.optionalMessage = 'A new check request is required for deal #' + this.currentDeal.code + '.'
      }

      if (state === false) {
        this.clearAllEmails()
      }
    },
    onEmailCheckRequest: function () {
      var emailAddresses = this.selectedAddresses.join(', ')
      var checkRequestEmailModel = {
        subscriberId: this.currentDeal.subscriberId,
        dealerId: this.currentDeal.dealerId,
        dealId: this.currentDeal.id,
        destinationAddresses: emailAddresses,
        overridingSubject: this.overridingSubject,
        optionalMessage: this.optionalMessage,
        type: 'Deal'
      }

      dealService.queueCheckRequestMail(checkRequestEmailModel).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.clearAllEmails()
          this.isNewCheckRequest = false
        }
      }).catch((error) => {
        throw error
      })
    },
    emailAdded: function (data) {
      this.selectedAddresses.push(data)
    },
    clearAllEmails () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedAddresses = []
    },
    calculatedFrontGross: function (dealItem) {
      let reconCost = this.calculatedReconLive(dealItem)
      let purchaseCostTotal = 0
      let tradeAcvDifference = this.totalAcvDifference || 0.00
      if (dealItem.forInventory && dealItem.forInventory.purchaseCostTotal) {
        purchaseCostTotal = dealItem.forInventory.purchaseCostTotal
      }
      let inventoryAdditionalCosts = this.inventoryAdditionalCostsTotal(dealItem)
      let actualInventoryCost = purchaseCostTotal + reconCost + tradeAcvDifference + inventoryAdditionalCosts
      let result = dealItem.salePrice + this.additionalCostsGP - (actualInventoryCost)
      // dealItem.frontValue = this.round2Digits(result)
      return this.round2Digits(result)
    },
    calculatedFrontGrossCDK: function (dealItem) {
      let tradeAcvDifference = this.tradeAcvDifference || 0.00
      let actualInventoryCost = this.frontGrossSubtotalCDK(dealItem) + tradeAcvDifference
      let result = dealItem.salePrice + this.additionalCostsGP - (actualInventoryCost)
      return this.round2Digits(result)
    },
    frontGrossSubtotalCDK: function (dealItem) {
      let result = (dealItem.forInventory) ? (dealItem.forInventory.generalLedgerBalance) ? dealItem.forInventory.generalLedgerBalance : 0.00 : 0.00
      return this.round2Digits(result)
    },
    dealItemTotalGP: function (dealItem) {
      let holdValue = dealItem.vehicleType === 'New' ? dealItem.holdValue : 0.00
      return dealItem.frontValue + dealItem.warValue + dealItem.finResValue + holdValue + dealItem.mvpValue + this.dealItemProductGP(dealItem)
    },
    dealItemProductGP: function (dealItem) {
      return dealItem.itemProducts.filter(y => y.type === 'Product' && !y.delete).map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next, 0)
    },
    onLoadBookDealCapture: function (progressStatus) {
      if (this.selectedDealStatus && this.selectedDealStatus.altName === 'Allocated') {
        this.setStatusAsNotSelectable('Cancelled')
        this.setStatusAsNotSelectable('Allocated')
      }
      this.progressStatus = progressStatus
      this.bookDealCaptureActive = true
    },
    onLaunchStatusChangeModal: function (statusObj) {
      let progressStatusTo = statusObj.name
      if (statusObj.widgetClass !== 'selectable') return

      if (progressStatusTo === 'Allocated') {
        this.openConfirmFulfillModal()
        return
      }

      this.changeStatusTo = statusObj
      this.statusProgressionWizardActive = false
      if (progressStatusTo === 'Delivered' && this.isPendingDeal) {
        this.setDeliveryDateActive = true
        return
      }

      if ((progressStatusTo === 'Pending' || progressStatusTo === 'AwaitingArrival') && this.isPendingCredit) {
        this.setPromiseDateActive = true
        return
      }

      this.confirmStatusChangeActive = true
    },
    onConfirmPendingStatusChange: function (save) {
      if (this.selectedDealStatus.altName === 'Pending') {
        this.$validator.validate('progress-to-delivered.*').then(result => {
          if (result) {
            this.onConfirmStatusChange(save)
          }
        })
      }
    },
    onConfirmPendingCreditStatusChange: function (save) {
      if (this.isPendingCredit) {
        this.$validator.validate('progress-from-pending-credit.*').then(result => {
          if (result) {
            this.onConfirmStatusChange(save)
          }
        })
      }
    },
    onConfirmStatusChange: function (save) {
      if (save) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            if (save && this.changeStatusTo && this.selectedDealStatus) {
              let active = (this.selectedDealStatus.name !== 'Ordered') ? this.changeStatusTo.statusProgressionWizardActive : true
              this.statusProgressionWizardActive = active
              this.selectedDealStatus = this.changeStatusTo
            } else {
              this.statusProgressionWizardActive = true
            }
            this.changeStatusTo = null
            this.confirmStatusChangeActive = false
            this.setDeliveryDateActive = false
            this.setPromiseDateActive = false
          }
        })
      } else {
        this.changeStatusTo = null
        this.confirmStatusChangeActive = false
        this.setDeliveryDateActive = false
        this.setPromiseDateActive = false
      }
    },
    inventoryHoldValue: function (inventoryItem) {
      if (inventoryItem) {
        var vehicleMake = this.allVehicleMakes.find((x) => x.id === (inventoryItem.makeId || inventoryItem.vehicleMakeId))
        if (vehicleMake !== undefined) {
          var vehicleMakeModel = vehicleMake.versions.find((x) => x.id === (inventoryItem.modelId || inventoryItem.vehicleMakeVersionId))
          if (vehicleMakeModel !== undefined) {
            let msrpToUse = inventoryItem.msrp
            let msrpDeductions = this.defaultMsrpDeductions ? this.defaultMsrpDeductions : 0
            msrpToUse = (vehicleMake.holdbackValueCalculation && vehicleMake.holdbackValueCalculation === 'BaseMsrp') ? vehicleMakeModel.msrp : msrpToUse
            if (msrpToUse && msrpToUse !== 0 && vehicleMakeModel.holdBackPercentage !== null) {
              return (msrpToUse - msrpDeductions) * vehicleMakeModel.holdBackPercentage / 100
            } else {
              return vehicleMakeModel.holdValue - msrpDeductions
            }
          }
        }
      }
      return 0.00
    },
    onSetHoldBack (dealItem) {
      if (dealItem.holdValue !== this.inventoryHoldValue(dealItem.forInventory)) {
        if (!this.isHoldbackEditable) {
          this.failedToast('You need to unlock holdback for editing in order to reset the holdback value!')
        } else {
          dealItem.holdValue = this.inventoryHoldValue(dealItem.forInventory)
        }
      }
    },
    calculatedReconCDK: function (dealItem) {
      let purchaseCostTotal = 0.00
      let generalLedgerBalance = 0.00
      if (dealItem.forInventory) {
        if (dealItem.forInventory.purchaseCostTotal) {
          purchaseCostTotal = dealItem.forInventory.purchaseCostTotal
        }
        if (dealItem.forInventory.generalLedgerBalance) {
          generalLedgerBalance = dealItem.forInventory.generalLedgerBalance
        }
      }
      let result = generalLedgerBalance - (purchaseCostTotal + this.inventoryAdditionalCostsTotal(dealItem))
      return this.round2Digits(result)
    },
    calculatedReconLive: function (dealItem) {
      let result = 0.00
      /*
      if (dealItem.forInventory && dealItem.forInventory.reconCost) {
        result = dealItem.forInventory.reconCost + -(this.additionalCostsRecon)
      } else {
        result = -(this.additionalCostsRecon)
      }
      */
      if (dealItem.forInventory && dealItem.forInventory.reconCost) {
        result = dealItem.forInventory.reconCost
      }
      return this.round2Digits(result)
    },
    frontGrossSubtotalLive: function (dealItem) {
      let purchaseCostTotal = 0.00
      if (dealItem.forInventory && dealItem.forInventory.purchaseCostTotal) {
        purchaseCostTotal = dealItem.forInventory.purchaseCostTotal
      }
      let result = purchaseCostTotal + this.calculatedReconLive(dealItem) + this.inventoryAdditionalCostsTotal(dealItem)
      return this.round2Digits(result)
    },
    inventoryAdditionalCostsTotal: function (dealItem) {
      return dealItem.forInventory && dealItem.forInventory.additionalCostsTotal ? this.round2Digits(dealItem.forInventory.additionalCostsTotal) : 0.00
    },
    clearErrors: function () {
      this.errors.clear()
      this.serverErrors = []
      this.serverErrorMessage = ''
    },
    onSavedPaymentControl: function () {
      eventBus.$emit('reload-deal-list')
      eventBus.$emit('reload-payment-tracking-list')
      this.reloadDeal()
    },
    onCancelPaymentRequest: function () {
      eventBus.$emit('reload-deal-list')
      eventBus.$emit('reload-payment-tracking-list')
      this.reloadDeal()
    },
    onCloseSmsConversationControl: function () {
      this.smsConversationControlActive = false
    },
    onInventorySaved: function (modifiedInventoryItem) {
      let firstSoldItemInventory = this.soldItems.length > 0 ? this.soldItems[0].forInventory : null
      if (firstSoldItemInventory && firstSoldItemInventory.id === modifiedInventoryItem.id && modifiedInventoryItem.status === 'Active') {
        firstSoldItemInventory.status = 'Active'
      }

      let tradeItem = this.tradeItems.length > 0 ? this.tradeItems[0].forInventory : null
      if (tradeItem && tradeItem.id === modifiedInventoryItem.id && modifiedInventoryItem.status === 'Active') {
        tradeItem.status = 'Active'
        eventBus.$emit('reload-deal-list')
        this.reloadDeal()
      }

      this.editInventoryActive = false
      this.editTradePreEntryActive = false
    },
    onInventoryCancelled: function () {
      this.editInventoryActive = false
      this.editTradePreEntryActive = false
    },
    restoreDealAdditionalCosts: function () {
      let savedSupplementalCosts = this.supplementalCosts.filter((x) => x.id !== 0)
      savedSupplementalCosts.forEach((x) => {
        x.delete = true
      })
      this.supplementalCosts = savedSupplementalCosts

      let dealer = this.$store.state.dealer.currentDealer
      let dealSupplementalCosts = dealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
      if (this.firstSoldItemInventory && this.firstSoldItemInventory.vehicleMakeId) {
        let vehicleMakeSpecificCosts = this.currentDealer.defaultSupplementalCosts.filter(f => f.vehicleMakes.some(o => o.vehicleMakeId === this.firstSoldItemInventory.vehicleMakeId))
        let vehicleMakeSupplementalCosts = vehicleMakeSpecificCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
        dealSupplementalCosts = this.currentDealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
        dealSupplementalCosts.forEach(function (item, index) {
          let foundIndex = vehicleMakeSupplementalCosts.findIndex(x => x.name === item.name)
          if (foundIndex === -1) {
            vehicleMakeSupplementalCosts.push(item)
          }
        })
        dealSupplementalCosts = []
        dealSupplementalCosts = [...vehicleMakeSupplementalCosts]
      }
      dealSupplementalCosts = this.uniqueDefaultCosts(dealSupplementalCosts)
      let validSupplementalCosts = dealSupplementalCosts.filter((y) => {
        var isValid = true
        if (this.selectedSaleType && this.isTypeofWholesale) {
          isValid = isValid && y.appliesTo.includes('Wholesale')
        }

        if (this.selectedSaleType && this.isSaleTypeOfRetail) {
          isValid = isValid && y.appliesTo.includes('Retail')
        }

        if (this.firstSoldItemSaleType && this.firstSoldItemSaleType !== '') {
          isValid = isValid && y.appliesTo.includes(this.firstSoldItemSaleType)
        }
        return isValid === true
      })

      validSupplementalCosts.forEach((x) => {
        let cost = {
          ...x,
          id: 0,
          dealerDefaultCostId: x.id,
          delete: false
        }
        this.supplementalCosts.push(cost)
      })
    },
    buildNotesSectionOverview: function (deal, length) {
      var note = deal.notes.length > 0 ? deal.notes[0].note : ''
      note = note.includes('SAVED RECON ADJUSTMENTS') ? 'Saved Recon Adjustments' : note
      if (note.length > length) {
        return note.substring(0, length) + '...'
      } else {
        return note
      }
    },
    onOpenVerifySupplier: function () {
      this.reopenSendWireControlModal = true
      eventBus.$emit('on-open-verify-supplier')
    },
    onOpenSendWireControl: function () {
      if (this.reopenSendWireControlModal) {
        eventBus.$emit('on-open-send-wire-control')
        this.reopenSendWireControlModal = false
      }
    },
    onSaveGetReady: function (soldServiceRequest) {
      this.soldServiceRequest = soldServiceRequest
      this.soldServiceRequest.lines = soldServiceRequest.lines.map((x) => {
        return {
          description: x.description,
          isActive: x.isActive,
          opCode: x.opCode,
          position: x.position
        }
      })

      if (soldServiceRequest.updateExpectedDeliveryDate) {
        this.currentDeal.promiseDate = soldServiceRequest.requiredAt
      }

      this.hasServiceRequest = true
      this.confirmedGetReadySave = true
      this.isGetReadyCapture = false
      this.savePartialDeal(false, false)
    },
    cancelGetReady: function () {
      this.confirmedGetReadySave = false
      this.hasServiceRequest = false
      this.isGetReadyCapture = false
    },
    skipGetReady: function () {
      this.confirmedGetReadySave = true
      this.hasServiceRequest = true
      this.isGetReadyCapture = false
      this.savePartialDeal(false, false)
    },
    setStatusAsNotSelectable: function (altName) {
      var index = this.statusWidgetDealTypes.findIndex((x) => x.altName === altName || x.name === altName)
      if (index !== -1) {
        var statusObject = this.statusWidgetDealTypes.find((x) => x.altName === altName)
        this.$set(this.statusWidgetDealTypes, index, {
          id: statusObject.id,
          name: statusObject.name,
          altName: statusObject.altName,
          widgetOrder: statusObject.widgetOrder,
          icon: statusObject.icon,
          widgetClass: 'notselectable',
          selectableId: statusObject.selectableId,
          statusProgressionWizardActive: statusObject.statusProgressionWizardActive
        })
      }
    },
    openConfirmFulfillModal: function () {
      this.statusProgressionWizardActive = false
      eventBus.$emit('on-open-fulfill-order-control')
    },
    setInventoryActive: function () {
      this.setDealPendingArrival = false
      this.isNewArrivalCaptureActive = true
    },
    saveChanges: function () {
      this.isSavingAllChanges = true
      this.dealItems.forEach(element => {
        this.savePricing(element)
      })
      this.onSaveDeal()
    },
    requestStatusType: function (type) {
      let found = this.definedTypes.serviceRequestStatusType.options.find((x) => (x.name === type || x.altName === type))
      return (!found) ? '' : found.name
    },
    requestType: function (type) {
      let found = this.definedTypes.serviceRequestType.options.find((x) => (x.name === type || x.altName === type))
      return (!found) ? '' : found.name
    },
    onViewDeal: function (dealId) {
      if (this.hasFeatureAccess('deal.view')) {
        this.$emit('close')
        this.$router.push({name: 'DealsQuickView', params: { id: dealId }})
      }
    },
    onPopFillExpectedDeliveryDate: function () {
      this.currentDeal.deliveryDate = this.currentDeal.promiseDate
    },
    onPopFillDeliveryDate: function () {
      var currentDate = new Date()
      this.currentDeal.deliveryDate = currentDate
    },
    convertLocalToUtc: function (date) {
      try {
        var localValue = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
        let utcValue = moment.tz(localValue, moment.defaultZone.name).utc().format('YYYY-MM-DD HH:mm')
        return utcValue
      } catch (dateConversionError) {
        console.log('Error converting date to UTC')
        console.log(date)
        console.log(dateConversionError)
      }
    },
    onRestoreRefund: function (restoreRefund) {
      this.restoreRefund = restoreRefund
      const previousStatus = this.statusWidgetDealTypes.find((x) => x.altName === this.currentDeal.typeBeforeCancellation || x.name === this.currentDeal.typeBeforeCancellation)
      if (previousStatus) {
        this.selectedDealStatus = previousStatus
      }
      this.restoreCancelledDealActive = false
    },
    getProgressionStatusName: function (data) {
      if (this.currentDeal.status === 'PreDeal' && data.altName === 'Pending') return 'PreDeal'
      return data.altName
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    },
    showDealCompletedIcon: function (type, status, isBookingFinalised) {
      let result = false
      if (type) {
        let dealStatusType = status === 'PreDeal' && type !== 'Cancelled' && type !== 'Backup Deals' ? 'PreDeal' : type
        if (dealStatusType === 'Booked' && isBookingFinalised) {
          result = true
        }
      }
      return result
    },
    showPricing: function () {
      this.pricingMode = true
      this.bookDealCaptureActive = true
    },
    getMatchingInboundStatus: function (statusValue) {
      if (statusValue) {
        let result = this.definedTypes.dealInboundStatusTypes.options.find((x) => x.altName === statusValue)
        if (result && result.name) {
          return result.name
        }
      }
      return ''
    },
    buildCreditStatusToolTip: function (currentDeal) {
      let fullStatus = ''
      if (currentDeal.creditApplicationStatus === undefined || currentDeal.creditApplicationStatus === null || currentDeal.creditApplicationStatus === '') return '-'
      fullStatus = currentDeal.creditApplicationStatus

      if (fullStatus === 'Completed') {
        if (currentDeal.dealCreditApplicationStatus === undefined || currentDeal.dealCreditApplicationStatus === null || currentDeal.dealCreditApplicationStatus === '') return fullStatus
        return currentDeal.creditApplicationStatus + ' (' + this.getCreditStatusDisplayFromCreditStatusValue(currentDeal.dealCreditApplicationStatus) + ')'
      }

      return fullStatus
    },
    buildCreditStatusForTitle: function (currentDeal) {
      let fullStatus = ''
      if (currentDeal.creditApplicationStatus === undefined || currentDeal.creditApplicationStatus === null || currentDeal.creditApplicationStatus === '') return '-'
      fullStatus = '(' + currentDeal.creditApplicationStatus + ')'
      if (currentDeal.creditApplicationStatus === 'Completed') {
        if (currentDeal.dealCreditApplicationStatus === undefined || currentDeal.dealCreditApplicationStatus === null || currentDeal.dealCreditApplicationStatus === '') return fullStatus
        return '(' + currentDeal.creditApplicationStatus + '-' + this.getCreditStatusDisplayFromCreditStatusValue(currentDeal.dealCreditApplicationStatus) + ')'
      }

      return fullStatus
    },
    getCreditStatusDisplayFromCreditStatusValue: function (dealCreditApplicationStatus) {
      if (!dealCreditApplicationStatus || dealCreditApplicationStatus === '') return ''
      let matchingStageEnum = this.definedTypes.dealCreditApplicationStatus.options.find(x => x.altName === dealCreditApplicationStatus)
      return matchingStageEnum ? matchingStageEnum.name : dealCreditApplicationStatus
    },
    setCreditInfo: function () {
      if (this.currentDeal) {
        if (this.currentDeal.dealCreditApplicationStatus) {
          this.dealCreditApplicationStatus = this.definedTypes.dealCreditApplicationStatus.options.find(x => x.altName === this.currentDeal.dealCreditApplicationStatus)
        } else {
          this.dealCreditApplicationStatus = null
        }
        if (this.currentDeal.creditApplicationBankApproval !== null) {
          this.bankApprovalStatus = this.currentDeal.creditApplicationBankApproval
        }
        this.creditApplicationNote = ''
        if (this.currentDeal.creditApplicationNote !== '' && this.currentDeal.creditApplicationNote !== undefined && this.currentDeal.creditApplicationNote !== null) {
          this.creditApplicationNote = this.currentDeal.creditApplicationNote
        }
      }
    },
    saveCreditApplicationStatusInfo: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      let that = this

      let creditAppNote = this.creditApplicationNote ? this.creditApplicationNote : ''
      let selectedCreditStatus = this.dealCreditApplicationStatus ? this.dealCreditApplicationStatus.altName : ''
      let creditAppBankApproval = this.bankApprovalStatus

      this.currentDeal.creditApplicationNote = creditAppNote
      this.currentDeal.dealCreditApplicationStatus = selectedCreditStatus
      this.currentDeal.creditApplicationBankApproval = creditAppBankApproval

      let dealCreditApplicationStatusModel = {
        dealId: this.currentDeal.id,
        dealCreditApplicationStatus: selectedCreditStatus,
        bankApprovalStatus: creditAppBankApproval,
        note: creditAppNote,
        attachments: []
      }

      let existingBankCreditApplicationApprovalFiles = this.bankCreditApplicationFiles.filter((y) => (y.id && y.id > 0))
      let attachmentFile = this.processAttachments(this.bankCreditApplicationFiles, existingBankCreditApplicationApprovalFiles, 15)
      if (attachmentFile) {
        dealCreditApplicationStatusModel.attachments.push(attachmentFile)
      }
      dealCreditApplicationStatusModel.attachments.push(...existingBankCreditApplicationApprovalFiles)

      dealService.updateCreditApplicationStatusInfo(dealCreditApplicationStatusModel).then(response => {
        that.isSaving = false
        if (response.errors) {
          that.serverErrors = response.errors
        } else {
          that.currentDeal = response.deal
          eventBus.$emit('deal-changed', {id: that.currentDeal.id})
          eventBus.$emit('quickview-refresh')
        }
      }).catch((error) => {
        that.serverErrorMessage = error.message
        that.isSaving = false
      })
    },
    clearCreditApplicationStatus: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      let that = this

      let dealCreditApplicationStatusModel = {
        dealId: this.currentDeal.id
      }

      dealService.clearCreditApplicationStatus(dealCreditApplicationStatusModel).then(response => {
        that.isSaving = false
        if (response.errors) {
          that.serverErrors = response.errors
        } else {
          that.currentDeal = response.deal
          this.bankApprovalStatus = null
          this.dealCreditApplicationStatus = null
          eventBus.$emit('deal-changed', {id: that.currentDeal.id})
          eventBus.$emit('quickview-refresh')
        }
      }).catch((error) => {
        that.serverErrorMessage = error.message
        that.isSaving = false
      })
    },
    isGrossDisabled: function (dealItem) {
      if (this.frontGrossSubtotalCDK(dealItem) < 15000) return false

      if (dealItem && dealItem.forInventory && dealItem.forInventory.purchaseCostTotal) {
        return dealItem.forInventory.purchaseCostTotal > 15000
      }
      return false
    },
    getDealAttachmentsByType: function (type) {
      let resultAttachments = []
      if (this.currentDeal && this.currentDeal.attachments && this.currentDeal.attachments.length > 0) {
        let orderedAttachments = _.orderBy(this.currentDeal.attachments, ['createdAt'], ['desc'])
        orderedAttachments.filter(y => y.status !== 'Deleted' && y.classType === type).forEach(x => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.fileType = this.getFileType(x.fileLocation)
          resultAttachments.push(x)
        })
      }
      return resultAttachments
    },
    processAttachments: function (currentFiles, existingFiles, fileClassType) {
      let attachments = currentFiles.filter((y) => (!y.id || y.id <= 0) && y.status !== 'Deleted').map((x) => {
        return {
          uploadName: x.name,
          uploadSize: x.size,
          uniqueId: x.uniqueId,
          type: x.type
        }
      })
      if (attachments && attachments.length > 0) {
        existingFiles.forEach(x => {
          if (x.status === 'Active') {
            x.status = 'Archived'
          }
        })
        let attachmentFile = this.getAttachmentFile(attachments, fileClassType)
        if (attachmentFile) {
          return attachmentFile
        }
      }
      return null
    },
    getAttachmentFile: function (dealAttachments, classType) {
      let classDict = {
        15: 'BankCreditApplicationApproval'
      }
      let dealAttachmentClassType = classDict[classType]
      if (dealAttachmentClassType) {
        let uploadFileIndex = dealAttachments.length - 1
        let dealAttachmentModel = {
          dealId: this.currentDealId,
          classType: dealAttachmentClassType,
          uploadName: dealAttachments[uploadFileIndex].uploadName,
          uploadSize: dealAttachments[uploadFileIndex].uploadSize,
          uniqueId: dealAttachments[uploadFileIndex].uniqueId,
          type: dealAttachments[uploadFileIndex].type
        }
        return dealAttachmentModel
      }
      return null
    },
    scrollToCreditApplicationStatusSync: function () {
      if (this.currentDeal && this.currentDeal.creditApplicationStatus === '') return false
      let that = this
      this.$nextTick(() => {
        setTimeout(function () {
          that.$goToBlock(that.$refs.creditApplicationStatusContainer, {
            behavior: 'smooth',
            block: 'end',
            inline: 'end'
          })
        }, 500)
      })
    },
    commissionRequestStatusChange: function (selectedCommissionRequestStatus, index) {
      if (selectedCommissionRequestStatus) {
        var commission = this.commissions[index]
        if (commission) {
          commission.requestStatus = selectedCommissionRequestStatus.altName
        }
      }
    },
    getCommissionRequestIconColour: function (commissionRequest) {
      if (commissionRequest) {
        if (commissionRequest.requestStatus === 'Requested') {
          return 'has-text-warning'
        } else if (commissionRequest.requestStatus === 'Approved') {
          return 'has-text-success'
        } else if (commissionRequest.requestStatus === 'Rejected') {
          return 'has-text-danger'
        }
      }
      return ''
    },
    createTradeWorkFlowItem: function (tradeItem) {
      let forInventory = tradeItem.forInventory
      let incoming = forInventory.possessionStatus === 'Inbound'
      let tradeHasTitle = forInventory.titleStatus === 'Yes' || forInventory.titleStatus === 'PayOff' || forInventory.titleStatus === 'Lost'
      let tradeHasAgreement = forInventory.sellersAgreementStatus === 'Signed' || forInventory.sellersAgreementStatus === 'Complete'
      let tradeHasAgreementPending = forInventory.hasPendingRetailAgreements
      let tradeHasCheckRequest = forInventory.paymentRequestStatus === 'Sent'
      let carFaxStatus = -1
      if (forInventory.carFaxRating && (forInventory.carFaxRating.brandedTitle === 1 || forInventory.carFaxRating.totalAccidents > 0 ||
        forInventory.carFaxRating.accidentIndicator === 1 || forInventory.carFaxRating.frameDamageOrRepair === 1 || forInventory.carFaxRating.airBagDeployed === 1 ||
        forInventory.carFaxRating.damaged === 1 || forInventory.carFaxRating.majorProblems === 1 || forInventory.carFaxRating.recall === 1)) {
        carFaxStatus = 0
      }
      let isPpa = forInventory.type === 'ForPpaAppraisal'
      let tradeAllowanceApproved = tradeItem.tradeAllowanceApproved

      let item = {
        tradeId: forInventory.id,
        hasTrade: true,
        tradeIncoming: incoming,
        tradeHasTitle: tradeHasTitle,
        tradeHasAgreement: tradeHasAgreement,
        tradeHasCheckRequest: tradeHasCheckRequest,
        tradeDamaged: carFaxStatus === 0 || forInventory.isDamaged,
        carFaxStatus: carFaxStatus,
        tradeHasAgreementPending: tradeHasAgreementPending,
        tradeArrivalSkipped: forInventory.arrivalSkipped,
        tradeArrivalSkippedBy: forInventory.arrivalSkippedBy,
        tradeIsPPA: isPpa,
        dealId: this.currentDealId,
        tradeAllowanceApproved: tradeAllowanceApproved,
        tradeStockNo: forInventory.stockNo,
        tradeExamined: forInventory.ppaExamined,
        tradeExaminedByName: forInventory.ppaExaminedByName,
        tradeExaminedAt: forInventory.ppaExaminedAt,
        stockNo: forInventory.stockNo
      }

      return item
    }
  },
  watch: {
    '$route.params.id': function (id) {
      let that = this
      this.checkDirty(function () {
        that.selectedDealStatus = null
        if (id) {
          that.loadDeal(id)
        } else {
          eventBus.$emit('close-global-quick-view', {})
        }
      })
    },
    isDirty: function (newValue, oldValue) {
      this.$emit('dirty-state-change', newValue)
    },
    selectedDealStatus: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.altName !== this.currentDeal.type) {
        if (newValue.widgetClass === 'notselectable' && this.currentDeal.type !== 'Cancelled') {
          this.selectedDealStatus = oldValue
        } else if (newValue.altName === 'Pending' && oldValue && oldValue.altName === 'AwaitingArrival' && !this.canBypassAwaitingToPendingCheck) {
          this.isNewArrivalCaptureActive = true
          // set it back to awaiting arrival as arrival capture form will update status to pending
          this.selectedDealStatus = oldValue
        } else if (newValue.altName === 'Booked' && oldValue && oldValue.altName === 'Delivered' && !this.canBypassDeliveredToBookedCheck) {
          console.log('selectedDealStatus new', newValue.altName)
          console.log('selectedDealStatus old', oldValue.altName)
          this.onLoadBookDealCapture(true)
          // set it back to Delivered as booking wizard form will update status to Booked
          this.selectedDealStatus = oldValue
        } else if (this.BackupDeal && newValue.altName === 'Delivered' && oldValue && oldValue.altName === 'Pending') {
          // set it back to Pending as this was a backup deal being switched to a primary deal, user cannot progress a backup deal
          this.selectedDealStatus = oldValue
        } else if (newValue.altName === 'AwaitingArrival' && oldValue.altName === 'Locating' && !this.canBypassLocateToAwaitingCheck) {
          this.isLocatedCaptureActive = true
          // set it back to locate as locate capture will update to awaiting arrival
          this.selectedDealStatus = oldValue
        } else if (newValue.altName === 'AwaitingArrival' && oldValue.altName === 'PendingCredit' && !this.canBypassLocateToAwaitingCheck) {
          this.isLocatedCaptureActive = true
          // set it back to locate as locate capture will update to awaiting arrival
          this.selectedDealStatus = oldValue
        } else {
          this.currentDeal.type = newValue.altName

          // If progressing from AwaitingArrival we must set soldAt to today and the promiseDate 3 days later
          if (newValue.altName === 'Pending' && oldValue && oldValue.altName === 'AwaitingArrival') {
            let proposedPromiseDate = new Date()
            proposedPromiseDate.setHours(13, 0, 0, 0)
            proposedPromiseDate = this.addDays(proposedPromiseDate, 3)
            this.currentDeal.promiseDate = proposedPromiseDate

            let soldAt = new Date()
            soldAt.setHours(0, 0, 0, 0)
            this.currentDeal.soldAt = soldAt
          }

          this.savePartialDeal(true, true)

          // make all statuses not selectable
          this.definedTypes.dealTypes.forEach((x) => {
            var index = this.statusWidgetDealTypes.findIndex((y) => (y.name === x.name || y.altName === x.altname) && y.id !== newValue.id)
            if (index !== -1) {
              this.$set(this.statusWidgetDealTypes, index, {
                id: x.id,
                name: x.name,
                altName: x.altName,
                widgetOrder: x.widgetOrder,
                icon: x.icon,
                widgetClass: 'notselectable',
                selectableId: x.selectableId,
                statusProgressionWizardActive: x.statusProgressionWizardActive })
            }
          })
          // make next step status selectable
          this.makeStatusesSelectable(newValue.id)

          this.canBypassAwaitingToPendingCheck = false
          this.canBypassDeliveredToBookedCheck = false
          this.canBypassLocateToAwaitingCheck = false
          this.isLocatedCaptureActive = false
        }

        if (newValue.altName === 'Cancelled') {
          this.currentDeal.typeBeforeCancellation = oldValue.altName
        }
      }
    },
    dealCreditApplicationStatus: function (newValue, oldValue) {
      if (newValue && newValue.altName === 'Approved' && !this.bankApprovalStatus) {
        this.bankApprovalStatus = true
      }
    },
    bankApprovalStatus: function (newValue, oldValue) {
      if (newValue !== this.currentDeal.creditApplicationBankApproval) {
        if (newValue) {
          this.dealCreditApplicationStatus = this.definedTypes.dealCreditApplicationStatus.options.find(x => x.altName === 'Approved')
        }
      }
    },
    supplementalCosts: {
      handler (newValue, oldValue) {
        let comparison = _.isEqual(newValue, this.initialSupplementalCosts)
        if (!comparison) {
          this.onFormChange()
        }
      },
      deep: true
    },
    commissions: {
      handler (newValue, oldValue) {
        let comparison = _.isEqual(newValue, this.initialCommissions)
        if (!comparison) {
          this.onFormChange()
        }
      },
      deep: true
    },
    bookDealCaptureActive: function (newValue, oldValue) {
      if (newValue === false) {
        this.reloadDeal()
      }
    }
  },
  mounted: function () {
    eventBus.$on('inventory-arrived-update-deal-status-to-pending', this.setStatusPending)
    eventBus.$on('update-deal-status-to-booked', this.setStatusBooked)
    eventBus.$on('update-deal-status-to-awaitingarrival', this.setStatusAwaitingArrival)
    eventBus.$on('reload-deal', this.reloadDeal)
    eventBus.$on('inventory-changed', this.reloadDeal)
    eventBus.$on('deal-documents-uploaded', this.reloadDeal)
    eventBus.$on('quickview-refresh', this.reloadDeal)
    eventBus.$on('customer-ofac-status-changed', this.reloadDeal)
    eventBus.$on('unlock-booked-deal', this.unlockBookedDeal)
    this.scrollToCreditApplicationStatusSync()
    var dealId = this.id ? this.id : this.$route.params.id
    this.target = this.$route.params.target !== null || this.$route.params.target !== undefined ? this.$route.params.target : ''
    this.isDocumentPodCollapsed = this.target === '' || this.target !== 'documents'
    if (dealId) {
      let that = this
      this.loadDeal(dealId, () => {
        setTimeout(() => {
          that.$nextTick(() => {
            if (that.target !== '' && that.$refs[that.target] !== undefined) {
              that.$goToBlock(that.$refs[that.target])
            }
          })
        }, 500)
      })
    }
  },
  beforeDestroy () {
    eventBus.$off('inventory-arrived-update-deal-status-to-pending', this.setStatusPending)
    eventBus.$off('update-deal-status-to-booked', this.setStatusBooked)
    eventBus.$off('update-deal-status-to-awaitingarrival', this.setStatusAwaitingArrival)
    eventBus.$off('reload-deal', this.reloadDeal)
    eventBus.$off('inventory-changed', this.reloadDeal)
    eventBus.$off('quickview-refresh', this.reloadDeal)
    eventBus.$off('customer-ofac-status-changed', this.reloadDeal)
    eventBus.$off('unlock-booked-deal', this.unlockBookedDeal)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .notes {
    padding-bottom:10px;
  }

  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }
  .status-icon {
    margin-right:5px;
  }

  ul.status-breadcrumb>li+li:before {
    padding: 0 5px;
  }

  .inactiveLink {
    pointer-events: none;
    cursor: default;
  }

  .service-request-column {
    vertical-align: middle;
  }

  .appointment-date {
    float: left !important;
    width: 200px !important;
    font-size: 11px !important;
  }

  .appointment-sms-button {
    cursor: pointer;
    padding-left: 3px;
    font-size: 19px;
  }

  .edit-inventory {
    overflow:auto;
    height: calc(100vh - 300px);
  }

  .float-button-right {
    float: right !important;
  }

  @media screen and (max-width: 536px) {
    .float-button-right {
      float: none !important;
    }

    .spw-footer > button {
      margin-bottom: 2px;
    }

    .status-breadcrumb > li {
      display: block;
    }

    .status-breadcrumb {
      display: block !important;
    }

    #deal-status {
      text-align: -webkit-center;
    }
  }

  .spw-footer {
    width: 100%;
  }

  .fal[data-type]:after {
    padding: 0.2em !important;
  }
</style>
