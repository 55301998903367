import { format } from '@/components/external/vmoney/utils'

var mixin = {
  methods: {
    convertWorkItemStatusIdToStatusType: function (id) {
      return this.definedTypes.roServiceWorkItemStatusType.options.find((x) => x.id === +id)
    },
    getColorClassByWorkItemStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-danger'
          break
        case 1:
          result = 'has-text-success'
          break
        case 2:
          result = 'has-text-warning'
          break
        case 3:
          result = 'has-text-danger'
          break
        case 4:
          result = 'has-text-danger'
          break
        case 5:
          result = 'has-text-success'
          break
        case 6:
          result = 'has-text-danger'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getStockNoColorClassByGetReadyAndAccessoryStatus: function (stockNo, getReady, accessoryDetailOverview) {
      var result = 'has-text-grey-light'
      if (stockNo) {
        if (stockNo.indexOf('LOC') >= 0) {
          result = 'has-text-black'
        } else if (getReady !== 6) {
          result = 'has-text-danger'
        } else if (getReady === 6 && accessoryDetailOverview !== 'All accessories have been accounted for.') {
          result = 'has-text-orange'
        } else if (getReady === 6) {
          result = 'has-text-primary'
        }
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getIconByAccessoryDetailOverview: function (accessoryDetailOverview) {
      let classToAttach = {}
      if (accessoryDetailOverview === 'All accessories have been accounted for.') {
        classToAttach['fa-check-circle'] = true
        classToAttach['has-text-success'] = true
      } else {
        classToAttach['fa-exclamation-triangle'] = true
        classToAttach['has-text-danger'] = true
      }
      return classToAttach
    },
    getIconByLastDamageStatus: function (inventory) {
      let classToAttach = {}
      if (inventory.isDamaged === true) {
        classToAttach['fa-exclamation-triangle'] = true
        classToAttach['has-text-danger'] = true
      } else {
        classToAttach['fa-check-circle'] = true
        classToAttach['has-text-success'] = true
      }
      return classToAttach
    },
    getIconByFleetStatus: function (inventory) {
      let classToAttach = {}
      if (inventory.isFleet === true) {
        classToAttach['fa-check-circle'] = true
        classToAttach['has-text-success'] = true
      } else {
        classToAttach['fa-times-circle'] = true
        classToAttach['has-text-danger'] = true
      }
      return classToAttach
    },
    getIconByPublishStatus: function (inventory) {
      let classToAttach = {}
      if (inventory.doNotPublish === true) {
        classToAttach['fa-times-circle'] = true
        classToAttach['has-text-danger'] = true
      } else {
        classToAttach['fa-check-circle'] = true
        classToAttach['has-text-success'] = true
      }
      return classToAttach
    },
    getIconByDemoStatus: function (inventory) {
      let classToAttach = {}
      if (inventory.isDemo === true) {
        classToAttach['fa-check-circle'] = true
        classToAttach['has-text-success'] = true
      } else {
        classToAttach['fa-times-circle'] = true
        classToAttach['has-text-danger'] = true
      }
      return classToAttach
    },
    getIconByReadyToMarketStatus: function (inventory) {
      let classToAttach = {}
      if (inventory.readyToMarket === true) {
        classToAttach['fa-check-circle'] = true
        classToAttach['has-text-success'] = true
      } else {
        classToAttach['fa-times-circle'] = true
        classToAttach['has-text-danger'] = true
      }
      return classToAttach
    },
    getIconByTitleStatus: function (lastTitleDocument) {
      let classToAttach = {}
      if (lastTitleDocument === '') {
        classToAttach['fa-exclamation-triangle'] = true
        classToAttach['has-text-danger'] = true
      } else {
        classToAttach['fa-check-circle'] = true
        classToAttach['has-text-success'] = true
      }
      return classToAttach
    },
    getLastDamageDescription: function (inventory) {
      var result = 'No damage recorded'
      var damageNoteAttribute = (inventory && inventory.attributes) ? inventory.attributes.find((x) => x.name === 'DamageNotes') : null
      var damageNote = damageNoteAttribute ? damageNoteAttribute.value : ''
      result = damageNote && damageNote !== '' ? damageNote : 'No damage recorded'
      return result
    },
    getTitleStatusDescription: function (lastTitleDocument) {
      var result = lastTitleDocument === '' ? 'Missing' : 'Received'
      return result
    },
    getIconClassByDealStatus: function (type, returnType) {
      var result = 'has-text-grey-light'
      if (type) {
        switch (type) {
          case 'Delivered':
            result = 'fal fa-truck fa-lg has-text-danger'
            break
          case 'Booked':
            result = 'fal fa-book fa-lg has-text-primary'
            break
          case 'AwaitingArrival':
            result = 'fal fa-arrow-circle-down fa-lg has-text-link'
            break
          case 'Awaiting Arrival':
            result = 'fal fa-arrow-circle-down fa-lg has-text-link'
            break
          case 'Pending':
            result = 'fal fa-clock fa-lg has-text-orange'
            break
          case 'Pending Delivery':
            result = 'fal fa-clock fa-lg has-text-orange'
            break
          case 'Cancelled':
            result = 'fal fa-ban fa-lg has-text-black'
            break
          case 'Backup Deals':
            result = 'fal fa-clone fa-lg has-text-purple'
            break
          case 'Ordered':
            result = 'fal fa-receipt fa-lg has-text-maroon'
            break
          case 'Locating':
            result = 'fal fa-binoculars fa-lg has-text-brown'
            break
          case 'Allocated':
            result = 'fal fa-ballot-check fa-lg has-text-warning'
            break
          case 'PendingCredit':
            result = 'fal fa-file-signature fa-lg has-text-teal'
            break
          case 'Pending Credit':
            result = 'fal fa-file-signature fa-lg has-text-teal'
            break
          case 'PreDeal':
            result = 'fal fa-clock fa-lg has-text-violet'
            break
        }
      }

      if (returnType === 'string') {
        return result
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getDealStatusType: function (row) {
      if (row.status === 'PreDeal' && row.type !== 'Cancelled' && row.type !== 'Backup Deals') return 'PreDeal'
      return row.type
    },
    getDealStatusTypeByDeal: function (deal) {
      if (deal.status === 'PreDeal' && deal.type !== 'Cancelled' && deal.type !== 'Backup Deals') return 'PreDeal'
      return deal.type
    },
    getColorClassByDealStatus: function (type, status) {
      var result = ' has-text-grey-light'
      if (type) {
        let dealStatusType = status === 'PreDeal' && type !== 'Cancelled' && type !== 'Backup Deals' ? 'PreDeal' : type
        switch (dealStatusType) {
          case 'Delivered':
            result = ' has-text-danger'
            break
          case 'Booked':
            result = ' has-text-primary'
            break
          case 'Awaiting Arrival':
            result = ' has-text-link'
            break
          case 'AwaitingArrival':
            result = ' has-text-link'
            break
          case 'Pending Delivery':
            result = ' has-text-orange'
            break
          case 'Pending':
            result = ' has-text-orange'
            break
          case 'Cancelled':
            result = ' has-text-black'
            break
          case 'Backup Deals':
            result = ' has-text-purple'
            break
          case 'Ordered':
            result = ' has-text-maroon'
            break
          case 'Locating':
            result = 'h as-text-brown'
            break
          case 'Allocated':
            result = ' has-text-warning'
            break
          case 'PendingCredit':
            result = ' has-text-teal'
            break
          case 'Pending Credit':
            result = ' has-text-teal'
            break
          case 'Wholesale':
            result = 'has-text-light-pink'
            break
          case 'Pre-Deal':
            result = ' has-text-violet'
            break
          case 'PreDeal':
            result = ' has-text-violet'
        }
      }
      return result
    },
    getGroupCountColorClassByGroup: function (group, total) {
      var result = ''
      if (group && group !== '') {
        switch (group) {
          case 'Awaiting Arrival':
            result += ' is-info'
            break
          case 'Backup Deals':
            result += ' is-purple'
            break
          case 'Booked':
            result += ' is-success'
            break
          case 'Cancelled':
            result += ' is-black'
            break
          case 'Delivered':
            result += ' is-danger'
            break
          case 'Locating':
            result += ' is-brown'
            break
          case 'Ordered':
            result += ' is-maroon'
            break
          case 'Pending Delivery':
            result += ' is-orange'
            break
          case 'Wholesale':
            result += ' is-light-pink'
            break
          case 'Allocated':
            result += ' is-warning'
            break
          case 'Pending Credit':
            result += ' is-teal'
            break
          case 'Pre-Deal':
            result += ' is-violet'
            break
          default:
            result += ' is-black'
        }
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getDealStatusTitleForView: function (type, deal, toolTip = true) {
      var result = 'Available for sale'
      let dealCustomerName = deal ? (deal.customerName ? deal.customerName : '') : ''
      if (dealCustomerName) {
        dealCustomerName = ' - (' + dealCustomerName + ')'
      }
      var itemType = this.definedTypes.dealTypes.find((x) => x.id === +type)
      if (!itemType) {
        itemType = this.definedTypes.dealTypes.find((x) => x.name === type || x.altName === type)
      }
      let typeName = itemType ? (itemType.altName ? itemType.altName : '') : ''
      typeName = deal && deal.status === 'PreDeal' && typeName !== 'Cancelled' && typeName !== 'Backup Deals' ? 'PreDeal' : typeName

      if (typeName) {
        switch (typeName) {
          case 'Delivered':
            result = 'Sold: Delivered (' + this.$options.filters.formatDateOnlyShort(deal.promiseDate) + ')' + dealCustomerName
            break
          case 'Booked':
            result = 'Sold: Booked (' + this.$options.filters.formatDateOnlyShort(deal.soldAt) + ')' + dealCustomerName
            break
          case 'AwaitingArrival':
            result = 'Sold: Awaiting Arrival (' + this.$options.filters.formatDateOnlyShort(deal.soldAt) + ')' + dealCustomerName
            break
          case 'PendingDelivery':
            result = 'Sold: Pending Delivery (' + this.$options.filters.formatDateOnlyShort(deal.soldAt) + ', ' + deal.salesPersonName + ')' + dealCustomerName
            break
          case 'Pending':
            result = 'Sold: Pending Delivery (' + this.$options.filters.formatDateOnlyShort(deal.soldAt) + ', ' + deal.salesPersonName + ')' + dealCustomerName
            break
          case 'Cancelled':
            result = 'Available for sale'
            break
          case 'BackupDeals':
            result = 'Backup Deal' + dealCustomerName
            break
          case 'Ordered':
            result = 'Ordered' + dealCustomerName
            break
          case 'Locating':
            result = 'Locating' + dealCustomerName
            break
          case 'Allocated':
            result = 'Allocated' + dealCustomerName
            break
          case 'PendingCredit':
            result = 'Pending Credit' + dealCustomerName
            break
          case 'Pending Credit':
            result = 'Pending Credit' + dealCustomerName
            break
          case 'PreDeal':
            result = 'On Hold: Pre-Deal (' + this.$options.filters.formatDateOnlyShort(deal.soldAt) + ', ' + deal.salesPersonName + ')' + dealCustomerName
            break
        }
      }
      if (toolTip) {
        return {
          content: result,
          delay: {
            show: 500,
            hide: 100
          }
        }
      } else {
        return result
      }
    },
    getColorClassByBatchPaymentRequestStatus: function (status, batchPaymentRequestIsPaid, row) {
      let isAllArchived = false
      if (row && row.paymentRequests && row.paymentRequests !== '') {
        let paymentRequests = row.paymentRequests.split('#')
        let requests = paymentRequests.map(el => { return el.split(';;') })
        let hasScheduledPaymentsCount = 0
        let hasArchivedScheduledPaymentsCount = 0
        for (var i = 0; i < requests.length; i++) {
          hasScheduledPaymentsCount += parseInt(requests[i][4])
          hasArchivedScheduledPaymentsCount += parseInt(requests[i][5])
        }
        let hasScheduledPayments = hasScheduledPaymentsCount > 0
        let hasArchivedScheduledPayments = hasArchivedScheduledPaymentsCount > 0
        isAllArchived = hasArchivedScheduledPayments && !hasScheduledPayments
      } else if (row && row.isAllPaymentRequestsArchived != null) {
        isAllArchived = row.isAllPaymentRequestsArchived
      }

      var result = 'has-text-danger'
      switch (status) {
        case 'Queued':
          result = 'has-text-orange'
          break
        case 'Sent':
          if (batchPaymentRequestIsPaid) {
            result = 'has-text-primary'
          } else if (!isAllArchived) {
            result = 'has-text-warning'
          }
          break
        case 'Cancelled':
          result = 'has-text-black'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getBatchPaidStatus: function (inventory) {
      if (!inventory) return false
      if (inventory.paymentRequestPaid === true) return true
      if (inventory.paymentRequestPaid === false && inventory.isOnlyAccountingTypeUnpaid === true) return true
      return false
    },
    getBatchPaidStatusForList: function (row) {
      if (!row.paymentRequests || row.paymentRequests === '') return false
      let paymentRequests = row.paymentRequests.split('#')
      let requests = paymentRequests.map(el => { return el.split(';;') })
      let isAllPaid = row.batchPaymentRequestIsPaid
      if (isAllPaid === true) return true
      let isOnlyAccountingTypeUnpaid = !isAllPaid
      for (var i = 0; i < requests.length; i++) {
        let paymentTypeId = !isNaN(parseInt(requests[i][1])) ? parseInt(requests[i][1]) : -1
        let paymentType = this.definedTypes.paymentTypes.find((x) => x.id === paymentTypeId)
        let paymentTypeName = paymentType ? paymentType.name : '-'
        let paid = requests[i][2] && (requests[i][2] === '1' || requests[i][2] === '') ? requests[i][2] === '1' : false
        let hasScheduledPayments = requests[i][4] && requests[i][4] === '1'
        if ((paymentTypeName !== 'Accounting' && paid === false) || !hasScheduledPayments) {
          isOnlyAccountingTypeUnpaid = false
          break
        }
      }
      if (isAllPaid === false && isOnlyAccountingTypeUnpaid === true) return true
      return false
    },
    getColorClassByVehicleCount: function (count) {
      var result = 'has-text-black'
      if (count > 0) {
        result = 'has-text-primary'
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    makeOverview: function (inventory) {
      if (!inventory) {
        return ''
      }
      var result = ''
      result = inventory.stockNo
      result += '<br /> ' + inventory.name
      if (inventory.usage) {
        result += '<br /> ' + new Intl.NumberFormat().format(inventory.usage) + ' miles'
      } else {
        result += '<br /> Milage not captured.'
      }
      var exteriorColor = inventory.attributes.find((x) => x.name === 'ExteriorColor')
      var interiorColor = inventory.attributes.find((x) => x.name === 'InteriorColor')
      if (exteriorColor && exteriorColor.value) {
        result += '<br /> Ext. Color (' + exteriorColor.value + ')'
      }
      if (interiorColor && interiorColor.value) {
        result += '<br /> Int. Color (' + interiorColor.value + ')'
      }

      return result
    },
    getImage: function (inventory) {
      if (!inventory) {
        return ''
      }
      var defaultImage = require('@/assets/vehicle-placeholder.png')
      var primaryImages = inventory.attachments.filter((x) => x.type === 'Image' && x.classType === 'Primary')
      return primaryImages.length > 0 ? primaryImages[0].fileLocation : defaultImage
    },
    getAllImages: function (inventory) {
      if (!inventory) {
        return []
      }
      var primaryImages = inventory.attachments.filter((x) => x.type === 'Image' && x.classType === 'Primary').map((x) => {
        return {
          id: x.id,
          src: x.fileLocation
        }
      })
      return primaryImages
    },
    buildDealSectionTitle: function (deal, isDeal, primaryDealItem) {
      if (deal === null || deal.type === undefined || deal.type === null) {
        return ''
      }

      var result = ''
      var dealType = null
      let isPrimary = primaryDealItem ? primaryDealItem.isPrimary : true
      let dealStatusType = this.definedTypes.dealTypes.find((x) => x.name === deal.type || x.altName === deal.type)
      dealType = isPrimary || dealStatusType.name === 'Cancelled' || dealStatusType.name === 'Allocated' ? dealStatusType.name : 'Backup Deals'
      dealType = deal.status === 'PreDeal' && dealType !== 'Backup Deals' && dealType !== 'Cancelled' ? 'PreDeal' : dealType
      if (isDeal === false) {
        result = '<span class="is-vmiddle is-inline-block deal-buyer-title">' + deal.buyerName + '</span>'
        result += ' - <span class="icon is-vmiddle"><i class="' + this.getIconClassByDealStatus(dealType, 'string') + ' is-vmiddle"></i></span><span class="' + this.getColorClassByDealStatus(dealType) + ' is-vmiddle">' + dealType + '</span>'
        return result
      } else {
        result = 'Overview - '
        if (deal.dealCode) {
          result += deal.dealCode + ' <span class="icon is-vmiddle"><i class="' + this.getIconClassByDealStatus(dealType, 'string') + '"></i></span><span class="' + this.getColorClassByDealStatus(dealType) + '">' + dealType + '</span>'
        } else {
          result += '<span class="icon is-vmiddle"><i class="' + this.getIconClassByDealStatus(dealType, 'string') + '"></i></span><span class="' + this.getColorClassByDealStatus(dealType) + '">' + dealType + '</span>'
        }
        return result
      }
    },
    buildDealSectionTitleForInventoryTrade: function (title) {
      let result = '<span class="is-vmiddle is-inline-block deal-buyer-title">' + title + '</span>'
      return result
    },
    buildDealSectionOverviewTrade: function (type, isPrimary) {
      var dealType = null
      let dealStatusType = this.definedTypes.dealTypes.find((x) => x.name === type || x.altName === type)
      dealType = isPrimary || dealStatusType.name === 'Cancelled' ? dealStatusType.name : 'Backup Deals'
      dealType = dealStatusType === 'PreDeal' && dealType !== 'Cancelled' && dealType !== 'Backup Deals' ? 'PreDeal' : dealType
      let result = '<span class="icon is-vmiddle"><i class="' + this.getIconClassByDealStatus(dealType, 'string') + ' is-vmiddle"></i></span><span class="' + this.getColorClassByDealStatus(dealType) + ' is-vmiddle">' + dealType + '</span>'
      return result
    },
    buildDealSectionTitleForInventory: function (deal) {
      let result = '<span class="is-vmiddle is-inline-block deal-buyer-title">Deals - ' + deal.forPurchaserModel.fullName + '</span>'
      return result
    },
    buildDealSectionOverview: function (deal, soldDealItem) {
      var dealType = null
      let isPrimary = soldDealItem ? soldDealItem.isPrimary : true
      let dealStatusType = this.definedTypes.dealTypes.find((x) => x.name === deal.type || x.altName === deal.type)
      dealType = isPrimary || dealStatusType.name === 'Cancelled' ? dealStatusType.name : 'Backup Deals'
      dealType = deal.status === 'PreDeal' && dealType !== 'Cancelled' && dealType !== 'Backup Deals' ? 'PreDeal' : dealType
      let result = '<span class="icon is-vmiddle"><i class="' + this.getIconClassByDealStatus(dealType, 'string') + ' is-vmiddle"></i></span><span class="' + this.getColorClassByDealStatus(dealType) + ' is-vmiddle">' + dealType + '</span>'
      return result
    },
    buildDealWidgetTitle: function (deal, primaryDealItem) {
      var dealType = null
      let isPrimary = primaryDealItem ? primaryDealItem.isPrimary : true
      let dealStatusType = this.definedTypes.dealTypes.find((x) => x.name === deal.type || x.altName === deal.type)
      if (dealStatusType !== undefined && dealStatusType !== null) {
        dealType = isPrimary || dealStatusType.name === 'Allocated' ? dealStatusType.name : 'Backup Deals'
      }
      dealType = deal.status === 'PreDeal' && dealType !== 'Cancelled' && dealType !== 'Backup Deals' ? 'PreDeal' : dealType
      return 'Status - <span class="icon"><i class="' + this.getIconClassByDealStatus(dealType, 'string') + '"></i></span><span class="' + this.getColorClassByDealStatus(dealType) + '">' + dealType + '</span>'
    },
    getDataTypeByTradeStatus: function (row) {
      if (row.tradeIsPPA && !row.tradeHasCheckRequest) {
        return 'T ' + '\u0021'
      }

      if (row.tradeIsPPA) {
        return '\u0021'
      }

      return row.tradeHasCheckRequest === true ? '\u2714' : 'T'
    },
    getColorClassByTradeStatus: function (row) {
      var result = 'has-text-grey-light'
      if (row.hasTrade === true) {
        if (row.tradeIncoming === true && !row.tradeArrivalSkipped) {
          result = 'has-text-link'
        } else {
          if (row.tradeHasTitle === true) {
            result = 'has-text-warning'
            if (row.tradeHasCheckRequest) {
              result = 'has-text-warning'
              if (row.tradeHasAgreement) {
                result = 'has-text-warning'
                if (row.tradeAllowanceApproved) {
                  result = 'has-text-warning'
                  if (row.tradeExamined || row.tradeIsPpaExamined) {
                    result = 'has-text-primary'
                  }
                }
              }
            }
          } else {
            result = 'has-text-danger'
          }
        }
      }

      let classToAttach = {}
      if (row.tradeDamaged === true) {
        classToAttach['fa-car-crash'] = true
      } else {
        classToAttach['fa-car-alt'] = true
      }
      classToAttach[result] = true
      return classToAttach
    },
    getTradeDescriptionByTradeStatus: function (row) {
      var result = ''
      if (row.hasTrade === true) {
        result = '<span class="has-text-weight-semibold">Trade Vehicle - ' + row.tradeStockNo + '</span></br>'
        result += (row.tradeIncoming === true && !row.tradeArrivalSkipped) ? '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Vehicle is incoming and needs to be arrived. </br>' : ''
        result += (row.tradeArrivalSkipped) ? '<i class="fal fa-times-circle fa-lg has-text-info"></i><span class="has-text-info"> WARNING: Trade vehicle arrival was skipped by ' + row.tradeArrivalSkippedBy + '. This vehicle requires arrival and inspection. </span></br>' : ''
        result += (row.tradeHasTitle === true) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Title documents received OR pay off info received OR title is lost. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Title documents are outstanding. </br>'
        result += (row.tradeHasCheckRequest === true) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Check request has been issued. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Check request is outstanding. </br>'
        result += (row.tradeHasAgreement === true) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Signed sellers agreement has been received. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Signed sellers agreement is outstanding. </br>'
        result += (row.tradeAllowanceApproved === true) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Trade Allowance has been approved. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Trade Allowance appproval is outstanding. </br>'
        result += (row.tradeExamined === true || row.tradeIsPpaExamined) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Trade has been visually inspected. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Trade visual inspection is outstanding. </br>'
        result += (row.tradeIsPPA === true) ? '<i class="fal fa-times-circle fa-lg has-text-warning"></i> PPA trade vehicle must be converted. </br>' : ''
      }

      return result
    },
    getTradeDescriptionByTradeStatusArray: function (rows) {
      var result = ''
      rows.forEach((row) => {
        let trade = row.forInventory
        let incoming = trade.possessionStatus === 'Inbound'
        let tradeHasTitle = trade.titleStatus === 'Yes' || trade.titleStatus === 'PayOff' || trade.titleStatus === 'Lost'
        let tradeHasAgreement = trade.sellersAgreementStatus === 'Signed' || trade.sellersAgreementStatus === 'Complete'
        let tradeHasCheckRequest = trade.paymentRequestStatus === 'Sent'
        let isPpa = trade.type === 'ForPpaAppraisal'
        let tradeAllowanceApproved = row.tradeAllowanceApproved
        let item = {
          tradeIncoming: incoming,
          tradeHasTitle: tradeHasTitle,
          tradeHasAgreement: tradeHasAgreement,
          tradeHasCheckRequest: tradeHasCheckRequest,
          tradeArrivalSkipped: trade.arrivalSkipped,
          tradeArrivalSkippedBy: trade.arrivalSkippedBy,
          tradeIsPPA: isPpa,
          tradeAllowanceApproved: tradeAllowanceApproved,
          tradeStockNo: trade.stockNo,
          tradeExamined: trade.ppaExamined
        }

        result += '<span class="has-text-weight-semibold">Trade Vehicle - ' + item.tradeStockNo + '</span></br>'
        result += (item.tradeIncoming === true && !item.tradeArrivalSkipped) ? '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Vehicle is incoming and needs to be arrived. </br>' : ''
        result += (item.tradeArrivalSkipped) ? '<i class="fal fa-times-circle fa-lg has-text-danger"></i><span class="has-text-info"> WARNING: Trade vehicle arrival was skipped by ' + item.tradeArrivalSkippedBy + '. This vehicle requires arrival and inspection. </span></br>' : ''
        result += (item.tradeHasTitle === true) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Title documents received OR pay off info received OR title is lost. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Title documents are outstanding. </br>'
        result += (item.tradeHasCheckRequest === true) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Check request has been issued. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Check request is outstanding. </br>'
        result += (item.tradeHasAgreement === true) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Signed sellers agreement has been received. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Signed sellers agreement is outstanding. </br>'
        result += (item.tradeAllowanceApproved === true) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Trade Allowance has been approved. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Trade Allowance appproval is outstanding. </br>'
        result += (item.tradeExamined === true || item.tradeIsPpaExamined) ? '<i class="fal fa-check-circle fa-lg has-text-success"></i> Trade has been visually inspected. </br>' : '<i class="fal fa-times-circle fa-lg has-text-danger"></i> Trade visual inspection is outstanding. </br>'
        result += (item.tradeIsPPA === true) ? '<i class="fal fa-times-circle fa-lg has-text-warning"></i> PPA trade vehicle must be converted. </br>' : ''

        // result += this.getTradeDescriptionByTradeStatus(item)
        result += '</br>'
      })

      return result
    },
    getColorClassByAppointmentStatus: function (status) {
      var result = 'has-text-black'
      if (status) {
        switch (status) {
          case 'Not Set':
            result = 'has-text-grey-light'
            break
          case 'NotSet':
            result = 'has-text-grey-light'
            break
          case 'Set':
            result = 'has-text-orange'
            break
          case 'Scheduled':
            result = 'has-text-maroon'
            break
          case 'Confirmed':
            result = 'has-text-success'
            break
          case 'Cancelled':
            result = 'has-text-black'
            break
        }
      }
      return result
    },
    getColorClassWindowStickerUploads: function (inventory) {
      let windowStickerFiles = []
      inventory.attachments.forEach((x) => {
        if (x.type === 'Image' && x.classType === 'WindowSticker') {
          windowStickerFiles.push(x)
        } else if (x.type === 'Pdf' && x.classType === 'WindowSticker') {
          windowStickerFiles.push(x)
        }
      })

      let classToAttach = {}
      if (windowStickerFiles.length > 0) {
        classToAttach['has-text-success'] = true
      } else {
        classToAttach['has-text-danger'] = true
      }
      return classToAttach
    },
    getIconByOnSpecialStatus: function (inventory) {
      let classToAttach = {}
      if (inventory.isOnSpecial === false) {
        classToAttach['fa-times-circle'] = true
        classToAttach['has-text-danger'] = true
      } else {
        classToAttach['fa-check-circle'] = true
        classToAttach['has-text-success'] = true
      }
      return classToAttach
    },
    buildPaymentStatusTitle: function (row) {
      let content = ''

      if (row.paymentControlInitiatedFromBuyer) {
        return content
      }

      if (!row.paymentTrackingStatus) {
        content = 'Deposit request not sent.'
        return content
      }

      if (row.paymentTrackingStatus === 'Refund' || row.paymentTrackingStatus === 'Cancelled') {
        content = 'Deposit request not sent.'
        return content
      }

      let status = row.paymentTrackingStatus && row.paymentTrackingStatus === 'PendingInAccounting' ? 'Pending In Accounting' : row.paymentTrackingStatus
      status = status === 'NotYetPaid' ? 'Payment Pending' : status

      content += 'Status: ' + status

      if (row.wirePaymentReceived && row.wirePaymentReceived > 0) {
        content += '</br>' + this.getFormatedPaymentReceived(row.wirePaymentReceived, 'wire')
      }

      if (row.squarePaymentReceived && row.squarePaymentReceived > 0) {
        content += '</br>' + this.getFormatedPaymentReceived(row.squarePaymentReceived, 'square')
      }

      if (row.cashPaymentReceived && row.cashPaymentReceived > 0) {
        content += '</br>' + this.getFormatedPaymentReceived(row.cashPaymentReceived, 'cash')
      }

      if (row.checkPaymentReceived && row.checkPaymentReceived > 0) {
        content += '</br>' + this.getFormatedPaymentReceived(row.checkPaymentReceived, 'check')
      }

      if (row.otherPaymentReceived && row.otherPaymentReceived > 0) {
        content += '</br>' + this.getFormatedPaymentReceived(row.otherPaymentReceived, 'other')
      }

      content += row.paymentTotalReceived ? '</br>' + this.getFormatedPaymentReceived(row.paymentTotalReceived, 'total') : ''
      return content
    },
    getFormatedPaymentReceived: function (total, type) {
      if (total) {
        return type + ' payment received: $' + format(total)
      } else {
        return ''
      }
    },
    getColorClassByPaymentTrackingStatus: function (type) {
      var result = 'has-text-grey-light'
      if (type) {
        switch (type) {
          case 'NotYetPaid':
            result = 'has-text-warning'
            break
          case 'PendingInAccounting':
            result = 'has-text-success'
            break
          case 'Paid':
            result = 'has-text-success'
            break
          case 'Cancelled':
            // do not show colours for cancelled  or refund
            // result = 'has-text-black'
            result = ''
            break
          case 'Refund':
            // do not show colours for cancelled  or refund
            // result = 'has-text-danger'
            result = ''
            break
        }
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getTradeStatusBorderStatus: function (row) {
      return (row.tradeArrivalSkipped) ? 'border' : ''
    },
    getIconClassByPaymentTrackingType: function (type) {
      var result = 'has-text-grey-light'
      if (type) {
        switch (type) {
          case 'NotYetPaid':
            result = 'fal fa-clock has-text-orange'
            break
          case 'PendingInAccounting':
            result = 'fal fa-money-check-alt has-text-link'
            break
          case 'Paid':
            result = 'fal fa-money-bill-wave has-text-success'
            break
          case 'Cancelled':
            result = 'fal fa-ban has-text-black'
            break
          case 'Refund':
            result = 'far fa-undo has-text-black'
            break
        }
      }
      return result
    },
    getColorClassByPayerMismatch: function (row) {
      if (row.paymentStatus === 'NotYetPaid') {
        return 'has-text-grey-light'
      }

      if (row.type === 'Square') {
        return 'has-text-danger'
      }

      if (row.overridingManagerId !== null && row.overridingManagerId !== undefined) {
        return 'has-text-warning'
      }

      if (this.isCapturedPayerNameMismatch(row.capturedPayerName, row.customerName, row.coCustomerName)) {
        return 'has-text-danger'
      }

      return 'has-text-success'
    },
    getTitleByPayerMismatch: function (row) {
      if (row.paymentStatus === 'NotYetPaid') {
        return ''
      }

      let namesText = '</br>customer - ' + row.customerName
      if (row.coCustomerName && row.coCustomerName.length > 0) {
        namesText += '</br>co-customer - ' + row.coCustomerName
      }
      namesText += '</br>captured - ' + row.capturedPayerName

      if (row.type === 'Square') {
        return 'Square deposit'
      }

      if (row.overridingManagerId !== null && row.overridingManagerId !== undefined) {
        return 'Overriding Manager - ' + row.overridingManagerName + namesText
      }

      if (this.isCapturedPayerNameMismatch(row.capturedPayerName, row.customerName, row.coCustomerName)) {
        return 'Captured name does not match.' + namesText
      }

      return 'Captured name matches.' + namesText
    },
    isCapturedPayerNameMismatch: function (payerName, customerName, coCustomerName) {
      if (payerName && customerName && payerName.replace(/\s/g, '').toLowerCase() === customerName.replace(/\s/g, '').toLowerCase()) {
        return false
      }

      if (payerName && coCustomerName && payerName.replace(/\s/g, '').toLowerCase() === coCustomerName.replace(/\s/g, '').toLowerCase()) {
        return false
      }

      return true
    },
    getColourClassByPaymentTrackingType: function (status) {
      var result = 'has-text-grey-light'
      if (status) {
        switch (status) {
          case 'NotYetPaid':
            result = 'has-text-orange'
            break
          case 'PendingInAccounting':
            result = 'has-text-link'
            break
          case 'Paid':
            result = 'has-text-success'
            break
          case 'Cancelled':
            result = 'has-text-black'
            break
          case 'Refund':
            result = 'has-text-black'
            break
        }
      }
      return result
    },
    getPaymentIconType: function (type, status) {
      var result = ''
      if (type) {
        switch (type) {
          case 'Wire':
            result = 'fal fa-heart-rate '
            break
          case 'Square':
            result = 'fal fa-usd-square '
            break
          case 'Check':
            result = 'fal fa-money-check-alt '
            break
          case 'Cash':
            result = 'fal fa-sack-dollar '
            break
          case 'Other':
            result = 'fal fa-cash-register '
            break
          case 'SquareTerminal':
            result = 'fal fa-usd-square '
            break
        }
      }
      result += this.getColourClassByPaymentTrackingType(status)
      return result
    },
    buildDepositSectionTitle: function (paymentTracking) {
      let result = 'Overview - '
      result += '<span class="icon is-vmiddle"><i class="fa-lg ' + this.getPaymentIconType(paymentTracking.type, paymentTracking.paymentStatus) + '"></i></span><span class="is-vmiddle ' + this.getColourClassByPaymentTrackingType(paymentTracking.paymentStatus) + '"> ' + paymentTracking.type + '</span>'
      return result
    },
    getIconByPaymentTrackingStatus: function (status) {
      var result = ''
      if (status) {
        switch (status) {
          case 'NotYetPaid':
            result = 'fal fa-clock '
            break
          case 'PendingInAccounting':
            result = 'fal fa-money-check-alt '
            break
          case 'Paid':
            result = 'fal fa-money-bill-wave '
            break
          case 'Cancelled':
            result = 'fal fa-ban '
            break
          case 'Refund':
            result = 'far fa-undo '
            break
        }
      }
      return result
    },
    buildInventorySectionTitle: function (inventory) {
      if ((inventory && inventory.isServiceLoaner)) {
        let result = 'Overview '
        result += '<i class="grid-icon fa-fw fal fa-car-mechanic fa-lg is-right" v-tooltip="\'Service Loaner\'"/>'
        return result
      } else {
        return 'Overview'
      }
    },
    showDealItemCompletedIcon: function (type, status, isDealBookingFinalised) {
      let result = false
      if (type) {
        let dealStatusType = status === 'PreDeal' && type !== 'Cancelled' && type !== 'Backup Deals' ? 'PreDeal' : type
        if (dealStatusType === 'Booked' && isDealBookingFinalised) {
          result = true
        }
      }
      return result
    },
    getDataTypeByPaymentStatus: function (status) {
      return status === 'Paid' ? '\u2714' : ''
    }
  }
}

export default mixin
