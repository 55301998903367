<template>
  <div :id="'supplement-cost-section-' + instanceRenderKey" class="box" :key="instanceRenderKey">
    <section-header
      title="Inventory Additional Costs"
      :parentSelector="'#supplement-cost-section-' + instanceRenderKey"
      :overview="totalAdditionalCostsHeader"
      :collapsed="internalIsCollapsed"
      :isHtmlOverview="true"
      v-on:collapsed-status-changed="onCollapsedStatusChanged">
    </section-header>

    <div class="columns">
      <div class="column is-12">
        <b-table
          class="table"
          :data="groupedCostsForDisplay()"
          detailed
          customDetailRow
          :openedDetailed="['Packs']"
          :defaultSort="['name', 'desc']"
          detailKey="name"
          customRowKey="name"
          :showDetailIcon="true">

          <template slot-scope="props">
            <b-table-column field="name" label="Name">
              <span class="tag is-warning">{{ props.row.name }} ({{ props.row.count }})</span>
            </b-table-column>

            <b-table-column v-if="showVendor" field="vendorName" label="Vendor">
              &nbsp;
            </b-table-column>

            <b-table-column v-if="showNettAction" field="processingAction" label="Nett Action">
              &nbsp;
            </b-table-column>

            <b-table-column v-if="showInGlBalance" field="isInGlBalance" label="Is In GL Balance">
              &nbsp;
            </b-table-column>

            <b-table-column field="description" label="Description">
              &nbsp;
            </b-table-column>

            <b-table-column field="cost" label="Cost">
              &nbsp;
            </b-table-column>

            <b-table-column field="sellingPrice" label="Selling Price">
              &nbsp;
            </b-table-column>

            <b-table-column v-if="!isReadOnly" label="Actions">
              &nbsp;
            </b-table-column>
          </template>

          <template slot="detail" slot-scope="props">
            <tr v-for="(item, index) in props.row.items" :key="item.id + item.name">
              <td></td>
              <td :class="{'striked': item.delete === true}">
                {{ item.name }}
                <span class="has-text-danger is-pulled-right" v-if="item.delete === true"> (pending deletion)</span>
                <span class="has-text-success is-pulled-right" v-if="item.id === 0 && item.delete === false"> (pending addition)</span>
              </td>
              <td v-show="showVendor" :class="{'striked': item.delete === true}">{{ item.vendorName }}</td>
              <td v-show="showNettAction" :class="{'striked': item.delete === true}">{{ item.processingAction }}</td>
              <td v-show="showInGlBalance" :class="{'striked': item.delete === true}">{{ humanizedSwitch(item.isInGlBalance) }}</td>
              <td :class="{'striked': item.delete === true}">{{ item.description }}</td>
              <td :class="{'striked': item.delete === true}">{{ item.cost | currency }}</td>
              <td :class="{'striked': item.delete === true}">{{ item.sellingPrice | currency }}</td>
              <td v-if="!isReadOnly">
                <span v-if="isSaving" class="is-inline-block">
                  <i class="fas fa-spinner fa-spin fa-sm has-text-success" title="saving..."></i>
                </span>
                <span v-if="!isSaving" v-show="item.delete !== true" class="icon" @click="editSupplementalCost(item, index)">
                  <i class="fal fa-pencil"></i>
                </span>
                <span v-if="!isSaving" v-show="item.delete !== true" class="icon" @click="onDeleteById(item.id)">
                  <i class="fal fa-minus-circle"></i>
                </span>
              </td>
            </tr>
          </template>

          <template slot="empty">
            <section class="section">
              <div class="has-text-centered">
                <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                <p>No additional costs have been added.</p>
              </div>
            </section>
          </template>

          <template slot="footer" v-if="availableAdditionalCostsForDisplay().length > 0">
            <th :colspan="totalColumns - 3"></th>
            <th colspan="1"><label class="label">{{additionalCostsCostTotal() | currency}}</label></th>
            <th colspan="1"><label class="label">{{additionalCostsSellingTotal() | currency}}</label></th>
            <th colspan="1" v-if="!isReadOnly"></th>
          </template>
        </b-table>

        <!--
        <b-table :data="this.availableAdditionalCostsForDisplay()" class="table" :sticky-header="false">
          <template slot-scope="props">
            <b-table-column field="name" label="Name" sortable :class="{'striked': props.row.delete === true}">
              {{ props.row.name }}
              <span class="has-text-danger is-pulled-right" v-if="props.row.delete === true"> (pending deletion)</span>
              <span class="has-text-success is-pulled-right" v-if="props.row.id === 0 && props.row.delete === false"> (pending addition)</span>
            </b-table-column>

            <b-table-column v-if="showVendor" field="vendorName" label="Vendor" :class="{'striked': props.row.delete === true}" sortable>
              {{ props.row.vendorName }}
            </b-table-column>

            <b-table-column v-if="showNettAction" field="processingAction" label="Nett Action" :class="{'striked': props.row.delete === true}" sortable>
              {{ props.row.processingAction }}
            </b-table-column>

            <b-table-column v-if="showInGlBalance" field="isInGlBalance" label="Is In GL Balance" :class="{'striked': props.row.delete === true}" sortable>
              {{ humanizedSwitch(props.row.isInGlBalance) }}
            </b-table-column>

            <b-table-column field="description" label="Description" sortable :class="{'striked': props.row.delete === true}">
              {{ props.row.description }}
            </b-table-column>

            <b-table-column field="cost" label="Cost" sortable :class="{'striked': props.row.delete === true}">
              {{ props.row.cost | currency }}
            </b-table-column>

            <b-table-column field="sellingPrice" label="Selling Price" sortable :class="{'striked': props.row.delete === true}">
              {{ props.row.sellingPrice | currency }}
            </b-table-column>

            <b-table-column field="" label="Actions" v-if="!isReadOnly">
              <span class="icon" @click="editSupplementalCost(props.row, props.index)" v-if="props.row.delete !== true">
                <i class="fal fa-pencil"></i>
              </span>
              <span class="icon" @click="removeSupplementalCost(props.index)" v-if="props.row.delete !== true">
                <i class="fal fa-minus-circle"></i>
              </span>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="has-text-centered">
                <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                <p>No additional costs have been added.</p>
              </div>
            </section>
          </template>
          <template slot="footer" v-if="availableAdditionalCostsForDisplay().length > 0">
            <th :colspan="totalColumns - 3"></th>
            <th colspan="1"><label class="label">{{additionalCostsCostTotal() | currency}}</label></th>
            <th colspan="1"><label class="label">{{additionalCostsSellingTotal() | currency}}</label></th>
            <th colspan="1" v-if="!isReadOnly"></th>
          </template>
        </b-table>
      -->
      </div>
    </div>
    <inventory-supplemental-cost-capture
      :value="this.newSupplementalCost"
      :supplementalCosts="this.value"
      v-on:on-save-supplemental-cost="onSaveSupplementalCost"
      v-on:on-delete-supplemental-cost="onSaveSupplementalCost">
    </inventory-supplemental-cost-capture>
    <div class="inner-add-remove-button">
      <slot></slot>
      <a class="button is-primary" @click="addSupplementalCost()" v-if="!isReadOnly">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
      <button v-if="!isReadOnly" class="button is-warning is-pulled-right" type="button" @click="restoreInventoryAdditionalCosts()">Restore Defaults</button>
      <div class="is-pulled-right mr-5">
        <inventory-supplemental-cost-calculator
          v-model="value"
          :showVendor="false"
          :showNettAction="false"
          :showInGlBalance="false">
        </inventory-supplemental-cost-calculator>
        </div>
    </div>
  </div>
</template>

<script>

import SectionHeader from '@/components/generic/SectionHeader'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import InventorySupplementalCostCapture from '@/components/generic/sales/InventorySupplementalCostCapture'
import inventoryService from '@/services/inventoryService'
import utilitiesMixin from '@/mixins/generic/utilities'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import InventorySupplementalCostCalculatorComponent from '@/components/generic/inventory/InventorySupplementalCostCalculatorComponent'
import eventBus from '@/eventBus'

export default {
  inject: {
  },
  name: 'InventorySupplementalCostComponent',
  mixins: [utilitiesMixin, humanizedSwitchMixin],
  components: {
    'button-list-selector': ButtonListSelector,
    'switch-selector': SwitchSelector,
    'section-header': SectionHeader,
    'inventory-supplemental-cost-capture': InventorySupplementalCostCapture,
    'inventory-supplemental-cost-calculator': InventorySupplementalCostCalculatorComponent
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    restoreByDefault: {
      type: Boolean,
      default: false
    },
    selectedInventory: {
      type: Object,
      default: null
    },
    selectedInventoryId: {
      type: Number,
      default: 0
    },
    selectedSaleType: {
      type: Object,
      default: null
    },
    selectedMakeModel: {
      type: Object,
      default: null
    },
    selectedTypeStatus: {
      type: Object,
      default: null
    },
    selectedDispositionIntentionStatus: {
      type: Object,
      default: null
    },
    dispositionSource: {
      type: String,
      default: 'Inventory'
    },
    showVendor: {
      type: Boolean,
      default: true
    },
    showNettAction: {
      type: Boolean,
      default: true
    },
    showInGlBalance: {
      type: Boolean,
      default: true
    },
    autoSaveChanges: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newSupplementalCost: this.createGenericSupplementalCost(),
      total: 0,
      instanceRenderKey: this.$uuid.v4(),
      internalIsCollapsed: this.isCollapsed,
      showDetailIcon: true,
      isSaving: false
    }
  },
  computed: {
    totalColumns: function () {
      let value = 9
      if (!this.showVendor) value -= 1
      if (!this.showNettAction) value -= 1
      if (!this.showInGlBalance) value -= 1
      return value
    },
    totalAdditionalCostsHeader: function () {
      return '<div style="margin-top: -4px; margin-left: 20px">' +
        this.totalCostsHeader +
        this.totalPacksCostsHeader +
        this.totalManualCostsHeader +
        '</div>'
    },
    totalCostsHeader: function () {
      return '<span class="tag is-info">Total (' + this.totalCount + ') ' + this.$formatCurrency(this.additionalCostsCostTotal()) + '</span>'
    },
    totalPacksCostsHeader: function () {
      return '<span class="tag is-success ml-5">Packs (' + this.packsCount + ') ' + this.$formatCurrency(this.additionalCostsCostTotal() - this.manualCostTotal()) + '</span>'
    },
    totalManualCostsHeader: function () {
      return '<span class="tag is-success ml-5">Manual (' + this.manualCount + ') ' + this.$formatCurrency(this.manualCostTotal()) + '</span>'
    },
    packsCount: function () {
      return this.availablePacksCostsForDisplay().filter(x => !x.delete).length
    },
    manualCount: function () {
      return this.availableManualCostsForDisplay().filter(x => !x.delete).length
    },
    totalCount: function () {
      return this.packsCount + this.manualCount
    }
  },
  methods: {
    toggle (row) {
      this.$refs.table.toggleDetails(row)
    },
    onCollapsedStatusChanged: function (newState) {
      this.internalIsCollapsed = newState
    },
    availableAdditionalCostsForDisplay: function () {
      return this.value.filter(x => this.instanceRenderKey && (!x.hasOwnProperty('prune') || x.prune === false))
    },
    availableManualCostsForDisplay: function () {
      return this.availableAdditionalCostsForDisplay().filter(x => !x.isAuto)
    },
    availablePacksCostsForDisplay: function () {
      return this.availableAdditionalCostsForDisplay().filter(x => x.isAuto)
    },
    groupedCostsForDisplay: function () {
      return [{
        name: 'Packs',
        vendorName: null,
        processingAction: null,
        isInGlBalance: null,
        description: null,
        cost: null,
        sellingPrice: null,
        count: this.packsCount,
        items: this.availablePacksCostsForDisplay()
      },
      {
        name: 'Manual',
        vendorName: null,
        processingAction: null,
        isInGlBalance: null,
        description: null,
        cost: null,
        sellingPrice: null,
        count: this.manualCount,
        items: this.availableManualCostsForDisplay()
      }]
    },
    availableAdditionalCosts: function () {
      return this.value.filter(x => !x.delete && !x.prune)
    },
    additionalCostsCostTotal: function () {
      if (!this.value) {
        return 0
      }
      return this.availableAdditionalCosts().reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.cost)
      }, 0)
    },
    additionalCostsSellingTotal: function () {
      if (!this.value) {
        return 0
      }
      return this.availableAdditionalCosts().reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.sellingPrice)
      }, 0)
    },
    manualCostTotal: function () {
      return this.availableManualCostsForDisplay().reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.cost)
      }, 0)
    },
    onSaveSupplementalCost: function (savedSupplementalCost) {
      this.saveSupplementalCosts(this.selectedInventory)
    },
    populateInventoryAdditionalCosts: function () {
      console.log('Populating inventory additional costs')
      let that = this
      var proposedSupplementalCosts = []
      var dealer = this.$store.state.dealer.currentDealer
      let activeInventoryCosts = dealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Inventory')

      let selectedMakeCosts = []
      if (this.selectedMakeModel && this.selectedMakeModel.makeId) {
        let vehicleMakeSpecificCosts = activeInventoryCosts.filter(f => f.vehicleMakes.some(o => o.vehicleMakeId === that.selectedMakeModel.makeId))
        selectedMakeCosts = [...vehicleMakeSpecificCosts]
      }

      let nonSpecificMakeCosts = activeInventoryCosts.filter(x => x.vehicleMakes.length === 0)
      let filteredCosts = []
      selectedMakeCosts = selectedMakeCosts.filter((y) => {
        var isValid = true
        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'New') {
          isValid = isValid && y.appliesTo.includes('New')
        }

        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'Purchased') {
          isValid = isValid && y.appliesTo.includes('Purchased')
        }

        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'PpaClosed') {
          isValid = isValid && y.appliesTo.includes('PPA Closed')
        }

        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'Consigned') {
          isValid = isValid && y.appliesTo.includes('Consigned')
        }
        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'TradeIn') {
          isValid = isValid && y.appliesTo.includes('Trade In')
        }
        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'ForPpaAppraisal') {
          isValid = isValid && y.appliesTo.includes('Pre-Purchase Appraisal')
        }
        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'LeaseReturn') {
          isValid = isValid && y.appliesTo.includes('Lease Return')
        }

        if (y.appliesToDispositionIntention !== 'All') {
          if (that.selectedDispositionIntentionStatus && that.selectedDispositionIntentionStatus.altName.indexOf('Retail') === 0) {
            isValid = isValid && y.appliesToDispositionIntention === 'Retail'
          }

          if (that.selectedDispositionIntentionStatus && that.selectedDispositionIntentionStatus.altName.indexOf('Wholesale') === 0) {
            isValid = isValid && y.appliesToDispositionIntention === 'Wholesale'
          }
        }
        return isValid === true
      })

      filteredCosts = nonSpecificMakeCosts.filter((y) => {
        var isValid = true
        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'New') {
          isValid = isValid && y.appliesTo.includes('New')
        }

        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'Purchased') {
          isValid = isValid && y.appliesTo.includes('Purchased')
        }

        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'PpaClosed') {
          isValid = isValid && y.appliesTo.includes('PPA Closed')
        }

        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'Consigned') {
          isValid = isValid && y.appliesTo.includes('Consigned')
        }
        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'TradeIn') {
          isValid = isValid && y.appliesTo.includes('Trade In')
        }
        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'ForPpaAppraisal') {
          isValid = isValid && y.appliesTo.includes('Pre-Purchase Appraisal')
        }
        if (that.selectedTypeStatus && that.selectedTypeStatus.altName === 'LeaseReturn') {
          isValid = isValid && y.appliesTo.includes('Lease Return')
        }

        if (y.appliesToDispositionIntention !== 'All') {
          if (that.selectedDispositionIntentionStatus && that.selectedDispositionIntentionStatus.altName.indexOf('Retail') === 0) {
            isValid = isValid && y.appliesToDispositionIntention === 'Retail'
          }

          if (that.selectedDispositionIntentionStatus && that.selectedDispositionIntentionStatus.altName.indexOf('Wholesale') === 0) {
            isValid = isValid && y.appliesToDispositionIntention === 'Wholesale'
          }
        }
        return isValid === true
      })

      if (this.selectedMakeModel && this.selectedMakeModel.makeId) {
        filteredCosts.forEach(function (item, index) {
          let foundIndex = selectedMakeCosts.findIndex(x => x.name.trim() === item.name.trim())
          if (foundIndex === -1) {
            selectedMakeCosts.push(item)
          }
        })
        filteredCosts = [...selectedMakeCosts]
      }

      filteredCosts = this.uniqueDefaultCosts(filteredCosts)
      filteredCosts.forEach((x) => {
        let cost = {
          ...x,
          id: 0,
          dealerDefaultCostId: x.id,
          delete: false
        }
        proposedSupplementalCosts.push(cost)
      })

      proposedSupplementalCosts.forEach((proposedSupplementalCost) => {
        var existingSupplementalCost = that.value.find((x) => x.name === proposedSupplementalCost.name && x.cost === proposedSupplementalCost.cost)
        if (existingSupplementalCost) {
          existingSupplementalCost.delete = false
          existingSupplementalCost.prune = false
          existingSupplementalCost.isAuto = true
        } else {
          proposedSupplementalCost.isAuto = true
          that.value.push(proposedSupplementalCost)
        }
      })
      this.total = this.value.length
      this.saveSupplementalCosts(this.selectedInventory)
    },
    restoreInventoryAdditionalCosts: function () {
      if (this.isReadOnly) return
      this.value.forEach((x) => {
        x.prune = false
        if (x.dealerDefaultCostId !== null) {
          x.delete = true
          console.log('Deleting cost ' + x.name)
          if (x.id === 0) {
            console.log('Pruning cost ' + x.name)
            x.prune = true
          }
        }
      })
      this.populateInventoryAdditionalCosts()

      if (this.dispositionSource !== 'Inventory') {
        let changesDetected = false
        this.value.forEach((x) => {
          console.log('Processing cost item ' + x.name + ' for delete ' + x.delete + ' for prune ' + x.prune)
          if (x.requiresMatchedSaleIntention === false && x.delete) {
            console.log('Restoring cost item ' + x.name)
            changesDetected = true
            x.delete = false
            x.prune = false
          }
        })
        if (changesDetected) {
          this.saveSupplementalCosts(this.selectedInventory)
        }
      }
    },
    editSupplementalCost (supplement, index) {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost = {
        index: index,
        id: supplement.id,
        name: supplement.name,
        payeeType: supplement.payeeType,
        vendorId: supplement.vendorId,
        vendorName: supplement.vendorName,
        attachments: supplement.attachments,
        processingAction: supplement.processingAction,
        description: supplement.description,
        cost: supplement.cost,
        sellingPrice: supplement.sellingPrice,
        dealId: supplement.dealId,
        isInGlBalance: supplement.isInGlBalance,
        appliesToDispositionIntention: supplement.appliesToDispositionIntention,
        appliesOnSaleOnly: supplement.appliesOnSaleOnly,
        requiresMatchedSaleIntention: supplement.requiresMatchedSaleIntention,
        additionalIncome: supplement.additionalIncome,
        dealerDefaultCostId: supplement.dealerDefaultCostId,
        isAddingCost: true,
        isAuto: supplement.isAuto
      }
    },
    removeSupplementalCost (index) {
      var item = this.value[index]
      if (item.id === 0) {
        this.$delete(this.value, index)
      } else {
        item.delete = true
      }
      this.saveSupplementalCosts(this.selectedInventory)
    },
    addSupplementalCost: function () {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost.isAddingCost = true
    },
    createGenericSupplementalCost () {
      return {
        id: 0,
        name: null,
        vendorId: 0,
        payeeType: 'Supplier',
        vendorName: '',
        attachments: [],
        description: '',
        cost: null,
        sellingPrice: null,
        isAddingCost: false,
        processingAction: null,
        appliesToDispositionIntention: 'All',
        appliesOnSaleOnly: false,
        requiresMatchedSaleIntention: false,
        dealerDefaultCostId: null,
        isInGlBalance: false,
        additionalIncome: false
      }
    },
    saveSupplementalCosts: function (inventory) {
      if (!inventory) return
      inventory.supplementalCosts = []
      this.value.forEach(function (cost) {
        var supplementalCost = {
          id: cost.id,
          name: cost.name,
          payeeType: cost.payeeType,
          vendorId: cost.vendorId,
          vendorName: cost.vendorName,
          attachments: cost.attachments,
          processingAction: cost.processingAction,
          description: cost.description,
          cost: cost.cost,
          sellingPrice: cost.sellingPrice,
          isInGlBalance: cost.isInGlBalance,
          appliesToDispositionIntention: cost.appliesToDispositionIntention,
          appliesOnSaleOnly: cost.appliesOnSaleOnly,
          requiresMatchedSaleIntention: cost.requiresMatchedSaleIntention,
          additionalIncome: cost.additionalIncome,
          dealerDefaultCostId: cost.dealerDefaultCostId,
          delete: cost.delete === undefined ? false : cost.delete,
          isAuto: cost.isAuto === undefined || cost.isAuto === null ? false : cost.isAuto
        }
        inventory.supplementalCosts.push(supplementalCost)
      }, this)
      if (this.autoSaveChanges) {
        this.isSaving = true
        inventoryService.saveSupplementalCosts(inventory.id, inventory.supplementalCosts).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.failedToast('Oops! Something went wrong')
            this.serverErrors = response.errors
          } else {
            this.successToast('Save successful.')
            this.$emit('inventory-costs-updated', response.updatedSupplementalCosts)
            this.$emit('inventory-costs-totals-updated', this.additionalCostsCostTotal())
            this.instanceRenderKey = this.$uuid.v4()
          }
        }).catch((error) => {
          this.failedToast('Oops! Something went wrong')
          this.isSaving = false
          this.serverErrorMessage = error.message
        })
      } else {
        console.log('inventory-costs-updated without save...')
        this.$emit('inventory-costs-updated', inventory.supplementalCosts)
        this.instanceRenderKey = this.$uuid.v4()
      }
    },
    onDeleteById: function (id) {
      var index = this.value.findIndex(x => x.id === id)
      if (index !== -1) {
        this.removeSupplementalCost(index)
      }
    }
  },
  watch: {
    value: function (newValue, oldValue) {

    },
    selectedSaleType: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.restoreInventoryAdditionalCosts()
      }
    },
    selectedDispositionIntentionStatus: function (newValue, oldValue) {
      if (newValue && ((oldValue && newValue.id !== oldValue.id) || (this.selectedInventoryId === 0))) {
        this.restoreInventoryAdditionalCosts()
      }
    },
    selectedTypeStatus: function (newValue, oldValue) {
      if (newValue && ((oldValue && newValue.id !== oldValue.id) || (this.selectedInventoryId === 0))) {
        this.restoreInventoryAdditionalCosts()
      }
    },
    'selectedMakeModel': {
      handler: function (newValue, oldValue) {
        if (newValue && ((oldValue && newValue.makeId !== oldValue.makeId) || (this.selectedInventoryId === 0))) {
          this.restoreInventoryAdditionalCosts()
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted: function () {
    if (this.restoreByDefault) {
      this.restoreInventoryAdditionalCosts()
    }
  },
  created: function () {
    eventBus.$on('delete-cost-by-id', this.onDeleteById)
  },
  destroyed () {
    eventBus.$off('delete-cost-by-id', this.onDeleteById)
  }
}

</script>

<style scoped>
  .inline-block {
    display: inline-block;
  }

  .input {
    background: white;
    height: 32px;
    width: 150px;
  }

  .colon-alignment {
    vertical-align: sub;
  }

  .label-displacement {
    position: fixed;
    margin-top: -20px;
    margin-left: 1px;
  }

  .control-displacement {
    position: fixed;
    margin-top: -16px;
  }

  .control.has-icons-left .icon, .control.has-icons-right .icon {
    height: 30px !important;
}
</style>
