<template>
  <div class="dropdown is-up is-right" :id="instanceName">
    <div class="dropdown-trigger">
      <i @click.stop.prevent="toggleDropdown" class="icon fal fa-info-circle fa-lg has-text-info dropdown-btn clickable" v-tooltip="'Click for detail'"/>
    </div>
    <div ref="dropdownContainer" class="dropdown-menu box has-background-grey-lighter">
      <div class="fg-header deposit-qv-header is-flex small-bottom-margin" style="padding: 0.75rem">
        <div class="columns" style="width: -webkit-fill-available">
          <div class="column is-flex">
            <a v-if="!isReadOnly" class="fg-save-action button has-text-success mr-3" @click="saveAdjustments(false)" target="_blank" v-tooltip="{content: 'save adjustments'}">
              <i class="fal fa-save fa-m"></i>
            </a>
            <a v-if="!isReadOnly" class="fg-save-action button has-text-danger" @click="removeAdjustments" target="_blank" v-tooltip="{content: 'remove adjustments'}">
              <i class="far fa-ban fa-m"></i>
            </a>
          </div>
          <div class="column is-6">
            <section v-if="isGpReconciled" id="reconciled-container" class="level is-mobile" style="margin: 5px;">
              <div class="level-item has-text-centered has-background-success has-text-white box is-flex" style="padding: 3px;">
                <div class="heading mt-5 mr-5"><span><i class="fas fa-check-circle"></i></span> Reconciled GP</div>
                <div v-if="hasAnyCdkAccrualSelections" class="heading mt-5 mr-5">
                  <span class="is-size-7 has-text-warning">CDK&nbsp;</span>{{fgAccrualModel.cdkAdjustmentAmount | currency}}
                </div>
                <div v-if="hasAnyLiveAccrualSelections" class="heading mt-5">
                  <span class="is-size-7 has-text-warning">LIVE&nbsp;</span>{{fgAccrualModel.liveAdjustmentAmount | currency}}
                </div>
              </div>
            </section>
          </div>
          <div class="column">
            <a class="button is-danger is-pulled-right" @click="closeDropDown">Close</a>
          </div>
        </div>
      </div>
      <div class="dropdown-content has-background-grey-lighter">
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <span v-if="showDebugInfo">
          calculatedTotalFrontGrossCDK:{{ calculatedTotalFrontGrossCDK }} <br>
          calculatedTotalFrontGrossLive:{{ calculatedTotalFrontGrossLive }} <br>
          available: {{ availableBalance }} <br>
          liveAdjustmentAmount: {{ fgAccrualModel.liveAdjustmentAmount }} <br>
          cdkAdjustment: {{ fgAccrualModel.cdkAdjustmentAmount }} <br>
          defaultAmountAddLive: {{ defaultAmountAddLive }} <br>
          workingCdkAdjustmentAmount: {{ workingCdkAdjustmentAmount }} <br>
          workingLiveAdjustmentAmount: {{ workingLiveAdjustmentAmount }} <br>
          defaultNoteAmountToDisplay: {{ defaultNoteAmountToDisplay }} <br>
          defaultNoteAmountToDisplayLive: {{ defaultNoteAmountToDisplayLive }} <br>
          defaultNoteAmountRemoveCdkToDisplayLive: {{ defaultNoteAmountRemoveCdkToDisplayLive }} <br>
          defaultNoteAmountLive: {{ defaultNoteAmountLive }} <br>
          totalChecked: {{ totalChecked }} <br>
          totalCheckedLive: {{ totalCheckedLive }} <br>
          purchaseCostDerived: {{ purchaseCostDerived }}
        </span>
        <div class="columns is-multiline is-mobile fg-columns">
          <div class="column">
            <div v-if="showAutoUncheckMessage" class="is-flex notification is-warning">
              <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
              <span class="ml-10">
                Auto detection has unchecked the default note as the amount has since been updated since the last time the adjustment was saved. Please review the amount and select it again if it should still apply.
              </span>
            </div>
            <div id="default-note-instruction" class="box fg-sticky has-background-info-light">
              <section-header title="Default Note Instruction" parentSelector="#default-note-instruction" :collapsed="false"></section-header>
              <table class="table header is-striped is-fullwidth">
                <thead><tr><th>Instruction</th><th class="text-right">Apply Adjustment</th></tr></thead>
                <tr>
                  <td>{{cdkInstruction}}<br /><span class="has-text-info is-size-7">(select certain fees below to adjust accrue amount)</span></td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultAmountCdk"></b-checkbox>
                  </td>
                </tr>
                <tr>
                  <td>{{cdkInstructionFees}}<br /><span class="has-text-info is-size-7">(select certain fees below to adjust accrue amount)</span></td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultAmountCdkFees"></b-checkbox>
                  </td>
                </tr>
                <tr v-if="showAccruePurchaseCostInstruction">
                  <td>{{cdkPurchaseCostInstruction}}</td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultPurchaseCostCdk"></b-checkbox>
                  </td>
                </tr>
              </table>

              <table class="table header is-striped is-fullwidth">
                <thead><tr><th>Instruction</th><th class="text-right">Apply Adjustment</th></tr></thead>
                <tr>
                  <td v-if="defaultNoteAmountToDisplayLive < 0">
                    <span class="tag is-danger">Add {{defaultNoteAmountToDisplayLive | currency}} to LIVE</span> to match recon in CDK. (accrue additional recon for {{defaultNoteAmountToDisplayLive | currency}} that was not setup in live)
                  </td>
                  <td v-else>
                    <span class="tag is-danger">Remove {{defaultNoteAmountToDisplayLive | currency}} from LIVE</span> to match recon in CDK.
                  </td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultAmountAddLive"></b-checkbox>
                  </td>
                </tr>
                <tr>
                  <td v-if="defaultNoteAmountRemoveCdkToDisplayLive < 0">
                    <span class="tag is-warning">Remove {{defaultNoteAmountRemoveCdkToDisplayLive | currency}} from CDK</span> inventory balance as it was not approved. charge back {{defaultNoteAmountRemoveCdkToDisplayLive | currency}} for excess recon that was not approved
                  </td>
                  <td v-else>
                    <span class="tag is-warning">RECON Accrual</span> add {{defaultNoteAmountRemoveCdkToDisplayLive | currency}} to CDK to match recon in LIVE
                  </td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultAmountRemoveCdk"></b-checkbox>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="tag is-warning">Inventory Additional Cost</span> add {{defaultNoteAmountRemoveCdkToDisplayLive | currency}} to CDK to match additional costs in LIVE
                  </td>
                  <td class="text-right">
                    <b-checkbox v-model="inventoryAdditionalCostsCdk"></b-checkbox>
                  </td>
                </tr>
              </table>
            </div>

            <front-gross-accrual-inventory-costs
              v-for="x in frontGrossAccrualInventoryCostComponents"
              v-bind:key="x.type + fgLineItemsAccrualKey"
              v-model="forInventory"
              v-bind:initAdjustments="currentAdjustments"
              v-bind:type="x.type"
              v-on:onPersistAdjustment="onPersistAdjustment"
              v-on:onRemoveAdjustment="onRemoveAdjustment">
            </front-gross-accrual-inventory-costs>

          </div>
          <div class="column" :class="getRightSideSize" style="min-width: fit-content;">
            <div class="fg-sticky">
              <div class="is-hidden-mobile mr-3">
                <calculator-control
                  :key="'fg' + instanceName" :id="'fg' + instanceName"
                  :isInLine="true"
                  v-on:onToggleSoftCalculator="onToggleSoftCalculator">
                </calculator-control>
              </div>
            </div>
          </div>
        </div>
        <b-loading :is-full-page="true" :active.sync="isSaving" :canCancel="false"></b-loading>
      </div>
    </div>
  </div>
</template>

<script>

import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import dealService from '@/services/dealService'
import utilitiesMixin from '@/mixins/generic/utilities'
import CalculatorControl from '@/components/generic/CalculatorControl'
import SectionHeader from '@/components/generic/SectionHeader'
import eventBus from '@/eventBus'
import frontGrossAccrualInventoryCosts from '@/components/generic/sales/FrontGrossAccrualInventoryCosts'

export default {
  name: 'FrontGrossAccrualLineItems',
  mixins: [utilitiesMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'calculator-control': CalculatorControl,
    'section-header': SectionHeader,
    'front-gross-accrual-inventory-costs': frontGrossAccrualInventoryCosts
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    forInventory: {
      type: Object,
      default: null
    },
    persistedAdjustments: {
      type: String,
      default: ''
    },
    dealItem: {
      type: Object,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    saveAdjustmentsUuid: {
      type: String,
      default: ''
    },
    toggleDefaultPurchaseCostCdkUuid: {
      type: String,
      default: ''
    },
    showAutoUncheckMessage: {
      type: Boolean,
      default: false
    },
    isDealProgressingToBooked: {
      type: Boolean,
      default: false
    },
    balanceFrontGrossCdk: {
      type: Number,
      default: 0
    },
    balanceFrontGrossLive: {
      type: Number,
      default: 0
    },
    cdkGlbalanceToPurchaseCostPercentage: {
      type: Number,
      default: 0
    },
    frontGrossDiff: {
      type: Number,
      default: 0
    },
    calculatedTotalFrontGrossCDK: {
      type: Number,
      default: 0
    },
    calculatedTotalFrontGrossLive: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      fgAccrualModel: this.value,
      instanceName: this.$uuid.v4(),
      defaultAmountCdk: false,
      defaultAmountAddLive: false,
      defaultAmountRemoveCdk: false,
      currentAdjustments: [],
      serverErrorMessage: '',
      serverErrors: [],
      isSaving: false,
      defaultPurchaseCostCdk: false,
      isSoftCalulatorOpen: false,
      defaultAmountCdkFees: false,
      totalAmountOfCheckedActiveItems: 0,
      totalAmountOfCheckedYouCanRemoveItems: 0,
      totalAmountOfCheckedDeletedItems: 0,
      inventoryAdditionalCostsCdk: 0,
      fgLineItemsAccrualKey: this.$uuid.v4(),
      initAdjustments: [],
      totalAmountOfCheckedDeletedItemsLive: 0,
      showDebugInfo: false
    }
  },
  computed: {
    cdkInstruction: function () {
      return 'Accrue ' + this.$options.filters.currency(this.defaultNoteAmountToDisplay) + ' for Recon setup in LIVE but not yet posted in CDK'
    },
    cdkInstructionFees: function () {
      return 'Accrue ' + this.$options.filters.currency(this.defaultNoteAmountToDisplay) + ' for Fees setup in LIVE but not yet posted in CDK'
    },
    liveInstructionAdd: function () {
      if (this.defaultNoteAmountToDisplayLive < 0) {
        return 'Add ' + this.$options.filters.currency(-(this.defaultNoteAmountToDisplayLive)) + ' to Live to match recon in CDK. (accrue additional recon for ' + this.$options.filters.currency(this.defaultNoteAmountToDisplayLive) + ' that was not setup in live)'
      }

      return 'Remove ' + this.$options.filters.currency(this.defaultNoteAmountToDisplayLive) + ' from LIVE to match recon in CDK.'
    },
    dealId: function () {
      return this.dealItem ? this.dealItem.dealId : 0
    },
    cdkInstructionRemove: function () {
      if (this.defaultNoteAmountRemoveCdkToDisplayLive < 0) {
        return 'Remove ' + this.$options.filters.currency(-(this.defaultNoteAmountRemoveCdkToDisplayLive)) + ' from CDK inventory balance as it was not approved. (charge back ' + this.$options.filters.currency(this.defaultNoteAmountRemoveCdkToDisplayLive) + ' for excess recon that was not approved)'
      }

      return 'RECON Accrual - add to ' + this.$options.filters.currency(this.defaultNoteAmountRemoveCdkToDisplayLive) + ' to CDK to match recon in LIVE'
    },
    showAccruePurchaseCostInstruction: function () {
      return this.cdkGlbalanceToPurchaseCostPercentage < 40
    },
    cdkPurchaseCostInstruction: function () {
      return 'Accrue ' + this.$options.filters.currency(this.purchaseCostDerived) + ' for the purchase cost of the vehicle. (GL balance is less than 40% of purchase cost)'
    },
    getRightSideSize: function () {
      return this.isSoftCalulatorOpen ? 'is-5' : 'is-1'
    },
    purchaseCostDerived: function () {
      return this.forInventory ? this.forInventory.purchaseCost + this.forInventory.commission : 0
    },
    availableBalance: function () {
      return this.round2Digits(this.calculatedTotalFrontGrossCDK - this.calculatedTotalFrontGrossLive)
    },
    workingLiveAdjustmentAmount: function () {
      let result = 0

      /*
      if (this.defaultAmountAddLive === true) {
        result += this.availableBalance
      }

      result += this.totalCheckedLive

      return this.round2Digits(result)
      */

      if (this.defaultAmountAddLive === true) {
        result += this.defaultNoteAmountToDisplayLive
      }

      result += this.totalCheckedLive

      return this.round2Digits(result)
    },
    workingCdkAdjustmentAmount: function () {
      let result = 0

      /*
      let workingBalance = this.availableBalance + this.fgAccrualModel.cdkAdjustmentAmount

      if (this.defaultAmountCdk === true) {
        result += this.workingBalance
      }

      result += this.totalChecked

      return this.round2Digits(result)
      */

      if (this.defaultAmountCdk === true) {
        // result += this.availableBalance
        result += this.defaultNoteAmountToDisplay
      }

      if (this.defaultAmountCdkFees === true) {
        // result += this.availableBalance
        result += this.defaultNoteAmountToDisplay
      }

      if (this.defaultPurchaseCostCdk === true) {
        result += this.purchaseCostDerived
      }

      if (this.defaultAmountRemoveCdk === true) {
        result += -(this.defaultNoteAmountLive)
      }

      if (this.inventoryAdditionalCostsCdk === true) {
        result += -(this.defaultNoteAmountLive)
      }

      result += this.totalChecked

      return this.round2Digits(result)
    },
    defaultNoteAmountToDisplay: function () {
      /*
      let amount = this.availableBalance

      if (this.defaultAmountCdk === true || this.defaultAmountCdkFees === true || this.defaultAmountRemoveCdk === true || this.inventoryAdditionalCostsCdk === true) {
        return this.fgAccrualModel.cdkAdjustmentAmount - this.totalChecked + amount
      }

      return amount
      */

      let amount = this.availableBalance
      if (this.defaultAmountCdk === true || this.defaultAmountCdkFees === true || this.defaultAmountRemoveCdk === true || this.inventoryAdditionalCostsCdk === true) {
        if (this.defaultPurchaseCostCdk === true) {
          return this.fgAccrualModel.cdkAdjustmentAmount - this.purchaseCostDerived - this.totalChecked + amount
        }
        return this.fgAccrualModel.cdkAdjustmentAmount - this.totalChecked + amount
      }

      if (amount === 0) {
        return this.fgAccrualModel.liveAdjustmentAmount - this.totalCheckedLive
      }

      return amount
    },
    defaultNoteAmountLive: function () {
      let amount = 0

      amount = -(this.availableBalance)

      return amount
    },
    defaultNoteAmountToDisplayLive: function () {
      let amount = this.availableBalance

      if (this.defaultAmountAddLive === true) {
        return this.fgAccrualModel.liveAdjustmentAmount - this.totalCheckedLive + amount
      }

      if (this.defaultAmountRemoveCdk === true) {
        return this.fgAccrualModel.cdkAdjustmentAmount - this.totalChecked
      }

      if (this.inventoryAdditionalCostsCdk === true) {
        return this.fgAccrualModel.cdkAdjustmentAmount - this.totalChecked
      }

      if (this.defaultPurchaseCostCdk === true) {
        return this.fgAccrualModel.cdkAdjustmentAmount - this.purchaseCostDerived - this.totalChecked + amount
      }

      /*
      let amount = 0

      if (this.defaultNoteAmountLive === 0) {
        amount = this.fgAccrualModel.liveAdjustmentAmount > 0 ? this.fgAccrualModel.liveAdjustmentAmount : this.fgAccrualModel.cdkAdjustmentAmount - this.totalChecked
      } else {
        amount = -(this.defaultNoteAmountLive)
      }

      return amount
      */

      if (amount === 0) {
        return this.fgAccrualModel.cdkAdjustmentAmount - this.totalChecked
      }

      return amount
    },
    defaultNoteAmountRemoveCdkToDisplayLive: function () {
      return this.defaultNoteAmountToDisplay
    },
    isGpReconciled: function () {
      return this.frontGrossDiff === 0
    },
    hasAnyCdkAccrualSelections: function () {
      return this.fgAccrualModel.cdkAdjustmentAmount !== 0
    },
    hasAnyLiveAccrualSelections: function () {
      return this.fgAccrualModel.liveAdjustmentAmount !== 0
    },
    inventoryDefaultCosts: function () {
      // return this.$lodash.differenceWith(this.forInventory.supplementalCosts, this.forInventory.addedSupplementalCostsAfterInitialCreate, this.$lodash.isEqual)
      return this.forInventory.supplementalCosts
    },
    totalChecked: function () {
      return this.totalAmountOfCheckedActiveItems + this.totalAmountOfCheckedYouCanRemoveItems + this.totalAmountOfCheckedDeletedItems
    },
    totalCheckedLive: function () {
      return this.totalAmountOfCheckedDeletedItemsLive
    },
    frontGrossAccrualInventoryCostComponents: function () {
      let fgInventoryCostComponents = []

      if (this.packsCostsForDisplay.length > 0) {
        fgInventoryCostComponents.push({type: 'ActiveItemsInLive'})
      }

      if (this.manualCostsForDisplay && this.manualCostsForDisplay.length > 0) {
        fgInventoryCostComponents.push({type: 'ItemsYouCanRemove'})
      }

      if (this.forInventory.inActiveSupplementalCosts && this.forInventory.inActiveSupplementalCosts.length > 0) {
        fgInventoryCostComponents.push({type: 'ItemsDeletedInLive'})
      }

      return fgInventoryCostComponents
    },
    inventoryAdditionalCostsCdkInstruction: function () {
      return 'Inventory Additional Cost - add to ' + this.$options.filters.currency(this.defaultNoteAmountRemoveCdkToDisplayLive) + ' to CDK to match additional costs in LIVE'
    },
    packsCostsForDisplay: function () {
      return this.inventoryDefaultCosts.filter(x => x.isAuto)
    },
    manualCostsForDisplay: function () {
      return this.inventoryDefaultCosts.filter(x => !x.isAuto)
    }
  },
  methods: {
    persistAdjustment: function (adjustment, app) {
      let adj = {
        id: adjustment.id,
        text: adjustment.text,
        amount: adjustment.amount,
        app: app
      }

      let found = this.currentAdjustments.find(x => x.id === adj.id && x.app === app)
      if (found === null || found === undefined) {
        this.currentAdjustments.push(adj)
        this.syncChanges()
        return
      }

      // if its found then we can remove then add again incase amount has updated
      this.removeAdjustment(adjustment, app)
      this.persistAdjustment(adjustment, app)
    },
    removeAdjustment: function (adjustment, app) {
      let indexToDelete = this.currentAdjustments.findIndex((x) => x.id === adjustment.id && x.app === app)
      if (indexToDelete !== -1) {
        this.currentAdjustments.splice(indexToDelete, 1)
        this.syncChanges()
      }
    },
    setCurrentFromPersistedAdjustments: function () {
      let that = this
      if (that.currentAdjustments && that.currentAdjustments.length === 0) {
        if (that.persistedAdjustments && that.persistedAdjustments !== null && that.persistedAdjustments !== undefined) {
          let persistedAdjustmentsToJson = JSON.parse(that.persistedAdjustments)
          persistedAdjustmentsToJson.forEach(x => {
            that.currentAdjustments.push(x)
          })
        }
      }

      that.$nextTick(() => {
        setTimeout(function () {
          that.initCheckedItems()
        }, 300)
      })
    },
    initCheckedItems: function () {
      let that = this

      // this must be first, this logic updates amount which is used later
      let localDefaultPurchaseCostCdk = that.currentAdjustments.find(x => x.id === 4)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultPurchaseCostCdk && that.isDealProgressingToBooked) {
        if (localDefaultPurchaseCostCdk.amount && localDefaultPurchaseCostCdk.amount === that.purchaseCostDerived) {
          that.defaultPurchaseCostCdk = true
          that.syncChanges()
        } else {
          that.removeAdjustment(localDefaultPurchaseCostCdk)
          that.$emit('update:showAutoUncheckMessage', true)
          that.syncChanges()
        }
      } else if (localDefaultPurchaseCostCdk) {
        that.defaultPurchaseCostCdk = true
        that.syncChanges()
      }

      let localDefaultAmountCdk = that.currentAdjustments.find(x => x.id === 1)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultAmountCdk && that.isDealProgressingToBooked) {
        if (localDefaultAmountCdk.amount && localDefaultAmountCdk.amount === that.defaultNoteAmountToDisplay) {
          that.$nextTick(() => {
            setTimeout(function () {
              that.defaultAmountCdk = true
              that.syncChanges()
            }, 20)
          })
        } else {
          that.$nextTick(() => {
            setTimeout(function () {
              that.removeAdjustment(localDefaultAmountCdk)
              that.$emit('update:showAutoUncheckMessage', true)
            }, 20)
          })
        }
      } else if (localDefaultAmountCdk) {
        that.$nextTick(() => {
          setTimeout(function () {
            that.defaultAmountCdk = true
            that.syncChanges()
          }, 20)
        })
      }

      let localDefaultAmountCdkFees = that.currentAdjustments.find(x => x.id === 5)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultAmountCdkFees && that.isDealProgressingToBooked) {
        if (localDefaultAmountCdkFees.amount && localDefaultAmountCdkFees.amount === that.defaultNoteAmountToDisplay) {
          that.$nextTick(() => {
            setTimeout(function () {
              that.defaultAmountCdkFees = true
              that.syncChanges()
            }, 20)
          })
        } else {
          that.$nextTick(() => {
            setTimeout(function () {
              that.removeAdjustment(localDefaultAmountCdkFees)
              that.$emit('update:showAutoUncheckMessage', true)
            }, 20)
          })
        }
      } else if (localDefaultAmountCdkFees) {
        that.$nextTick(() => {
          setTimeout(function () {
            that.defaultAmountCdkFees = true
            that.syncChanges()
          }, 20)
        })
      }

      let localDefaultAmountAddLive = that.currentAdjustments.find(x => x.id === 2)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultAmountAddLive && that.isDealProgressingToBooked) {
        if (localDefaultAmountAddLive.amount && localDefaultAmountAddLive.amount === that.defaultNoteAmountToDisplayLive) {
          that.$nextTick(() => {
            setTimeout(function () {
              that.defaultAmountAddLive = true
              that.syncChanges()
            }, 20)
          })
        } else {
          that.$nextTick(() => {
            setTimeout(function () {
              that.removeAdjustment(localDefaultAmountAddLive)
              that.$emit('update:showAutoUncheckMessage', true)
            }, 20)
          })
        }
      } else if (localDefaultAmountAddLive) {
        that.$nextTick(() => {
          setTimeout(function () {
            that.defaultAmountAddLive = true
            that.syncChanges()
          }, 20)
        })
      }

      let localDefaultAmountRemoveCdk = that.currentAdjustments.find(x => x.id === 3)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultAmountRemoveCdk && that.isDealProgressingToBooked) {
        if (localDefaultAmountRemoveCdk.amount && localDefaultAmountRemoveCdk.amount === that.defaultNoteAmountRemoveCdkToDisplayLive) {
          that.$nextTick(() => {
            setTimeout(function () {
              that.defaultAmountRemoveCdk = true
              that.syncChanges()
            }, 20)
          })
        } else {
          that.$nextTick(() => {
            setTimeout(function () {
              that.removeAdjustment(localDefaultAmountRemoveCdk)
              that.$emit('update:showAutoUncheckMessage', true)
            }, 20)
          })
        }
      } else if (localDefaultAmountRemoveCdk) {
        that.$nextTick(() => {
          setTimeout(function () {
            that.defaultAmountRemoveCdk = true
            that.syncChanges()
          }, 20)
        })
      }

      let localInventoryAdditionalCostsCdk = that.currentAdjustments.find(x => x.id === 6)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localInventoryAdditionalCostsCdk && that.isDealProgressingToBooked) {
        if (localInventoryAdditionalCostsCdk.amount && localInventoryAdditionalCostsCdk.amount === that.defaultNoteAmountRemoveCdkToDisplayLive) {
          that.$nextTick(() => {
            setTimeout(function () {
              that.inventoryAdditionalCostsCdk = true
              that.syncChanges()
            }, 20)
          })
        } else {
          that.$nextTick(() => {
            setTimeout(function () {
              that.removeAdjustment(localInventoryAdditionalCostsCdk)
              that.$emit('update:showAutoUncheckMessage', true)
            }, 20)
          })
        }
      } else if (localInventoryAdditionalCostsCdk) {
        that.$nextTick(() => {
          setTimeout(function () {
            that.inventoryAdditionalCostsCdk = true
            that.syncChanges()
          }, 20)
        })
      }

      // that.$forceUpdate()
      // that.syncChanges()
    },
    setTextForCurrentAdjustments: function () {
      this.currentAdjustments.forEach(x => {
        if (x.id === 1) {
          x.text = this.cdkInstruction
          x.amount = this.defaultNoteAmountToDisplay
        }

        if (x.id === 5) {
          x.text = this.cdkInstructionFees
          x.amount = this.defaultNoteAmountToDisplay
        }

        if (x.id === 2) {
          x.text = this.liveInstructionAdd
          x.amount = this.defaultNoteAmountToDisplayLive
        }

        if (x.id === 3) {
          x.text = this.cdkInstructionRemove
          x.amount = this.defaultNoteAmountRemoveCdkToDisplayLive
        }

        if (x.id === 4) {
          x.text = this.cdkPurchaseCostInstruction
          x.amount = this.purchaseCostDerived
        }

        if (x.id === 6) {
          x.text = this.inventoryAdditionalCostsCdkInstruction
          x.amount = this.defaultNoteAmountRemoveCdkToDisplayLive
        }
      })
    },
    saveAdjustments: function (bypassToast) {
      this.setTextForCurrentAdjustments()
      let dealItemModels = []
      let dealItemModel = {
        id: this.dealItem.id,
        dealItemType: 'Sold',
        reconAdjustment: JSON.stringify(this.currentAdjustments)
      }

      dealItemModels.push(dealItemModel)
      let dealModel = {
        id: this.dealId,
        items: dealItemModels
      }

      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true

      dealService.updateReconAdjustment(dealModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
          if (!bypassToast) this.failedToast('Oops! Something went wrong')
        } else {
          this.$emit('update-adjustments', dealItemModel.reconAdjustment)
          if (!bypassToast) this.successToast('Success! Adjustments Saved')
        }
      }).catch((error) => {
        if (!bypassToast) this.failedToast('Oops! Something went wrong')
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    toggleDropdown: function () {
      let fgDropDown = document.getElementById(this.instanceName)
      if (!fgDropDown) return
      if (fgDropDown.classList.contains('is-active')) {
        this.onClose(fgDropDown)
      } else {
        fgDropDown.classList.add('is-active')
      }
    },
    closeDropDown: function () {
      let fgDropDown = document.getElementById(this.instanceName)
      if (fgDropDown && fgDropDown.classList.contains('is-active')) {
        this.onClose(fgDropDown)
      }
    },
    onClose: function (fgDropDown) {
      fgDropDown.classList.remove('is-active')
    },
    onToggleSoftCalculator: function (isOpen) {
      this.isSoftCalulatorOpen = isOpen
    },
    syncCdkAdjustmentAmount: function () {
      let that = this
      that.fgAccrualModel.cdkAdjustmentAmount = that.workingCdkAdjustmentAmount
      // that.fgAccrualModel.cdkAdjustmentAmount = that.defaultNoteAmountToDisplay
      that.$emit('input', that.fgAccrualModel)
    },
    syncLiveAdjustmentAmount: function () {
      let that = this
      that.fgAccrualModel.liveAdjustmentAmount = that.workingLiveAdjustmentAmount
      that.$emit('input', that.fgAccrualModel)
    },
    syncChanges: function () {
      let that = this
      that.$forceUpdate()

      // this will sync selected adjustments total with parent control so that new balances can be calculated and synced
      that.$nextTick(() => {
        setTimeout(function () {
          that.sumAllCheckedLists()
          that.syncCdkAdjustmentAmount()
          that.syncLiveAdjustmentAmount()
        }, 10)
      })
    },
    removeAdjustments: function () {
      this.currentAdjustments = []

      if (this.defaultAmountCdk === true) this.defaultAmountCdk = false
      if (this.defaultAmountCdkFees === true) this.defaultAmountCdkFees = false

      if (this.defaultAmountAddLive === true) this.defaultAmountAddLive = false
      if (this.defaultAmountRemoveCdk === true) this.defaultAmountRemoveCdk = false
      if (this.inventoryAdditionalCostsCdk === true) this.inventoryAdditionalCostsCdk = false

      if (this.defaultPurchaseCostCdk === true) this.defaultPurchaseCostCdk = false

      this.unCheckAll()

      this.syncChanges()

      /*
      this.$nextTick(() => {
        this.syncCdkAdjustmentAmount()
        this.syncLiveAdjustmentAmount()
      })
        */
    },
    fgOpenWithCalculator: function () {
      this.toggleDropdown()
      eventBus.$emit('open-calculator')
    },
    keyUp: function (keyEvent) {
      if (keyEvent.key === 'Escape' | keyEvent.keyCode === 27) {
        this.closeDropDown()
      }
    },
    onPersistAdjustment: function (x) {
      this.persistAdjustment(x.cost, x.app)
    },
    onRemoveAdjustment: function (y) {
      this.removeAdjustment(y.cost, y.app)
    },
    sumCheckedActiveItems: function () {
      if (!this.forInventory) this.totalAmountOfCheckedActiveItems = 0

      if (this.packsCostsForDisplay.length > 0) {
        this.totalAmountOfCheckedActiveItems = this.$lodash.sumBy(this.packsCostsForDisplay, function (x) {
          return x.checked !== null && x.checked !== undefined && x.checked === true ? x.cost - x.sellingPrice : 0
        })
      }
    },
    sumItemsYouCanRemoveCheckedAmount: function () {
      if (!this.forInventory) this.totalAmountOfCheckedYouCanRemoveItems = 0

      if (this.manualCostsForDisplay.length > 0) {
        this.totalAmountOfCheckedYouCanRemoveItems = this.$lodash.sumBy(this.manualCostsForDisplay, function (x) {
          return x.checked !== null && x.checked !== undefined && x.checked === true ? x.cost - x.sellingPrice : 0
        })
      }
    },
    sumDeletedItemsCheckedAmount: function () {
      if (!this.forInventory) this.totalAmountOfCheckedDeletedItems = 0
      if (!this.forInventory) this.totalAmountOfCheckedDeletedItemsLive = 0

      if (this.forInventory.inActiveSupplementalCosts.length > 0) {
        // apply to CDK
        this.totalAmountOfCheckedDeletedItems = this.$lodash.sumBy(this.forInventory.inActiveSupplementalCosts, function (x) {
          return x.checked !== null && x.checked !== undefined && x.checked === true ? x.cost - x.sellingPrice : 0
        })

        // apply to LIVE
        this.totalAmountOfCheckedDeletedItemsLive = this.$lodash.sumBy(this.forInventory.inActiveSupplementalCosts, function (x) {
          return x.checkedLive !== null && x.checkedLive !== undefined && x.checkedLive === true ? x.cost - x.sellingPrice : 0
        })
      }
    },
    sumAllCheckedLists: function () {
      this.sumCheckedActiveItems()
      this.sumItemsYouCanRemoveCheckedAmount()
      this.sumDeletedItemsCheckedAmount()
    },
    syncDeletedCost: function (persistedAdjustmentsToJson) {
      // let that = this
      /*
      persistedAdjustmentsToJson.forEach(x => {
        that.currentAdjustments.push(x)
      })
      that.currentAdjustments = persistedAdjustmentsToJson

      that.$nextTick(() => {
        setTimeout(function () {
          console.log('saveAdjustments')
          // auto save adjustments
          let bypassNotification = true
          that.saveAdjustments(bypassNotification)
        }, 5000)
      })
      */
      /*
      // eslint-disable-next-line
      let deletedCosts = this.$lodash.remove(this.manualCostsForDisplay, function (x) {
        return x.id === deletedCost.id
      })

      deletedCosts = this.$lodash.remove(this.supplementalCosts, function (x) {
        return x.id === deletedCost.id
      })
      */

      // deletedCost.checked = false
      // this.removeAdjustment(deletedCost, 'cdk')
      // this.forInventory.inActiveSupplementalCosts.push(deletedCost)

      /*
      // eslint-disable-next-line
      let deletedCosts = this.$lodash.remove(this.currentAdjustments, function (x) {
        return x.id === deletedCost.id
      })
      */
      // this.syncChanges()
      /*
      let that = this
      this.$nextTick(() => {
        setTimeout(function () {
          that.syncChanges()

          // auto save adjustments
          let bypassNotification = true
          that.saveAdjustments(bypassNotification)
        }, 500)
      })
      */
    },
    updatefgLineItemsAccrualKey: function () {
      this.fgLineItemsAccrualKey = this.$uuid.v4()
    },
    unCheckAll: function () {
      this.packsCostsForDisplay.forEach(element => {
        if (element.checked) element.checked = false
        this.$forceUpdate()
      })

      this.manualCostsForDisplay.forEach(element => {
        if (element.checked) element.checked = false
        this.$forceUpdate()
      })

      this.forInventory.inActiveSupplementalCosts.forEach(element => {
        /*
        if (element.checked) {
          this.$nextTick(() => {
            this.toggleInActiveCost(element, 'cdk')
          })
        }
        if (element.checkedLive) this.toggleInActiveCost(element, 'live')
        */
        if (element.checked) element.checked = false
        if (element.checkedLive) element.checkedLive = false
        this.$forceUpdate()
      })
    },
    purchaseCostUpdatedHandler: function () {

    }
  },
  watch: {
    defaultAmountCdk: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        let amount = this.defaultNoteAmountToDisplay
        if (newValue === true) {
          this.persistAdjustment({id: 1, text: this.cdkInstruction, amount: amount})
        } else {
          this.removeAdjustment({id: 1, text: this.cdkInstruction})
        }
      }

      if (newValue === true && this.defaultAmountCdkFees === true) {
        this.defaultAmountCdkFees = false
      }

      if (newValue === true && this.defaultAmountAddLive === true) {
        this.defaultAmountAddLive = false
      }

      if (newValue === true && this.defaultAmountRemoveCdk === true) {
        this.defaultAmountRemoveCdk = false
      }

      if (newValue === true && this.inventoryAdditionalCostsCdk === true) {
        this.inventoryAdditionalCostsCdk = false
      }
    },
    defaultAmountCdkFees: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        let amount = this.defaultNoteAmountToDisplay
        if (newValue === true) {
          this.persistAdjustment({id: 5, text: this.cdkInstructionFees, amount: amount})
        } else {
          this.removeAdjustment({id: 5, text: this.cdkInstructionFees})
        }
      }

      if (newValue === true && this.defaultAmountCdk === true) {
        this.defaultAmountCdk = false
      }

      if (newValue === true && this.defaultAmountAddLive === true) {
        this.defaultAmountAddLive = false
      }

      if (newValue === true && this.defaultAmountRemoveCdk === true) {
        this.defaultAmountRemoveCdk = false
      }

      if (newValue === true && this.inventoryAdditionalCostsCdk === true) {
        this.inventoryAdditionalCostsCdk = false
      }
    },
    defaultAmountAddLive: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        /*
        if (newValue === true && this.defaultAmountRemoveCdk) {
          this.defaultAmountRemoveCdk = false
        }

        if (newValue === true && this.inventoryAdditionalCostsCdk) {
          this.inventoryAdditionalCostsCdk = false
        }
        */

        let amount = this.defaultNoteAmountToDisplayLive
        if (newValue === true) {
          this.persistAdjustment({id: 2, text: this.liveInstructionAdd, amount: amount})
        } else {
          this.removeAdjustment({id: 2, text: this.liveInstructionAdd})
        }
      }

      if (newValue === true && this.defaultAmountCdk === true) {
        this.defaultAmountCdk = false
      }

      if (newValue === true && this.defaultAmountCdkFees === true) {
        this.defaultAmountCdkFees = false
      }

      if (newValue === true && this.defaultAmountRemoveCdk === true) {
        this.defaultAmountRemoveCdk = false
      }

      if (newValue === true && this.inventoryAdditionalCostsCdk === true) {
        this.inventoryAdditionalCostsCdk = false
      }
    },
    defaultAmountRemoveCdk: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        let amount = this.defaultNoteAmountLive
        if (newValue === true) {
          this.persistAdjustment({id: 3, text: this.cdkInstructionRemove, amount: amount})
        } else {
          this.removeAdjustment({id: 3, text: this.cdkInstructionRemove})
        }
      }

      if (newValue === true && this.defaultAmountCdk === true) {
        this.defaultAmountCdk = false
      }

      if (newValue === true && this.defaultAmountCdkFees === true) {
        this.defaultAmountCdkFees = false
      }

      if (newValue === true && this.defaultAmountAddLive === true) {
        this.defaultAmountAddLive = false
      }

      if (newValue && this.inventoryAdditionalCostsCdk === true) {
        this.inventoryAdditionalCostsCdk = false
      }
    },
    saveAdjustmentsUuid: function (newValue, oldValue) {
      let that = this
      if (newValue && oldValue) {
        /*
        that.$nextTick(() => {
          setTimeout(function () {
            console.log('timed saveAdjustments')
            // auto save adjustments
            let bypassNotification = true
            that.saveAdjustments(bypassNotification)
          }, 500)
        })
          */
        let bypassNotification = true
        that.saveAdjustments(bypassNotification)
      }
    },
    defaultPurchaseCostCdk: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        let amount = this.purchaseCostDerived
        if (newValue === true) {
          this.persistAdjustment({id: 4, text: this.cdkPurchaseCostInstruction, amount: amount})
        } else {
          this.removeAdjustment({id: 4, text: this.cdkPurchaseCostInstruction})
        }
      }
      this.syncChanges()

      this.$emit('toggleDefaultPurchaseCostCdk', newValue)
    },
    toggleDefaultPurchaseCostCdkUuid: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.defaultPurchaseCostCdk = !this.defaultPurchaseCostCdk
      }
    },
    isSoftCalulatorOpen: function (newValue, oldValue) {
      let dropdownContainer = this.$refs.dropdownContainer
      if (dropdownContainer) {
        if (newValue === true) {
          dropdownContainer.setAttribute('style', 'max-width: 1000px !important;')
        } else {
          dropdownContainer.setAttribute('style', 'max-width: 820px !important;')
        }
      }
    },
    value: function (newValue, oldValue) {
      this.fgAccrualModel = newValue
    },
    totalChecked: function (newValue, oldValue) {
      this.$forceUpdate()
      this.syncChanges()
    },
    totalCheckedLive: function (newValue, oldValue) {
      this.$forceUpdate()
      this.syncChanges()
    },
    inventoryAdditionalCostsCdk: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        let amount = this.defaultNoteAmountLive
        if (newValue === true) {
          this.persistAdjustment({id: 6, text: this.inventoryAdditionalCostsCdkInstruction, amount: amount})
        } else {
          this.removeAdjustment({id: 6, text: this.inventoryAdditionalCostsCdkInstruction})
        }
      }

      if (newValue === true && this.defaultAmountCdk === true) {
        this.defaultAmountCdk = false
      }

      if (newValue === true && this.defaultAmountCdkFees === true) {
        this.defaultAmountCdkFees = false
      }

      if (newValue === true && this.defaultAmountAddLive === true) {
        this.defaultAmountAddLive = false
      }

      if (newValue === true && this.defaultAmountRemoveCdk === true) {
        this.defaultAmountRemoveCdk = false
      }
    },
    availableBalance: function (newValue, oldValue) {
      // this.$forceUpdate()
    },
    currentAdjustments: function (newValue, oldValue) {
      // this.updatefgLineItemsAccrualKey()
    },
    purchaseCostDerived: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.syncChanges()
      }
    }
  },
  mounted: function () {
    this.unCheckAll()
    this.setCurrentFromPersistedAdjustments()
  },
  created: function () {
    eventBus.$on('fg-sync-deleted-cost', this.syncDeletedCost)
    eventBus.$on('fg-open-with-calculator', this.fgOpenWithCalculator)
    eventBus.$on('purchase-cost-updated', this.purchaseCostUpdatedHandler)
    window.addEventListener('keyup', this.keyUp)
  },
  destroyed () {
    eventBus.$off('fg-sync-deleted-cost', this.syncDeletedCost)
    eventBus.$off('fg-open-with-calculator', this.fgOpenWithCalculator)
    eventBus.$off('purchase-cost-updated', this.purchaseCostUpdatedHandler)
    window.removeEventListener('keyup', this.keyUp)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.gross-calc-container {
  max-height: 650px !important;
  height: 650px !important;
  overflow-y: scroll !important;
}

.fg-columns {
  min-height: 650px !important;
}

.dropdown-menu {
  position: fixed !important;
  top: 100px !important;
  left: 5px !important;
  width: 70% !important;
  height: 90% !important;
  max-height: 650px !important;
  z-index: 999999999999 !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  max-width: 820px !important;
  padding: 0px !important;
}

@media screen and (max-width: 1240px) {
  .dropdown-menu {
    left: 5px !important;
    width: 98% !important;
    height: 80% !important;
    max-height: 80% !important;
    z-index: 999999999999 !important;
  }
}

.dropdown-content {
  box-shadow: none !important;
}

.fg-save-action {
  border: 1px dashed #e0e0e3;
  border-radius: 50%;
  background: white;
  width: 2.25em;
  color: #4b6271;
  cursor: pointer;
}

div.fg-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

table.header {
  background-color:hsl(206, 70%, 96%) !important;
}

.text-right {
  text-align: right !important
}

.background-white {
  background-color: white !important;
}

.fg-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 199;
}

</style>
