import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapVehicleMakeGetters = createNamespacedHelpers('vehicleMake').mapGetters
const mapSubscriberGetters = createNamespacedHelpers('subscriber').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

var mixin = {
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapVehicleMakeGetters(['allVehicleMakes']),
    ...mapSubscriberGetters(['getCurrentSubscriber']),
    ...mapUserGetters(['currentUser']),
    ...mapDealerState(['currentDealer'])
  },
  methods: {
    mapInventoryToAgingRule: function (metric, makeId, inventoryTypeId, age) {
      if (!this.currentDealer) return ''

      var rangedSet
      var colorCode = ''
      let requiredConditionType = [1, 3, 4].includes(inventoryTypeId) ? this.definedTypes.conditionTypes.Used : this.definedTypes.conditionTypes.New
      let sellsCurrentMake = this.currentDealer.sellsVehicleMakes.find((x) => x.id === makeId)
      if (sellsCurrentMake && sellsCurrentMake.agingRules && sellsCurrentMake.agingRules.length > 0) {
        rangedSet = sellsCurrentMake.agingRules.filter((x) => x.metric === metric && x.condition.id === requiredConditionType && x.limit <= age)
      }

      if (!rangedSet) {
        if (this.currentDealer.agingRules && this.currentDealer.agingRules.length > 0) {
          rangedSet = this.currentDealer.agingRules.filter((x) => x.metric === metric && x.condition.id === requiredConditionType && x.limit <= age)
        }
      }

      if (!rangedSet) {
        let currentMake = this.allVehicleMakes.find((x) => x.id === makeId)
        if (currentMake && currentMake.agingRules && currentMake.agingRules.length > 0) {
          rangedSet = currentMake.agingRules.filter((x) => x.metric === metric && x.condition.id === requiredConditionType && x.limit <= age)
        }
      }

      if (!rangedSet) {
        if (this.getCurrentSubscriber.agingRules && this.getCurrentSubscriber.agingRules.length > 0) {
          rangedSet = this.getCurrentSubscriber.agingRules.filter((x) => x.metric === metric && x.condition.id === requiredConditionType && x.limit <= age)
        }
      }

      if (rangedSet && rangedSet.length > 0) {
        let applicableRange = _.last(_.sortBy(rangedSet, ['limit']))
        colorCode = applicableRange.colorCode
      }
      return colorCode
    },
    getDspAgingStyle: function (inventory, includeMargin) {
      let colorCode = this.mapInventoryToAgingRule('Days Since Purchase', inventory.makeId, inventory.typeId, inventory.daysSincePurchase)
      let stylesToAttach = {color: colorCode, marginRight: (includeMargin === true ? '5px' : '5px')}
      return stylesToAttach
    },
    getDolAgingStyle: function (inventory, includeMargin) {
      let colorCode = this.mapInventoryToAgingRule('Days On Lot', inventory.makeId, inventory.typeId, inventory.daysOnLot)
      let stylesToAttach = {color: colorCode, marginRight: (includeMargin === true ? '5px' : '5px')}
      return stylesToAttach
    },
    getDsmAgingStyle: function (inventory, includeMargin) {
      let colorCode = this.mapInventoryToAgingRule('Days Since Marketed', inventory.makeId, inventory.typeId, inventory.daysSinceMarketed)
      let stylesToAttach = {color: colorCode, marginRight: (includeMargin === true ? '5px' : '5px')}
      return stylesToAttach
    },
    getDtmAgingStyle: function (inventory, includeMargin) {
      let colorCode = this.mapInventoryToAgingRule('Days To Market', inventory.makeId, inventory.typeId, inventory.daysToMarket)
      let stylesToAttach = {color: colorCode, marginRight: (includeMargin === true ? '5px' : '5px')}
      return stylesToAttach
    },
    getDtrAgingStyle: function (inventory, includeMargin) {
      let colorCode = this.mapInventoryToAgingRule('Days To Recon', inventory.makeId, inventory.typeId, inventory.daysToRecon)
      let stylesToAttach = {color: colorCode, marginRight: (includeMargin === true ? '5px' : '5px')}
      return stylesToAttach
    },
    getDfeAgingStyle: function (inventory, includeMargin) {
      let colorCode = this.mapInventoryToAgingRule('Days From Entry', inventory.makeId, inventory.typeId, inventory.createdAt)
      let stylesToAttach = {color: colorCode, marginRight: (includeMargin === true ? '5px' : '5px')}
      return stylesToAttach
    },
    getColorClassByDealItemStatus: function (row) {
      let status = row.dealStatus
      var result = 'has-text-grey-light'

      var item = this.definedTypes.dealTypes.find((x) => x.id === +status)
      if (!item) {
        item = this.definedTypes.dealTypes.find((x) => x.name === status || x.altName === status)
      }

      if (item) {
        let dealStatusType = row.dealGenericStatusName === 'PreDeal' && item.altName !== 'Cancelled' ? 'PreDeal' : item.altName
        switch (dealStatusType) {
          case 'Wholesaled':
            result = ' has-text-light-pink'
            break
          case 'Wholesale':
            result = ' has-text-light-pink'
            break
          case 'Delivered':
            result = ' has-text-danger'
            break
          case 'Booked':
            result = ' has-text-primary'
            break
          case 'AwaitingArrival':
            result = ' has-text-link'
            break
          case 'Pending':
            result = ' has-text-orange'
            break
          case 'Cancelled':
            result = ' has-text-black'
            break
          case 'Ordered':
            result = ' has-text-maroon'
            break
          case 'Locating':
            result = ' has-text-brown'
            break
          case 'Allocated':
            result = ' has-text-warning'
            break
          case 'PendingCredit':
            result = ' has-text-teal'
            break
          case 'PreDeal':
            result = ' has-text-violet'
            break
        }
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getColorClassByMyKaarmaLinkStatus: function (item) {
      let classToAttach = {}
      if (item.myKaarmaUrl === '#') {
        classToAttach['has-text-orange'] = true
      } else {
        classToAttach['has-text-success'] = true
      }
      return classToAttach
    },
    getColorClassByExtendedHoldStatus: function (serviceRequest) {
      let classToAttach = {}
      if (serviceRequest.extendedHold === true) {
        classToAttach['fa-pause-circle'] = true
        classToAttach['has-text-danger'] = true
      } else {
        classToAttach['fa-play-circle'] = true
        classToAttach['has-text-success'] = true
      }
      return classToAttach
    },
    getColorClassByAccessoryStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-success'
          break
        case 1:
          result = 'has-text-danger'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getColorClassBySupplierContactStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 'Active':
          result = 'has-text-success'
          break
        case 'InActive':
          result = 'has-text-danger'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getColorClassByGetReadyStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-danger'
          break
        case 1:
          result = 'has-text-warning'
          break
        case 2:
          result = 'has-text-danger'
          break
        case 3:
          result = 'has-text-warning'
          break
        case 4:
          result = 'has-text-warning'
          break
        case 5:
          result = 'has-text-danger'
          break
        case 6:
          result = 'has-text-grey-light'
          break
        case 7:
          result = 'has-text-success'
          break
        case 8:
          result = 'has-text-grey-light'
          break
        case 9:
          result = 'has-text-success'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getColorClassByGetLocationStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case false:
          result = 'has-text-success'
          break
        case true:
          result = 'has-text-danger'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getColorClassByLoanerStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-danger'
          break
        case 1:
          result = 'has-text-success'
          break
        case 2:
          result = 'has-text-orange'
          break
        case 3:
          result = 'has-text-link'
          break
        case 4:
          result = 'has-text-orange'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getDescriptionByAgreementStatus: function (status, defaultIfNotSent, typeId) {
      var result = ''
      if (typeId !== undefined) {
        if ([5, 6].includes(typeId)) {
          return 'This item needs to be converted before agreements can be finalized'
        }
        if (typeId === 2) {
          return 'Not applicable for this inventory type'
        }
      }
      switch (status) {
        case 0:
          result = defaultIfNotSent || 'Agreement has not been sent to customer for signing'
          break
        case 5:
          result = 'Agreement sent but customer has declined to signing process'
          break
        case 6:
          result = 'Agreement sent but customer has re-assigned the signing'
          break
        case 7:
          result = 'Agreement sent but process has been cancelled'
          break
        case 8:
          result = 'Agreement sent but document sent is invalid for signing'
          break
        case 9:
          result = 'Agreement sent but email address used is invalid'
          break
        case 1:
          result = 'Agreement sent and awaiting customer response'
          break
        case 2:
          result = 'Agreement sent and customer has viewed document but has not yet signed'
          break
        case 3:
          result = 'Agreement sent and customer has signed the document'
          break
        case 4:
          result = 'Updated agreement sent and awaiting customer signature'
          break
        case 10:
          result = 'Updated agreement sent, signed and downloaded'
          break
        case 11:
          result = 'Not applicable for this type of item'
          break
      }
      return result
    },
    getUploadDescriptionByAgreementStatus: function (sellersAgreementUploadedBy, sellersAgreementUploadedAt) {
      var result = ''

      result += 'File has been uploaded through the system.<br />'
      result += '<br />'
      result += 'Uploaded By: ' + sellersAgreementUploadedBy + '<br />'
      result += 'Uploaded At: ' + this.$options.filters.formatDateOnlyShort(sellersAgreementUploadedAt)

      return result
    },
    getColorClassByAgreementStatus: function (status, typeId) {
      var result = 'has-text-grey-light'
      if (typeId !== undefined && [2, 5, 6].includes(typeId)) {
        result = 'has-text-grey-light'
      } else {
        switch (status) {
          case 0:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
            result = 'has-text-danger'
            break
          case 1:
          case 2:
          case 4:
            result = 'has-text-orange'
            break
          case 10:
          case 3:
          case 11:
            result = 'has-text-success'
            break
        }
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getColorClassByTitleStatus: function (inventory) {
      var result = 'has-text-grey-light'
      const titleUploadStatus = inventory.titleUploadStatus
      const payOffUploadStatus = inventory.payoffUploadStatus
      const lostAffidavitUploadStatus = inventory.lostAffidavitUploadStatus
      const inventoryTypeId = inventory.typeId
      const titleStatus = inventory.titleStatus
      const originalTitleReceived = inventory.originalTitleReceived
      const isTitleApplicable = !([2, 6].includes(inventoryTypeId) || titleUploadStatus === -1)

      if (isTitleApplicable) {
        if (originalTitleReceived) {
          result = 'has-text-success'
        } else {
          if (titleStatus === 'Yes') {
            if (titleUploadStatus === 0) result = 'has-text-danger'
            if (titleUploadStatus === 1) result = 'has-text-orange'
            if (titleUploadStatus === 2) result = 'has-text-success'
          }

          if (titleStatus === 'PayOff') {
            if (payOffUploadStatus === 0) result = 'has-text-danger'
            if (payOffUploadStatus === 1) result = 'has-text-orange'
            if (payOffUploadStatus === 2) result = 'has-text-success'
          }

          if (titleStatus === 'Lost') {
            if (lostAffidavitUploadStatus === 0) result = 'has-text-danger'
            if (lostAffidavitUploadStatus === 1) result = 'has-text-success'
          }

          if (titleStatus === 'Pending') {
            result = 'has-text-danger'
          }
        }
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getColorClassByDetailsStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 1:
          result = 'has-text-danger'
          break
        case 0:
          result = 'has-text-success'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getColorClassByTitlePayoffStatus: function (inventory) {
      var result = 'has-text-grey-light'

      if (inventory.isPayOff || inventory.titleStatus === 'PayOff') {
        result = 'has-text-success'
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    monthDiff: function (d1, d2) {
      let months = 0
      months = (d2.getFullYear() - d1.getFullYear()) * 12
      months += d2.getMonth() - d1.getMonth()
      return months
    },
    getColorClassByLoanerExpirationPeriod: function (mileage, expirationDate) {
      var result = 'has-text-grey-light'
      let mildUsage = false
      let heavyUsage = false
      let expirationClose = false
      let expirationDanger = false
      let convertedDate = new Date(Date.parse(expirationDate))
      let today = new Date()

      let months = this.monthDiff(today, convertedDate)

      if (mileage >= 3000 && mileage < 4500) {
        mildUsage = true
      } else if (mileage >= 4500) {
        heavyUsage = true
      }

      if (months <= 3 && months > 1) {
        expirationClose = true
      } else if (months <= 1) {
        expirationDanger = true
      }

      if (mildUsage || expirationClose) {
        result = 'has-text-orange'
      }

      if (heavyUsage || expirationDanger) {
        result = 'has-text-danger'
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getColorClassByLoanerStatusForView: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-danger'
          break
        case 1:
          result = 'has-text-success'
          break
        case 2:
          result = 'has-text-orange'
          break
        case 3:
          result = 'has-text-link'
          break
        case 4:
          result = 'has-text-orange'
          break
      }

      return result
    },
    getColorClassByGetReadyStatusForView: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-danger'
          break
        case 1:
          result = 'has-text-warning'
          break
        case 2:
          result = 'has-text-danger'
          break
        case 3:
          result = 'has-text-warning'
          break
        case 4:
          result = 'has-text-warning'
          break
        case 5:
          result = 'has-text-danger'
          break
        case 6:
          result = 'has-text-grey-light'
          break
        case 7:
          result = 'has-text-success'
          break
        case 8:
          result = 'has-text-grey-light'
          break
        case 9:
          result = 'has-text-success'
          break
      }

      return result
    },
    getColorClassByAccessoryStatusForView: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-success'
          break
        case 1:
          result = 'has-text-danger'
          break
      }

      return result
    },
    buildLoanerSectionTitle: function (inventory) {
      let result = '<span class="is-vmiddle is-inline-block">Loaners</span>'
      return result
    },
    buildLoanerSectionOverview: function (inventory) {
      let loanedBy = inventory.loanerEvents.length > 0 ? inventory.loanerEvents[0].loanedByFullName : ''
      let hasLoanedBy = loanedBy !== null && loanedBy !== ''
      let loanerType = this.definedTypes.loanerEventType.options.find((x) => x.id === inventory.loanerStatusId) ? this.definedTypes.loanerEventType.options.find((x) => x.id === inventory.loanerStatusId).name : ''
      if (loanerType === 'Not Set') {
        loanerType = 'On Site'
      }
      let loanerTypeColor = this.getColorClassByLoanerStatusForView(inventory.loanerStatusId)
      let overview = hasLoanedBy ? loanerType + ': ' + loanedBy : loanerType
      let result = '<span class="icon is-vmiddle"><i class="fal fa-arrow-circle-right is-vmiddle ' + loanerTypeColor + '"/></i></span><span class="is-vmiddle">' + overview + '</span>'
      return result
    },
    buildWorkshopSectionTitle: function (inventory) {
      if (!inventory.serviceRequests) return ''
      let type = inventory.serviceRequests.length > 0 ? this.definedTypes.serviceRequestType.options.find((x) => x.altName === inventory.serviceRequests[0].serviceRequestType).name : ''
      if (type === 'Additional Reconditioning') type = 'Add. Recon'
      let detail = type !== null && type !== '' ? 'Shop - ' + type : 'Shop'
      let result = '<span class="is-vmiddle is-inline-block">' + detail + '</span>'
      return result
    },
    buildWorkshopSectionOverview: function (inventory, includeCosts) {
      if (!inventory) return ''
      let getReadyStatus = this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === inventory.getReadyStatusTypeId) ? this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === inventory.getReadyStatusTypeId).easyName : ''
      let getReadyStatusType = this.getColorClassByGetReadyStatusForView(inventory.getReadyStatusTypeId)
      let result = '<span class="icon is-vmiddle"><i class="fal fa-wrench is-vmiddle ' + getReadyStatusType + '"/></i></span><span class="is-vmiddle">' + getReadyStatus + '</span>'
      if (inventory && includeCosts === true) {
        result += ' - Total: ' + this.$formatCurrency(inventory.serviceRequestsTotal)
      }
      return result
    },
    buildAccessorySectionTitle: function (inventory) {
      let hasAccessoryRecords = inventory.accessoryEvents.length > 0
      let keys = hasAccessoryRecords && inventory.accessoryEvents[0].keyStatus !== '' ? inventory.accessoryEvents[0].keyStatus : 'none'
      let carCover = hasAccessoryRecords && inventory.accessoryEvents[0].carCoverStatus !== '' ? inventory.accessoryEvents[0].carCoverStatus : 'none'
      let books = hasAccessoryRecords && inventory.accessoryEvents[0].booksStatus !== '' ? inventory.accessoryEvents[0].booksStatus : 'none'
      let batteryCharger = hasAccessoryRecords && inventory.accessoryEvents[0].batteryChargerStatus !== '' ? inventory.accessoryEvents[0].batteryChargerStatus : 'none'
      let info = 'Keys: ' + keys + ' - Car Cover: ' + carCover + ' - Books: ' + books + ' - Battery Charger: ' + batteryCharger + ''
      let result = '<span class="is-vmiddle is-inline-block" title="' + info + '">Accessories</span>'
      return result
    },
    buildAccessorySectionOverview: function (inventory) {
      let hasAccessoryRecords = inventory.accessoryEvents.length > 0
      let givenTo = hasAccessoryRecords ? inventory.accessoryEvents[0].givenTo : ''
      var accessoryStatus = this.definedTypes.accessoryEventType.options.find((x) => x.id === inventory.accessoryStatusId) ? this.definedTypes.accessoryEventType.options.find((x) => x.id === inventory.accessoryStatusId).name : ''
      if (accessoryStatus === 'Not Set') {
        accessoryStatus = 'Not Checked In'
      } else if (givenTo !== null && givenTo !== '') {
        accessoryStatus += ': ' + givenTo
      }
      let accessoryStatusType = this.getColorClassByAccessoryStatusForView(inventory.accessoryStatusId)
      let keys = hasAccessoryRecords > 0 && inventory.accessoryEvents[0].keyStatus !== '' ? inventory.accessoryEvents[0].keyStatus : 'none'
      let carCover = hasAccessoryRecords > 0 && inventory.accessoryEvents[0].carCoverStatus !== '' ? inventory.accessoryEvents[0].carCoverStatus : 'none'
      let books = hasAccessoryRecords > 0 && inventory.accessoryEvents[0].booksStatus !== '' ? inventory.accessoryEvents[0].booksStatus : 'none'
      let batteryCharger = hasAccessoryRecords > 0 && inventory.accessoryEvents[0].batteryChargerStatus !== '' ? inventory.accessoryEvents[0].batteryChargerStatus : 'none'
      let info = 'Keys: ' + keys + ' - Car Cover: ' + carCover + ' - Books: ' + books + ' - Battery Charger: ' + batteryCharger + ''
      let result = '<span class="icon is-vmiddle" title="' + info + '"><i class="fal fa-key is-vmiddle ' + accessoryStatusType + '"/></i></span><span class="is-vmiddle" title="' + info + '">' + accessoryStatus + '</span>'

      return result
    },
    buildNotesSectionOverview: function (inventory, length) {
      var note = inventory.note

      if (note && note.length > length) {
        return note.substring(0, length) + '...'
      } else {
        return note
      }
    },
    getHumanizedInventoryType: function (type) {
      return this.definedTypes.inventoryTypes.find((x) => x.altName === type).name
    },
    showDealCompletedIcon: function (row) {
      let status = row.dealStatus
      var result = false

      var item = this.definedTypes.dealTypes.find((x) => x.id === +status)
      if (!item) {
        item = this.definedTypes.dealTypes.find((x) => x.name === status || x.altName === status)
      }
      if (item) {
        let dealStatusType = row.dealGenericStatusName === 'PreDeal' && item.altName !== 'Cancelled' ? 'PreDeal' : item.altName
        if (dealStatusType === 'Booked' && row.isDealBookingFinalised) {
          result = true
        }
      }
      return result
    }
  }
}

export default mixin
