import { render, staticRenderFns } from "./NewDeal.vue?vue&type=template&id=16825b00&scoped=true"
import script from "./NewDeal.vue?vue&type=script&lang=js"
export * from "./NewDeal.vue?vue&type=script&lang=js"
import style0 from "./NewDeal.vue?vue&type=style&index=0&id=16825b00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16825b00",
  null
  
)

export default component.exports