<template>
  <div>
    <button type="button" class="button is-info" @click.stop.prevent="showModal = true">
      <span class="icon" v-tooltip="'fullscreen checklist'">
        <i class="fal fa-tasks fa-2x"></i>
      </span>
      <span></span>
    </button>

    <b-modal
      v-if="showModal"
      :active.sync="showModal"
      scroll="keep"
      :has-modal-card="true"
      :canCancel="true"
      fullScreen>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <portal-target name="modal-header" slim/>
            <span class="tag is-info is-large">Total: {{ $formatCurrency(additionalCostsCostTotal) }}</span>
            <span class="tag is-success is-medium ml-5">Packs Total: {{ $formatCurrency(additionalCostsCostTotal - manualCostTotal)  }}</span>
            <span class="tag is-success is-medium ml-5">Manual Total: {{ $formatCurrency(manualCostTotal)  }}</span>
          </p>
        </header>
        <section class="modal-card-body small-v-padding">

          <div class="header has-background-white">
            <div id="cost-calc" class="level is-mobile">
              <div class="level-item has-text-centered has-background-info has-text-white box">
                <div>
                  <p class="heading">Total Selected</p>
                  <p class="title has-text-white">{{ $formatCurrency(sumSelectedPacks() + sumSelectedManual()) }} ({{ selectedPacks().length + selectedManual().length }})</p>
                </div>
              </div>
              <div class="level-item has-text-centered has-background-success has-text-white box">
                <div>
                  <p class="heading">Selected Packs</p>
                  <p class="title has-text-white">{{ $formatCurrency(sumSelectedPacks()) }} ({{ selectedPacks().length }})</p>
                </div>
              </div>
              <div class="level-item has-text-centered has-background-success has-text-white box">
                <div>
                  <p class="heading">Selected Manual</p>
                  <p class="title has-text-white">{{ $formatCurrency(sumSelectedManual()) }} ({{ selectedManual().length }})</p>
                </div>
              </div>
            </div>
          </div>

          <div class="list">
            <b-table
              class="table small-v-padding"
              :data="groupedCostsForDisplay"
              detailed
              customDetailRow
              :defaultSort="['name', 'desc']"
              detailKey="name"
              customRowKey="name"
              :showDetailIcon="true">

              <template slot-scope="props">
                <b-table-column field="name" label="Name">
                  <span class="tag is-warning">{{ props.row.name }} ({{ props.row.count }})</span>
                </b-table-column>

                <b-table-column v-if="showVendor" field="vendorName" label="Vendor">
                  &nbsp;
                </b-table-column>

                <b-table-column v-if="showNettAction" field="processingAction" label="Nett Action">
                  &nbsp;
                </b-table-column>

                <b-table-column v-if="showInGlBalance" field="isInGlBalance" label="Is In GL Balance">
                  &nbsp;
                </b-table-column>

                <b-table-column field="description" label="Description">
                  &nbsp;
                </b-table-column>

                <b-table-column field="cost" label="Cost">
                  &nbsp;
                </b-table-column>

                <b-table-column field="sellingPrice" label="Selling Price">
                  &nbsp;
                </b-table-column>

                <b-table-column label="Actions">
                  <b-checkbox type="is-success" v-model="checkAll" :native-value="props.row.name"></b-checkbox>
                </b-table-column>
              </template>

              <template slot="detail" slot-scope="props">
                <tr v-for="(item) in props.row.items" :key="item.id">
                  <td></td>
                  <td :class="{'striked': item.selected === true, 'has-text-success': item.selected === true}">{{ item.name }}</td>
                  <td v-show="showVendor" :class="{'striked': item.selected === true, 'has-text-success': item.selected === true}">{{ item.vendorName }}</td>
                  <td v-show="showNettAction" :class="{'striked': item.selected === true, 'has-text-success': item.selected === true}">{{ item.processingAction }}</td>
                  <td v-show="showInGlBalance" :class="{'striked': item.selected === true, 'has-text-success': item.selected === true}">{{ humanizedSwitch(item.isInGlBalance) }}</td>
                  <td :class="{'striked': item.selected === true, 'has-text-success': item.selected === true}">{{ item.description }}</td>
                  <td :class="{'striked': item.selected === true, 'has-text-success': item.selected === true}">{{ item.cost | currency }}</td>
                  <td :class="{'striked': item.selected === true, 'has-text-success': item.selected === true}">{{ item.sellingPrice | currency }}</td>
                  <td>
                    <b-checkbox :ref="item.id + '_chk'" v-model="item.selected" type="is-success" @click.native="toggleCheck(item.id)"></b-checkbox>
                  </td>
                </tr>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="has-text-centered">
                    <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                    <p>No additional costs have been added.</p>
                  </div>
                </section>
              </template>

              <template slot="footer" v-if="availableAdditionalCostsForDisplay.length > 0">
                <th :colspan="totalColumns - 3"></th>
                <th colspan="1"><label class="label">{{additionalCostsCostTotal | currency}}</label></th>
                <th colspan="1"><label class="label">{{additionalCostsSellingTotal | currency}}</label></th>
                <th colspan="1"></th>
              </template>
            </b-table>
          </div>

        </section>
        <footer class="modal-card-foot">
          <button type="button" class="button is-danger is-pulled-right" @click.stop.prevent="showModal = false">Close</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import SectionHeader from '@/components/generic/SectionHeader'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'

export default {
  inject: {
  },
  name: 'InventorySupplementalCostCalulatorComponent',
  mixins: [utilitiesMixin, humanizedSwitchMixin],
  components: {
    'button-list-selector': ButtonListSelector,
    'switch-selector': SwitchSelector,
    'section-header': SectionHeader
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showVendor: {
      type: Boolean,
      default: true
    },
    showNettAction: {
      type: Boolean,
      default: true
    },
    showInGlBalance: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceRenderKey: this.$uuid.v4(),
      showDetailIcon: true,
      showModal: false,
      checkAll: []
    }
  },
  computed: {
    totalColumns: function () {
      let value = 9
      if (!this.showVendor) value -= 1
      if (!this.showNettAction) value -= 1
      if (!this.showInGlBalance) value -= 1
      return value
    },
    totalCostsHeader: function () {
      return '<span class="tag is-info is-large">Total: ' + this.$formatCurrency(this.additionalCostsCostTotal) + '</span>'
    },
    totalPacksCostsHeader: function () {
      return '<span class="tag is-info is-large ml-5">Packs Total: ' + this.$formatCurrency(this.additionalCostsCostTotal - this.manualCostTotal) + '</span>'
    },
    totalManualCostsHeader: function () {
      return '<span class="tag is-info is-large ml-5">Manual Total: ' + this.$formatCurrency(this.manualCostTotal) + '</span>'
    },
    packsCount: function () {
      return this.availablePacksCostsForDisplay().filter(x => !x.delete).length
    },
    manualCount: function () {
      return this.availableManualCostsForDisplay.filter(x => !x.delete).length
    },
    availableAdditionalCostsForDisplay: function () {
      return this.value.filter(x => this.instanceRenderKey && (!x.hasOwnProperty('prune') || x.prune === false))
    },
    availableManualCostsForDisplay: function () {
      return this.availableAdditionalCostsForDisplay.filter(x => !x.isAuto)
    },
    availableAdditionalCosts: function () {
      return this.value.filter(x => !x.delete && !x.prune)
    },
    additionalCostsCostTotal: function () {
      if (!this.value) {
        return 0
      }
      return this.availableAdditionalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.cost)
      }, 0)
    },
    additionalCostsSellingTotal: function () {
      if (!this.value) {
        return 0
      }
      return this.availableAdditionalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.sellingPrice)
      }, 0)
    },
    manualCostTotal: function () {
      return this.availableManualCostsForDisplay.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.cost)
      }, 0)
    },
    groupedCostsForDisplay: function () {
      return [{
        name: 'Packs',
        vendorName: null,
        processingAction: null,
        isInGlBalance: null,
        description: null,
        cost: null,
        sellingPrice: null,
        count: this.packsCount,
        items: this.availablePacksCostsForDisplay()
      },
      {
        name: 'Manual',
        vendorName: null,
        processingAction: null,
        isInGlBalance: null,
        description: null,
        cost: null,
        sellingPrice: null,
        count: this.manualCount,
        items: this.availableManualCostsForDisplay
      }]
    }
  },
  methods: {
    availablePacksCostsForDisplay: function () {
      return this.availableAdditionalCostsForDisplay.filter(x => x.isAuto)
    },
    toggleCheck: function (id) {
      let cost = this.availablePacksCostsForDisplay().find(x => x.id === id)
      if (!cost) {
        cost = this.availableManualCostsForDisplay.find(x => x.id === id)
      }

      if (cost) {
        cost.selected = (cost.selected !== null && cost.selected !== undefined) ? !cost.selected : true
      }

      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    sumSelectedPacks: function () {
      return this.selectedPacks().reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.cost)
      }, 0)
    },
    selectedPacks: function () {
      return this.availablePacksCostsForDisplay().filter(x => x.selected && x.selected === true)
    },
    sumSelectedManual: function () {
      return this.selectedManual().reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.cost)
      }, 0)
    },
    selectedManual: function () {
      return this.availableManualCostsForDisplay.filter(x => x.selected && x.selected === true)
    },
    setPacksCheckBoxes: function (value) {
      this.availablePacksCostsForDisplay().forEach((x) => {
        x.selected = value
        this.$nextTick(() => {
          this.$forceUpdate()
        })
      })
    },
    setManualCheckBoxes: function (value) {
      this.availableManualCostsForDisplay.forEach((x) => {
        x.selected = value
        this.$nextTick(() => {
          this.$forceUpdate()
        })
      })
    }
  },
  watch: {
    value: function (newValue, oldValue) {

    },
    checkAll: function (newValue, oldValue) {
      let packsNewIndex = newValue.findIndex(x => x === 'Packs')
      let packsOldIndex = (oldValue) ? oldValue.findIndex(x => x === 'Packs') : -1
      if (packsNewIndex !== -1 && packsOldIndex === -1) {
        this.setPacksCheckBoxes(true)
      } else if (packsNewIndex === -1 && packsOldIndex !== -1) {
        this.setPacksCheckBoxes(false)
      }

      let manualNewIndex = newValue.findIndex(x => x === 'Manual')
      let manualOldIndex = (oldValue) ? oldValue.findIndex(x => x === 'Manual') : -1
      if (manualNewIndex !== -1 && manualOldIndex === -1) {
        this.setManualCheckBoxes(true)
      } else if (manualNewIndex === -1 && manualOldIndex !== -1) {
        this.setManualCheckBoxes(false)
      }
    }
  },
  mounted: function () {
  },
  created: function () {
  },
  destroyed () {
  }
}

</script>

<style scoped>
  .header {
    position: sticky;
    top: 0;
  }

  .header + .list {
    margin-top: 20px !important;
  }
</style>
