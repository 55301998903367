<template>
  <section>
    <b-modal :key="instanceId" :active.sync="isPurchaseCostEditable" scroll="keep" :has-modal-card="true" :canCancel="false">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Update Purchase Cost</p>
        </header>
        <section class="modal-card-body" style="padding:12px">
          <form class="view-container">
            <div class="columns">
              <div class="column is-12">
                <div class="field">
                  <label class="label">Purchase Cost</label>
                  <div class="control has-icons-left">
                    <money name="purchaseCost" data-vv-as="purchase cost" v-validate="'decimal:2'" class="is-small" :class="{'input': true, 'is-danger': errors.has('purchaseCost') }" type="text" v-model="firstSoldItemInventory.purchaseCost" v-bind="$globalMoneyFormat" placeholder="0000.00"></money>
                      <span v-show="errors.has('purchaseCost')" class="help is-danger"><i v-show="errors.has('purchaseCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('purchaseCost') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-12">
                <div class="field">
                  <label class="label">Purchase Commission</label>
                  <div class="control has-icons-left">
                    <money name="commission" data-vv-as="purchase commission" v-validate="'decimal:2'" class="is-small" :class="{'input': true, 'is-danger': errors.has('commission') }" type="text" v-model="firstSoldItemInventory.commission" v-bind="$globalMoneyFormat" placeholder="0000.00"></money>
                      <span v-show="errors.has('commission')" class="help is-danger"><i v-show="errors.has('commission')" class="fas fa-exclamation-triangle"></i> {{ errors.first('commission') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="onClose()">Done</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formattingMixin from '@/mixins/inventory/formatting'
import utilitiesMixin from '@/mixins/generic/utilities'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import eventBus from '@/eventBus'

export default {
  name: 'PurchaseCostTotalCapture',
  mixins: [utilitiesMixin, formattingMixin, validateBeforeSubmitMixin],
  components: {
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    isPurchaseCostEditable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      firstSoldItemInventory: this.value
    }
  },
  computed: {
  },
  methods: {
    onClose: function () {
      this.firstSoldItemInventory.purchaseCost = this.firstSoldItemInventory.purchaseCost === null ? 0.00 : this.firstSoldItemInventory.purchaseCost
      this.firstSoldItemInventory.commission = this.firstSoldItemInventory.commission === null ? 0.00 : this.firstSoldItemInventory.commission
      this.$emit('update:isPurchaseCostEditable', false)
      eventBus.$emit('purchase-cost-updated', {})
    }
  },
  mounted: function () {
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 300px !important;
    max-width: 300px !important;
    width: 300px !important;
  }

  .modal-card-body {
    max-height: 240px !important;
    min-height: 240px !important;
    height: 240px !important;
  }
</style>
