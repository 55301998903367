import requiresAuth from './route_guards'

export default [
  {
    path: '/inventory?',
    name: 'ListInventory',
    component: () => import('@/components/inventory/ListInventory'),
    beforeEnter: requiresAuth
  },
  {
    name: 'InventoryQuickView',
    path: '/inventory/qview/:id/:isServiceRequestPod/:isLoanerPod/:offerPod/:autoLaunchControl?',
    meta: {
      quickViewComponent: {
        component: () => import('@/components/inventory/ViewInventory'),
        qvHeaderClass: 'inventory-qv-header',
        qvBodyClass: 'inventory-qv-body',
        qvFooterClass: ''
      }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/new',
    name: 'NewInventory',
    component: () => import('@/components/inventory/NewInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/view/:id',
    name: 'ViewInventory',
    component: () => import('@/components/inventory/ViewInventory'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/edit/:id',
    name: 'EditInventory',
    component: () => import('@/components/inventory/EditInventory'),
    beforeEnter: requiresAuth,
    props: true
  },
  {
    path: '/inventory/edit/:id/:convert',
    name: 'EditInventoryConvert',
    component: () => import('@/components/inventory/EditInventory'),
    beforeEnter: requiresAuth,
    props: true
  },
  {
    path: '/inventory/missingtitles',
    name: 'ListMissingTitlesInventory',
    component: () => import('@/components/inventory/ListMissingTitlesInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/missingphotos',
    name: 'ListMissingPhotosInventory',
    component: () => import('@/components/inventory/ListMissingPhotosInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/missingaccessories',
    name: 'ListMissingAccessoriesInventory',
    component: () => import('@/components/inventory/ListMissingAccessoriesInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/missingpurchasepaperwork',
    name: 'ListMissingPurchasePaperworkInventory',
    component: () => import('@/components/inventory/ListMissingPurchasePaperworkInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/loanedout',
    name: 'ListLoanedOutInventory',
    component: () => import('@/components/inventory/ListLoanedOutInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/dealergroup',
    name: 'ListDealerGroupInventory',
    component: () => import('@/components/inventory/ListDealerGroupInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/ppa',
    name: 'ListPpaInventory',
    component: () => import('@/components/inventory/ListPpaInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/pricing',
    name: 'ListPricingInventory',
    component: () => import('@/components/inventory/ListPricingInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/spiff',
    name: 'ListSpiffInventory',
    component: () => import('@/components/inventory/ListSpiffInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/special',
    name: 'ListOnSpecialInventory',
    component: () => import('@/components/inventory/ListOnSpecialInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/spiffspecial',
    name: 'ListSpiffSpecialInventory',
    component: () => import('@/components/inventory/ListSpiffSpecialInventory'),
    beforeEnter: requiresAuth
  },
  {
    path: '/inventory/:stockNo?',
    name: 'ListInventoryWithStockNo',
    component: () => import('@/components/inventory/ListInventory'),
    beforeEnter: requiresAuth
  }
]
