<template>
  <div class="dropdown is-up is-right" :id="instanceName">
    <div class="dropdown-trigger">
      <i @click.stop.prevent="toggleDropdown" class="icon fal fa-info-circle fa-lg has-text-info dropdown-btn clickable" title="click for detail"/>
    </div>
    <div ref="dropdownContainer" class="dropdown-menu box has-background-grey-lighter">
      <div class="fg-header deposit-qv-header is-flex small-bottom-margin" style="padding: 0.75rem">
        <a class="button is-danger" style="margin-right: auto;" @click="closeDropDown">Close</a>

        <section v-if="isGpReconciled" id="reconciled-container" class="level is-mobile" style="margin: 5px;">
          <div class="level-item has-text-centered has-background-success has-text-white box is-flex" style="padding: 3px;">
            <div class="heading mt-5 mr-5"><span><i class="fas fa-check-circle"></i></span> Reconciled GP</div>
            <div v-if="hasAnyCdkAccrualSelections" class="heading mt-5 mr-5">
              <span class="is-size-7 has-text-warning">CDK&nbsp;</span>{{cdkAdjustmentAmount | currency}}
            </div>
            <div v-if="hasAnyLiveAccrualSelections" class="heading mt-5">
              <span class="is-size-7 has-text-warning">LIVE&nbsp;</span>{{liveAdjustmentAmount | currency}}
            </div>
          </div>
        </section>

        <a v-if="!isReadOnly" class="fg-save-action button has-text-success mr-3" @click="saveAdjustments(false)" target="_blank" v-tooltip="{content: 'save adjustments'}">
          <i class="fal fa-save fa-m"></i>
        </a>

        <a v-if="!isReadOnly" class="fg-save-action button has-text-danger" @click="removeAdjustments()" target="_blank" v-tooltip="{content: 'remove adjustments'}">
          <i class="far fa-ban fa-m"></i>
        </a>
      </div>
      <div class="dropdown-content has-background-grey-lighter">
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <div class="columns is-multiline is-mobile fg-columns">
          <div class="column">
            <div v-if="showAutoUncheckMessage" class="is-flex notification is-warning">
              <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
              <span class="ml-10">
                Auto detection has unchecked the default note as the amount has since been updated since the last time the adjustment was saved. Please review the amount and select it again if it should still apply.
              </span>
            </div>
            <div id="default-note-instruction" class="box fg-sticky has-background-info-light">
              <section-header title="Default Note Instruction" parentSelector="#default-note-instruction" :collapsed="false"></section-header>
              <table class="table header is-striped is-fullwidth">
                <thead><tr><th>Instruction</th><th class="text-right">Apply Adjustment</th></tr></thead>
                <tr>
                  <td>{{cdkInstruction}}<br /><span class="has-text-info is-size-7">(select certain fees below to adjust accrue amount)</span></td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultAmountCdk"></b-checkbox>
                  </td>
                </tr>
                <tr>
                  <td>{{cdkInstructionFees}}<br /><span class="has-text-info is-size-7">(select certain fees below to adjust accrue amount)</span></td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultAmountCdkFees"></b-checkbox>
                  </td>
                </tr>
                <tr v-if="showAccruePurchaseCostInstruction">
                  <td>{{cdkPurchaseCostInstruction}}</td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultPurchaseCostCdk"></b-checkbox>
                  </td>
                </tr>
              </table>

              <table class="table header is-striped is-fullwidth">
                <thead><tr><th>Instruction</th><th class="text-right">Apply Adjustment</th></tr></thead>
                <tr>
                  <td v-if="defaultNoteAmountToDisplayLive > 0">
                    <span class="tag is-danger">Add {{defaultNoteAmountToDisplayLive | currency}} to LIVE</span> to match recon in CDK. (accrue additional recon for {{defaultNoteAmountToDisplayLive | currency}} that was not setup in live)
                  </td>
                  <td v-else>
                    <span class="tag is-danger">Remove {{-(defaultNoteAmountToDisplayLive) | currency}} from LIVE</span> to match recon in CDK.
                  </td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultAmountAddLive"></b-checkbox>
                  </td>
                </tr>
                <tr>
                  <td v-if="defaultNoteAmountRemoveCdkToDisplayLive > 0">
                    <span class="tag is-warning">Remove {{defaultNoteAmountRemoveCdkToDisplayLive | currency}} from CDK</span> inventory balance as it was not approved. charge back {{defaultNoteAmountRemoveCdkToDisplayLive | currency}} for excess recon that was not approved
                  </td>
                  <td v-else>
                    <span class="tag is-warning">Add {{-(defaultNoteAmountRemoveCdkToDisplayLive) | currency}} to CDK</span> to match recon in LIVE
                  </td>
                  <td class="text-right">
                    <b-checkbox v-model="defaultAmountRemoveCdk"></b-checkbox>
                  </td>
                </tr>
              </table>
            </div>
            <div id="deleted-in-live" class="box" v-if="forInventory.inActiveSupplementalCosts.length > 0">
              <section-header
                title="Items Deleted In LIVE"
                parentSelector="#deleted-in-live"
                :collapsed="true"
                :overview="'('+ forInventory.inActiveSupplementalCosts.length +')'">
              </section-header>
              <table class="table background-white is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th>Line Item</th>
                    <th>Note Instruction</th>
                    <th class="text-right no-content-wrap" style="white-space: nowrap" >Apply To CDK</th>
                    <th class="text-right no-content-wrap" style="white-space: nowrap" >Apply To LIVE</th>
                  </tr>
                </thead>
                <tr v-for="(supplementalCost) in forInventory.inActiveSupplementalCosts" :key="supplementalCost.id">
                  <td class="striked">{{(supplementalCost.cost - supplementalCost.sellingPrice) | currency}} - {{supplementalCost.name}}</td>
                  <td><span class="tag is-warning">Remove in CDK</span> {{supplementalCost.name}} as it was removed in Live</td>
                  <td class="text-right"><b-checkbox :ref="supplementalCost.id + '_chk'" :name="supplementalCost.id + '_chk'" @click.native="toggleInActiveCost(supplementalCost, 'cdk')" v-model="supplementalCost.checked"></b-checkbox></td>
                  <td class="text-right"><b-checkbox :ref="supplementalCost.id + '_chkLive'" :name="supplementalCost.id + '_chkLive'" @click.native="toggleInActiveCost(supplementalCost, 'live')" v-model="supplementalCost.checkedLive"></b-checkbox></td>
                </tr>
              </table>
            </div>
            <div id="can-remove" class="box" v-if="forInventory.addedSupplementalCostsAfterInitialCreate.length > 0">
              <section-header
                title="Items You Can Remove"
                parentSelector="#can-remove"
                :collapsed="true"
                :overview="'('+ forInventory.addedSupplementalCostsAfterInitialCreate.length +')'">
              </section-header>
              <table class="table background-white is-striped is-fullwidth">
                <thead><tr><th>Line Item</th><th class="text-right">Remove from LIVE</th><th class="text-right">Add to CDK</th></tr></thead>
                <tr v-for="(supplementalCost) in forInventory.addedSupplementalCostsAfterInitialCreate" :key="supplementalCost.id">
                  <td :class="getItemsYouCanRemoveClass(supplementalCost)">{{(supplementalCost.cost - supplementalCost.sellingPrice) | currency}} - {{supplementalCost.name}}</td>
                  <td class="text-right">
                    <i v-if="!supplementalCost.deleted || supplementalCost.deleted === false"
                      class="icon fas fa-trash-alt pointer"
                      @click="toggleDeleteActiveCost(supplementalCost)"
                      title="Delete"/>
                  </td>
                  <td class="text-right">
                    <b-checkbox
                      v-if="!supplementalCost.deleted || supplementalCost.deleted === false"
                      :ref="supplementalCost.id + '_chk'"
                      :name="supplementalCost.id + '_chk'"
                      @click.native="toggleActiveCostAfterCreate(supplementalCost)"
                      v-model="supplementalCost.checked"></b-checkbox>
                  </td>
                </tr>
              </table>
            </div>
            <div id="active-in-live" class="box" v-if="inventoryDefaultCosts.length > 0">
              <section-header
                title="Active Items In Live"
                parentSelector="#active-in-live"
                :collapsed="true"
                :overview="'('+ inventoryDefaultCosts.length +')'">
              </section-header>
              <table class="table background-white is-striped is-fullwidth">
                <thead><tr><th>Line Item</th><th class="text-right">Apply to CDK</th></tr></thead>
                <tr v-for="(supplementalCost) in inventoryDefaultCosts" :key="supplementalCost.id">
                  <td>{{(supplementalCost.cost - supplementalCost.sellingPrice) | currency}} - {{supplementalCost.name}}</td>
                  <td class="text-right">
                    <b-checkbox
                      :ref="supplementalCost.id + '_chk'"
                      :name="supplementalCost.id + '_chk'"
                      @click.native="toggleActiveCost(supplementalCost)"
                      v-model="supplementalCost.checked"></b-checkbox>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="column" :class="getRightSideSize" style="min-width: fit-content;">
            <div class="fg-sticky">
              <div class="is-hidden-mobile mr-3">
                <calculator-control
                  :key="'fg' + instanceName" :id="'fg' + instanceName"
                  :isInLine="true"
                  v-on:onToggleSoftCalculator="onToggleSoftCalculator">
                </calculator-control>
              </div>
            </div>
          </div>
        </div>
        <b-loading :is-full-page="true" :active.sync="isSaving" :canCancel="false"></b-loading>
      </div>
    </div>
  </div>
</template>

<script>

import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import dealService from '@/services/dealService'
import utilitiesMixin from '@/mixins/generic/utilities'
import CalculatorControl from '@/components/generic/CalculatorControl'
import SectionHeader from '@/components/generic/SectionHeader'
import eventBus from '@/eventBus'

export default {
  name: 'FrontGrossAccrualLineItems',
  mixins: [utilitiesMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'calculator-control': CalculatorControl,
    'section-header': SectionHeader
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'cdk' // types = cdk, live
    },
    forInventory: {
      type: Object,
      default: null
    },
    persistedAdjustments: {
      type: String,
      default: ''
    },
    dealItem: {
      type: Object,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    saveAdjustmentsUuid: {
      type: String,
      default: ''
    },
    toggleDefaultPurchaseCostCdkUuid: {
      type: String,
      default: ''
    },
    showAutoUncheckMessage: {
      type: Boolean,
      default: false
    },
    isDealProgressingToBooked: {
      type: Boolean,
      default: false
    },
    cdkAdjustmentAmount: {
      type: Number,
      default: 0
    },
    liveAdjustmentAmount: {
      type: Number,
      default: 0
    },
    balanceFrontGrossCdk: {
      type: Number,
      default: 0
    },
    balanceFrontGrossLive: {
      type: Number,
      default: 0
    },
    cdkGlbalanceToPurchaseCostPercentage: {
      type: Number,
      default: 0
    },
    frontGrossDiff: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      defaultAmountCdk: false,
      defaultAmountAddLive: false,
      defaultAmountRemoveCdk: false,
      currentAdjustments: [],
      serverErrorMessage: '',
      serverErrors: [],
      isSaving: false,
      defaultPurchaseCostCdk: false,
      isSoftCalulatorOpen: false,
      defaultAmountCdkFees: false,
      deletedItemsCheckedAmount: 0,
      deletedItemsCheckedLiveAmount: 0,
      itemsYouCanRemoveCheckedAmount: 0,
      activeItemsCheckedAmount: 0,
      defaultNoteInstructionAmount: 0,
      usedBalanceFrontGrossCdk: 0
    }
  },
  computed: {
    cdkInstruction: function () {
      return 'Accrue ' + this.$options.filters.currency(this.defaultNoteAmountToDisplay) + ' for Recon setup in LIVE but not yet posted in CDK'
    },
    cdkInstructionFees: function () {
      return 'Accrue ' + this.$options.filters.currency(this.defaultNoteAmountToDisplay) + ' for Fees setup in LIVE but not yet posted in CDK'
    },
    liveInstructionAdd: function () {
      if (this.defaultNoteAmountToDisplayLive > 0) {
        return 'Add ' + this.$options.filters.currency(this.defaultNoteAmountToDisplayLive) + ' to Live to match recon in CDK. (accrue additional recon for ' + this.$options.filters.currency(this.defaultNoteAmountToDisplayLive) + ' that was not setup in live)'
      }

      return 'Remove ' + this.$options.filters.currency(-(this.defaultNoteAmountToDisplayLive)) + ' from LIVE to match recon in CDK.'
    },
    cdkInstructionRemove: function () {
      if (this.defaultNoteAmountRemoveCdkToDisplayLive > 0) {
        return 'Remove ' + this.$options.filters.currency(this.defaultNoteAmountRemoveCdkToDisplayLive) + ' from CDK inventory balance as it was not approved. (charge back ' + this.$options.filters.currency(this.defaultNoteAmountRemoveCdkToDisplayLive) + ' for excess recon that was not approved)'
      }

      return 'Add ' + this.$options.filters.currency(-(this.defaultNoteAmountRemoveCdkToDisplayLive)) + ' to CDK to match recon in LIVE.'
    },
    isTypeCdk: function () {
      return this.type === 'cdk'
    },
    isTypeLive: function () {
      return this.type === 'live'
    },
    dealId: function () {
      return this.dealItem ? this.dealItem.dealId : 0
    },
    canSave: function () {
      return this.currentAdjustments.length !== 0
    },
    showAccruePurchaseCostInstruction: function () {
      return this.cdkGlbalanceToPurchaseCostPercentage < 40
    },
    cdkPurchaseCostInstruction: function () {
      return 'Accrue ' + this.$options.filters.currency(this.purchaseCostDerived) + ' for the purchase cost of the vehicle. (GL balance is less than 40% of purchase cost)'
    },
    getRightSideSize: function () {
      return this.isSoftCalulatorOpen ? 'is-5' : 'is-1'
    },
    inventoryDefaultCosts: function () {
      return this.$lodash.differenceWith(this.forInventory.supplementalCosts, this.forInventory.addedSupplementalCostsAfterInitialCreate, this.$lodash.isEqual)
    },
    purchaseCostDerived: function () {
      return this.forInventory ? this.forInventory.purchaseCost + this.forInventory.commission : 0
    },
    workingLiveAdjustmentAmount: function () {
      let result = 0

      if (this.defaultAmountAddLive === true) {
        let amountToAchieveBalance = this.balanceFrontGrossCdk
        result += amountToAchieveBalance
      }

      result += this.deletedItemsCheckedLiveAmount

      return result
    },
    workingCdkAdjustmentAmount: function () {
      let result = 0

      if (this.defaultAmountCdk === true) {
        result += this.availableBalance
      }

      if (this.defaultAmountCdkFees === true) {
        result += this.availableBalance
      }

      if (this.defaultPurchaseCostCdk === true) {
        result += this.purchaseCostDerived
      }

      if (this.defaultAmountRemoveCdk) {
        // result += this.availableBalance
        result += result - this.availableBalance
      }

      result += this.deletedItemsCheckedAmount

      result += this.itemsYouCanRemoveCheckedAmount

      result += this.activeItemsCheckedAmount

      return result
    },
    availableBalance: function () {
      let amountToAchieveBalance = -(this.balanceFrontGrossLive)

      if (this.defaultPurchaseCostCdk === true) {
        amountToAchieveBalance = amountToAchieveBalance - this.purchaseCostDerived
      }

      /*
      if (this.defaultAmountRemoveCdk === true) {
        amountToAchieveBalance -= amountToAchieveBalance
      }
      */

      if (this.anyCheckedAdjustmentsCdk) {
        amountToAchieveBalance -= this.sumAllCheckedListItems
      }

      return amountToAchieveBalance
    },
    sumAllCheckedListItems: function () {
      this.sumDeletedItemsCheckedAmount()
      this.sumItemsYouCanRemoveCheckedAmount()
      this.sumActiveItemsCheckedAmount()
      return this.deletedItemsCheckedAmount + this.itemsYouCanRemoveCheckedAmount + this.activeItemsCheckedAmount
    },
    anyCheckedAdjustmentsCdk: function () {
      return this.cdkAdjustmentAmount !== 0
    },
    defaultNoteAmount: function () {
      return this.balanceFrontGrossCdk + this.cdkAdjustmentAmount - this.sumAllCheckedListItems
    },
    defaultNoteAmountToDisplay: function () {
      let amountToAchieveBalance = -(this.balanceFrontGrossLive)

      if (this.defaultPurchaseCostCdk === true) {
        amountToAchieveBalance = amountToAchieveBalance - this.purchaseCostDerived
      }

      if (this.defaultAmountRemoveCdk === true) {
        amountToAchieveBalance += amountToAchieveBalance - this.availableBalance
      }

      if (this.anyCheckedAdjustmentsCdk) {
        amountToAchieveBalance -= this.sumAllCheckedListItems
      }

      return amountToAchieveBalance
    },
    defaultNoteAmountToDisplayLive: function () {
      let amountToAchieveBalance = -(this.balanceFrontGrossCdk)
      // let amountToAchieveBalance = -(this.cdkAdjustmentAmount)

      if (this.defaultAmountRemoveCdk) {
        amountToAchieveBalance += -(this.defaultNoteAmountToDisplay)
      }

      return amountToAchieveBalance
    },
    defaultNoteAmountRemoveCdkToDisplayLive: function () {
      return this.defaultNoteAmountToDisplayLive
    },
    isGpReconciled: function () {
      return this.frontGrossDiff === 0
    },
    anyAccrualAdjustmentsSelected: function () {
      return this.hasAnyCdkAccrualSelections || this.hasAnyLiveAccrualSelections
    },
    hasAnyCdkAccrualSelections: function () {
      return this.cdkAdjustmentAmount !== 0
    },
    hasAnyLiveAccrualSelections: function () {
      return this.liveAdjustmentAmount !== 0
    }
  },
  methods: {
    toggleInActiveCost: function (row, app) {
      let found = this.forInventory.inActiveSupplementalCosts.find(x => x.id === row.id)
      if (found) {
        found.text = 'Remove in CDK ' + row.name + ' as it was removed in Live'
        if (app === 'live') found.text = 'Add to LIVE ' + row.name + ' to match recon in CDK'
        this.markCheckedStatus(found, app)
      }
    },
    toggleActiveCostAfterCreate: function (row) {
      let found = this.forInventory.addedSupplementalCostsAfterInitialCreate.find(x => x.id === row.id)
      if (found) {
        found.text = 'Add in CDK - ' + row.name
        this.markCheckedStatus(found, 'cdk')
      }
    },
    toggleActiveCost: function (row) {
      let found = this.inventoryDefaultCosts.find(x => x.id === row.id)
      if (found) {
        found.text = 'Add in CDK - ' + row.name
        this.markCheckedStatus(found, 'cdk')
      }
    },
    toggleDeleteActiveCost: function (row) {
      let found = this.forInventory.addedSupplementalCostsAfterInitialCreate.find(x => x.id === row.id)
      if (found) {
        found.text = 'Item You Can Remove - ' + row.name

        if (found.deleted === null || found.deleted === undefined) {
          found.deleted = true
        }

        // if its checked, it means the adjustment is applied. This must now be unchecked as we deleting the cost now
        if (found.checked !== null && found.checked !== undefined && found.checked === true) {
          this.markCheckedStatus(found, false)
        }

        this.$forceUpdate()

        // handle delete in inventory supp component
        eventBus.$emit('delete-cost-by-id', found.id)

        let that = this
        this.$nextTick(() => {
          setTimeout(function () {
            that.syncChanges()

            // auto save adjustments
            let bypassNotification = true
            that.saveAdjustments(bypassNotification)
          }, 500)
        })
      }
    },
    markCheckedStatus: function (cost, app) {
      if (cost && app === 'cdk') {
        if (cost.checked === null || cost.checked === undefined) {
          cost.checked = true
        } else {
          cost.checked = !cost.checked
        }

        if (cost.checked === true) {
          this.persistAdjustment(cost, app)
        } else {
          this.removeAdjustment(cost, app)
        }
      }

      if (cost && app === 'live') {
        if (cost.checkedLive === null || cost.checkedLive === undefined) {
          cost.checkedLive = true
        } else {
          cost.checkedLive = !cost.checkedLive
        }

        if (cost.checkedLive === true) {
          this.persistAdjustment(cost, app)
        } else {
          this.removeAdjustment(cost, app)
        }
      }

      /*
      this.$nextTick(() => {
        this.$forceUpdate()
        console.log('cost', cost)
        console.log('app', app)
      })
      */
    },
    markCheckedStatusOnly: function (cost, app) {
      if (cost && app === 'cdk') {
        if (cost.checked === null || cost.checked === undefined) {
          cost.checked = true
        } else {
          cost.checked = !cost.checked
        }
      }

      if (cost && app === 'live') {
        if (cost.checkedLive === null || cost.checkedLive === undefined) {
          cost.checkedLive = true
        } else {
          cost.checkedLive = !cost.checkedLive
        }
      }

      this.$nextTick(() => {
        this.$forceUpdate()
        this.syncChanges()
      })
    },
    persistAdjustment: function (adjustment, app) {
      let adj = {
        id: adjustment.id,
        text: adjustment.text,
        amount: adjustment.amount,
        app: app
      }

      let found = this.currentAdjustments.find(x => x.id === adj.id && x.app === app)
      if (found === null || found === undefined) {
        this.currentAdjustments.push(adj)
        this.syncChanges()
        return
      }

      // if its found then we can remove then add again incase amount has updated
      this.removeAdjustment(adjustment, app)
      this.persistAdjustment(adjustment, app)
    },
    removeAdjustment: function (adjustment, app) {
      let indexToDelete = this.currentAdjustments.findIndex((x) => x.id === adjustment.id && x.app === app)
      if (indexToDelete !== -1) {
        this.currentAdjustments.splice(indexToDelete, 1)
        this.syncChanges()
      }
    },
    init: function () {
      let that = this

      if (this.currentAdjustments && this.currentAdjustments.length === 0) {
        if (this.persistedAdjustments && this.persistedAdjustments !== null && this.persistedAdjustments !== undefined) {
          console.log('persistedAdjustments', this.persistedAdjustments)
          let testing = JSON.parse(this.persistedAdjustments)
          console.log('testing', testing)
          testing.forEach(x => {
            this.currentAdjustments.push(x)
          })
          // this.currentAdjustments = JSON.parse(this.persistedAdjustments)
          console.log('current ad', this.currentAdjustments)
        }
      }

      /*
      var obj = JSON.parse(this.persistedAdjustments, function (key, value) {
        if (key === 'id') {
          return value
        } else {
          return value
        }
      })

      console.log('obj', obj)
      */

      // this must be first, this logic updates amount which is used later
      let localDefaultPurchaseCostCdk = this.currentAdjustments.find(x => x.id === 4)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultPurchaseCostCdk && this.isDealProgressingToBooked) {
        if (localDefaultPurchaseCostCdk.amount && localDefaultPurchaseCostCdk.amount === this.purchaseCostDerived) {
          this.defaultPurchaseCostCdk = true
          this.syncChanges()
        } else {
          this.removeAdjustment(localDefaultPurchaseCostCdk)
          this.$emit('update:showAutoUncheckMessage', true)
          this.syncChanges()
        }
      } else if (localDefaultPurchaseCostCdk) {
        this.defaultPurchaseCostCdk = true
        this.syncChanges()
      }

      let localDefaultAmountCdk = this.currentAdjustments.find(x => x.id === 1)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultAmountCdk && this.isDealProgressingToBooked) {
        if (localDefaultAmountCdk.amount && localDefaultAmountCdk.amount === this.defaultNoteAmountToDisplay) {
          this.$nextTick(() => {
            setTimeout(function () {
              that.defaultAmountCdk = true
              that.syncChanges()
            }, 20)
          })
        } else {
          this.$nextTick(() => {
            setTimeout(function () {
              that.removeAdjustment(localDefaultAmountCdk)
              that.$emit('update:showAutoUncheckMessage', true)
            }, 20)
          })
        }
      } else if (localDefaultAmountCdk) {
        this.$nextTick(() => {
          setTimeout(function () {
            that.defaultAmountCdk = true
            that.syncChanges()
          }, 20)
        })
      }

      let localDefaultAmountCdkFees = this.currentAdjustments.find(x => x.id === 5)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultAmountCdkFees && this.isDealProgressingToBooked) {
        if (localDefaultAmountCdkFees.amount && localDefaultAmountCdkFees.amount === this.defaultNoteAmountToDisplay) {
          this.$nextTick(() => {
            setTimeout(function () {
              that.defaultAmountCdkFees = true
              that.syncChanges()
            }, 20)
          })
        } else {
          this.$nextTick(() => {
            setTimeout(function () {
              that.removeAdjustment(localDefaultAmountCdkFees)
              that.$emit('update:showAutoUncheckMessage', true)
            }, 20)
          })
        }
      } else if (localDefaultAmountCdkFees) {
        this.$nextTick(() => {
          setTimeout(function () {
            that.defaultAmountCdkFees = true
            that.syncChanges()
          }, 20)
        })
      }

      let localDefaultAmountAddLive = this.currentAdjustments.find(x => x.id === 2)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultAmountAddLive && this.isDealProgressingToBooked) {
        if (localDefaultAmountAddLive.amount && localDefaultAmountAddLive.amount === this.defaultNoteAmountToDisplayLive) {
          this.$nextTick(() => {
            setTimeout(function () {
              that.defaultAmountAddLive = true
              that.syncChanges()
            }, 20)
          })
        } else {
          this.$nextTick(() => {
            setTimeout(function () {
              that.removeAdjustment(localDefaultAmountAddLive)
              that.$emit('update:showAutoUncheckMessage', true)
            }, 20)
          })
        }
      } else if (localDefaultAmountAddLive) {
        this.$nextTick(() => {
          setTimeout(function () {
            that.defaultAmountAddLive = true
            that.syncChanges()
          }, 20)
        })
      }

      let localDefaultAmountRemoveCdk = this.currentAdjustments.find(x => x.id === 3)
      // when deal is being booked we want to uncheck the default if the amount saved is different to current amount so that deal washout can read the latest amount
      if (localDefaultAmountRemoveCdk && this.isDealProgressingToBooked) {
        if (localDefaultAmountRemoveCdk.amount && localDefaultAmountRemoveCdk.amount === this.defaultNoteAmountRemoveCdkToDisplayLive) {
          this.$nextTick(() => {
            setTimeout(function () {
              that.defaultAmountRemoveCdk = true
              that.syncChanges()
            }, 20)
          })
        } else {
          this.$nextTick(() => {
            setTimeout(function () {
              that.removeAdjustment(localDefaultAmountRemoveCdk)
              that.$emit('update:showAutoUncheckMessage', true)
            }, 20)
          })
        }
      } else if (localDefaultAmountRemoveCdk) {
        this.$nextTick(() => {
          setTimeout(function () {
            that.defaultAmountRemoveCdk = true
            that.syncChanges()
          }, 20)
        })
      }

      this.$nextTick(() => {
        setTimeout(function () {
          that.forInventory.addedSupplementalCostsAfterInitialCreate.forEach(element => {
            let found = that.currentAdjustments.findIndex(x => x.id === element.id)
            if (found !== -1) {
              that.markCheckedStatus(element, false)
            }
          })
        }, 20)
      })

      this.$nextTick(() => {
        setTimeout(function () {
          /*
          that.forInventory.inActiveSupplementalCosts.forEach(element => {
            let found = that.currentAdjustments.find(x => x.id === element.id)
            console.log('found', found)
            if (found) {
              that.markCheckedStatus(element, found.app)
            }
          })
          */
          that.currentAdjustments.forEach(currentAdjustment => {
            let found = that.forInventory.inActiveSupplementalCosts.find(x => x.id === currentAdjustment.id)
            if (found) {
              console.log('found', found)
              that.markCheckedStatusOnly(found, currentAdjustment.app)
            }
          })
        }, 20)
      })

      this.$nextTick(() => {
        setTimeout(function () {
          that.inventoryDefaultCosts.forEach(element => {
            let found = that.currentAdjustments.find(x => x.id === element.id)
            if (found) {
              console.log('found', found)
              that.markCheckedStatus(element, found.app)
            }
          })
        }, 20)
      })
    },
    setTextForCurrentAdjustments: function () {
      this.currentAdjustments.forEach(x => {
        if (x.id === 1) {
          x.text = this.cdkInstruction
          x.amount = this.defaultNoteAmountToDisplay
        }

        if (x.id === 5) {
          x.text = this.cdkInstructionFees
          x.amount = this.defaultNoteAmountToDisplay
        }

        if (x.id === 2) {
          x.text = this.liveInstructionAdd
          x.amount = this.defaultNoteAmountToDisplayLive
        }

        if (x.id === 3) {
          x.text = this.cdkInstructionRemove
          x.amount = this.defaultNoteAmountRemoveCdkToDisplayLive
        }

        if (x.id === 4) {
          x.text = this.cdkPurchaseCostInstruction
          x.amount = this.purchaseCostDerived
        }
      })
    },
    saveAdjustments: function (bypassToast) {
      this.setTextForCurrentAdjustments()
      let dealItemModels = []
      let dealItemModel = {
        id: this.dealItem.id,
        dealItemType: 'Sold',
        reconAdjustment: JSON.stringify(this.currentAdjustments)
      }

      dealItemModels.push(dealItemModel)
      let dealModel = {
        id: this.dealId,
        items: dealItemModels
      }

      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true

      console.log('dealModel', dealModel)

      dealService.updateReconAdjustment(dealModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
          if (!bypassToast) this.failedToast('Oops! Something went wrong')
        } else {
          if (!bypassToast) this.successToast('Success! Adjustments Saved')
        }
      }).catch((error) => {
        if (!bypassToast) this.failedToast('Oops! Something went wrong')
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    toggleDefaultPurchaseCostCdk: function () {
      this.defaultAmountCdk = !this.defaultPurchaseCostCdk
      this.defaultPurchaseCostCdk = !this.defaultPurchaseCostCdk
    },
    getItemsYouCanRemoveClass: function (cost) {
      return cost && cost.deleted ? 'striked' : ''
    },
    toggleDropdown: function () {
      let fgDropDown = document.getElementById(this.instanceName)
      if (!fgDropDown) return
      if (fgDropDown.classList.contains('is-active')) {
        this.onClose(fgDropDown)
      } else {
        fgDropDown.classList.add('is-active')
      }
    },
    closeDropDown: function () {
      let fgDropDown = document.getElementById(this.instanceName)
      if (fgDropDown && fgDropDown.classList.contains('is-active')) {
        this.onClose(fgDropDown)
      }
    },
    onClose: function (fgDropDown) {
      fgDropDown.classList.remove('is-active')
    },
    removeCdkAdjustments: function () {
      this.removeAdjustment({id: 1})
      this.removeAdjustment({id: 4})
      this.removeAdjustment({id: 5})
    },
    removeLiveAdjustments: function () {
      this.removeAdjustment({id: 2})
      this.removeAdjustment({id: 3})
    },
    onToggleSoftCalculator: function (isOpen) {
      this.isSoftCalulatorOpen = isOpen
    },
    sumDeletedItemsCheckedAmount: function () {
      if (!this.forInventory) return false
      if (this.forInventory.inActiveSupplementalCosts.length > 0) {
        this.deletedItemsCheckedAmount = this.$lodash.sumBy(this.forInventory.inActiveSupplementalCosts, function (x) {
          return x.checked !== null && x.checked !== undefined && x.checked === true ? x.cost - x.sellingPrice : 0
        })
      }
    },
    sumDeletedItemsCheckedLiveAmount: function () {
      if (!this.forInventory) return false
      if (this.forInventory.inActiveSupplementalCosts.length > 0) {
        let result = 0
        result = this.$lodash.sumBy(this.forInventory.inActiveSupplementalCosts, function (x) {
          return x.checkedLive !== null && x.checkedLive !== undefined && x.checkedLive === true ? x.cost - x.sellingPrice : 0
        })
        this.deletedItemsCheckedLiveAmount = -(result)
      }
    },
    sumItemsYouCanRemoveCheckedAmount: function () {
      if (!this.forInventory) return false
      if (this.forInventory.addedSupplementalCostsAfterInitialCreate.length > 0) {
        this.itemsYouCanRemoveCheckedAmount = this.$lodash.sumBy(this.forInventory.addedSupplementalCostsAfterInitialCreate, function (x) {
          return x.checked !== null && x.checked !== undefined && x.checked === true ? x.cost - x.sellingPrice : 0
        })
      }
    },
    sumActiveItemsCheckedAmount: function () {
      if (!this.forInventory) return false
      if (this.inventoryDefaultCosts.length > 0) {
        this.activeItemsCheckedAmount = this.$lodash.sumBy(this.inventoryDefaultCosts, function (x) {
          return x.checked !== null && x.checked !== undefined && x.checked === true ? x.cost - x.sellingPrice : 0
        })
      }
    },
    syncDeletedCost: function (deletedCost) {
      // eslint-disable-next-line
      let deletedCosts = this.$lodash.remove(this.forInventory.addedSupplementalCostsAfterInitialCreate, function (x) {
        return x.id === deletedCost.id
      })

      deletedCosts = this.$lodash.remove(this.supplementalCosts, function (x) {
        return x.id === deletedCost.id
      })

      this.forInventory.inActiveSupplementalCosts.push(deletedCost)

      deletedCosts = this.$lodash.remove(this.currentAdjustments, function (x) {
        return x.id === deletedCost.id
      })

      this.syncChanges()
    },
    syncCdkAdjustmentAmount: function () {
      this.sumDeletedItemsCheckedAmount()
      this.sumItemsYouCanRemoveCheckedAmount()
      this.sumActiveItemsCheckedAmount()
      this.$forceUpdate()
      console.log('workingCdkAdjustmentAmount', this.workingCdkAdjustmentAmount)
      this.$nextTick(() => {
        // this.$emit('update:cdkAdjustmentAmount', this.workingCdkAdjustmentAmountMan())
        this.$emit('update:cdkAdjustmentAmount', this.workingCdkAdjustmentAmount)
      })
    },
    syncLiveAdjustmentAmount: function () {
      this.sumDeletedItemsCheckedLiveAmount()
      this.$nextTick(() => {
        this.$emit('update:liveAdjustmentAmount', this.workingLiveAdjustmentAmount)
      })
    },
    setUsedBalanceFrontGrossCdk: function () {
      this.usedBalanceFrontGrossCdk = this.balanceFrontGrossCdk
    },
    syncChanges: function () {
      // set used balance first before we update parent as the available balance will now change
      this.setUsedBalanceFrontGrossCdk()

      this.$forceUpdate()

      // this will sync selected adjustments total with parent control so that new balances can be calculated and synced
      this.syncCdkAdjustmentAmount()
      this.syncLiveAdjustmentAmount()
    },
    workingCdkAdjustmentAmountMan: function () {
      let result = 0

      if (this.defaultAmountCdk === true) {
        console.log('1', this.availableBalance)
        result += this.availableBalance
      }

      if (this.defaultAmountCdkFees === true) {
        console.log('2', this.availableBalance)
        result += this.availableBalance
      }

      if (this.defaultPurchaseCostCdk === true) {
        console.log('3', this.purchaseCostDerived)
        result += this.purchaseCostDerived
      }

      if (this.defaultAmountRemoveCdk) {
        console.log('4', this.availableBalance)
        result += this.availableBalance
      }

      console.log('5', this.deletedItemsCheckedAmount)
      result += this.deletedItemsCheckedAmount

      console.log('6', this.itemsYouCanRemoveCheckedAmount)
      result += this.itemsYouCanRemoveCheckedAmount

      console.log('7', this.activeItemsCheckedAmount)
      result += this.activeItemsCheckedAmount

      console.log('8', result)
      return result
    },
    workingLiveAdjustmentAmountMan: function () {
      let result = 0

      if (this.defaultAmountAddLive === true) {
        let amountToAchieveBalance = this.balanceFrontGrossCdk
        result += amountToAchieveBalance
      }

      console.log('workingLiveAdjustmentAmountMan', result)
      return result
    },
    removeAdjustments: function () {
      this.currentAdjustments = []

      if (this.defaultAmountCdk) this.defaultAmountCdk = false
      if (this.defaultAmountCdkFees) this.defaultAmountCdkFees = false

      if (this.defaultAmountAddLive) this.defaultAmountAddLive = false
      if (this.defaultAmountRemoveCdk) this.defaultAmountRemoveCdk = false

      if (this.defaultPurchaseCostCdk) this.defaultPurchaseCostCdk = false

      this.forInventory.addedSupplementalCostsAfterInitialCreate.forEach(element => {
        if (element.checked) element.checked = false
        this.$forceUpdate()
      })

      this.forInventory.inActiveSupplementalCosts.forEach(element => {
        if (element.checked) {
          console.log('element checked', element)
          this.$nextTick(() => {
            this.toggleInActiveCost(element, 'cdk')
          })
        }
        if (element.checkedLive) this.toggleInActiveCost(element, 'live')
        this.$forceUpdate()
      })

      this.inventoryDefaultCosts.forEach(element => {
        if (element.checked) element.checked = false
        this.$forceUpdate()
      })

      this.$nextTick(() => {
        this.syncCdkAdjustmentAmount()
        this.syncLiveAdjustmentAmount()
      })
    },
    fgOpenWithCalculator: function () {
      this.toggleDropdown()
      eventBus.$emit('open-calculator')
    },
    keyUp: function (keyEvent) {
      if (keyEvent.key === 'Escape' | keyEvent.keyCode === 27) {
        this.closeDropDown()
      }
    }
  },
  watch: {
    defaultAmountCdk: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        let amount = this.defaultNoteAmountToDisplay
        if (newValue === true) {
          this.persistAdjustment({id: 1, text: this.cdkInstruction, amount: amount})
        } else {
          this.removeAdjustment({id: 1, text: this.cdkInstruction})
        }
        this.syncChanges()
      }
    },
    defaultAmountCdkFees: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        let amount = this.defaultNoteAmountToDisplay
        if (newValue === true) {
          this.persistAdjustment({id: 5, text: this.cdkInstructionFees, amount: amount})
        } else {
          this.removeAdjustment({id: 5, text: this.cdkInstructionFees})
        }
      }
    },
    defaultAmountAddLive: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        if (newValue === true && this.defaultAmountRemoveCdk) {
          this.defaultAmountRemoveCdk = false
        }
        let amount = this.defaultNoteAmountToDisplayLive
        if (newValue === true) {
          this.persistAdjustment({id: 2, text: this.liveInstructionAdd, amount: amount})
        } else {
          this.removeAdjustment({id: 2, text: this.liveInstructionAdd})
        }
        this.syncChanges()
      }
    },
    defaultAmountRemoveCdk: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        if (newValue === true && this.defaultAmountAddLive) {
          this.defaultAmountAddLive = false
        }
        let amount = this.defaultNoteAmountRemoveCdkToDisplayLive
        if (newValue === true) {
          this.persistAdjustment({id: 3, text: this.cdkInstructionRemove, amount: amount})
        } else {
          this.removeAdjustment({id: 3, text: this.cdkInstructionRemove})
        }
        this.syncChanges()
      }
    },
    saveAdjustmentsUuid: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.saveAdjustments(true)
      }
    },
    defaultPurchaseCostCdk: function (newValue, oldValue) {
      if (oldValue !== null || oldValue !== undefined) {
        let amount = this.purchaseCostDerived
        if (newValue === true) {
          this.persistAdjustment({id: 4, text: this.cdkPurchaseCostInstruction, amount: amount})
        } else {
          this.removeAdjustment({id: 4, text: this.cdkPurchaseCostInstruction})
        }
        this.syncChanges()
      }

      this.$emit('toggleDefaultPurchaseCostCdk', newValue)
    },
    toggleDefaultPurchaseCostCdkUuid: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.toggleDefaultPurchaseCostCdk()
      }
    },
    forInventory: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.forInventory.addedSupplementalCostsAfterInitialCreate.forEach(element => {
          let found = this.currentAdjustments.findIndex(x => x.id === element.id)
          if (found !== -1) {
            element.checked = true
          }
        })

        this.forInventory.inActiveSupplementalCosts.forEach(element => {
          let found = this.currentAdjustments.findIndex(x => x.id === element.id)
          if (found !== -1) {
            element.checked = true
          }
        })

        this.inventoryDefaultCosts.forEach(element => {
          let found = this.currentAdjustments.findIndex(x => x.id === element.id)
          if (found !== -1) {
            element.checked = true
          }
        })
      }
    },
    isSoftCalulatorOpen: function (newValue, oldValue) {
      let dropdownContainer = this.$refs.dropdownContainer
      if (dropdownContainer) {
        if (newValue === true) {
          dropdownContainer.setAttribute('style', 'max-width: 1000px !important;')
        } else {
          dropdownContainer.setAttribute('style', 'max-width: 820px !important;')
        }
      }
    },
    value: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.syncChanges()
      }
    },
    cdkAdjustmentAmount: function (newValue, oldValue) {
      this.syncLiveAdjustmentAmount()
    }
  },
  mounted: function () {
    this.init()
  },
  created: function () {
    eventBus.$on('fg-sync-deleted-cost', this.syncDeletedCost)
    eventBus.$on('fg-open-with-calculator', this.fgOpenWithCalculator)
    window.addEventListener('keyup', this.keyUp)
  },
  destroyed () {
    eventBus.$off('fg-sync-deleted-cost', this.syncDeletedCost)
    eventBus.$off('fg-open-with-calculator', this.fgOpenWithCalculator)
    window.removeEventListener('keyup', this.keyUp)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.gross-calc-container {
  max-height: 650px !important;
  height: 650px !important;
  overflow-y: scroll !important;
}

.fg-columns {
  min-height: 650px !important;
}

.dropdown-menu {
  position: fixed !important;
  top: 100px !important;
  left: 5px !important;
  width: 70% !important;
  height: 90% !important;
  max-height: 650px !important;
  z-index: 999999999999 !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  max-width: 820px !important;
  padding: 0px !important;
}

@media screen and (max-width: 1240px) {
  .dropdown-menu {
    left: 5px !important;
    width: 98% !important;
    height: 80% !important;
    max-height: 80% !important;
    z-index: 999999999999 !important;
  }
}

.dropdown-content {
  box-shadow: none !important;
}

.fg-save-action {
  border: 1px dashed #e0e0e3;
  border-radius: 50%;
  background: white;
  width: 2.25em;
  color: #4b6271;
  cursor: pointer;
}

div.fg-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

table.header {
  background-color:hsl(206, 70%, 96%) !important;
}

.text-right {
  text-align: right !important
}

.background-white {
  background-color: white !important;
}

.fg-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 199;
}

</style>
