import _ from 'lodash'
import eventBus from '@/eventBus'
import serviceRequestService from '@/services/serviceRequestService'
import ServiceRequestLine from '@/components/serviceRequest/ServiceRequestLine'
import ServiceRequestInspectionType from '@/components/serviceRequest/ServiceRequestInspectionType'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import InventorySelector from '@/components/generic/InventorySelector'
import NoteCapture from '@/components/generic/NoteCapture'
import NoteTimelineView from '@/components/generic/NoteTimelineView'
import SectionHeader from '@/components/generic/SectionHeader'
import inventoryService from '@/services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

var mixin = {
  components: {
    'service-request-line': ServiceRequestLine,
    'service-request-inspection-type': ServiceRequestInspectionType,
    'button-list-selector': ButtonListSelector,
    'inventory-selector': InventorySelector,
    'note-capture': NoteCapture,
    'note-timeline-view': NoteTimelineView,
    'section-header': SectionHeader,
    'error-display-component': ErrorDisplayComponent
  },
  data () {
    return {
      mode: 0,
      selectedInventory: null,
      selectedPriority: null,
      requiredAt: null,
      roNumber: '',
      selectedServiceRequestStatus: null,
      selectedServiceRequestType: null,
      notes: [],
      currentServiceRequest: {
        id: 0,
        lines: []
      },
      serverErrors: [],
      serverErrorMessage: '',
      isLoading: false,
      isFullPage: true,
      isSaving: false,
      isWorkItemDefinition: false,
      isInspectionTypeDefinition: false,
      defaultDealerInspectionTypes: [],
      isLoadDefaultInspectionTypes: false,
      isUploadingFile: false,
      fileUploadCount: 0,
      childComponentIsUploadingFile: false,
      showMediaSave: false,
      isSavingFile: false,
      attachPermission: 'servicerequest.add.attachment',
      myKaarmaPopupPollInterval: undefined
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    isFinanceProductRequest: function () {
      return this.selectedServiceRequestType && this.selectedServiceRequestType.id === this.definedTypes.serviceRequestType.FinanceProductRequest
    },
    isAdditionalReconditioningRequest: function () {
      return this.selectedServiceRequestType && this.selectedServiceRequestType.id === this.definedTypes.serviceRequestType.AdditionalReconditioning
    },
    isGetReadyRequest: function () {
      return this.selectedServiceRequestType && this.selectedServiceRequestType.id === this.definedTypes.serviceRequestType.GetReady
    },
    myKaarmaApprovalRequired: function () {
      return this.currentServiceRequest.myKaarmaMpiUrl !== null && this.currentServiceRequest.myKaarmaMpiUrl !== ''
    },
    isInspectionTypesCollapsed: function () {
      if (!this.selectedServiceRequestType) return true
      if (this.isAdditionalReconditioningRequest) return true
      return false
    },
    isRequestDefinition: function () {
      return this.mode === 0
    },
    canShowInspectionTypes: function () {
      if (!this.selectedServiceRequestType) return false
      if (this.isAdditionalReconditioningRequest) return false
      if (this.isFinanceProductRequest) return false
      return true
    },
    allPriorities: function () {
      return this.definedTypes.serviceRequestPriorityType.options
    },
    allInspectionTypes: function () {
      var allTypes = []
      this.definedTypes.inspectionTypes.forEach((type) => {
        allTypes.push(type)
      })
      return allTypes
    },
    validServiceLines: function () {
      return this.currentServiceRequest.lines.filter(x => x.status !== 'Deleted')
    },
    validServiceInspectionTypes: function () {
      return this.currentServiceRequest.inspectionTypes.filter(x => x.status !== 'Deleted')
    },
    activeServiceRequestStatusTypes: function () {
      let notApplicaableTypes = [
        this.definedTypes.serviceRequestStatusType.NotSet,
        this.definedTypes.serviceRequestStatusType.Cancelled,
        this.definedTypes.serviceRequestStatusType.OnHold,
        this.definedTypes.serviceRequestStatusType.Declined
      ]
      return this.definedTypes.serviceRequestStatusType.options.filter(
        (x) => !notApplicaableTypes.includes(x.id)
      )
    },
    activeServiceRequestTypes: function () {
      let defaults = [
        this.definedTypes.serviceRequestType.GetReady,
        this.definedTypes.serviceRequestType.FirstRo,
        this.definedTypes.serviceRequestType.AdditionalReconditioning,
        this.definedTypes.serviceRequestType.FinanceProductRequest
      ]
      if (!this.selectedInventory) {
        return this.definedTypes.serviceRequestType.options.filter((x) => defaults.includes(x.id))
      }
      if (this.selectedInventory.hasFirstRo === false) {
        return this.definedTypes.serviceRequestType.options.filter((x) => defaults.includes(x.id))
      }

      if (this.currentServiceRequest && this.currentServiceRequest.id > 0) {
        return this.definedTypes.serviceRequestType.options.filter((x) => defaults.includes(x.id))
      }

      defaults = [
        this.definedTypes.serviceRequestType.GetReady,
        this.definedTypes.serviceRequestType.AdditionalReconditioning
      ]
      return this.definedTypes.serviceRequestType.options.filter((x) => defaults.includes(x.id))
    },
    nonCancelledDeals: function () {
      return this.selectedInventory && this.selectedInventory.deals.length > 0 ? this.selectedInventory.deals.filter((x) => x.type !== 'Cancelled' && x.items.find((y) => y.forInventory.id === this.selectedInventory.id && y.dealItemType === 'Sold')) : []
    },
    deliveredOrSoldDeals: function () {
      return this.nonCancelledDeals.filter((x) => x.type === 'Booked' || x.type === 'Delivered')
    },
    hasDeliveredOrSoldDeals: function () {
      return this.deliveredOrSoldDeals.length > 0
    },
    addInspectionBtnTitle: function () {
      return this.hasDeliveredOrSoldDeals ? 'Add disabled because of a linked delivered/booked deal' : 'Click to add new inspection type.'
    },
    addWorkItemBtnTitle: function () {
      return this.hasDeliveredOrSoldDeals && !this.isAdmin ? 'Add disabled because of a linked delivered/booked deal' : 'Click to add new work item.'
    },
    isAddBtnDisabled: function () {
      return this.currentServiceRequest.requestStatusType === 'Complete' || (this.hasDeliveredOrSoldDeals && !this.isAdmin)
    },
    isAdmin () {
      return this.$store.getters['user/isInRole']('Admin Role')
    },
    isComplete: function () {
      return (this.currentServiceRequest && this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.currentServiceRequest.requestStatusTypeId)) && this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.currentServiceRequest.requestStatusTypeId).altName === 'Complete'
    }
  },
  methods: {
    lineTitleDescription: function (serviceRequestLine) {
      var lineDescription = this.$toTitleCase(serviceRequestLine.workRequired) + '\n\n'
      lineDescription += (serviceRequestLine.description || '') + '\n\n'
      lineDescription += 'Total Cost: ' + this.$formatCurrency(serviceRequestLine.totalCost) + '\n'
      if (this.isFinanceProductRequest) {
        lineDescription += 'Vendor Cost: ' + this.$formatCurrency(serviceRequestLine.vendorCost) + '\n'
      }
      lineDescription += 'Total Hours: ' + this.$formatCurrency(serviceRequestLine.totalHours) + '\n'
      lineDescription += 'Manufacturer Cost: ' + this.$formatCurrency(serviceRequestLine.manufacturerCost) + '\n'
      lineDescription += 'Manufacturer Hours: ' + this.$formatCurrency(serviceRequestLine.manufacturerHours) + '\n'
      lineDescription += 'Parts Cost: ' + this.$formatCurrency(serviceRequestLine.partsCost) + '\n'
      lineDescription += 'Labor Cost: ' + this.$formatCurrency(serviceRequestLine.labourCost) + '\n'
      lineDescription += 'Other Cost: ' + this.$formatCurrency(serviceRequestLine.otherCost) + '\n'
      return lineDescription
    },
    actionTitleDescription: function (action, serviceRequestLine) {
      var lineDescription = 'Click to ' + action + ' this work item. \n\n'
      lineDescription += 'Total Cost: ' + this.$formatCurrency(serviceRequestLine.totalCost) + '\n'
      if (this.isFinanceProductRequest) {
        lineDescription += 'Vendor Cost: ' + this.$formatCurrency(serviceRequestLine.vendorCost) + '\n'
      }
      lineDescription += 'Total Hours: ' + this.$formatCurrency(serviceRequestLine.totalHours) + '\n'
      lineDescription += 'Manufacturer Cost: ' + this.$formatCurrency(serviceRequestLine.manufacturerCost) + '\n'
      lineDescription += 'Manufacturer Hours: ' + this.$formatCurrency(serviceRequestLine.manufacturerHours) + '\n'
      lineDescription += 'Parts Cost: ' + this.$formatCurrency(serviceRequestLine.partsCost) + '\n'
      lineDescription += 'Labor Cost: ' + this.$formatCurrency(serviceRequestLine.labourCost) + '\n'
      lineDescription += 'Other Cost: ' + this.$formatCurrency(serviceRequestLine.otherCost) + '\n'
      return lineDescription
    },
    loadInspectionTypesCost: function (inventoryId, inspectionTypeId) {
      return new Promise((resolve, reject) => {
        inventoryService.getApplicableInspectionTypeCosts(inventoryId, inspectionTypeId).then(response => {
          resolve(response)
        })
          .catch(error => {
            reject(error.response.data)
          })
      })
    },
    loadDefaultInspectionTypes: function () {
      if (this.currentDealer.inspectionTypes && this.currentDealer.inspectionTypes.length > 0) {
        let isInventoryPpa = this.selectedInventory && this.selectedInventory.type === 'ForPpaAppraisal'
        this.currentDealer.inspectionTypes.forEach((y) => {
          if (isInventoryPpa && y.inspectionType !== 4) {
            return
          }

          var inspectionType = {
            id: 0,
            inspectionType: y.inspectionType,
            inspectionTypeAttachments: [],
            isActive: isInventoryPpa,
            status: 'Active'
          }

          this.defaultDealerInspectionTypes.push(inspectionType)
        })
      }
    },
    onCancel: function () {
      this.onComplete()
    },
    onComplete: function () {
      this.$router.push({name: this.lastActiveListPage !== '' ? this.lastActiveListPage : 'ListServiceRequests'})
    },
    convertInspectionType: function (id) {
      return this.definedTypes.inspectionTypes.find((x) => x.id === id).name
    },
    getColorClassByServiceRequestLineStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-danger'
          break
        case 1:
          result = 'has-text-danger'
          break
        case 2:
          result = 'has-text-danger'
          break
        case 3:
          result = 'has-text-success'
          break
        case 4:
          result = 'has-text-success'
          break
        case 5:
          result = 'has-text-danger'
          break
        case 6:
          result = 'has-text-danger'
          break
        case 7:
          result = 'has-text-success'
          break
        case 8:
          result = 'has-text-success'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    convertIdToStatusType: function (id) {
      return this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === +id)
    },
    addNewServiceRequestLine: function () {
      this.currentServiceRequestLineId = '0'
      this.mode = 1
      this.isWorkItemDefinition = true
    },
    onEditServiceRequestLine: function (serviceRequestLine) {
      if (!serviceRequestLine.id) {
        alert('This work line item was added as a result of the newly added inspection type. Please save your changes first before editing this work line item.')
      } else {
        this.currentServiceRequestLineId = serviceRequestLine.id
        this.mode = 1
        this.isWorkItemDefinition = true
      }
    },
    onCancelServiceRequestLine: function () {
      this.currentServiceRequestLineId = '0'
      this.mode = 0
      this.isWorkItemDefinition = false
    },
    onSaveServiceRequestLine: function (line, statusId) {
      this.currentServiceRequestLineId = '0'
      line.serviceRequestLineStatusId = statusId
      this.ensureIdDataTypeSafety(line.parts)
      this.currentServiceRequest.lines.push(line)
      this.mode = 0
      this.isWorkItemDefinition = false
      eventBus.$emit('reload-service-request-list')
    },
    onSaveEditServiceRequestLine: function (line, statusId, typeStatus) {
      var that = this
      if (this.currentServiceRequestLineId !== undefined) {
        this.currentServiceRequest.lines = this.$lodash.filter(this.currentServiceRequest.lines, function (current) {
          return current.id !== that.currentServiceRequestLineId
        })
        this.currentServiceRequestLineId = '0'
        this.ensureIdDataTypeSafety(line.parts)
        this.currentServiceRequest.lines.push(line)
        this.mode = 0
        this.isWorkItemDefinition = false
        eventBus.$emit('reload-service-request-list')
        return
      }
      if (line.inspectionTypeId !== undefined && line.inspectionTypeId > 0) {
        if (this.currentServiceRequest.lines.find((x) => x.inspectionTypeId === line.inspectionTypeId)) {
          this.mode = 0
          this.isWorkItemDefinition = false
          eventBus.$emit('reload-service-request-list')
          return
        }
      }

      line.serviceRequestLineStatusId = statusId
      let foundLine = this.currentServiceRequest.lines.find((x) => x.workRequired === line.workRequired)
      if (typeStatus !== 'Deleted' && foundLine === undefined) {
        this.ensureIdDataTypeSafety(line.parts)
        this.currentServiceRequest.lines.push(line)
        this.mode = 0
        this.isWorkItemDefinition = false
        eventBus.$emit('reload-service-request-list')
        return
      }
      this.ensureIdDataTypeSafety(line.parts)
      this.currentServiceRequest.lines.push(line)
      this.mode = 0
      this.isWorkItemDefinition = false
      eventBus.$emit('reload-service-request-list')
    },
    addNewServiceRequestInspectionType: function () {
      this.currentServiceRequestInspectionTypeId = '0'
      this.mode = 2
      this.isInspectionTypeDefinition = true
    },
    onEditServiceRequestInspectionType: function (item) {
      this.currentServiceRequestInspectionTypeId = item.id
      this.mode = 2
      this.isInspectionTypeDefinition = true
    },
    onCancelServiceRequestInspectionType: function () {
      this.currentServiceRequestInspectionTypeId = '0'
      this.mode = 0
      this.isInspectionTypeDefinition = false
    },
    onSaveServiceRequestInspectionType: function (inspectionType, typeId) {
      this.currentServiceRequestInspectionTypeId = '0'
      inspectionType.serviceRequestInspectionTypeId = typeId
      this.currentServiceRequest.inspectionTypes.push(inspectionType)
      this.mode = 0
      this.isInspectionTypeDefinition = false
    },
    onSaveEditServiceRequestInspectionType: function (inspectionType, typeId) {
      var that = this
      this.currentServiceRequest.inspectionTypes = this.$lodash.filter(this.currentServiceRequest.inspectionTypes, function (current) {
        return current.id !== that.currentServiceRequestInspectionTypeId
      })
      this.currentServiceRequestInspectionTypeId = '0'
      if (inspectionType.status === 'Active' || Number.isInteger(inspectionType.id)) {
        inspectionType.inspectionType = typeId
        this.currentServiceRequest.inspectionTypes.push(inspectionType)
      }
      this.mode = 0
      this.isInspectionTypeDefinition = false
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
        this.showMediaSave = true
        this.scrollToFileSync()
      }
    },
    fileUploadsComplete: function () {
      this.childComponentIsUploadingFile = false
    },
    fileUploadsStarted: function () {
      this.childComponentIsUploadingFile = true
    },
    showMyKaarmaApproval: function () {
      let myKaarmaPopup = window.open(this.currentServiceRequest.myKaarmaMpiUrl, '_mk_approval')
      if (this.myKaarmaPopupPollInterval) {
        clearInterval(this.myKaarmaPopupPollInterval)
      }
      this.myKaarmaPopupPollInterval = setInterval(() => {
        if (myKaarmaPopup.closed) {
          clearInterval(this.myKaarmaPopupPollInterval)
          this.onResyncMyKaarma()
        }
      }, 500)
    },
    onResyncMyKaarma: function () {
    },
    scrollToFileSync: function () {
      let that = this
      this.$nextTick(() => {
        setTimeout(function () {
          that.$goToBlock(that.$refs.saveMediaContainer, {
            behavior: 'smooth',
            block: 'end',
            inline: 'end'
          })
        }, 500)
      })
    },
    onSyncFiles: function () {
      this.isSavingFile = true
      var serviceRequestSyncAttachmentsModel = {
        serviceRequestId: this.currentServiceRequest.id,
        attachments: []
      }
      let existingFiles = this.requestAttachments.filter((y) => (y.id && y.id > 0))
      let newFiles = this.requestAttachments.filter((y) => (!y.id || y.id <= 0) && y.status !== 'Deleted').map((x) => {
        return {
          uploadName: x.name,
          uploadSize: x.size,
          uniqueId: x.uniqueId,
          type: x.type,
          status: x.status
        }
      })
      serviceRequestSyncAttachmentsModel.attachments.push(...existingFiles)

      let that = this
      if (newFiles.length > 0) {
        for (var newFileIndex = 0; newFileIndex < newFiles.length; newFileIndex++) {
          let uniqueId = newFiles[newFileIndex].uniqueId
          let classType = this.getServiceRequestClassTypeByFileType(uniqueId)

          var serviceRequestAttachmentModel = {
            serviceRequestId: this.currentServiceRequest.id,
            classType: classType,
            uploadName: newFiles[newFileIndex].uploadName,
            uploadSize: newFiles[newFileIndex].uploadSize,
            uniqueId: newFiles[newFileIndex].uniqueId,
            type: newFiles[newFileIndex].type
          }
          serviceRequestSyncAttachmentsModel.attachments.push(serviceRequestAttachmentModel)
        }
      }

      serviceRequestService.syncAttachments(serviceRequestSyncAttachmentsModel).then(response => {
        if (response.errors) {
          that.serverErrorMessage = response.message
          that.serverErrors = response.errors
          that.isSavingFile = false
        } else if (response.result === true) {
          that.isSavingFile = false
          that.reloadServiceRequest()
        } else {
          that.serverErrorMessage = 'An error occurred saving service request attachments. Please contact support'
        }
      }).catch((error) => {
        that.serverErrorMessage = error.message
        that.isSavingFile = false
      })
    },
    canShowRoStatusIcon: function (row) {
      return row.roId !== 0 && row.roStatus !== ''
    },
    getRoStatusClass: function (row) {
      if (row.roId !== 0 && row.roStatus !== '') {
        return row.roStatus === 'Closed' ? 'has-text-danger fa-do-not-enter' : 'has-text-success fa-car-mechanic'
      }
      return ''
    },
    getRoStatusTitle: function (row) {
      if (row.roId !== 0 && row.roStatus !== '') {
        return row.roStatus === 'Closed' ? 'RO service closed.' : 'RO service in progress.'
      }
      return ''
    }
  },
  watch: {
    requestAttachments: {
      handler (newValue, oldValue) {
        let comparison = _.isEqual(newValue, this.initialRequestAttachments)
        if (this.initialRequestAttachments && !comparison) {
          this.showMediaSave = true
          this.scrollToFileSync()
        } else {
          this.showMediaSave = false
        }
      },
      deep: true
    }
  },
  beforeDestroy () {
    eventBus.$off('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$off('file-uploads-started', this.fileUploadsStarted)
    if (this.myKaarmaPopupPollInterval) clearInterval(this.myKaarmaPopupPollInterval)
  },
  created: function () {
    eventBus.$on('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$on('file-uploads-started', this.fileUploadsStarted)
  }
}

export default mixin
